
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import dateAfterValidator from './date-after'
import dateNowAfterValidator from './date-now-after'
import dateBeforeValidator from './date-before'
import dateBetweenValidator from './date-between'
import dateNowBeforeValidator from './date-now-before'

export default (vue: any) => {
  for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
      ...validation
    })
  }

  // Registramos reglas personalizadas
  const customRules = [
    dateBeforeValidator,
    dateNowBeforeValidator,
    dateAfterValidator,
    dateNowAfterValidator,
    dateBetweenValidator
  ]

  for (const rule of customRules) {
    extend(rule.name, rule.definition)
  }

  localize({
    'es': {
      messages: es.messages,
      fields: {
        reminders: {
          required: 'Este campo es obligatorio',
          dateBetween: 'Los recordatorios deben establecerse entre las fechas de la medición'
        },
        customDate: {
          dateNowAfter: (field: any, placeholders: any) => {
            return placeholders.startDate
              ? `La fecha debe ser posterior (o igual) a ${placeholders.startDate}.`
              : `La fecha debe ser posterior (o igual) al día actual.`
          }
        }
      }
    },
    'en': {
      messages: en.messages,
      fields: {
        reminders: {
          required: 'This field is required',
          dateBetween: 'The reminders must be set between measurement dates'
        },
        customDate: {
          dateNowAfter: (field: any, placeholders: any) => {
            return placeholders.startDate
              ? `The date must be after (or equal to) the ${placeholders.startDate}.`
              : `The date must be after (or equal to) the current day.`
          }
        }
      }
    }
  })
  localize('es', es)

  vue.component('ValidationObserver', ValidationObserver)
  vue.component('ValidationProvider', ValidationProvider)
}
