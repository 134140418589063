
<template>
    <v-container flat>
      <v-card flat>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(changeStep)">
            <v-row>
              <v-col cols="12" class="pa-3 headline">
                {{ $t('evaluations.date') }}
                <v-divider></v-divider>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3">
                <x-date-picker
                  ref="delivered"
                  :rules="edit ? 'required' : 'required|dateNowAfter'"
                  v-model="evaluation.deliveredAt.value"
                  :readonly="evaluation.status != 'pending'"
                  @date-picked="evaluation.deliveredAt.value = $event"
                  :label="$t('evaluations.date_delivery')"
                  :append-outer-icon="$t('help.icon')"
                  :help="$t('help.evaluation.create.deliveredAt')"
                  name="customDate"
                ></x-date-picker>
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  :filled="evaluation.status!=='pending'"
                  v-bind:style="evaluation.status!=='pending' ? 'cursor: not-allowed;' : ''"
                  :items="hours"
                  persistent-hint
                  v-model="evaluation.deliveredAt.hour"
                  light
                  :label="$t('evaluations.hours')"
                  autofocus
                  name="hours"
                  :disabled="evaluation.status!=='pending'"
                  :data-vv-as="$t('evaluations.hours') | lowerCase"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="3">
                <x-date-picker
                  v-model="evaluation.validUntil.value"
                  @date-picked="evaluation.validUntil.value = $event"
                  :label="$t('evaluations.poll_valid_until')"
                  name="customDate"
                  :help="$t('help.evaluation.create.validUntil')"
                  ref="valid_until"
                  :rules="'required|dateNowAfter:@' + $t('evaluations.date_delivery')"
                ></x-date-picker>
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  :items="hours"
                  persistent-hint
                  v-model="evaluation.validUntil.hour"
                  light
                  :label="$t('evaluations.hours_valid_until')"
                  autofocus
                  name="hours_valid_until"
                  :data-vv-as="$t('evaluations.hours_valid_until') | lowerCase"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :filled="evaluation.status!=='pending'"
                  v-bind:style="evaluation.status!=='pending' ? 'cursor: not-allowed;' : ''"
                  persistent-hint
                  :items="timeZones"
                  v-model="evaluation.timeZone"
                  light
                  :label="$t('evaluations.time_zone')"
                  name="selection_type"
                  :data-vv-as="$t('evaluations.time_zone') | lowerCase"
                  :append-outer-icon="$t('help.icon')"
                  :disabled="evaluation.status!=='pending'"
                  @click:append-outer="$store.dispatch('help/display', $t('help.evaluation.create.timezone'))"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="reminder">
                <v-switch
                  v-model="evaluation.switchDate"
                  color="primary"
                  :label="$t('evaluations.want_send_reminders')"
                  :append-icon="$t('help.icon')"
                  @click:append="$store.dispatch('help/display', $t('help.evaluation.create.reminders'))"
                />
              </v-col>
            </v-row>

            <v-row
              align-center justify-start row fill-height
              v-bind:key="index" v-for="(reminder,index) in evaluation.reminders"
              v-show="evaluation.switchDate"
            >
              <v-col cols="12" sm="7" md="3" class="mr-2" >
                <x-date-picker
                  v-model="evaluation.reminders[index].value"
                  @date-picked="evaluation.reminders[index].value = $event"
                  :label="$t('evaluations.date')"
                  :rules="evaluation.switchDate ?
                    `required|dateBetween:${validateUntil.ref},${validateUntil.finish},${evaluation.reminders[index].hour}` : ''
                  "
                  name="reminders"
                  vid="input.the_reminders"
                  :reff="'child_picker' + index"
                  ref="parent_picker"
                ></x-date-picker>
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <x-inputs-autocomplete
                  :items="hours"
                  :input="evaluation.reminders[index].hour"
                  @updateInput="($event) => evaluation.reminders[index].hour = $event"
                  light
                  :label="$t('evaluations.hours')"
                  name="hours"
                  rules="required"
                  :bind-style="evaluation.status != 'pending' ? 'cursor: not-allowed;' : ''"
                  autofocus
                  :append-outer-icon="$t('help.icon')"
                  :help-message="$t('help.evaluation.create.timezone')"
                />
              </v-col>
              <!-- ADD/REMOVE REMINDERS -->
              <v-col cols="12" sm="12" md="5" v-if="evaluation.reminders.length == index + 1" class="pt-5">
                <v-btn outlined rounded
                  color="red"
                  class="mr-2"
                  @click="evaluation.status !== 'completed' ? deleteReminder(index) : {}"
                >
                  {{ $t('input.trash') }}
                </v-btn>

                <v-btn outlined rounded
                  color="green"
                  @click="evaluation.status !== 'completed' ? addReminder(index) : {}"
                >
                  {{ $t('input.add') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn
                  block
                  large
                  @click="changeStep(true)"
                >{{ $t('input.back') }}</v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn
                  color="primary"
                  block
                  large
                  type="submit"
                >{{ $t('input.next') }}</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-container>
</template>

<script>
import timeZoneService from '../../../services/time-zones'

export default {
  props: {
    evaluation: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      edit: Boolean(this.$route.params.slug),
      timeZones: [],
      hours: []
    }
  },
  computed: {
    validateUntil: function () {
      const { deliveredAt, validUntil } = this.evaluation
      return {
        ref: new Date(`${deliveredAt.value} ${deliveredAt.hour}:00`).getTime(),
        finish: new Date(`${validUntil.value} ${validUntil.hour}:00`).getTime()
      }
    }
  },
  methods: {
    validateReminders () {
      if (this.evaluation.reminders && this.evaluation.reminders.length > 0) {
        for (const rem of this.evaluation.reminders) {
          let remTime = new Date(`${rem.value} ${rem.hour}:00`).getTime()
          if (this.validateUntil.ref < remTime) {
            if (this.validateUntil.finish < remTime && rem.value !== '') {
              return false
            }
          } else if (rem.value !== '') {
            return false
          }
        }
      }
      return true
    },
    validateReminderDate (rem) {
      if (rem.value !== '') {
        let remTime = new Date(`${rem.value} ${rem.hour}:00`).getTime()
        if (this.validateUntil.ref > remTime || this.validateUntil.finish < remTime) {
          return [this.$t('evaluations.invalid_reminder_date')]
        }
      }
      return []
    },
    getOffsetObject () {
      const text = this.evaluation.timeZone
      const arr = []
      this.timeZones.forEach(element => {
        if (element.text === `${text}`) {
          arr.push(element)
        }
      })
      return arr
    },
    validTimeZone () {
      if (this.edit) {
        return true
      }
      var offsetObj = this.getOffsetObject()
      if (offsetObj.length < 1) {
        return false
      }
      var date = new Date()
      var utc = date.getTime() + (date.getTimezoneOffset() * 60000)
      var newDate = new Date(utc + (3600000 * (parseFloat(offsetObj[0].offset))))
      const dates = this.validateUntil
      if (dates) {
        return newDate.getTime() < dates.ref
      } else {
        return false
      }
    },
    changeStep (isBack = false) {
      if (isBack) {
        this.$emit('changeStep', this.evaluation, +this.step - 1)
        return
      }
      if (this.validTimeZone() && this.validateUntil.ref < this.validateUntil.finish && this.validateReminders()) {
        this.$emit('changeStep', this.evaluation, isBack ? +this.step - 1 : +this.step + 1)
      } else {
        this.$store.dispatch('alert/error', this.$t('errors.error_select_dates'))
      }
    },
    getHours () {
      for (let i = 0; i < 24; i++) {
        i < 10 ? this.hours.push('0' + i + ':00') : this.hours.push(i + ':00')
      }
    },
    addReminder (i) {
      this.$refs.parent_picker[i].$refs['child_picker' + i].validate()
        .then(res => {
          if (res.valid) {
            this.evaluation.reminders.length === 5
              ? this.$store.dispatch('alert/error', this.$t('errors.validator/reminders'))
              : this.evaluation.reminders.push({
                value: '',
                hour: '23:00'
              })
          }
        })
    },
    deleteReminder (index) {
      if (this.evaluation.reminders.length === 1) {
        this.$store.dispatch('alert/error', this.$t('errors.validator/delete_reminder'))
      } else {
        this.evaluation.reminders.splice(index, 1)
      }
    },
    getTimeZones () {
      return timeZoneService.list()
        .then((res) => {
          this.timeZones = res.items.map((item) => ({
            value: item.text,
            text: item.text,
            offset: item.offset
          }))
        })
    }
  },
  created () {
    this.getTimeZones()
    this.getHours()
  }
}
</script>

<style>
  .reminder div.v-input__control {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    width: auto;
  }
</style>
