
export default {
  'apology': 'We\'re sorry!',
  'not_found': 'The page you are looking for has not been found.',
  'forbidden': 'You are not authorized to access this information.',
  'back_home': 'Back to top',
  'auth/invalid-credentials': 'The data entered is incorrect.',
  // tslint:disable-next-line:max-line-length
  'auth/user-is-disabled': 'The entered user is disabled.<br/>Please contact the administrator.',
  'auth/employee-no-exist': 'Employee not found.',
  'auth/demo-request-exist': 'There is already a demo account request with the requested email.',
  'validator/verify_fields': 'Please check the form fields.',
  'employees/enterprise-is-not-active': 'This company is not active.',
  'validator/invalid-input': 'One or more fields are invalid.',
  'validator/academicDegreeId-invalid-input': 'The education level field is required for the demographic cut.',
  'validator/countryId-invalid-input': 'The country field is required for the demographic cut.',
  'validator/headquarterId-invalid-input': 'The location field is required for the demographic cut.',
  'validator/chargeId-invalid-input': 'The job title field is required for the demographic cut.',
  'validator/departmentId-invalid-input': 'The area/department field is required for the demographic cut.',
  'validator/genderId-invalid-input': 'The gender field is required for the demographic cut.',
  'validator/jobTypeId-invalid-input': 'The type of contract field is required for the demographic cut.',
  'validator/birthdate-invalid-input': 'The date of birth field is required for demographic cut.',
  'validator/admission-invalid-input': 'The start date field is required for the demographic cut.',
  // tslint:disable-next-line:max-line-length
  'employees/identify-document-not-in-headers': 'The header for the identification document is not in the file.',
  'employees/missing-required-headers-in-csv': 'The file must have all the required headers.',
  'employees/empty-fields': 'All fields of the form must be filled.',
  'employees/employee-already-exists': 'This employee already exists.',
  'employees/employee-already-exists-another-enterprise': 'This employee already exists in another company.',
  'employees/employee-already-active-another-enterprise': 'This employee is already listed as active in another company.',
  'email/unique:User,email,user_id': 'The specified email is already in use.',
  'validator/reminders': 'You can only select five reminders.',
  'validator/delete_reminder': 'You cannot delete this reminder.',
  'engagements/token-not-found': 'The supplied token could not be found.',
  // tslint:disable-next-line:max-line-length
  'engagements/token-poll-completed': 'This assessment has already been completed.<br/><br/>Remember that once the assessment is completed, it is not possible to access it again.',
  'engagements/poll-not-found': 'The requested assessment does not exist.<br/><br/>Check your access token.',
  // tslint:disable-next-line:max-line-length
  'engagements/poll-has-ended': 'The requested assessment has ended.<br/><br/>Once the end date is reached, you will not be able to access it.',
  // tslint:disable-next-line:max-line-length
  'engagements/poll-employee-not-found': 'The assessment you are trying to access does not exist.<br/><br/>Please check your participation and try again.',
  'engagements/missing-questions': 'All questions must be answered.',
  'engagements/missing-extra-questions': 'All additional questions must be answered.',
  'pulses/token-not-found': 'The supplied token could not be found.',
  // tslint:disable-next-line:max-line-length
  'pulses/token-poll-completed': 'This assessment has already been completed.<br/><br/>Remember that, once the assessment is completed, it is not possible to access it again.',
  'pulses/poll-not-found': 'The requested assessment does not exist.<br/><br/>Check your access token.',
  // tslint:disable-next-line:max-line-length
  'pulses/poll-has-ended': 'The requested assessment has ended.<br/><br/>Once the end date is reached, you will not be able to access the assessment.',
  // tslint:disable-next-line:max-line-length
  'pulses/poll-employee-not-found': 'The assessment you are trying to access does not exist.<br/><br/>Please check your participation and try again.',
  'pulses/missing-extra-questions': 'All additional questions must be answered.',
  'undefined': 'A system error has occurred.',
  'timezone': 'The selected time is not valid for that time zone.',
  'question_empty': 'The question cannot be empty.',
  'validator/select_one': 'Please select at least one language.',
  'validator/empty-field': 'The field cannot be empty.',
  'validator/no_receivers': 'The assessment must have at least one respondent.',
  'image/size': 'Image size must be less than 2MB.',
  'employees_limit': 'You have already reached the limit of collaborators allowed by your license.',
  'engagements_limit': 'You have already reached the limit of Engagement allowed by your license.',
  'pulses_limit': 'You have already reached the limit of Pulses allowed by your license.',
  'modal_identify_type': 'Please select a valid identity type.',
  'modal_identify_document': 'Please enter a valid identification number.',
  'modal_first_name': 'Please enter a valid name.',
  'modal_last_name': 'Please enter a valid last name.',
  'modal_exists_email': 'The email entered already exists.',
  'modal_user_not_employee': 'The email entered, exists as another type of user',
  'modal_exist_identification': 'The ID number entered already exists.',
  'modal_email': 'Enter a valid email address.',
  'modal_gender': 'Please select a valid gender.',
  'modal_antiquity_range': 'Please enter a valid start date.',
  'modal_department': 'Please select a valid department.',
  'modal_charge': 'Select a valid job title.',
  'modal_job_type': 'Please select a valid contract type.',
  'modal_age_range': 'Please enter a valid date of birth.',
  'modal_country': 'Please select a valid country.',
  'modal_headquarter': 'Please select a valid location.',
  'modal_academic_degree': 'Select a valid education level.',
  'modal_validation_error': 'There are records to be corrected.',
  'email_already_taken': 'The email entered is already in use.',
  'groups/updated-plan-some': 'The change was not applied to all companies.',
  'groups/updated-plan-none': 'The change was not applied due to an error.',
  'video/size': 'The size of the video must be less than 20MB.',
  'error_disable_questionnaires': 'Not all questionnaires can be deactivated',
  'auth/enterprise_disabled': 'The requested company is inactive',
  'auth/employee_disabled': 'The requested employee is inactive',
  'auth/password-not-match': 'The current password is incorrect',
  'auth/email-not-match': 'The current email is incorrect',
  'auth/email-already-registered': 'The new email is already assigned to another user',
  'required_enterprise_p1': 'To continue you must select a company.',
  'required_enterprise_p2': 'You will be redirected to Suite shortly.',
  'error_select_dates': 'You must select a valid time range.',
  'no_balance': 'Insufficient balance',
  'no_balance_msg': 'Company with insufficient balance. If you continue the process, the system will try to consume from authorized balances.',
  'invalid_service': 'Invalid service',
  'invalid_evaluation': 'Invalid Assessment',
  'demographic_report/001': 'You have no tokens available',
  'demographic_report/002': 'ID is required',
  'demographic_report/003': 'It must have at least one demographic cut',
  'demographic_report/004': 'No results found for the current assessment',
  'demographic_report/005': 'Internal error when consigning the report'
}
