
export default {
  insert_document: 'Enter your ID number',
  verify: 'Check assessments',
  polls_list: 'Available assessments',
  pending_polls_header: [
    { text: 'Name', value: '', sortable: false, class: 'text-xs-left' },
    { text: 'Type', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Ends at', value: '', sortable: false, class: 'text-xs-center' },
    { text: 'Actions', value: '', sortable: false, class: 'text-xs-center' }
  ],
  fill_poll: 'Take assessment',
  back_to_verify_polls: 'Back to assessment verification'
}
