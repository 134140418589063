<template>
  <v-dialog v-model="show" width="70em" persistent>
    <v-card>
      <v-card-title
        class="headline white--text primary lighten-2"
        style="padding: 1em"
        primary-title
      >
        {{ editEvaluated ? $t('evaluations.edit_evaluators') : $t('evaluations.add_evaluated') }}
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12">
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(pushEvaluator)">
                <v-row>
                <!-- <v-row v-for="(ev, n) in evaluated" :key="n"> -->
                  <v-col cols="12" align="center">
                    <h2 class="mt-3">
                      {{ $t('evaluations.evaluate') }}
                    </h2>
                  </v-col>
                  <v-col cols="12">
                    <x-inputs-autocomplete
                      :items="filterLeaders()"
                      :input="evaluated.employee.employee"
                      @updateInput="($event) => evaluated.employee.employee = $event"
                      light
                      :label="$t('evaluations.evaluate')"
                      name="evaluated"
                      rules="required"
                      autofocus
                      return-object
                      :disabled="!!editEvaluated"
                    />
                  </v-col>
                  <v-col cols="12" align="center">
                    <h2>
                      {{ $t('evaluations.evaluators') }}
                    </h2>
                  </v-col>
                  <v-col cols="4">
                    <x-inputs-autocomplete
                      :items="filterEvaluators('leadersEvaluators')"
                      :input="leadersEvaluators"
                      @updateInput="($event) => setEvaluators(evaluated, 'leadersEvaluators', $event)"
                      light
                      :label="$t('evaluations.leaders')"
                      name="leaders"
                      multiple chips
                      return-object
                    />
                  </v-col>
                  <v-col cols="4">
                    <x-inputs-autocomplete
                      :items="filterEvaluators('pairsEvaluators')"
                      :input="pairsEvaluators"
                      @updateInput="($event) => setEvaluators(evaluated, 'pairsEvaluators', $event)"
                      light
                      :label="$t('evaluations.pairs')"
                      name="pairs"
                      multiple chips
                      return-object
                    />
                  </v-col>
                  <v-col cols="4">
                    <x-inputs-autocomplete
                      :items="filterEvaluators('dependentsEvaluators')"
                      :input="dependentsEvaluators"
                      @updateInput="($event) => setEvaluators(evaluated, 'dependentsEvaluators', $event)"
                      light
                      :label="$t('evaluations.dependents')"
                      name="dependents"
                      multiple chips
                      return-object
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-btn block large
                      @click="closeDialog"
                    >
                      {{ $t('input.cancel') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-btn block large
                      color="primary"
                      type="submit"
                    >
                      {{ $t('input.add') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  props: {
    employees: Array,
    evaluation: Object,
    editEvaluated: {
      type: Object,
      default: () => (null)
    }
  },
  data () {
    return {
      show: true,
      evaluated: {
        employee: {
          employee: null
        },
        leadersEvaluators: [],
        dependentsEvaluators: [],
        pairsEvaluators: []
      }
    }
  },
  methods: {
    init () {
      if (this.editEvaluated) {
        ['leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators'].forEach((key) => {
          this.evaluated[key] = this.editEvaluated[key].map(this.employeeMapper)
        })
        this.evaluated.employee.employee = this.employeeMapper(this.editEvaluated.employee.employee)
      }
    },
    employeeMapper (emp) {
      if (emp.text) {
        return emp
      } else {
        return {
          text: `${emp.firstName} ${emp.lastName}`,
          value: emp.id,
          ...emp
        }
      }
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    pushEvaluator () {
      this.$emit('pushEvaluator', this.evaluated)
    },
    filterLeaders () {
      if (this.editEvaluated) {
        return [this.editEvaluated.employee.employee]
      } else {
        const evaluators = this.evaluated.leadersEvaluators.concat(this.evaluated.dependentsEvaluators).concat(this.evaluated.pairsEvaluators)
        return this.employees.filter(employee => {
          let valid = true
          if (this.evaluation.evaluated.length) {
            valid = !this.evaluation.evaluated.find(evaluated => {
              return evaluated !== this.evaluated && evaluated.employee.employee.id === employee.id
            })
          }
          if (valid) {
            if (evaluators.length) {
              valid = !evaluators.find(evaluator => evaluator.id === employee.id)
            }
          }
          return valid
        })
      }
    },
    filterEvaluators (key) {
      const keys = ['leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators'].filter((k) => k !== key)
      const cloneItem = { ...this.evaluated }
      const employees = []
      keys.forEach((k) => {
        employees.push(...cloneItem[k])
      })
      return this.employees.filter(employee => {
        let valid = true
        if (this.evaluated.employee.employee) {
          valid = this.evaluated.employee.employee.id !== employee.id
        }
        if (valid && employees.length) {
          valid = !employees.find(empl => empl.id === employee.id)
        }
        return valid
      })
    },
    getEvaluators (evaluated, key) {
      return evaluated[key]
    },
    setEvaluators (evaluated, key, list) {
      evaluated[key] = list
    }
  },
  computed: {
    leadersEvaluators () {
      return this.getEvaluators(this.evaluated, 'leadersEvaluators')
    },
    pairsEvaluators () {
      return this.getEvaluators(this.evaluated, 'pairsEvaluators')
    },
    dependentsEvaluators () {
      return this.getEvaluators(this.evaluated, 'dependentsEvaluators')
    }
  },
  mounted () {
    this.init()
  }
})
</script>
