
import evaluationService from '../../../services/evaluations'

import PDFUtil from '../utils/pdf'

export default {
  methods: {
    calculatePages () {
      const results = this.answersResponsibility.general
      const gralKeys = Object.keys(results)
      let endResults = gralKeys.length <= 10 ? 9 : 10
      for (const general of gralKeys) {
        endResults++
        const specifics = results[general].specific
        if (Object.keys(specifics).length > 8) {
          endResults++
        }
      }
      return [
        { text: this.$t('organizationalReport.background_1'), sta: 2, end: 2, color: '#BFBFBF' },
        { text: this.$t('organizationalReport.background_2'), sta: 3, end: 4, color: '#BFBFBF' },
        { text: this.$t('organizationalReport.background_2'), sta: 5, end: 8, color: '#BFBFBF' },
        { text: this.$t('organizationalReport.background_2'), sta: 9, end: endResults, color: '#BFBFBF' },
        { text: this.$t('organizationalReport.background_3'), sta: endResults + 1, end: endResults + 2, color: '#BFBFBF' },
        { text: this.$t('organizationalReport.background_4'), sta: endResults + 3, end: endResults + 3, color: '#BFBFBF' }
      ]
    },
    writeRotatedText (text, color, isFO = false) {
      const canvas = document.createElement('canvas')
      canvas.width = 40
      canvas.height = 842
      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = color
      ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(40, 650)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '17pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), isFO ? 190 : 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    generateFooter (pageNum) {
      const footerContent = []
      const maxFooterLogoWidth = 128
      let footerLogoHeight = 40
      let footerLogoWidth = footerLogoHeight
      let topMargin = -4
      const baseMarginDiff = footerLogoHeight + topMargin
      if (this.enterpriseLogo) {
        footerLogoWidth = footerLogoWidth * this.coverLogoRatio
        if (footerLogoWidth > maxFooterLogoWidth) {
          footerLogoWidth = maxFooterLogoWidth
          footerLogoHeight = maxFooterLogoWidth / this.coverLogoRatio
          topMargin = baseMarginDiff - footerLogoHeight
        }
        footerContent.push({
          columns: [
            {
              image: this.enterpriseLogo || this.cultureCoverBase64,
              fit: [footerLogoWidth, footerLogoHeight],
              margin: [14, topMargin, 0, 0]
            },
            {
              margin: [0, 10, 0, 0],
              width: 'auto',
              text: this.$t('individualReport.copyright'),
              fontSize: 10
            },
            {
              margin: [0, 24, 60, 0],
              text: pageNum.toString(),
              alignment: 'right',
              color: 'grey'
            }
          ]
        })
      } else {
        footerContent.push({
          columns: PDFUtil.generateCenteredText(this.$t('individualReport.copyright'), 10),
          color: 'grey'
        })
        footerContent.push({
          margin: [0, 0, 60, 0],
          text: pageNum.toString(),
          alignment: 'right',
          color: 'grey'
        })
      }
      return footerContent
    },
    async $getInitialData () {
      await evaluationService.getOneReportByThreadId(this.thread._id, this.pollId)
        .then((res) => {
          if (res.enterpriseLogo) {
            this.enterpriseLogo = `data:image/png;base64,${res.enterpriseLogo}`
          }
          let data = res.thread.data
          this.reportLang = data.lang
          this.questionnaire = data.questionnaire
          this.evaluation = data.evaluation
          this.expectedPolls = {
            auto: data.evaluated,
            leaders: data.totalAnswersByrelation.leadersEvaluators,
            pairs: data.totalAnswersByrelation.pairsEvaluators,
            dependents: data.totalAnswersByrelation.dependentsEvaluators
          }
          this.completedPolls = {
            auto: data.evaluatedCompleted,
            leaders: data.totalAnswersByrelationCompleted.leadersEvaluators,
            pairs: data.totalAnswersByrelationCompleted.pairsEvaluators,
            dependents: data.totalAnswersByrelationCompleted.dependentsEvaluators
          }
          this.hasPrevious = data.hasPrevious

          // GENERAL SCORES (Highest/Lowest)
          // Others
          this.highestScoreGeneralEvaluatorsItems = data.highestScoreGeneralEvaluatorsItems
          this.lowestScoreGeneralEvaluatorsItems = data.lowestScoreGeneralEvaluatorsItems
          // Leaders
          this.highestScoreGeneralLeadersEvaluatorsItems = data.highestScoreGeneralLeadersEvaluatorsItems
          this.lowestScoreGeneralLeadersEvaluatorsItems = data.lowestScoreGeneralLeadersEvaluatorsItems
          // Pairs
          this.highestScoreGeneralPairsEvaluatorsItems = data.highestScoreGeneralPairsEvaluatorsItems
          this.lowestScoreGeneralPairsEvaluatorsItems = data.lowestScoreGeneralPairsEvaluatorsItems
          // Dependants
          this.highestScoreGeneralDependentsEvaluatorsItems = data.highestScoreGeneralDependentsEvaluatorsItems
          this.lowestScoreGeneralDependentsEvaluatorsItems = data.lowestScoreGeneralDependentsEvaluatorsItems

          // SPECIFIC SCORES (Highest/Lowest)
          // Others
          this.highestScoreSpecificEvaluatorsItems = data.highestScoreSpecificEvaluatorsItems
          this.lowestScoreSpecificEvaluatorsItems = data.lowestScoreSpecificEvaluatorsItems
          // Leaders
          this.highestScoreSpecificLeadersEvaluatorsItems = data.highestScoreSpecificLeadersEvaluatorsItems
          this.lowestScoreSpecificLeadersEvaluatorsItems = data.lowestScoreSpecificLeadersEvaluatorsItems
          // Pairs
          this.highestScoreSpecificPairsEvaluatorsItems = data.highestScoreSpecificPairsEvaluatorsItems
          this.lowestScoreSpecificPairsEvaluatorsItems = data.lowestScoreSpecificPairsEvaluatorsItems
          // Dependants
          this.highestScoreSpecificDependentsEvaluatorsItems = data.highestScoreSpecificDependentsEvaluatorsItems
          this.lowestScoreSpecificDependentsEvaluatorsItems = data.lowestScoreSpecificDependentsEvaluatorsItems

          //  RESULTS
          this.answersResponsibility = data.answersResponsibility

          // SCATTER
          this.highestScoreScatterGeneral = data.highestScoreScatterGeneral
          this.lowestScoreScatterGeneral = data.lowestScoreScatterGeneral
          this.highestScoreScatterSpecific = data.highestScoreScatterSpecific
          this.lowestScoreScatterSpecific = data.lowestScoreScatterSpecific

          // TREND
          this.positiveTrends = data.positiveTrends
          this.negativeTrends = data.negativeTrends

          // GAPS
          this.positiveGaps = data.positiveGaps
          this.negativeGaps = data.negativeGaps
        })
        .catch((err) => {
          console.log(err)
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    async $getConfiguration () {
      return {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 50, 50],
        info: {
          title: this.$t('organizationalReport.title'),
          author: 'OCC Solutions',
          subject: this.$t('organizationalReport.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2,
          margin: [0, 25, 0, 0]
        },
        header: (currentPage, pageSize) => {
          let headerLogoHeight = 49
          let headerLogoWidth = 128
          let topMargin = 6
          if (this.enterpriseLogo) {
            const tmpWdth = headerLogoHeight * this.coverLogoRatio
            if (tmpWdth > headerLogoWidth) {
              headerLogoHeight = headerLogoWidth / this.coverLogoRatio
              if (this.coverLogoRatio > (headerLogoWidth / headerLogoHeight)) {
                topMargin += headerLogoHeight / 2
              }
            } else {
              headerLogoWidth = tmpWdth
            }
          }
          const resultObj = {
            image: this.enterpriseLogo || this.cultureCoverBase64,
            width: headerLogoWidth,
            height: headerLogoHeight,
            margin: [15, topMargin, 25, 15]
          }
          if (currentPage === 1) return [{}]
          return [resultObj]
        },
        footer: (currentPage) => {
          return this.generateFooter(currentPage)
        },
        background: (currentPage) => {
          const temp = this.calculatePages()
          const pageCont = temp.find(t => t.sta <= currentPage && t.end >= currentPage)

          if (!pageCont) return {}

          const result = {
            image: this.writeRotatedText(pageCont.text, pageCont.color),
            aligment: 'center',
            absolutePosition: { x: 810, y: 0 }
          }

          return result
        },
        content: [
          // Cover
          this.$generateCover(),
          // TOC
          this.$generateTableOfContents(),
          // Scores
          this.$generateScores(),
          // General Results
          this.$generateResultsGral(),
          // Specific Results
          this.$generateResultsSpec(),
          // Scatter
          this.$generateScatter(),
          // Trend
          this.$generateTrend()
        ]
      }
    }
  }
}
