
export default {
  dashboard: 'Tableros',
  enterprises: 'Empresas',
  employees: 'Colaboradores',
  engagements: 'Engagement',
  questionnairesEngagement: 'Cuestionario Engagement',
  questionnairesPulse: 'Cuestionario Pulse',
  engagement: 'Engagement',
  pulse: 'Pulse',
  setting: 'Configuración',
  academic_degrees: 'Niveles académicos',
  languages: 'Idiomas',
  departments: 'Áreas / Departamentos',
  countries: 'Países',
  demographic_cuts: 'Cortes demográficos',
  enterprise_sizes: 'Tamaños de empresa',
  genders: 'Géneros',
  job_types: 'Tipos de contratación',
  sectors: 'Sectores',
  committalQuestions: 'Preguntas de compromiso',
  wordQuestions: 'Preguntas abiertas',
  group: 'Grupo',
  headquarters: 'Sedes',
  charges: 'Niveles de cargos',
  ages: 'Rango de edad',
  antiquity: 'Rango de antigüedad',
  sign_out: 'Cerrar sesión',
  token: 'OCC Tokens',
  profile: 'Perfil',
  go_back: 'Volver',
  language: 'Lenguaje',
  calculator: 'Calculadora',
  operations: 'Resumen de Operaciones',
  services: 'Precios y recomendaciones',
  enterprises_list: 'Empresas',
  profile_list: 'Perfil',
  measuring_tools: 'Herramientas',
  customers: 'Clientes',
  terms_and_conditions: 'Términos y condiciones',
  products: 'Productos',
  token_price: 'Precio de Tokens',
  questionnaires: 'Cuestionarios',
  evaluations: 'Valoraciones',
  open_questions: 'Preguntas abiertas',
  suite: 'Suite',
  conceptual_framework: 'Marco conceptual'
}
