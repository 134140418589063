
<template>
  <v-row>
    <v-col>
      <v-card>
        <v-toolbar dense class="mb-3 white--text" color="primary">
          <v-toolbar-title class="center--text">
            {{ $t('dashboard.evaluations_resume') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row v-if="info.length" justify="center">
            <v-col xs="12" sm="6" v-for="(evaluation, k) in info" :key="k">
              <v-card>
                <v-toolbar dense>
                  <v-toolbar-title>
                    {{evaluation.displayName || evaluation.name}}
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col xs="12" sm="6">
                      {{$t('evaluations.evaluated')}}: <b>{{ evaluation.evaluated.length }}</b>
                    </v-col>
                    <v-col xs="12" sm="6">
                      {{$t('evaluations.status')}}:
                      <v-chip
                        color="success"
                        outlined
                        v-if="evaluation.status === 'completed'"
                      >
                        {{$t(`evaluations.${evaluation.status}`)}}
                      </v-chip>
                      <v-chip
                        color="primary"
                        outlined
                        v-else-if="evaluation.status === 'in_progress'"
                      >
                        {{$t(`evaluations.${evaluation.status}`)}}
                      </v-chip>
                      <v-chip
                        color="warning"
                        outlined
                        v-else-if="evaluation.status === 'pending'"
                      >
                        {{$t(`evaluations.${evaluation.status}`)}}
                      </v-chip>
                      <v-chip
                        color="error"
                        outlined
                        v-else
                      >
                        {{$t(`evaluations.${evaluation.status}`)}}
                      </v-chip>
                    </v-col>
                    <v-col xs="12" sm="6">
                      {{$t('evaluations.start_at')}}: <b>{{evaluation.deliveredAt | date}}</b>
                    </v-col>
                    <v-col xs="12" sm="6">
                      {{$t('evaluations.end_at')}}: <b>{{evaluation.validUntil | date}}</b>
                    </v-col>
                    <v-col cols="12" align="center" v-if="evaluation.evaluated.length">
                      <v-btn
                        color="primary"
                        outlined
                        class="text-capitalize"
                        :to="`/evaluations/${evaluation.slug}/details`"
                      >
                        {{ $t('dashboard.see_details') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="center" v-else>
            <v-col cols="12" align="center">
              <h1 class="headline">
                {{ $t('dashboard.no_created_evaluations') }}
              </h1>
            </v-col>
            <v-col cols="12" align="center">
              <v-btn
                large
                color="primary"
                to="/evaluations/create"
                outlined
              >
                {{ $t('dashboard.go_create_evaluation') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  name: 'DashboardEnterprise',
  props: {
    info: Array,
    user: Object
  },
  data () {
    return {

    }
  }
})
</script>

<style lang="css" scoped>
.text-center{
  text-align: center !important;
}
.info-data{
  font-size: 3em;
}
.minipercentage{
  font-size: 2em !important;
}
.padding-left-name{
  padding-left: .4em !important;
}
.expired-plan{
  color: red;
  font-weight: bold;
}
.padding-right-name{
  padding-right: .4em !important;
}
.text-success{
  color: #4caf50 !important
}
.text-error{
  color: #ff5252 !important
}
.text-progress{
  color: #ad7816 !important;
}
.no-polls{
  font-size: 1.8em;
  color: lightgray;
}
.padding-card{
  padding: 3em;
}
.no-color{
  color: #ffffff00;
}
.pulse {
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(56, 153, 218);
  animation: pulse 2s infinite;
}

.no-decoration{
  text-decoration: none;
}

.survey-title{
  font-size: 1.2em;
  font-weight: bold;
}
</style>
