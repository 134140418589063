
export default {
  index_title: 'Report content',
  index1: [
    '1. Why is Leadership important?',
    '2. Why a 360° Leadership assessment?',
    '3. What is the 360° Leadership® Model like?'
  ],
  index2: [
    { name: 'DIRECTION', index: 1 },
    { name: 'MOBILIZATION', index: 2 },
    { name: 'ACHIEVEMENT', index: 3 }
  ],
  index3: [
    '4. What is the assessment tool like?',
    '5. How to interpret the report?',
    '6. Results Leadership Assessment',
    '6.1. Highest scores',
    '6.2. Lowest scores',
    '6.3. Results by Responsibility',
    '6.4. Results by Behavior',
    '6.5. Strengths and opportunity for improvement, confirmed and unknown',
    '6.6. Gap analysis',
    '6.7. Scatter analysis',
    '6.8. Trend Ranking',
    '6.9. Consolidation of open questions'
  ],
  for_what_leadership_important: '1. Why is Leadership important?',
  for_what_leadership_important_texts: [
    'In today\'s world, where change is permanent and its speed is dizzying, the role of organizations, understood as dynamic systems, is to anticipate change. If such forecasting is not possible, at least they must continuously adapt to new realities. Its leaders must be active agents, responsible for managing such changes, adapting, monitoring and permanently executing strategies that guarantee the organization\'s competitiveness in a sustainable manner.',
    'Ricardo Matamala Señor, consulting partner and founder of the firms OCC Solutions and Leadership & Transformation, defines Leadership as the art of inspiring, mobilizing and directing all the intelligence, emotions and energy of a team, to ensure the achievement of common purposes and objectives. Similarly, Ronald A. Heifetz, Professor of Leadership at Harvard University, defines it as a "mobilization process in which we all help each other, to face an uncertain reality and develop new capacities that allow us to successfully face adaptive challenges.',
    'In light of these definitions, Leadership must be understood as a dynamic and constantly changing process, which far from depending on a single person or leader, involves a group of interrelated people who, after coordinating and aligning their respective jobs, achieve outstanding results. To this extent, we could affirm that Leadership is a collaborative process to achieve common dreams, purposes and goals. Consequently, this process entails the ability of people to take initiative, create and consolidate teams, promote, encourage and inspire other human beings.',
    'Likewise, attracting, developing and keeping the best talent becomes one of the key functions of a leader, who will be responsible for facilitating the development of people and directing them to be their best version for the world. From the above, the great importance of Leadership in the development of teams and organizations becomes evident. It is the leaders who, together with their work team, transform a certain vision of reality, mobilizing and directing all the intelligence, emotions and collective energy, to obtain sustainable and outstanding achievements.',
    'However, each of the competencies of leaders can be developed and adopted, as long as there is an authentic process of awareness and a real and legitimate desire for change and development. That is to say, Leadership can be developed, once there is sufficient information about the impact that our actions have on other people and our own self-assessment. In this way, having identified our strengths and areas for improvement, it is possible to structure a focused and pertinent action plan. The 360° Leadership model (Management, Mobilization and Achievement) started from the previous premises to develop it at a conceptual level, so it includes the skills that a high-performance leader must have in today\'s world.'
  ],
  for_what_360_leadership_measure_title: '2. Why a 360° leadership assessment?',
  for_what_360_leadership_measure: [
    'A 360° assessment reflects the perceptions of people and serves as the basis for in-depth work to develop the skills of leaders. Due to its nature, it cannot be said that it is an exact reflection of reality, but it does help to understand the how leaders behave in the organization and the impact they have on others.',
    'This report shows the results of your 360° Leadership assessment. Your openness and attitude during the reading of this report will be fundamental to analyze the results and the effectiveness of the process. Here are the most important recommendations:'
  ],
  preparation: [
    '1. PREPARATION: ',
    'Allow a reasonable amount of time to analyze the results, make sure to review the entire report thoroughly and carefully, before starting to prioritize solutions and define concrete actions.'
  ],
  commitment: [
    '2. COMMITMENT: ',
    'Define a first version of the development plan using the suggested formats.'
  ],
  feedback: [
    '3. FEEDBACK: ',
    'Once the action plan has been defined and the final version is finished, it is suggested to present it in three conversations, with the leader, with peers and with collaborators, where they are thanked for their time and frankness. The development plan is presented here, too.'
  ],
  follow_up: [
    '4. FOLLOW-UP: ',
    'The key to success is in the follow-up of the actions. We also recommend performing this assessment in six-month cycles to streamline the development process and observe trends. In case of performing the assessment frequently, it is very important to analyze the trend report.'
  ],
  for_what_360_leadership_measure2: 'Feedback is a gift. The leader who does not know his/her impact is doomed to failure. This information is of high value and a pretext to open spaces for transparent and constructive conversation.',
  what_is_leadership_model: '3. What is the 360° Leadership Model like?',
  what_is_leadership_model_text: 'The 360° Leadership model is made up of three Processes: Management, Mobilization and Achievement, which, in turn, entail five responsibilities respectively.',
  title: 'Results report',
  subtitle: 'Individual Leadership Report',
  direction: 'Management',
  direction_texts: [
    'A leader is an authentic and upright person who, through their consistent actions, shows the team what is correct and suitable, both with their personal principles and with those of the organization.',
    'It is they, who with their example, guide the organizational culture and the behavior of the other members of the work team through the observable experience of a set of values. The leader creates a scenario of accompaniment to ensure the successful development of the culture that the organization requires, when executing strategies and achieving objectives.',
    'The leaders permanently adjust such strategies to the new realities of the environment and ensure everyone\'s understanding and commitment to the goals and plans; they mobilize the strategic thinking of the team through regular conversations and the creation of spaces that promote collective intelligence. Finally, they help everyone internalize their personal purpose in the face of their assigned work and lead their personal and professional life in a balanced manner. For this reason, leaders do not exhaust their impact in the professional sphere, they focus their efforts on the integral growth of the human being in all aspects of life.',
    'Consequently, business leadership must start from the identification of a purpose that, for the leader and the team, can be translated into an inspiring vision, a transcendent mission and challenging objectives. Hence, their main responsibilities are as follows:',
    '√ Agree with the team on the vision, mission and objectives.\n√ Live the values and lead the culture.\n√ Build strategies together with the team collectively and permanently.\n√ Inspire and lead by example. \n√ Promote personal leadership.'
  ],
  mobilization: 'Mobilization',
  mobilization_texts: [
    'Leaders have a responsibility to inspire others, to create a sense of belonging, pride and desire to commit and give their best for a higher purpose. Mobilizing refers mainly to the effective management of change, innovation and empowerment of the commitment and skills of each of the team members, as a vital aspect of the organization\'s competitiveness and sustainability.',
    'For this reason, the leader recognizes the need to create and maintain relationships of trust, based on effective two-way communication: one in which listening, asking questions and sharing information are essential elements to lead, motivate people and ensure a successful performance. Mobilizing talent requires empathy, accompaniment, closeness and, in general, good relationships.',
    'Similarly, leaders recognize the importance of trusting their team. Delegating and empowering people, assigning and balancing responsibilities both individually and collectively, is part of the daily dynamics of their work team; This ensures autonomous and committed people and teams.',
    'Finally, they must create and maintain conditions where the intrinsic motivation of each person can emerge and contribute to achieving common goals. It is important to know what motivates each human being and direct their talent in a way that is consistent with those motivations. As a result, the responsibilities associated with the Mobilization process are as follows:',
    '√ Ensure effective communication.\n√ Recognize empowerment and responsibility.\n√ Manage change and promote innovation.\n√ Maintain relationships of trust.\n√ Lead motivation of people.'
  ],
  achievement: 'Achievement',
  achievement_texts: [
    'Achievement is obtained by developing high performance teams, where people share goals, values and rules of the game. These teams are characterized by the fact that people perceive that one\'s problem is everyone\'s problem, therefore, both the responsibilities such as achievements and challenges that arise are shared.',
    'Achievement is related to a long-term vision and disciplined actions. For this reason, the leader promotes the overall development of each individual, ensuring their continuous learning, through follow-up actions that they implement on a recurring basis. With this discipline, the leader has a high chance of anticipating contingencies and carrying out the necessary plans effectively.',
    'Finally, all of the above occurs provided there is a healthy work environment, a high level of Engagement, where relationships, enjoyment and the way people feel when working, are determining factors for a successful, competitive and satisfactory execution in terms of results.',
    'Having leaders who promote business sustainability, under a conscious perspective of delivering results that generate value and balance for all stakeholders, is transcendental and imperative in today\'s world.',
    'Therefore, the Achievement process implies the following responsibilities:',
    '√ Develop high-performance teams.\n√ Ensure continuous learning and comprehensive development.\n√ Maintain a follow-up discipline.\n√ Lead the work environment and create healthy environments.\n√ Promote business sustainability'
  ],
  how_is_the_tool_title: '4. How is the assessment tool?',
  how_is_the_tool: [
    'Leadership 360° is a leadership competency assessment tool that, in a comprehensive and systematized manner, generates an assessment of the behavior of leaders within an organization. The tool consolidates the perceptions of different people (Leader(s), Peers and Collaborators), who hold various positions and relate to them in various ways, to measure their performance and competencies and contrast them with their self-perception.'
  ],
  what_is_the_utility: 'What is it for?',
  what_is_the_utility_text: [
    'The purpose of the 360° Leadership assessment tool is to give leaders the necessary feedback to define a competency development plan that allows them to improve their performance and grow fully as human beings.',
    'The scope of the 360° Leadership assessment is as follows:',
    '√ Raise awareness of the impact generated on others and contrast it with self-esteem.\n√ Identify strengths to reinforce them and weaknesses to turn them into opportunities for growth and development.\n√ Motivate people to improve their performance and involve them with the objectives of the organization.\n√ Promote conversations and spaces for feedback in all directions.\n√ Define plans for the development of individual and collective skills.'
  ],
  how_it_works: 'How does it work?',
  how_it_works_text: [
    'The assessment is made up of a series of questions, which correspond to personal attributes of the LEADER (the Leader as a person) and to the processes of the 360° Leadership model (Management, Mobilization and Achievement).',
    'The assessment is made up of a series of behaviors and behaviors related to the competencies and values of the 360° Leadership Model, on which each assessor rates the frequency between 1 and 6 according to the following scale:'
  ],
  scores_meaning: [
    'Never',
    'Hardly ever',
    'Rarely',
    'Sometimes',
    'Almost always',
    'Always'
  ],
  how_to_interpret_the_report_title: '5. How to interpret the report?',
  how_to_interpret_the_report: [
    'The effectiveness of the assessment depends on a good understanding and interpretation of the results. The report highlights the strengths and opportunities for improvement of the person assessed and allows the analysis of trends, gaps and dispersion of the results.',
    'The report must be understood according to the person being assessed, that is:'
  ],
  me: 'Me',
  relations_texts: [
    'It corresponds to the self-assessment you assigned',
    'It corresponds to the appreciation of your leader(s) or superior(s).',
    'It corresponds to the average of the perceptions of the people with whom you have the same position or rank within the organizational structure',
    'It corresponds to the average of the assessments made by those who report to you',
    'It corresponds to the average of the ratings given by your leader, peers and collaborators'
  ],
  references_text: 'For each of the above categories you will find the following references',
  bars: 'SHADED BARS',
  bars_text: 'They show the dispersion of the responses, that is, their range. Keep in mind that the previously mentioned scores appear on the bar.',
  score: 'Score',
  scores: 'Scores',
  notes: 'Comments',
  score_text: 'It shows the score obtained according to each assessor or group of assessors and is represented as the symbol X.',
  ten_percent: '10% Higher',
  ten_percent_text: 'It corresponds to the average assessment of the 10% of the leaders of our organization who obtained the highest score in the last 2 years, and is represented by the symbol of a green rhombus:',
  leaders_average: 'Average leaders',
  leaders_average_text: 'It reflects the average of the total group assessed in each of the competencies and values. With this reference you will be able to know if you are located in the average of the group assessed, above or below. It is represented by a circle:',
  trend: 'Trend',
  trend_text: 'This column is applied in cases where the test is performed more than once, which presents the changes that have occurred. The trend symbol will appear, if the assessment in each case increases or decreases by 0.4. This column is key to monitoring the process and verifying whether or not the action plan has produced the expected results.',
  plus_minus_symbols: 'Symbols\n (+) and (-)',
  plus_minus_symbols_text: 'If after the text, at the end of a Responsibility or Behavior, a positive symbol (+) appears, it means that the self-assessment is 1 point higher than the average of Others, which implies that their self-perception is overrated; In contrast, a negative symbol (-) will appear when your self-perception is less than 1, compared to the average of Others, which means your self-perception is underrated.',
  others_symbols: 'Symbols\n (#;%)',
  others_symbols_text: 'As far as peers are concerned, the assessment has the Don\'t know/No answer option and for this reason a number followed by a percentage may appear in some of the Behaviors. This accounts for the number and percentage of people who qualified for this option. With regard to Responsibilities, only the percentage of said group is shown.',
  bold_text: 'In each table the highest scores will be in bold and the lowest underlined. Scores from the self-assessment will not be taken into account.',
  how_to_interpret_the_report2: [
    'This way of presenting the information facilitates understanding and at the same time allows for an in-depth analysis of each valued element.',
    'The 360° Leadership Model ',
    'has 10 personal leadership attribute questions (the Leader as a person). Additionally, each of the 3 Processes of the Leadership model (Management, Mobilization and Achievement) has 5 Responsibilities and each responsibility in turn has 3 Behaviors.',
    'The following is a brief description of the content of the report:'
  ],
  higher_scores: [
    'Highest scores',
    'Identifying the highest scores allows us to observe the strengths of the person so that they can be recognized, strengthened and deepened. The table presents the 6 highest behaviors according to the Other score and the corresponding value for each assessor category. It is recommended to leverage on strengths, to work on areas for improvement.'
  ],
  lower_scores: [
    'Lowest scores',
    'Lowest scores refers to weaknesses or areas for improvement. It is of great importance to admit that as human beings we have some skills where there are opportunities for improvement. The table presents the 6 behaviors with the lowest scores according to the average of Other assessors. To read these results, a high level of commitment and humility is required to be able to look at oneself and become aware of the aspects where making an effort for development is required.'
  ],
  process_results: [
    'Results by Process',
    'This section shows the result of the averages for each of the processes of the 360° Leadership Model (The Leader as a Person, Management, Mobilization and Achievement). With these results it is possible to evaluate the status of the competencies in a general way and have a first idea of the perceptions and opportunities. The information is presented graphically and numerically to facilitate analysis. When applicable, you can see the score of the previous assessment and the trend.'
  ],
  responsability_results: [
    'Results by Responsibility',
    'In this part of the report, the information is presented to the Leader as a Person and to each of the 15 responsibilities of the 360° Leadership Model, grouped in each of the 3 Processes.'
  ],
  behavior_results: [
    'Results by Behavior',
    'The tables of the detailed questions show the results in a precise way for each of the questions that were answered (Behaviors), grouped by each of the Processes and Responsibilities.'
  ],
  confirmed_fo: [
    'Strengths and Opportunities for Improvement, Confirmed and Unknown',
    'This section categorizes the behaviors in your assessment according to the following criteria:',
    '√ If the Others average was greater than or equal to 5 and their Self-Assessment was 5 or 6, then it is a confirmed Strength; if your Self-assessment was 4 or less, it is an unknown Strength.',
    '√ If the Others average was less than or equal to 4 and your Self-Assessment was 4 or less, this is a confirmed Improvement Opportunity; if your Self-assessment was 5 or higher, it is an unknown Improvement Opportunity.',
    'In all cases, up to 6 behaviors will appear, those with the highest and lowest scores, depending on the case.'
  ],
  gaps_analysis_title: '6.6. Gap Analysis',
  gaps_analysis: [
    'Gaps between Self-assessment and the average of Others:',
    'The gaps allow us to observe the differences between the self-assessment and the perceptions of the different people are assessed, so that the differences between them are visible. This information is important to analyze the levels of awareness of the leader and the knowledge they have about their impact on others.',
    'These tables show the highest gaps at the Behavior level. The largest gaps are shown first when Self-assessment is higher than Other\'s, and then the largest gaps are shown when Other\'s assessment is higher than Self-assessment.'
  ],
  gaps_analysis2: [
    'Gaps between assessors',
    'This table shows the highest gaps found between the averages of the different assessors. This information is important to the extent that it shows the behaviors in which the leader is being perceived differently according to the different populations of assessors: Leader(s), Peers and Collaborators.'
  ],
  dispersion_analysis: [
    '6.7. Dispersion Analysis',
    'This part of the report shows the behaviors where there was greater dispersion (diverse opinions) and where there was less dispersion (greater consensus). This assessment is obtained through the standard deviation of the responses of Others.'
  ],
  trends_report: [
    'Trend Report',
    'This part of the report shows the trends where there were greater positive and negative changes with respect to the previous assessment.'
  ],
  open_questions: [
    'Open questions',
    'This section contains the compilation of answers given by those who assessed you and are related to the opportunities and strengths that your assessors find in you, and the advice they would give you to be more effective in your role as a Leader.'
  ],
  thanks_message: 'THANK YOU FOR YOUR INTEREST AND WE HOPE THAT THIS INFORMATION WILL BE GREATLY USEFUL FOR YOUR DEVELOPMENT AS A LEADER AND AS A PERSON',
  results_liderazgo: '6. Results Leadership Assessment',
  results_text: 'In tables 1 and 2, each column corresponds to the average assessment of',
  results_note: '* Throughout the report, the Other category refers to the average of Leader(s), Peers and Collaborators',
  higher_scores_title: '6.1. Highest scores',
  lower_scores_title: '6.2. Lowest scores',
  responsability_results_title: '6.3. Results by Responsibility',
  responsability_specifics_results_title: '6.4. Results by Behavior',
  confirmed_fo_title: '6.5. Strengths and Opportunities for Improvement, Confirmed and Unknown',
  trend_ranking: '6.8. Trend Ranking',
  open_questions_title: '6.9. Consolidation of Open Questions',
  open_questions_t: 'Open questions',
  higher_positives_trends: 'Higher Positive Trends',
  higher_negatives_trends: 'Higher Negative Trends',
  process_results_text: 'Result by Process',
  general_responsibility_results_text: 'Result by Responsibility',
  table: 'Table',
  table1: 'Six Highest Scores (according to Others)',
  table2: 'Six Lowest Scores (according to Others)',
  table3: 'Results by Responsibility',
  table4: 'Results by Responsibility and Behavior',
  table7: 'Results by Responsibility and Behavior for the Process of',
  table25: 'Gap Analysis between Self-assessment and Average of Others',
  table26: 'Assessor Gap Analysis',
  table_fo_1: 'Confirmed and Unknown Strengths',
  table_fo_2: 'Opportunities for Improvement, Confirmed and Unknown',
  chart1: 'This graph compares the average of your Self-Assessment in each Responsibility (color band) against the average obtained by the rating of other assessors (gray line).',
  responsability_results_text: 'This part of the report presents the results by responsibility. In total, there are 15 responsibilities, 5 related to Management, 5 related to Mobilization and 5 related to Achievement.',
  higher_dispersion: 'Greater dispersion',
  lower_dispersion: 'Less dispersion',
  higher_bold: 'Highest ratings in bold',
  lower_sub: 'Lowest ratings underlined',
  // FO
  confirmed_strengths: 'Confirmed Strengths',
  confirmed_strengths_text: 'Average of Others greater than or equal to 5 and Self-assessment 5 or 6.',
  unknown_strengths: 'Unknown Strengths',
  unknown_strengths_text: 'Average of Others greater than or equal to 5 and Self-assessment 4 or less.',
  confirmed_improvement_opportunities: 'Confirmed Opportunities for Improvement',
  confirmed_improvement_opportunities_text: 'Average of Others less than 5 and Self-assessment 4 or less.',
  unknown_improvement_opportunities: 'Unknown Opportunities for Improvement',
  unknown_improvement_opportunities_text: 'Average of Others less than 5 and Self-assessment 5 or higher.',
  greater_auto: 'Self-assessment higher than the average of Others',
  lower_auto: 'Self-assessment lower than the average of Others',
  higher_score: 'Highest score',
  lower_score: 'Lowest score',
  // Resultados Generales
  general_results: '9. General Results',
  general_results_text: 'In brief, for each Process, the average obtained by the leaders (white bar) and their Individual Self-Assessment (color bar) are presented. The dark color in each Process corresponds to the current assessment, and the light color to the previous assessment (in a first application of the Assessment, you will only find one bar per Process).',
  // Brechas
  positive_negative_gaps: '10. Positive and negative gaps',
  positive_negative_gaps_text: 'After analyzing the full assessment result, these are the largest gaps between your Individual Self-Assessment and the Leaders\' Average. On the right side are the positive ones (where you rate yourself better than the average of Leaders) and on the left side are the negative ones (where you rate yourself below average).',
  // Fortalezas y oportunidades de mejora
  strengths_opportunities: '11. Strengths and Opportunities for Improvement',
  strengths_opportunities_text: 'This is the summary of the 5 highest scores (Strengths) and the 5 lowest (Opportunities for Improvement) of the leaders, with their respective dispersion and Individual Self-Assessment.',
  // Seguimiento
  strengths: 'Strengths',
  leader_strengths: 'The strengths of this leader are',
  leader_opportunities: 'The Opportunities for Improvement of this leader are',
  leader_tips: 'The challenges to being a more inspiring and effective leader are',
  information: 'Information',
  ranking: 'Ranking',
  improvement_opportunities: 'Opportunities for Improvement',
  actual_evaluation: 'Current assessment',
  average_team: 'Team Average',
  individual: 'Individual',
  dimension: 'Dimension',
  attribute: 'Attribute',
  average_leaders: 'Average Leaders',
  average: 'Average',
  poll_answers: 'Assessment responses',
  autoevaluation: 'self assessment',
  autoevaluations: 'self-assessments',
  leaders: 'Leader(s)',
  pairs: 'Peers',
  dependents: 'Collaborators',
  others: 'Others',
  total: 'Total',
  auto: 'Auto',
  evaluator: 'Assessor',
  received_evaluations: 'Assessments Received',
  received: 'Received',
  requested_evaluations: 'Assessments Requested',
  requested: 'Expected',
  answers_rate: 'Response Rate',
  process_abbr: 'P',
  specific_responsability: 'Behaviour',
  general_responsability: 'Responsibility',
  responsability: 'Responsibility',
  behavior: 'Behavior',
  process: 'Process',
  actual_dispersion: 'Current Dispersion',
  previous_average: 'Previous Average',
  actual_average: 'Current Average',
  previous_score: 'Previous Score',
  actual_score: 'Current Score',
  previous: 'Previous',
  percent_break: 'Jump %',
  current: 'Current',
  dispersion: 'Dispersion',
  as_person: 'As a person',
  average_as_person: 'Average as a person',
  direction_average: 'Management average',
  mobilization_average: 'Mobilization average',
  achievement_average: 'Mobilization average',
  leader_as_person: 'Leader as a person',
  results: 'Results',
  copyright: 'Powered by OCC Solutions.',
  higher_gap: 'Positive higher gaps',
  lower_gap: 'Negative higher gaps',
  gap: 'Gap',
  gaps: 'Gaps',
  chart: 'Graph',
  strengths_and_opportunities: 'Strengths and Opportunities',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  data_range: 'Data range (Max-Min)',
  no_answer: '(#;%) Don\'t know/No answer',
  leadership: 'Leadership',
  concepts: 'Concepts',
  no_evaluators: 'There are no assessors',
  no_others_evaluators: 'There are no other assessors',
  there_is_no: 'There aren\'t any ',
  input_generating_report: 'Generating report',
  input_failed_generation: 'Generation failed',
  input_download_report: 'Download report',
  input_update_progress: 'Update progress',
  input_no_participation: 'No participation',
  input_generate_report: 'Generate report',
  opetaion_init: 'The "Individual Report" download process has started, this process may take several minutes, you can see its progress by refreshing the page',
  cover_table_header_1: 'Assessments expected',
  cover_table_header_2: 'Assessments obtained',
  cover_table_header_3: 'Response rate',
  cover_table_row_1: 'COLLABORATORS',
  cover_table_row_2: 'TOTAL',
  cover_months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  background_1: 'CONTENTS',
  background_2: 'INTRODUCTION',
  background_3: 'RESULTS BY DIMENSIONS',
  background_4: 'RESULTS BY COMPETENCES',
  background_5: 'HIGHEST AND LOWEST BEHAVIORS',
  background_6: 'Communication and feedback.',
  background_7: 'Personal and professional development',
  background_8: 'Planning and implementation',
  background_9: 'GAP ANALYSIS',
  background_10: 'DISPERSION ANALYSIS',
  background_11: 'CONFIRMED AND UNKNOWN STRENGTHS',
  background_12: 'TREND ANALYSIS',
  index: [
    '01. Introduction',
    '02. Results by dimensions',
    '03. Results by competences',
    '04. Highest and lowest behaviors',
    '05. Result by Behavior',
    '06. Gap analysis',
    '07. Dispersion analysis',
    '08. Confirmed and unknown strengths',
    '09. Trend analysis',
    '10. Workplan'
  ],
  results_dimention_title_1: '02. RESULTS BY DIMENSIONS',
  results_dimention_table_info_header_1: 'INFORMATION REGARDING DIMENSIONS',
  results_dimention_table_info_sub_header_1: 'DIMENSION',
  results_dimention_table_info_sub_header_2: 'ABBREVIATION',
  results_dimention_table_info_sub_header_3: 'SELF',
  results_dimention_table_info_sub_header_4: 'TEAM',
  results_dimention_table_header_1: 'RESULT',
  results_dimention_table_header_2: 'LEVEL',
  results_dimention_table_header_3: 'INTERPRETATION',
  results_dimention_table_row_1_col_1: 'Between 5 and 6',
  results_dimention_table_row_1_col_2: 'High',
  results_dimention_table_row_1_col_3: 'Clear competence',
  results_dimention_table_row_2_col_1: 'Between 3 and 4',
  results_dimention_table_row_2_col_2: 'Acceptable',
  results_dimention_table_row_2_col_3: 'Acceptable performance',
  results_dimention_table_row_3_col_1: '2 and less than 3',
  results_dimention_table_row_3_col_2: 'Medium',
  results_dimention_table_row_3_col_3: 'Requires attention',
  results_dimention_table_row_4_col_1: 'Less than 2',
  results_dimention_table_row_4_col_2: 'Low',
  results_dimention_table_row_4_col_3: 'Requires priority development',
  results_dimention_title_2: '03. RESULTS BY COMPETENCES',
  results_dimention_title_2_table_header_1: 'Individual',
  results_dimention_title_2_table_header_2: 'Team',
  results_dimention_title_2_table_header_3: 'Trend',
  results_dimention_info_1: 'The individual assessment is the way in which you assess yourself, and as a team it is the average of how each of your collaborators assesses you.',
  results_dimention_info_2: 'This graph shows the overall results according to the averages of your self-assessment and the team\'s assessments.',
  rank_title: '04. HIGHEST AND LOWEST BEHAVIORS',
  rank_title_info: 'The following tables present the 6 highest and lowest behaviors according to the score given by the Team.',
  rank_sub_title_1: 'SIX HIGHEST SCORES (ACCORDING TO TEAM ASSESSMENT).',
  rank_table_1_header_1: 'DIMENSION',
  rank_table_1_header_2: 'COMPETENCE',
  rank_table_1_header_3: 'BEHAVIOR',
  rank_table_1_header_4: 'TEAM',
  rank_table_1_header_5: 'SELF',
  rank_sub_title_2: 'SIX LOWEST SCORES (ACCORDING TO TEAM ASSESSMENT).',
  rank_table_2_header_1: 'DIMENSION',
  rank_table_2_header_2: 'COMPETENCE',
  rank_table_2_header_3: 'BEHAVIOR',
  rank_table_2_header_4: 'TEAM',
  rank_table_2_header_5: 'SELF',
  results_title: '05. RESULT BY BEHAVIOR',
  results_info: 'Next, each behavior is presented with its self-assessment and the assessment of your team. The trend column shows if there was a positive or negative change according to the previous assessment. An arrow indicating the trend will appear if the rating increases or decreases by more than 0.4.',
  result_legend_1: 'Data range (min-max)',
  result_legend_2: 'Self-assessment',
  result_legend_3: 'Team',
  results_table_header_1: 'D',
  results_table_header_2: 'COMPETENCE',
  results_table_header_3: 'BEHAVIOR',
  results_table_header_4: 'ASSESSMENT',
  results_table_header_5: 'SELF',
  results_table_header_6: 'TEAM',
  results_table_header_7: 'TREND',
  gaps_title: '06. GAP ANALYSIS',
  gaps_title_info: 'In these tables are the highest gaps in terms of behavior. The largest gaps are shown first when the self-assessment is higher than the team\'s, and then the highest gaps when the Team\'s assessment is higher than the Self-assessment.',
  gaps_sub_title_1: 'Positive higher gaps',
  gaps_table_1_header_1: 'DIMENSION',
  gaps_table_1_header_2: 'COMPETENCE',
  gaps_table_1_header_3: 'BEHAVIOR',
  gaps_table_1_header_4: 'SELF',
  gaps_table_1_header_5: 'TEAM',
  gaps_table_1_header_6: 'GAP',
  gaps_sub_title_2: 'Negative higher gaps',
  gaps_table_2_header_1: 'DIMENSION',
  gaps_table_2_header_2: 'COMPETENCE',
  gaps_table_2_header_3: 'BEHAVIOR',
  gaps_table_2_header_4: 'SELF',
  gaps_table_2_header_5: 'TEAM',
  gaps_table_2_header_6: 'GAP',
  scatter_title: '07. Dispersion analysis',
  scatter_info: 'This part of the report shows the behaviors where there was greater dispersion (diverse opinions) and where there was less dispersion (greater consensus). This measurement is obtained through the standard deviation of the Team\'s responses.',
  scatter_header_1: 'GREATER DISPERSION',
  scatter_header_2: 'LESS DISPERSION',
  scatter_sub_header_1: 'DIMENSION',
  scatter_sub_header_2: 'COMPETENCE',
  scatter_sub_header_3: 'BEHAVIOR',
  scatter_sub_header_4: 'SELF',
  scatter_sub_header_5: 'TEAM',
  scatter_sub_header_6: 'DISPERSION',
  strengths_title: '08. Confirmed and unknown strengths',
  strengths_info_title_1: 'Confirmed Strengths',
  strengths_info_text_1: 'Team Average greater than or equal to 5 and Self-Assessment greater than or equal to 5.',
  strengths_info_title_2: 'Unknown Strengths',
  strengths_info_text_2: 'Team Average greater than or equal to 5 and Self-Assessment 4 or less.',
  strengths_info_title_3: 'Confirmed Opportunities for Improvement',
  strengths_info_text_3: 'Team Average less than or equal to 4 and Self-Assessment 4 or less.',
  strengths_info_title_4: 'Unknown Opportunities for Improvement',
  strengths_info_text_4: 'Team Average less than or equal to 4 and Self-Assessment 5 or higher.',
  strengths_table_header_1: 'BEHAVIOR',
  strengths_table_header_2: 'SELF',
  strengths_table_header_3: 'TEAM',
  trend_title: '09. Trend analysis',
  trend_info: 'In this part of the report, the behaviors where there was a greater positive and negative change in the rating versus the previous rating in the Team are shown.',
  trend_msg: 'Available from the second assessment',
  trend_table_1_title: 'POSITIVE TRENDS',
  trend_table_1_header_1: 'DIMENSION',
  trend_table_1_header_2: 'COMPETENCE',
  trend_table_1_header_3: 'BEHAVIOR',
  trend_table_1_header_4: 'CURRENT',
  trend_table_1_header_5: 'PREVIOUS',
  trend_table_1_header_6: 'TREND',
  trend_table_2_title: 'NEGATIVE TRENDS',
  trend_table_2_header_1: 'DIMENSION',
  trend_table_2_header_2: 'COMPETENCE',
  trend_table_2_header_3: 'BEHAVIOR',
  trend_table_2_header_4: 'CURRENT',
  trend_table_2_header_5: 'PREVIOUS',
  trend_table_2_header_6: 'TREND',
  plan_title: '10. Work plan',
  plan_info: 'Based on your results, we recommend creating a work plan for the categories with the largest gaps. For each category to work on, define a maximum of three actions and a strategy that allows monitoring compliance.',
  plan_table_header_1: 'DIMENSION',
  plan_table_header_2: 'SELF',
  plan_table_header_3: 'TEAM',
  plan_table_header_4: 'GAP',
  plan_table_header_5: 'STRENGTHS',
  plan_table_header_6: 'OPPORTUNITY AREAS',
  plan_table_header_7: 'EXPECTED RESULT',
  plan_table_header_8: 'ACTION PLAN',
  plan_table_header_9: 'MONITORING STRATEGY',
  conventions_table: 'Conventions Table'
}
