
export default {
  add_enterprise: 'Add Company',
  admin_left_table_title: 'Companies',
  admin_bottom_table_title: 'recent surveys',
  create_poll: 'Create survey',
  continue_poll: 'Continue survey',
  continue: 'Continue',
  employee_left_table_title: 'Surveys in progress',
  employee_progress: 'Progress percentage',
  employee_right_table_title: 'Assigned surveys',
  enterprise_left_table_title: 'Current plan',
  enterprise_right_table_title: 'Surveys',
  expired_plan: '(Plan expired)',
  general_list: 'General list',
  general_title: 'Board',
  has_started: 'started',
  not_started: 'To be started',
  init: 'Start',
  end: 'End',
  ended: 'Ended on',
  left_days: 'Remaining days',
  left_questions: 'Remaining questions',
  finished: 'Finished',
  progress: 'In progress',
  period_divisor: 'percentage',
  poll: 'Survey',
  poll_completed: 'Yes',
  poll_incompleted: 'No',
  poll_init_date: 'Start',
  poll_end_date: 'Valid until',
  qty_enterprises: 'Active companies',
  qty_employees: 'Collaborators',
  qty_licesenses: 'licenses',
  team_trends: 'Team trends',
  trend_chart: 'Trend chart',
  update_plan: 'Update Plan',
  dimension_leaders: 'The leaders',
  dimension_inspiration: 'My inspiration',
  dimension_job: 'My job',
  dimension_environment: 'Positive work environment',
  dimension_team: 'My team',
  dimension_development: 'My development and learning',
  view_poll_details: 'See survey details',
  has_no_polls: 'You need to create at least 2 surveys in the same period to see the graph.',
  start: 'Strat',
  start_poll: 'Start assessment',
  no_pending_activities: 'You have no pending activities',
  employee_activities_table_header: [
    'Assessment (Role)',
    'Action'
  ],
  autoevaluation: 'Self-assessment',
  evaluators: 'Assessors',
  leader: 'Leader',
  pair: 'Peer',
  dependent: 'Collaborator',
  name: 'Assessment name',
  dates: 'Dates',
  status: 'Status',
  enterprise: 'Company',
  customer: 'Client',
  actions: 'Actions',
  to: 'Until',
  last_polls: 'Latest assessments',
  see_evaluated: 'See assessors',
  peding: 'Pending',
  evaluations_resume: 'Assessment Summary',
  no_created_evaluations: 'You have no assessments created',
  go_create_evaluation: 'Go to Create Assessment',
  see_details: 'See details',
  evaluation_finished: 'Assessment finished'
}
