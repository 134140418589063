
export default {
  title: 'OCC Solutions Instructions - 360° Leadership: Bulk upload module.',
  title_body: 'The purpose of this instruction is to guide the end user in the correct way to enter the bulk upload format, to prevent errors in uploading user data.',
  demographic_items: 'demographic cuts',
  identifyType: 'ID type',
  matchCaseValue: 'One of the values stored in the application must be specified for this demographic cut, with proper use of upper and lower case letters.',
  example: 'Example',
  identifyDocument: 'Identification document',
  matchCaseNumber: 'Only numbers must be entered, without letters, spaces or special characters.',
  firstName: 'Name',
  matchCaseText: 'You must enter only letters or spaces, without numbers or special characters.',
  lastName: 'last name',
  email: 'Email',
  matchCaseEmail: 'A valid email must be specified, it cannot be repeated. Or you can leave this field empty.',
  gender: 'Gender',
  age: 'Age range (Date of birth)',
  antiquity: 'Seniority Range (Start date)',
  matchCaseDate: 'A valid date must be entered in yyyy/mm/dd or yyyy-mm-dd format, without spaces or letters',
  departments: 'Department/Area',
  charge: 'job title',
  charge_dependent_info: 'The job titles with departments identified in parentheses "()", can only be assigned to collaborators belonging to said department.',
  jobTypes: 'Contract Type',
  academicDegree: 'education level',
  country: 'Country',
  headquarter: 'office location',
  headquarter_dependent_info: 'The offices with countries identified in parentheses "()", can only be assigned to collaborators who belong to said country.',
  importDataToExcelTitle: 'Import data from CSV file to Excel Document.',
  step1: 'Step 1',
  step1_desc: 'Open Excel.',
  step2: 'Step 2',
  step2_desc: 'Select the Blank Book option',
  step3: 'Step 3',
  step3_desc: 'Go to the Data tab and in the Obtain external data section, select the From a text file option.',
  step4: 'Step 4',
  step4_desc: 'Select the CSV data file you want to import and then click the Import button.',
  step5: 'Step 5',
  step5_desc: 'At this point, the settings that will be used to import the data file are specified. It must be indicated that the values are Delimited, and that the Unicode uft-8 encoding will be used, as shown in the following image. This, for the correct conversion of accented words. Then you must press the Next button.',
  step6: 'Step 6',
  step6_desc: 'At this point, you must indicate which character will be used to separate values. The comma must be indicated as shown in the following image. Then the Finish button must be pressed.',
  step7: 'Step 7',
  step7_desc: 'Next, you will be asked if this data will be imported into the existing sheet or into a new sheet. By default, import into existing sheet will be selected, if not, select this option and press OK.',
  step8_desc: 'Finally, the data will be separated correctly and the accented words will be imported correctly.',
  availableItems: 'Available Values',
  use_methods: 'Form of use',
  description: 'By downloading the bulk upload format you will get a file in .csv format. In this file, you will find two columns, in which you will see the email and work relationship headers. In the email header column, you must enter the emails of your company\'s Collaborators and in the work relationship column, you must enter their values, such as',
  description_relations: 'leader, peer, collaborator',
  description_2: 'Respectively, as you consider the case to be appropriate for each collaborator.',
  description_3: 'The correct data entry format will be the email of the assessed collaborator, with the work relationship column empty, followed by each of the assessors with their respective relationship. This order must be repeated respectively with each one of the assessees that you want to change/add in the assessment',
  description_example: ''
}
