
import Services from './base-services'

const service = new Services('additional-demographics1')

export default {
  listFromSuite: () => {
    return service.suiteOperation(() => service.get('list'))
  }
}
