
export default {
  login: 'Log in',
  problems_to_login: 'Trouble logging in?',
  recover_password: 'Recover password',
  go_back: 'Go back',
  email_not_found: 'Email address {email} not found.',
  unexpected_error: 'An unexpected error has occurred. If the problem persists, please contact your administrator.',
  password_successfully_restablish: 'Password reset successful',
  password_sent_to_email: 'Your new password has been emailed to you.',
  // tslint:disable-next-line:max-line-length
  password_notification_sent: 'Notification sent to administrator to proceed with password reset.',
  accept: 'Accept',
  password_not_updated: 'Sorry, your password wasn\'t updated.',
  password_confirm: 'Confirm password',
  password_confirmation: 'Password confirmation',
  new_password: 'New password',
  new_password_confirm: 'Confirm new password',
  save_new_password: 'Save new password',
  password_update: 'Password update',
  password_updated: 'Your password has been successfully updated.',
  password_reset_requested: 'Password reset requested',
  check_your_email: 'We\'ve sent an email to the specified address with the steps to follow.',
  create_password: 'Create password',
  save_password: 'Save password',
  password_create: 'Create a password',
  password_created: 'Password created',
  password_not_created: 'Sorry, your password could not be created.',
  password_created_greet_title: 'Your password has been successfully created ',
  // tslint:disable-next-line:max-line-length
  password_created_greet_text: 'You can now use your email address and this new password to log in',
  token_not_valid: 'The verification code is invalid',
  // tslint:disable-next-line:max-line-length
  token_expired: 'This code has expired. Remember that for security purposes our access codes are only valid for a maximum of three days.',
  token_used: 'This code has already been used',
  // tslint:disable-next-line:max-line-length
  verify_or_contact: 'Please check the code or link you used and try again. You can also contact OCC Solutions for more information.',
  go_sign_in: 'Back to login.',
  logged_as_enterprise: 'At this moment you are identified as one of the associated companies. You can sign out temporarily if you wish to choose another company or acquire more tokens',
  logged_as_enterprise_superadmin: 'At this time you are identified as one of the companies associated with this client. You can temporarily sign out as a company if you wish to choose another company; or you can temporarily sign out as a client to return to the Super Administrator dashboard',
  logged_as_employee: 'Temporarily logged in as collaborator',
  logged_as_customer: 'At this moment you are in a temporary Administrator User Session. You can temporarily sign out to return to the Super Administrator dashboard',
  close_session: 'Sign out',
  close_session_as_customer: 'Sign out - client',
  close_session_as_enterprise: 'Sign out - Company',
  sign_up: 'Sign Up',
  select_customer_title: 'Select the account that best suits your needs',
  select_customer_personal: 'Single-Company Account',
  select_customer_personal_text: 'You can sign up as an administrator and register your company to manage it personally',
  select_customer_personal_select: 'Select',
  select_customer_commercial: 'Multi-Company Account',
  select_customer_commercial_text: 'You can sign up as an administrator to manage and administer multiple companies',
  select_customer_commercial_select: 'Select',
  has_no_account: 'Don\'t have an account yet?',
  customer_personal_d: 'You can sign up as an administrator and register your company to manage it personally.',
  customer_commercial_d: 'You can sign up as an administrator to manage and administer multiple companies.',
  // tslint:disable-next-line:max-line-length
  customer_personal_d_2: 'Register a user as administrator with your company to manage it personally.',
  customer_commercial_d_2: 'Register a user as administrator of multiple companies.',
  personal_account: 'Single-Company Account',
  commercial_account: 'Multi-Company Account',
  terms_and_confitions_header: 'Terms and conditions of personal use',
  terms_and_confitions_changed_header: 'We have changed our Terms and Conditions of personal use',
  confirm_logout: 'Are you sure you want to log out?'
}
