
import auth from './auth'
import customers from './customers'
import dashboard from './dashboard'
import employees from './employees'
import enterprises from './enterprises'
import errors from './errors'
import evaluations from './evaluations'
import groups from './groups'
import help from './help'
import input from './input'
import instructive from './instructive'
import languages from './languages'
import language from './language'
import navigation from './navigation'
import notifications from './notifications'
import operationSummary from './operation-summary'
import outside from './outside'
import pluralization from './pluralization'
import settings from './settings'
import tokens from './tokens'
import services from './services'
import pricing from './pricing'
import calculatorView from './calculator_view'
import questionnaires from './questionnaires'
import questions from './questions'
import organizationalReport from './organizational_report'
import individualReport from './individual_report'
import demographicReport from './demographic_report'
import reports from './reports'
import conceptualFrameworks from './conceptual_frameworks'

export default {
  /* General Labels */
  title: 'OCC Solutions - Pulse',
  active: 'Activo',
  inactive: 'Inactivo',
  no_data: 'No hay registros',
  registration_updated_successfully: 'Registro actualizado con éxito',
  record_saved_successfully: 'Registro guardado exitosamente',
  error_trying_update: 'Hubo un error al intentar actualizar',
  error_trying_save: 'Se produjo un error al intentar guardar',
  copyright: 'Todos los derechos reservados 2019',
  go_back: 'Volver',
  results_per_pages: 'Resultados por páginas',
  manual: 'Manuales de ayuda',
  go_to_manual: 'Haga click aquí para ir a la sección de manuales',
  page_all: 'Todos',
  /* Models */
  models: {
    enterprises: 'Empresas',
    groups: 'Grupos Empresariales',
    pulse: 'Pulse'
  },
  waiting: 'Por favor espere',
  maintenance: 'Página en construcción',
  // tslint:disable-next-line:max-line-length
  maintenance_text: 'Trabajamos cada día para lograr los objetivos<br/>y darle mejores resultados y experiencias.',
  /* Labels grouped by modules or type */
  auth,
  customers,
  dashboard,
  employees,
  enterprises,
  errors,
  evaluations,
  help,
  input,
  instructive,
  navigation,
  notifications,
  operationSummary,
  outside,
  pluralization,
  languages,
  language,
  settings,
  calculatorView,
  tokens,
  services,
  pricing,
  groups,
  questionnaires,
  page_text: 'de',
  questions,
  organizationalReport,
  individualReport,
  demographicReport,
  reports,
  conceptualFrameworks
}
