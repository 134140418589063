
import PDFUtil from '../utils/pdf'

export default {
  data () {
    return {
      maxGralRespCharsTitle: 45,
      maxGralRespChars: 120,
      legend: [
        this.$t('individualReport.data_range'),
        this.$t('individualReport.score'),
        this.$t('individualReport.higher_bold'),
        this.$t('individualReport.lower_sub')
      ]
    }
  },
  methods: {
    truncateGeneral (str, isTitle = false) {
      const maxChars = isTitle ? this.maxGralRespCharsTitle : this.maxGralRespChars
      return str.length > maxChars ? str.slice(0, maxChars) + '...' : str
    },
    getGralResultGap (gap, bold = false, fontSize = 9) {
      return {
        text: gap ? this.round(gap, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getBaseHeader (text, colSpan, fontSize, uppercase = false) {
      return {
        bold: true,
        fontSize: fontSize || 12,
        color: 'white',
        text: uppercase ? text.toUpperCase() : text,
        colSpan: colSpan || 1,
        alignment: 'center',
        margin: [2, 4, 2, 3],
        border: [true, true, true, true]
      }
    },
    getGralName (general) {
      const extract = general.match(/(\d+)/)[0]
      const idx = extract - 1
      const name = this.baseQuestionnaire.evaluations.generalEvaluation[idx].description
        ? this.baseQuestionnaire.evaluations.generalEvaluation[idx].description[this.user.lang]
        : this.questionnaire.general[general].name[this.user.lang]

      const text = this.truncateGeneral(name)
      return {
        text: text,
        fontSize: 9,
        margin: [0, 2, 0, 0],
        border: [true, true, true, true],
        style: { color: '#6d7a7a' }
      }
    },
    higherLowerScore (scores = [], score) {
      const lowest = scores[scores.length - 1]

      let text = ''
      let isBold = false
      let isUnderline = false
      let fontSize = 9

      if (isNaN(score)) {
        text = '--'
      } else if (!scores.length) {
        text = score.toFixed(2)
      }

      if (score === scores[0]) {
        isBold = true
        fontSize = 10
        text = score.toFixed(2)
      } else if (score === lowest) {
        isUnderline = true
        text = score.toFixed(2)
      } else {
        text = score.toFixed(2)
      }

      return {
        bold: isBold,
        fontSize: fontSize,
        style: { color: '#2e2d2d' },
        text: text,
        alignment: 'center',
        decoration: isUnderline ? 'underline' : '',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getResultsHeader (text, topMargin = 11) {
      return {
        bold: true,
        fontSize: 9,
        color: 'white',
        text: text,
        alignment: 'center',
        margin: [2, topMargin, 2, 1],
        border: [true, true, true, true]
      }
    },
    generalResultsTable () {
      const responsibilityResultsTables = []
      const firstTenRows = []
      const remainingRows = []
      const results = this.answersResponsibility.general

      // Get All Filtered Scores
      const allScores = []
      for (const key of Object.keys(results)) {
        allScores.push(results[key].filtered.score)
      }
      allScores.sort((a, b) => b - a)

      // Rows
      for (const general of Object.keys(results)) {
        const ttlData = results[general].score
        const cutData = results[general].filtered
        const gap = cutData.score - ttlData.score
        const trend = cutData.score - cutData.previousScore

        if (firstTenRows.length < 10) {
          firstTenRows.push([
            this.getGralName(general),
            PDFUtil.generateGeneralXDCChartT3(cutData.range, cutData.score),
            this.higherLowerScore(allScores, cutData.score),
            this.getScore(ttlData.score),
            this.getGralResultGap(gap),
            this.getPrevScore(cutData.previousScore),
            this.getTrend(trend)
          ])
        } else {
          remainingRows.push([
            this.getGralName(general),
            PDFUtil.generateGeneralXDCChartT3(cutData.range, cutData.score),
            this.higherLowerScore(allScores, cutData.score),
            this.getScore(ttlData.score),
            this.getGralResultGap(gap),
            this.getPrevScore(cutData.previousScore),
            this.getTrend(trend)
          ])
        }
      }

      responsibilityResultsTables.push([
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex < 1) ? '#BFBFBF' : undefined
            },
            border: [false, false, false, true]
          },
          table: {
            widths: ['44.5%', '26.5%', '5.5%', '5.5%', '5%', '6%', '7%'],
            headerRows: 1,
            body: [
              [
                this.getResultsHeader(this.gralResponsibilityTitle),
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 2, 15, 0] },
                      { text: '2\n|', margin: [0, 2, 15, 0] },
                      { text: '3\n|', margin: [0, 2, 15, 0] },
                      { text: '4\n|', margin: [0, 2, 15, 0] },
                      { text: '5\n|', margin: [0, 2, 15, 0] },
                      { text: '6\n|', margin: [0, 2, 15, 0] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  fontSize: 9,
                  border: [true, true, true, true]
                },
                this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_3`), 5),
                this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_4`), 5),
                this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_5`)),
                this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_6`), 5),
                this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_7`))
              ],
              ...firstTenRows
            ]
          },
          margin: [0, 2, 5, 0],
          border: [false, false, false, true],
          alignment: 'center',
          verticalAlignment: 'center'
        }
      ])

      if (remainingRows.length > 0) {
        responsibilityResultsTables.push([
          {
            layout: {
              defaultBorder: '',
              fillColor: function (rowIndex, node, columnIndex) {
                return (rowIndex < 1) ? '#BFBFBF' : undefined
              },
              border: [false, false, false, true]
            },
            table: {
              widths: ['44.5%', '26.5%', '5.5%', '5.5%', '5%', '6%', '7%'],
              headerRows: 1,
              body: [
                [
                  this.getResultsHeader(this.gralResponsibilityTitle),
                  { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                    table: {
                      headerRows: 1,
                      body: [[
                        { text: '1\n|', margin: [10, 2, 15, 0] },
                        { text: '2\n|', margin: [0, 2, 15, 0] },
                        { text: '3\n|', margin: [0, 2, 15, 0] },
                        { text: '4\n|', margin: [0, 2, 15, 0] },
                        { text: '5\n|', margin: [0, 2, 15, 0] },
                        { text: '6\n|', margin: [0, 2, 15, 0] }
                      ]]
                    },
                    bold: true,
                    style: { color: '#fff' },
                    fontSize: 9,
                    border: [true, true, true, true]
                  },
                  this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_3`), 5),
                  this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_4`), 5),
                  this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_5`)),
                  this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_6`), 5),
                  this.getResultsHeader(this.$t(`demographicReport.scores_table_2_sub_header_7`))
                ],
                ...remainingRows
              ]
            },
            margin: [0, 2, 5, 0],
            border: [false, false, false, true],
            alignment: 'center',
            verticalAlignment: 'center'
          }
        ])
      }

      return responsibilityResultsTables
    },
    $generateResultsGral () {
      return [
        PDFUtil.generateTitleLandscape(this.$t('demographicReport.results_title_1'), 'before', true),
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.results_title_1_sub_1')),
        PDFUtil.generateResultsLegend(this.legend),
        this.generalResultsTable()
      ]
    }
  }
}
