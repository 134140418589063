
import PDFUtil from '../utils/pdf'

export default {
  data () {
    return {
      maxScoreGralRespChars: 78,
      // charTest: {
      //   gral: 'wwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwww',
      //   spec: 'wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww'
      // },
      maxScoreGralAndSpecRespChars: {
        gral: 50,
        spec: 92
      }
    }
  },
  methods: {
    truncateGralText (str) {
      return str.length > this.maxScoreGralRespChars ? str.slice(0, this.maxScoreGralRespChars) + '...' : str
    },
    truncateRespText (str, type) {
      return str.length > this.maxScoreGralAndSpecRespChars[type] ? str.slice(0, this.maxScoreGralAndSpecRespChars[type]) + '...' : str
    },
    getBaseScoreHeader (text, colSpan, fontSize, marginTop = 2, uppercase = false) {
      return {
        bold: true,
        fontSize: fontSize || 12,
        color: 'white',
        text: uppercase ? text.toUpperCase() : text,
        colSpan: colSpan || 1,
        alignment: 'center',
        margin: [1, marginTop, 1, 0],
        border: [true, true, true, true]
      }
    },
    getGralRespName (general) {
      const resp = this.questionnaire.general
      const text = resp[general] ? this.truncateGralText(resp[general].name[this.user.lang]) : '--'

      return {
        text: text,
        fontSize: 9,
        margin: [0, 2, 0, 2],
        border: [true, true, true, true],
        lineHeight: 0.9,
        style: { color: '#6d7a7a' }
      }
    },
    getScoreRespNames (general, specific = undefined, truncate = false) {
      const resp = this.questionnaire.general
      let text = ''
      if (!specific) {
        text = resp[general] ? resp[general].name[this.user.lang] : '--'
        if (truncate) {
          text = this.truncateRespText(text, 'gral')
        }
      } else {
        text = resp[general].specific[specific][this.user.lang]
        if (truncate) {
          text = this.truncateRespText(text, 'spec')
        }
      }
      return {
        text: text,
        fontSize: 9,
        margin: [0, 3, 0, 0],
        border: [true, true, true, true],
        style: { color: '#6d7a7a' }
      }
    },
    isValidValue (value) {
      return value !== 0 && value !== 7
    },
    getScore (score, bold = false, fontSize = 9) {
      return {
        text: this.isValidValue(score) ? this.round(score, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getPrevScore (score, bold = false, fontSize = 9) {
      return {
        text: this.hasPrevious && this.isValidValue(score) ? this.round(score, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getRankGap (gap, bold = false, fontSize = 9) {
      return {
        text: this.isValidValue(gap) ? this.round(gap, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getRankTrend (trend, bold = false, fontSize = 9) {
      return {
        text: this.hasPrevious && this.isValidValue(trend) ? this.round(trend, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    doubleTableScores (highest, lowest) {
      const table = {
        widths: ['27.5%', '5.5%', '5.5%', '5%', '7%', '0.5%', '27%', '5.5%', '5.5%', '5%', '7%'],
        headerRows: 2,
        body: [
          [
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_header_1`), 5, 8, 2, true),
            {},
            {},
            {},
            {},
            {},
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_header_2`), 5, 8, 2, true),
            {},
            {},
            {},
            {}
          ],
          [
            this.getBaseScoreHeader(this.gralResponsibilityTitle, 1, 9, 8),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_sub_header_2`), 1, 9, 2),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_sub_header_3`), 1, 9, 2),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_sub_header_4`), 1, 9, 8),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_sub_header_5`), 1, 9, 8),
            {},
            this.getBaseScoreHeader(this.gralResponsibilityTitle, 1, 9, 8),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_sub_header_2`), 1, 9, 2),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_sub_header_3`), 1, 9, 2),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_sub_header_4`), 1, 9, 8),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_1_sub_header_5`), 1, 9, 8)
          ]
        ]
      }

      for (var i = 0; i < 3; i++) {
        table.body.push([
          this.getGralRespName(highest[i].general),
          this.getScore(highest[i].score),
          this.getScore(highest[i].second),
          this.getRankGap(highest[i].gap),
          this.getRankTrend(highest[i].trend),
          {},
          this.getGralRespName(lowest[i].general),
          this.getScore(lowest[i].score),
          this.getScore(lowest[i].second),
          this.getRankGap(lowest[i].gap),
          this.getRankTrend(lowest[i].trend)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex <= 1 && columnIndex !== 5) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, -5, 0, 25],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    singleTableScores (scores) {
      const table = {
        widths: ['25%', '46%', '5.5%', '5.5%', '5%', '6%', '7%'],
        headerRows: 1,
        body: [
          [
            this.getBaseScoreHeader(this.gralResponsibilityTitle, 1, 9, 8),
            this.getBaseScoreHeader(this.specResponsibilityTitle, 1, 9, 8),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_2_sub_header_3`), 1, 9, 2),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_2_sub_header_4`), 1, 9, 2),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_2_sub_header_5`), 1, 9, 8),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_2_sub_header_6`), 1, 9, 2),
            this.getBaseScoreHeader(this.$t(`demographicReport.scores_table_2_sub_header_7`), 1, 9, 8)
          ]
        ]
      }

      for (var i = 0; i < 5; i++) {
        table.body.push([
          this.getScoreRespNames(scores[i].general, undefined, true),
          this.getScoreRespNames(scores[i].general, scores[i].specific, true),
          this.getScore(scores[i].score),
          this.getScore(scores[i].second),
          this.getRankGap(scores[i].gap),
          this.getPrevScore(scores[i].previous),
          this.getRankTrend(scores[i].trend)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex < 1) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, -5, 0, 25],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    $generateScores () {
      return [
        // GENERAL SCORES
        PDFUtil.generateTitleLandscape(this.$t('demographicReport.scores_title_1'), 'before', true),
        // Others Scores
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scores_title_1_sub_1')),
        this.doubleTableScores(this.highestScoreGeneralEvaluatorsItems, this.lowestScoreGeneralEvaluatorsItems),
        // Leaders Scores
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scores_title_1_sub_2')),
        this.doubleTableScores(this.highestScoreGeneralLeadersEvaluatorsItems, this.lowestScoreGeneralLeadersEvaluatorsItems),
        // Pairs Scores
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scores_title_1_sub_3'), 'before'),
        this.doubleTableScores(this.highestScoreGeneralPairsEvaluatorsItems, this.lowestScoreGeneralPairsEvaluatorsItems),
        // Dependants Scores
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scores_title_1_sub_4')),
        this.doubleTableScores(this.highestScoreGeneralDependentsEvaluatorsItems, this.lowestScoreGeneralDependentsEvaluatorsItems),

        // SPECIFIC SCORES
        PDFUtil.generateTitleLandscape(this.$t('demographicReport.scores_title_2'), 'before', true),
        // Others Scores
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scores_title_2_sub_5')),
        this.singleTableScores(this.highestScoreSpecificEvaluatorsItems),
        this.singleTableScores(this.lowestScoreSpecificEvaluatorsItems),
        // Leaders Scores
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scores_title_2_sub_6'), 'before'),
        this.singleTableScores(this.highestScoreSpecificLeadersEvaluatorsItems),
        this.singleTableScores(this.lowestScoreSpecificLeadersEvaluatorsItems),
        // Pairs Scores
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scores_title_2_sub_7'), 'before'),
        this.singleTableScores(this.highestScoreSpecificPairsEvaluatorsItems),
        this.singleTableScores(this.lowestScoreSpecificPairsEvaluatorsItems),
        // Dependents Scores
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scores_title_2_sub_8'), 'before'),
        this.singleTableScores(this.highestScoreSpecificDependentsEvaluatorsItems),
        this.singleTableScores(this.lowestScoreSpecificDependentsEvaluatorsItems)
      ]
    }
  }
}
