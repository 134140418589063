
<template>
  <div style="display:inline;">
    <v-btn large
      color="success"
      class="mt-3"
      :disabled="thread.status !== 'completed'"
      :loading="lockPdfButton"
      @click="openPdf"
    >
      <v-icon>mdi-file-pdf</v-icon>
      <span v-if="thread.status === 'pending'">{{ $t('individualReport.input_generating_report') }}</span>
      <span v-else-if="thread.status === 'in_action' || thread.status === 'in_progress'">{{ $t('individualReport.input_generating_report') + ` ${thread.data.progress}%` }}</span>
      <span v-else-if="thread.status === 'failed'">{{ $t('individualReport.input_failed_generation') }}</span>
      <span v-else>{{ $t('individualReport.input_download_report') }}</span>
    </v-btn>

    <!-- Leadership 360 Logo -->
    <img
      src="/img/20210423_occ_l_logo.png"
      style="visibility:hidden;"
      id="Leadership360CoverOnly"
      alt="hidden"
      width="0"
      height="0"
    />
    <!-- Leadership 360 page Header Logo -->
    <img
      src="/img/20210423_x_occ_l_logo.png"
      style="visibility:hidden;"
      id="occCultureCover"
      alt="hidden"
      width="0"
      height="0"
    />
    <!-- Empty img container to load Enterprise Logo if any -->
    <img
      v-if="leadership360CoverOnlyBase64"
      :src="leadership360CoverOnlyBase64"
      id="dynamicEnterpriseLogo"
      class="d-none"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import pdfUtil from './utils_old/pdf'
import dataObj from './utils_old/data-obj'

import is from 'is_js'
import initial from './thread_mixins_individual/00-initial_calcs'
import cover from './thread_mixins_individual/01-cover'
import introduction from './thread_mixins_individual/02-introduction'
import conceptualFramework from './thread_mixins_individual/03-conceptual-framework'
import results from './thread_mixins_individual/04-results'
import chart from './thread_mixins_individual/05-chart'
import responsabilityResults from './thread_mixins_individual/06-responsability-results'
import responsibilitySpecificsResults from './thread_mixins_individual/07-responsibility-specifics-results'
import foResults from './thread_mixins_individual/08-fo-confirmed'
import gapsAnalysis from './thread_mixins_individual/09-gaps'
import dispersion from './thread_mixins_individual/10-dispersion'
import trends from './thread_mixins_individual/11-trends'
import openQuestions from './thread_mixins_individual/12-open-questions'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')
const echarts = require('echarts')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
  name: 'required-report',
  mixins: [
    cover,
    initial,
    introduction,
    conceptualFramework,
    results,
    chart,
    responsabilityResults,
    responsibilitySpecificsResults,
    foResults,
    gapsAnalysis,
    dispersion,
    trends,
    openQuestions
  ],
  props: {
    baseQuestionnaire: Object,
    thread: Object,
    pollId: String,
    evaluatedName: String
  },
  data () {
    return {
      renderPart: {
        flowerChart: false
      },
      // Chart
      flowerChart: null,
      // IMÁGENES
      cultureCoverSrc: null,
      lockPdfButton: false,
      chartHexColors: [
        '#eb604d',
        '#51c7af',
        '#1999da',
        '#da5946',
        '#0b8778',
        '#0070c0',
        '#ff7c80',
        '#13ddc5',
        '#0996ff',
        '#f2a180',
        '#eb604d',
        '#51c7af',
        '#1999da',
        '#da5946',
        '#0b8778'
      ],
      chartColors: [
        'rgba(235,96,77,0.7)',
        'rgba(81,199,175,0.7)',
        'rgba(25,153,218,0.7)',
        'rgba(218,89,70,0.6)',
        'rgba(11,135,120,0.7)',
        'rgba(0,112,192,0.7)',
        'rgba(255,124,128,0.7)',
        'rgba(19,221,197,0.7)',
        'rgba(9,150,255,0.7)',
        'rgba(242,161,128,0.7)',
        'rgba(235,96,77,0.7)',
        'rgba(81,199,175,0.7)',
        'rgba(25,153,218,0.7)',
        'rgba(218,89,70,0.6)',
        'rgba(11,135,120,0.7)'
      ],
      leadership360CoverOnlyBase64: null,
      tableQty: 0
    }
  },
  mounted () {
    this.cultureCoverSrc = document.getElementById('occCultureCover').src
    this.gralResponsibilityTitle = this.baseQuestionnaire.generalResponsibility[this.user.lang]
    this.specResponsibilityTitle = this.baseQuestionnaire.specificResponsibility[this.user.lang]
    this.evaluationOpenQs = this.baseQuestionnaire.evaluations.openQuestions
  },
  watch: {
    renderPart: {
      handler () {
        const hasFalses = Object.values(this.renderPart).includes(false)
        if (!hasFalses) this.renderPdf()
      },
      deep: true
    },
    cultureCoverSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureCoverSrc, (dataURL) => {
          this.cultureCoverBase64 = dataURL
        })
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    round (value, decimals, df) {
      if (isNaN(value)) return df !== undefined ? df : value
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    insertLineBreak (text, lineLength) {
      lineLength = lineLength || 20
      let texto = text.split('')
      const long = texto.length
      if (long > lineLength) {
        for (let i = lineLength; i >= 0; i--) {
          if (texto[i] === ' ') {
            texto[i] = '\n'
            const restan = long - (i + 1)
            if (restan > lineLength) {
              const text1 = texto.splice(i + 1, restan).join('')
              const resto = this.insertLineBreak(text1)
              texto = texto.splice(0, i + 1).concat(resto.split(''))
            }
            break
          }
        }
      }
      return texto.join('')
    },
    generateFlowerChart () {
      const canvas = document.createElement('canvas')
      const noZero = (n) => (!n ? '--' : this.round(n, 2, '--'))
      canvas.width = 1040 * 2
      canvas.height = 740 * 2

      const chartPieLocal = echarts.init(canvas)

      const base = {
        type: 'bar',
        coordinateSystem: 'polar',
        stack: 'a'
      }

      const baseBlack = {
        ...base,
        name: 'z',
        color: '#BDBDBD'
      }

      const baseBlank = {
        ...base,
        name: 'blank',
        color: 'rgba(0,0,0,0)'
      }

      let resp = []
      const responsabilitiesKeys = Object.keys(this.answersResponsibility.general)
      for (const key of responsabilitiesKeys) {
        const r = this.answersResponsibility.general[key].filtered
        const name = this.questionnaire.general[key].name[this.user.lang]
        resp.push({
          name,
          auto: noZero(r.auto),
          others: noZero(r.evaluators)
        })
      }

      const setData = (idx, value) => {
        const baseData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        baseData[idx] = value
        return baseData
      }

      const setColor = (idx, value) => ({
        ...base,
        data: setData(idx, value),
        color: this.chartColors[idx],
        name: headerData[idx]
      })

      const setSpace = (idx, value) => ({
        ...baseBlank,
        data: setData(idx, value)
      })

      const setBlack = (idx) => ({
        ...baseBlack,
        data: setData(idx, 0.1)
      })

      const getData = (auto, others, idx) => {
        auto = auto - 1
        others = others - 1
        const data = []
        if (others === -1) {
          data.push(setColor(idx, auto))
        } else if (auto > others) {
          data.push(setColor(idx, others - 0.1))
          data.push(setBlack(idx))
          data.push(setColor(idx, (auto - others)))
        } else {
          data.push(setColor(idx, auto))
          data.push(setSpace(idx, (others - auto) - 0.1))
          data.push(setBlack(idx))
        }
        return data
      }

      let countResp = 0
      const headerData = []
      const series = []
      const dynamicLength = resp.length > 10 ? 14 : 20
      for (const r of resp) {
        headerData.push(
          {
            value: `{a|${r.auto}}{b| | ${dataObj.previousScore(r.others)}}\n{a|${this.insertLineBreak(r.name, dynamicLength)}}`,
            textStyle: {
              rich: {
                a: { fontSize: 35, color: this.chartColors[countResp], align: 'center' },
                b: { fontSize: 35, color: '#000000', align: 'center' }
              }
            }
          }
        )
        series.push(...getData(r.auto, r.others, countResp++))
      }
      chartPieLocal.setOption({
        angleAxis: {
          type: 'category',
          data: headerData,
          z: 10
        },
        radiusAxis: {
          min: 0,
          max: 5,
          interval: 0.25,
          axisLabel: {
            show: true,
            fontWeight: 'bold',
            fontSize: 40,
            formatter: (value) => +value % 1 === 0 ? (value + 1) : ''
          },
          splitLine: {
            interval: 0.25,
            show: true
          },
          splitArea: {
            interval: 0.25,
            show: true
          }
        },
        polar: {},
        series: series,
        barWidth: '100%'
      })

      chartPieLocal.on('finished', () => {
        this.flowerChart = chartPieLocal.getDataURL()
        this.renderPart.flowerChart = true
      })
    },
    async openPdf () {
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      await this.$getInitialData()
    },
    writeRotatedText (text, color, isFO = false) {
      const canvas = document.createElement('canvas')
      canvas.width = 40
      canvas.height = 842
      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = color
      ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(40, 650)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '17pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), isFO ? 190 : 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    calculatePages (page) {
      let variablePage = 9
      let chartPage = 0
      let specificsPages = 0
      let specificsPagesEnd = 3
      // let maxPgOneLength = 4156 // Average number of characters per page
      let maxPgOneLength = 2500
      // let maxPgOneLength = 3700
      let lineJump = 77 // The number of characters that a line break occupies
      if (page >= 5) {
        // Check if Conceptual Framework's 3rd section fills more than one page
        let body3 = this.currentEvaluation.conceptualFramework.detail[2].body[this.user.lang]
        var lengths3 = body3.map((word) => {
          return word.length
        })
        let totalLength3 = lengths3.reduce((a, b) => a + b, 0)
        totalLength3 += (body3.length - 2) * lineJump
        if (totalLength3 > maxPgOneLength) {
          variablePage = variablePage + 1
        }

        // Check if Conceptual Framework's 5th section fills more than one page
        let body5 = this.currentEvaluation.conceptualFramework.detail[4].body[this.user.lang]
        var lengths5 = body5.map((word) => {
          return word.length
        })
        let totalLength5 = lengths5.reduce((a, b) => a + b, 0)
        totalLength5 += (body5.length - 2) * lineJump
        if (totalLength5 > maxPgOneLength) {
          variablePage = variablePage + 1
        }

        chartPage = variablePage + 3

        // Sum of: 2 High/Low scores, 1 Chart (excluded later) & 2 base pg for Gral. Resp.
        specificsPages = 5
        let qLength = Object.keys(this.questionnaire.general).length
        if (qLength > 10) {
          // Gral. Responsibilities takes 3 pages, add one more page
          specificsPages += 1
        }

        let count = 0
        let minPg = 0
        for (const general of Object.values(this.questionnaire.general)) {
          const specificsLength = Object.keys(general.specific).length
          minPg = 1
          specificsPages += minPg
          // If Grals. have more than 3 specifics, add one more page
          if (minPg === 1 && Object.keys(general.specific).length > 3) {
            specificsPages += 1
          }
          // If Grals. have more than 8 specifics, add one more page
          if (specificsLength > 8) {
            specificsPages += 1
          }
          // If Grals. have more than 13 specifics, add one more page
          if (specificsLength > 13) {
            specificsPages += 1
          }
          count++
        }
        specificsPagesEnd = variablePage + specificsPages
      }

      // #EB493C #48BBA1
      return [
        {
          text: this.$t('organizationalReport.background_1'),
          color: '#BFBFBF',
          sta: 2,
          end: 2
        },
        {
          text: `${this.$t('navigation.conceptual_framework')}`,
          color: '#BFBFBF',
          sta: 3,
          end: variablePage
        },
        {
          text: `${this.$t('individualReport.results')}`,
          color: '#BFBFBF',
          chartPage: chartPage,
          sta: variablePage + 1,
          end: specificsPagesEnd
        },
        {
          text: `${this.$t('individualReport.strengths_and_opportunities')}`,
          color: '#BFBFBF',
          isFO: true,
          sta: specificsPagesEnd + 1,
          end: specificsPagesEnd + 1
        },
        {
          text: `${this.$t('individualReport.gaps')}`,
          color: '#BFBFBF',
          sta: specificsPagesEnd + 2,
          end: specificsPagesEnd + 3
        },
        {
          text: `${this.$t('individualReport.dispersion')}`,
          color: '#BFBFBF',
          sta: specificsPagesEnd + 4,
          end: specificsPagesEnd + 4
        },
        {
          text: `${this.$t('individualReport.trend')}`,
          color: '#BFBFBF',
          sta: specificsPagesEnd + 5,
          end: specificsPagesEnd + 5
        },
        {
          text: `${this.$t('individualReport.open_questions_t')}`,
          color: '#BFBFBF',
          sta: specificsPagesEnd + 6,
          end: specificsPagesEnd + 9 + this.openQuestionsPlusPage
        }
      ]
    },
    renderPdf () {
      this.tableQty = Object.keys(this.questionnaire.general).length
      Object.keys(this.renderPart).forEach((key) => { this.renderPart[key] = false })
      const configuration = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 50, 50],
        info: {
          title: this.$t('individualReport.title'),
          author: 'OCC',
          subject: this.$t('individualReport.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2,
          margin: [0, 25, 0, 0]
        },
        header: (currentPage, pageSize) => {
          let headerLogoHeight = 49
          let headerLogoWidth = 128
          let topMargin = 6
          if (this.leadership360CoverOnlyBase64) {
            const tmpWdth = headerLogoHeight * this.coverLogoRatio
            if (tmpWdth > headerLogoWidth) {
              headerLogoHeight = headerLogoWidth / this.coverLogoRatio
              if (this.coverLogoRatio > (headerLogoWidth / headerLogoHeight)) {
                topMargin += headerLogoHeight / 2
              }
            } else {
              headerLogoWidth = tmpWdth
            }
          }
          const resultObj = {
            image: this.leadership360CoverOnlyBase64 || this.cultureCoverBase64,
            width: headerLogoWidth,
            height: headerLogoHeight,
            margin: [15, topMargin, 25, 15]
          }
          if (currentPage === 1) return [{}]
          return [resultObj]
        },
        footer: (currentPage) => {
          return this.generateFooter(currentPage)
        },
        background: (currentPage) => {
          const temp = this.calculatePages(currentPage)
          const pageCont = temp.find(t => t.sta <= currentPage && t.end >= currentPage)

          if (!pageCont) return {}

          const label = pageCont.text
          const color = pageCont.color
          const result = {
            image: this.writeRotatedText(label, color, pageCont.isFO),
            aligment: 'center',
            absolutePosition: { x: 810, y: 0 }
          }

          return result
        },
        content: [
          // Portada
          this.$generateCover(),
          // Índice
          this.$generateTableOfContents(),
          // Marco Conceptual
          this.$generateConceptualFramework(this.currentEvaluation.conceptualFramework.detail),
          // Resultados
          this.$generateLiderazgoResults(),
          this.$generateChartGraph(),
          this.$generateResponsibilityResults(),
          this.$generateSpecificResponsibilityResults(),
          // Confirmed Strengths
          this.$generateFoResults(),
          this.$generateGapsAnalysis(this.tableQty + 5),
          this.$generateDispersion(),
          this.$generateTrends(),
          // Open Questions
          this.$generateOpenQuestions(this.tableQty + 7)
        ]
      }
      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, `${this.currentEvaluation.name} - Individual - ${this.evaluatedName}.pdf`)
          this.closeRenderPdf()
        })
      } else {
        pdfmake.createPdf(configuration).download(`${this.currentEvaluation.name} - Individual - ${this.evaluatedName}.pdf`, () => {
          this.closeRenderPdf()
          this.leadership360CoverOnlyBase64 = null
          this.chartTableCharCount = 0
        })
      }
    },
    closeRenderPdf () {
      this.$store.dispatch('loading/hide')
      this.lockPdfButton = false
      this.$emit('pdfRenderedInd')
    },
    getSymbol (auto, others) {
      let dif = auto - others
      return dif > 1 ? ' (+)' : (dif < -1 ? ' (-)' : '')
    },
    getUnknownPercent (data) {
      return data.iNotKnowPercent > 0 ? ` (${data.iNotKnowCount};${data.iNotKnowPercent !== 100 ? this.round(data.iNotKnowPercent, 0) : data.iNotKnowPercent}%)` : ''
    },
    generateFooter (pageNum) {
      const footerContent = []
      const maxFooterLogoWidth = 128
      let footerLogoHeight = 40
      let footerLogoWidth = footerLogoHeight
      let topMargin = -4
      const baseMarginDiff = footerLogoHeight + topMargin
      if (this.leadership360CoverOnlyBase64) {
        footerLogoWidth = footerLogoWidth * this.coverLogoRatio
        if (footerLogoWidth > maxFooterLogoWidth) {
          footerLogoWidth = maxFooterLogoWidth
          footerLogoHeight = maxFooterLogoWidth / this.coverLogoRatio
          topMargin = baseMarginDiff - footerLogoHeight
        }
        footerContent.push({
          columns: [
            {
              image: this.leadership360CoverOnlyBase64,
              fit: [footerLogoWidth, footerLogoHeight],
              margin: [14, topMargin, 0, 0]
            },
            {
              margin: [0, 10, 0, 0],
              width: 'auto',
              text: this.$t('individualReport.copyright'),
              fontSize: 10
            },
            {
              margin: [0, 24, 60, 0],
              text: pageNum.toString(),
              alignment: 'right',
              color: 'grey'
            }
          ]
        })
      } else {
        footerContent.push({
          columns: pdfUtil.generateCenteredText(this.$t('individualReport.copyright'), 10),
          color: 'grey'
        })
        footerContent.push({
          margin: [0, 0, 60, 0],
          text: pageNum.toString(),
          alignment: 'right',
          color: 'grey'
        })
      }
      return footerContent
    }
  }
}
</script>
