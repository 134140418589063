
export default {
  create: 'Create Assessment',
  edit: 'Edit Assessment',
  no_data: 'There are no reviews to show',
  eval_no_data: 'There are no respondents to show',
  settings: 'Questionnaire Settings',
  overview: 'Survey Name',
  date: 'Date',
  questions: 'Questions',
  revition: 'Review and customization',
  poll_name: 'Assessment Name',
  want_external_name: '¿Do you want an external name for the respondents?',
  tooltip_name: 'Internal name of the assessment',
  evaluated_selection: 'Respondents and assessors',
  evaluated_selection_desc: 'In this section, you will be able to choose and assign the leaders who will be part of the survey and their assessors with their relationships. Remember that all the participants of the assessment must be previously uploaded.',
  evaluated_selection_warning: 'After the start date of the assessment, the assessors cannot be modified, nor their relationships with the assessors.',
  hours: 'Sent at (time)',
  hours_valid_until: 'Send before (time)',
  date_delivery: 'Release date',
  poll_valid_until: 'Assessment valid until',
  time_zone: 'Time zone',
  want_send_reminders: '¿Do you want to send reminders?',
  reminders: 'Send reminder',
  scheduled_reminders: 'Scheduled Reminders',
  external_name: 'Sample name',
  send_reminders: 'Send reminders',
  no_reminders: 'No scheduled reminders',
  questionnaire: 'Questionnaire',
  conceptual_framework: 'Conceptual framework',
  edit_questionnaire: 'I want to change the questionary',
  edit_conceptual_framework: 'I want to change the conceptual framework',
  personalization: 'Email customization',
  poll_invitation: 'Invitation to assessment',
  reminder_mail: 'Reminder email',
  tk_message: 'Thank you message',
  message_subject: 'Message Subject',
  body_message: 'Message body',
  message_from_the_company: 'Message from the company',
  all: 'All',
  in_progress: 'In progress',
  pending: 'Pending',
  creating: 'Creating',
  editing: 'Editing',
  completed: 'Completed',
  product_name: 'Liderazgo 360° a la medida',
  questionnaire_e: 'Tailor-made 360° Leadership Questionnaire',
  pdf_explained: 'This file contains the questionnaire questions "{name}" grouped by dimension.',
  copyright: 'Copyright by OCC - All Rights Reserved',
  title: 'OCC - 360° Leadership Report',
  details: 'Assessment Details',
  name: 'Name',
  status: 'Status',
  vigency: 'Validity',
  actions: 'Actions',
  evaluation: '360° Leadership Assessment',
  previous_assessment: 'Previous Assessment',
  prev_assesment_dialog_title: 'Select previous measurement for trend',
  prev_assesment_dialog_description: 'Choose the previous measurement with which you want to obtain trends.<br>Press the Skip button, to skip comparative chart.',
  total: 'Total',
  total_receptors: 'Total respondents: {n}',
  pending_evaluations: 'Pending',
  finished_evaluations: 'completed',
  of_polls: 'of surveys',
  leader: 'Leader',
  leaders: 'Leaders',
  dependent: 'Collaborator',
  dependents: 'Collaborators',
  pair: 'Peers',
  pairs: 'Peers',
  iam_autoevaluation: 'Self-assessment',
  iam_leader: 'I am their leader',
  iam_pair: 'We are peers',
  iam_dependent: 'My leader',
  tooltip_display_name: 'Sample name',
  want_massive: 'Do you want to add the leaders to be assessed and their assessors via bulk upload?',
  massive_upload_recomendations: 'The purpose of this section is to bulk upload the leaders to be assessed and their assessors. To prevent bulk upload errors, please note',
  massive_upload_recomendations_items: [
    'Before bulk uploading you must add all collaborators to the suite.',
    'Click on the download template button to download the file where you are going to define the relationships of each leader to be assessed.',
    'Only the following relationships are allowed: leader, peer, collaborator. The job title is case sensitive. Write relationships in lowercase.',
    'If any of the assessment participants does not have an email, you can add them manually with the add assessor or edit button.',
    'The respondent with all their various assessors must be written only once',
    'The same assessor cannot have two relationships for the same leader to be assessed.'
  ],
  add_evaluated: 'Add respondent',
  edit_evaluators: 'Edit assessors',
  download_instructive: 'Download instructions',
  download_template: 'Download template',
  warning_text: 'Errors have been found in the file data.',
  error_text: 'One of the respondents does not have an assigned assessor type.',
  // Fill
  as_person: 'As a person',
  management: 'Management',
  mobilization: 'Mobilization',
  achievement: 'Achievement',
  open_questions: 'Open questions',
  autoevaluation: 'Self-assessment',
  dont_know: 'IDK',
  information: 'The assessment is made up of a series of questions, which correspond to personal attributes of the leader.',
  information_2: 'The assessment is made up of a series of behaviors and behaviors related to the competencies and values of the Leadership Model, on which each assessor rates the frequency between 1 and 6 according to the following scale',
  score_label1: 'Never',
  score_label2: 'Hardly ever',
  score_label3: 'Rarely',
  score_label4: 'Sometimes',
  score_label5: 'Almost always',
  score_label6: 'Always',
  score_label7: 'I don\'t know',
  // Dialog messages
  chose_an_option: 'Choose an option',
  wellcome_title: 'Welcome to the Leadership assessment.',
  wellcome_instructions: 'The assessment is made up of a series of questions, which correspond to personal attributes of the leader.<br/>The assessment is made up of a series of behaviors and behaviors related to the competencies and values of the leadership model, on which each assessor rates the frequency between 1 and 6 according to the following scale.<br/><br/>Remember... to obtain results that are true to reality, make sure to answer with the truth. Your assessment is neither good nor bad, it is about finding the value that most faithfully reflects reality. We trust your criteria and sincerity.<br/><br/>Make sure you read the information properly.<br/><br/>The deadline to complete the survey is: {deadline}',
  end: 'Thank you very much for participating. You have successfully completed the survey.',
  middle: 'Very good! You have already completed half of the process, stay tuned for each question!',
  invalid_token: 'There is no survey for this access, please enter a valid one',
  before_date: 'The survey is not available for the current date',
  not_available: 'The survey is not available at this time',
  expiration_date: 'The survey you are trying to enter has ended',
  evaluation_completed: 'You have already completed this survey.',
  custom_msg: '<p>Welcome to the <b>tailored Leadership 360° assessment</b>, which collects perceptions of leadership skills from those who work with a leader to complement their self-perception. This information allows for a complete overview of the leader\'s performance and the impact it is having on their work environment.</p>' +
    '<p>This is a perception survey; No question has a right or wrong answer. Your sincerity and directness are essential for the results to be useful in the development of the leader. <b>Your responses are completely confidential</b>.</p>' +
    '<p>Key information about this survey:</p>' +
    '<p>The information provided will be handled <b>confidentially</b> and will be used for statistical purposes.</p>' +
    '<p>For a better experience use Google Chrome, remember that you must be connected to the internet.</p>' +
    '<p>If you have any questions or evidence any problems with the survey, please do not hesitate to contact us at: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</p>' +
    '<p>Remember that <b>this link is personal</b> and should not be shared.</p>' +
    '<p>We thank you in advance for your time in this important process.</p>',
  custom_reminder: '<p>Let us remind you that the <b>tailored 360° Leadership assessment</b> is pending. Your opinion is very important in this process. We want to remind you that:</p>' +
    '<p>This is a perception survey; No question has a right or wrong answer. Your <b>sincerity and directness</b> are essential for the results to be useful in the development of the leader. <b>Your responses are completely confidential</b>.' +
    '<p>Key information about this survey:</p>' +
    '<p>The information provided will be handled <b>confidentially</b> and will be used for statistical purposes.</p>' +
    '<p>For a better experience use <b>Google Chrome</b>, remember that you must be connected to the internet.</p>' +
    '<p>If you have any questions or evidence any problems with the survey, please do not hesitate to contact us at: <a href="mailto:info@occsolutions.org">info@occsolutions.org</a>.</p>' +
    '<p>Remember that <b>this link is personal</b> and should not be shared.</p>' +
    '<p>We thank you in advance for your time in this important process.</p>',
  filter_by: 'Filter by...',
  evaluated: 'Assessed',
  evaluate: 'Assessed',
  start_at: 'Start date',
  end_at: 'Closing Date',
  your_balance: 'Your balance',
  token_unit: 'OCC Tokens',
  balance_after: 'Balance after creating the assessment',
  non_active_employees: 'You must have at least one active collaborator for this survey.',
  acquire_tokens: 'Acquire more OCC Tokens',
  confirm_create_title: 'Confirm creation of the assessment',
  workshop_cost: 'Cost of the Assessment',
  invalid_reminder_date: 'Reminder Date must be within the release period',
  all_right: 'All rights reserved 2020. ©',
  automatic_message: 'This message was automatically sent from',
  show_list: 'See list',
  evaluators: 'Assessors',
  modal_del_title: 'Confirm Delete',
  modal_del_question: 'Do you want to delete this respondent?',
  confirm_edit_title: 'Confirm review edit<br/>New respondents',
  diff_cost: 'Difference cost',
  created_evaluation: 'Assessment created successfully! You will shortly be redirected to the operation summary',
  updated_evaluation: 'Assessment updated successfully',
  delete_evaluator_min_error: 'You cannot remove all assessors from a relationship',
  list_of_evaluators_for: 'List of assessors for',
  add_evaluator: 'Add assessor',
  the_evaluated: 'The respondent',
  the_evaluator: 'The assessor',
  no_evaluated_type: 'You do not have assessors of type',
  fix_document_or_try_again: 'Please correct the document and try again.',
  warnings_list: 'List of warnings',
  multiple_appears_warning: 'You appear more than once as assessed. So, his first participation was taken into account and the following ones were ignored.',
  evaluated_as_evaluator_warning: 'You have entered as an assessor of your own assessment, so this result was ignored and the rest of the assessors were added.',
  evaluated_not_found_warning: 'The evaluated people shown below were not found as collaborators of your company.',
  duplicated_as_evaluator_of: 'You appear more than once as an assessor in the assessment evaluation of',
  take_only_first_ap: 'reason why your first participation was taken into account and the others were ignored',
  added_as_evaluator_type: 'You have entered as assessor of type',
  on_the_evaluation_of: 'in the assessment of',
  not_found_as_employee: 'but you were not found as a collaborator in the system. That is why you were not taken into account and the others were added',
  evaluator_type_incorrect: 'but this type of assessor is incorrect, so you were not taken into account and the others were added',
  are_you_sure_continue_warnings: 'Do you want to continue despite upload failures?',
  remember_only_employee_active: 'Remember that those quiz participants who have not created an email will be ignored.',
  remember_warnings_msg: 'Remember that by continuing the data will remain as explained in the warnings, it is recommended to upload again',
  incorrect_file: 'Please upload a valid file',
  operation_failed: 'Unsuccessful operation, it is recommended',
  paid_measuring: 'The assessment has already been paid.',
  link: 'Copy link',
  copy_invitation_url: 'Copy the link and send it to your collaborators without email to complete the assessment.',
  link_copied: 'Link copied.',
  custom_questionnaire_info: 'If you wish to modify any of the statements in the survey, you may do so, as long as the OCC 360° Leadership model is maintained. Please email us at info@occsolutions.org',
  options: 'Options',
  generate_report: 'Generate reports',
  sending_reminders: 'Send reminders',
  close_evaluation: 'Close assessment',
  close_evaluation_q: 'Do you want to close the assessment?',
  send_reminders_q: 'Do you want to send reminders?',
  reminders_sent_succesfully: 'Reminders sent successfully',
  evaluation_closed_succesfully: 'Assessment closed correctly',
  fileExist: 'Video attached',
  deleteFile: 'Delete file',
  fileExistNote: 'To replace the attached file it is not necessary to delete the previous one.',
  evaluatedExists: 'There are assessees in the file that are already on the list',
  confirmation_modal_title: 'Save answers',
  confirmation_modal_des: 'Be sure to check your answers as once saved they cannot be changed.',
  no_leaders: 'There are no leaders',
  no_pairs: 'There are no peers',
  no_dependents: 'There are no collaborators',
  you_have_been_excluded: 'You have been excluded from this assessment',
  connect_success: 'You are now connected to the system. Your data has been successfully synced.',
  save_success: 'Your answer was successfully saved.',
  save_error: 'Your answer was not saved. Please check your connection.'
}
