
import evaluationService from '../../../services/evaluations'
export default {
  methods: {
    async $getInitialData () {
      await evaluationService.getOneReportByThreadId(this.thread._id, this.pollId)
        .then((res) => {
          this.currentEvaluation = res.thread.data.evaluation
          this.questionnaire = res.thread.data.questionnaire
          if (res.enterpriseLogo) {
            this.leadership360CoverOnlyBase64 = `data:image/png;base64,${res.enterpriseLogo}`
          }
          this.hasPrevious = res.thread.data.hasPrevious

          // set participation
          this.completedPollsFiltered = res.thread.data.totalAnswersFilteredByrelationCompleted
          this.expectedPollsFiltered = res.thread.data.totalAnswersFilteredByrelation

          // set answers
          this.answersResponsibility = res.thread.data.answersResponsibility
          this.highestScoreSpecificEvaluatorsItems = res.thread.data.highestScoreSpecificEvaluatorsItems
          this.lowestScoreSpecificEvaluatorsItems = res.thread.data.lowestScoreSpecificEvaluatorsItems
          this.strengthsConfirmed = res.thread.data.strengthsConfirmed
          this.strengthsUnknown = res.thread.data.strengthsUnknown
          this.improvementConfirmed = res.thread.data.improvementConfirmed
          this.improvementUnknown = res.thread.data.improvementUnknown
          this.positiveGaps = res.thread.data.positiveGaps
          this.negativeGaps = res.thread.data.negativeGaps
          this.evaluatorsGaps = res.thread.data.evaluatorsGaps
          this.highestScoreScatterSpecific = res.thread.data.highestScoreScatterSpecific
          this.lowestScoreScatterSpecific = res.thread.data.lowestScoreScatterSpecific
          this.positiveTrends = res.thread.data.positiveTrends
          this.negativeTrends = res.thread.data.negativeTrends
          this.openQuestions = res.thread.data.openQuestions.filtered
          this.generateFlowerChart()
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  }
}
