<template>
  <v-container>
    <template>
      <v-row>
        <v-col>
          <h4 class="display-1">{{ $t('questionnaires.edit_questions') }}</h4>
        </v-col>
      </v-row>
      <v-card>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          light
          grow
        >
          <v-tab>
            {{ $t('questionnaires.customization') }}
          </v-tab>
          <v-tab
            v-for="(evaluation, i) in questionnaire.evaluations"
            :key="i"
          >
            {{ $t(`questionnaires.${i}`) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-form ref="customForm" @submit.prevent="sendCustomization">
                <v-card-text>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="questionnaire.generalResponsibility[user.lang]"
                        :label="$t('questionnaires.generalResponsibility')"
                        :rules="[rules.required]"
                        required
                        flat
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="questionnaire.specificResponsibility[user.lang]"
                        :label="$t('questionnaires.specificResponsibility')"
                        :rules="[rules.required]"
                        required
                        flat
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="flex justify-center">
                  <v-btn
                    to="/questionnaires"
                    text
                  >
                    {{ $t('input.back') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                  >
                    {{ $t('input.update') }}
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-tab-item>

          <v-tab-item
            v-for="(item, iE) in questionnaire.evaluations"
            :key="iE"
          >
            <v-card flat class="px-4">
              <v-row v-if="iE === 'openQuestions'">
                <listOpenQuestions :information="questionnaire" :items="questionnaire.evaluations.openQuestions" />
              </v-row>
              <v-row v-else>
                <v-col>
                  <v-card-text>
                    <v-alert class="mt-2" text dense type="warning" dismissible>
                      {{ $t('questionnaires.configuration_warning') }}
                    </v-alert>
                    <v-form ref="evaluationsForm">
                      <v-expansion-panels multiple>
                        <v-expansion-panel
                          v-for="(general, iG) in item"
                          :key="iG"
                        >
                          <v-expansion-panel-header class="mb-n3">
                            <v-row no-gutters justify="end">
                              <v-col cols="auto" v-if="item.length > criteria.general.min && iE === 'autoEvaluation'">
                                <v-btn text x-small
                                  color="red"
                                  class="mt-2"
                                  @click="deleteGeneralResponsibility(item, iG)"
                                >
                                  <v-icon>mdi-window-close</v-icon>
                                </v-btn>
                              </v-col>
                              <v-col :cols="11" justify="end">
                                <v-tooltip
                                  bottom
                                  color="green"
                                  class="ma-0"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field light outlined dense
                                      v-on="on"
                                      v-model="general.name[user.lang]"
                                      :rules="[rules.required]"
                                      :ref="`${iE}${iG}`"
                                      :label="questionnaire.generalResponsibility[user.lang]"
                                      name="responsability"
                                      color="green"
                                      @keypress.enter="sendForm(iE, iG)"
                                    >
                                      <template v-slot:append>
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on }">
                                            <v-icon v-if="iE === 'generalEvaluation'"
                                              v-on="on"
                                              @click.stop="editDescription(general, iE, iG)"
                                            >
                                              mdi-content-save-edit-outline
                                            </v-icon>
                                          </template>
                                          <span>
                                            {{ $t('questions.edit_full_desc') }}
                                          </span>
                                        </v-tooltip>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <span>
                                    {{ $t('questionnaires.tooltip_edit') }}
                                  </span>
                                </v-tooltip>
                              </v-col>
                              <v-col cols="12"  class="text-right mt-n3" v-if="withEmptyValues.includes(`${iE}${iG}`)">
                                <span class="caption red--text" v-text="$t('questionnaires.empty_questions')" />
                              </v-col>
                            </v-row>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-list dense flat>
                              <v-list-item-group v-model="list">
                                <v-list-item v-for="(specific, iS) in general.specifics" :key="iS">
                                  <v-row no-gutters class="flex justify-end">
                                    <v-col cols="auto" align="end" v-if="general.specifics.length > criteria.specific.min && iE === 'autoEvaluation'">
                                      <v-btn text x-small color="red" @click="deleteSpecificResponsibility(general,iS,iG)">
                                        <v-icon>mdi-window-close</v-icon>
                                      </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                      <v-tooltip
                                        bottom
                                        color="blue"
                                      >
                                        <template v-slot:activator="{ on }" >
                                          <v-text-field dense light outlined
                                            v-on="on"
                                            v-model="specific[user.lang]"
                                            :input="specific[user.lang]"
                                            :rules="[rules.required]"
                                            :label="questionnaire.specificResponsibility[user.lang]"
                                            name="question"
                                            color="blue"
                                            @keypress.enter="verifiedInput(iE, iG, iS)"
                                          />
                                        </template>
                                        <span>
                                        {{ $t('questionnaires.tooltip_edit') }}
                                      </span>
                                      </v-tooltip>
                                    </v-col>
                                  </v-row>
                                </v-list-item>
                                <v-row class="flex justify-center" v-if="general.specifics.length < criteria.specific.max && iE === 'autoEvaluation'">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn x-small fab outlined color="primary" v-on="on" @click="addSpecificResponsibility(general, iG)">
                                        <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ add[user.lang]+' '+questionnaire.specificResponsibility[user.lang] }}</span>
                                  </v-tooltip>
                                </v-row>
                              </v-list-item-group>
                            </v-list>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row v-if="item.length < criteria.general.max && iE === 'autoEvaluation'">
                <v-col align="center" cols="12">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mr-8" x-small fab outlined color="blue" v-on="on" @click="addGeneralResponsibility(item)">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ add[user.lang]+' '+questionnaire.generalResponsibility[user.lang] }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    to="/questionnaires"
                    text
                  >
                    {{ $t('input.back') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
    <!-- Edit Details Modal -->
    <x-edit-details :key="editingIdx"
      :show-dialog="showDescriptionModal"
      :rules="rules"
      :model="editingDescription"
      @saveDescription="changeDescription"
      @close="closeDescription()"
    ></x-edit-details>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import questionnairesService from '../../services/questionnaires'
import listOpenQuestions from '../open-questions/list'
import XEditDetails from './components/edit-details'

export default Vue.extend({
  components: {
    listOpenQuestions,
    XEditDetails
  },
  data () {
    return {
      editingIdx: null,
      editingDescription: '',
      showDescriptionModal: false,
      list: 1,
      qClone: {}, // Questionnaire clone for comparison
      questionnaire: {
        evaluations: {},
        slug: '',
        generalResponsibility: {},
        specificResponsibility: {}
      },
      criteria: {
        general: {
          min: 3,
          max: 15
        },
        specific: {
          min: 2,
          max: 15
        }
      },
      tab: null,
      // maxLength: 80,
      rules: {
        required: v => !!v || this.$t('input.required_field')
      },
      add: {
        en: 'Add New',
        es: 'Agregar Nueva'
      },
      withEmptyValues: []
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getQuestionnaire()
  },
  methods: {
    getQuestionnaire () {
      this.$store.dispatch('loading/show')
      return questionnairesService.getOne(this.$route.params.slug)
        .then((res) => {
          this.questionnaire = res
          this.qClone = JSON.parse(JSON.stringify(this.questionnaire))
          this.$store.dispatch('loading/hide')
        }).catch(() => {
          this.$store.dispatch('loading/hide')
        })
    },
    sendForm (iE = null, iG = null, iS = null) {
      // Check if evaluation type & Gral. Responsability index are provided
      if (iE !== null && iG !== null) {
        // Check if Specific Responsability index is provided
        if (iS !== null) {
          // Is general edit. Check if text has changed
          if (this.qClone.evaluations[iE][iG] && this.qClone.evaluations[iE][iG].specifics[iS] && this.questionnaire.evaluations[iE][iG].specifics[iS][this.user.lang] === this.qClone.evaluations[iE][iG].specifics[iS][this.user.lang]) {
            // Has not changed. Do not update
            return
          }
        } else {
          // Is specific edit. Check if text has changed
          if (this.qClone.evaluations[iE][iG] && this.questionnaire.evaluations[iE][iG].name[this.user.lang] === this.qClone.evaluations[iE][iG].name[this.user.lang]) {
            // Has not changed. Do not update
            return
          }
        }
      }
      if (!this.validatedForms()) {
        return
      }
      this.$store.dispatch('loading/show')
      return this.sendData('edit')
    },
    validatedForms () {
      const error = () => this.$store.dispatch('alert/error', this.$t('errors.validator/verify_fields'))
      let valid = 1
      const form = this.$refs['evaluationsForm']
      if (form[0] && !form[0].validate()) {
        error()
        this.tab = 1
        valid = 0
      }
      if (form[1] && !form[1].validate()) {
        error()
        this.tab = 2
        valid = 0
      }
      return valid
    },
    sendCustomization () {
      if (!this.$refs['customForm'].validate()) {
        this.$store.dispatch('alert/error', this.$t('questionnaires.error_editing'))
        return
      }
      // this.verifiedDocument()
      this.$store.dispatch('loading/show')
      return this.sendData('customize')
    },
    sendData (action) {
      return questionnairesService[action](this.questionnaire.slug, this.questionnaire)
        .then((res) => {
          this.$store.dispatch('loading/hide')
          this.$store.dispatch('alert/success', this.$t('questionnaires.edited_succesfully'))
          this.qClone = JSON.parse(JSON.stringify(res))
          if (this.showDescriptionModal) {
            this.closeDescription()
          }
        })
        .catch(() => {
          this.$store.dispatch('loading/hide')
          this.$store.dispatch('alert/error', this.$t('questionnaires.error_editing'))
        })
    },
    addGeneralResponsibility (item) {
      const newResp = () => {
        return {
          name: {
            en: this.questionnaire.generalResponsibility.en,
            es: this.questionnaire.generalResponsibility.es
          },
          specifics: [
            {
              index: 1,
              en: this.questionnaire.specificResponsibility.en,
              es: this.questionnaire.specificResponsibility.es
            },
            {
              index: 2,
              en: this.questionnaire.specificResponsibility.en,
              es: this.questionnaire.specificResponsibility.es
            }
          ]
        }
      }
      item.push(newResp())
      this.questionnaire.evaluations.generalEvaluation.push(newResp())
    },
    deleteGeneralResponsibility (item, index) {
      item.splice(index, 1)
      this.questionnaire.evaluations.generalEvaluation.splice(index, 1)
      this.sendForm()
    },
    addSpecificResponsibility (item, indexG) {
      this.addNewSpecific(item)
      this.addNewSpecific(this.questionnaire.evaluations.generalEvaluation[indexG])
    },
    addNewSpecific (item) {
      const newEspec = {
        index: item.specifics.length + 1,
        en: this.questionnaire.specificResponsibility.en,
        es: this.questionnaire.specificResponsibility.es
      }
      item.specifics.push(newEspec)
    },
    deleteSpecificResponsibility (item, index, indexG) {
      item.specifics.splice(index, 1)
      this.questionnaire.evaluations.generalEvaluation[indexG].specifics.splice(index, 1)
      this.sendForm()
    },
    editDescription (data, type, idx) {
      this.$refs[`${type}${idx}`][0].blur()
      this.editingIdx = idx
      if (data.description) {
        this.editingDescription = data.description[this.user.lang]
      }
      this.showDescriptionModal = true
    },
    changeDescription (val) {
      const key = 'generalEvaluation'
      if (!this.questionnaire.evaluations[key][this.editingIdx].description) {
        this.questionnaire.evaluations[key][this.editingIdx].description = {
          en: 'xxxxxxxxxx',
          es: 'xxxxxxxxxx'
        }
      }
      this.questionnaire.evaluations[key][this.editingIdx].description[this.user.lang] = val

      this.$store.dispatch('loading/show')
      return this.sendData('edit')
    },
    closeDescription () {
      this.showDescriptionModal = false
      this.editingIdx = null
      this.editingDescription = ''
    },
    verifiedInput (indexEval, indexGen, indexSpe) {
      const specifics = (this.questionnaire.evaluations[indexEval][indexGen]['specifics']).filter(spec => spec[this.user.lang] === '' || spec[this.user.lang] === undefined)
      const error = `${indexEval}${indexGen}`
      if (specifics.length) {
        this.verifiedIfErrorExist(error)
      } else {
        this.withEmptyValues.splice(this.withEmptyValues.findIndex(i => i === error), 1)
      }
      this.sendForm(indexEval, indexGen, indexSpe)
    },
    verifiedIfErrorExist (error) {
      if (!this.withEmptyValues.includes(error)) {
        this.withEmptyValues.push(error)
      }
    }
  }
})
</script>
