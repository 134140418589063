
export default {
  cover_table_header_1: 'ESPERADAS',
  cover_table_header_2: 'RECIBIDAS',
  cover_table_header_3: 'PORCENTAJE DE RESPUESTAS',
  cover_table_row_1: 'AUTOVALORACIONES',
  cover_table_row_2: 'LÍDER(ES)',
  cover_table_row_3: 'PARES',
  cover_table_row_4: 'COLABORADORES',
  cover_table_row_5: 'TOTAL',
  index_title: 'Contenido del informe',
  title: 'Informe de Resultados',
  subtitle: 'Reporte Organizacional de Liderazgo',
  poll_answers: 'Respuestas a la encuesta',
  autoevaluations: 'Autovaloraciones',
  leaders: 'Líder(es)',
  pairs: 'Pares',
  dependents: 'Colaboradores',
  total: 'Total',
  received_evaluations: 'Valoraciones Recibidas',
  received: 'Recibidas',
  requested_evaluations: 'Valoraciones Solicitadas',
  requested: 'Esperadas',
  answers_rate: 'Porcentaje de Respuestas',
  legend_description: 'En las barras de la siguiente tabla, el color gris, el rombo y la cruz representan:',
  dispersion_description: 'La dispersión se calcula como la desviación estándar de los datos',
  table_description: 'En las barras de las tablas, el color gris, la cruz y el rombo representan: ',
  higher_scores: 'Puntajes más altos',
  lower_scores: 'Puntajes más bajos',
  general_responsability: 'Responsabilidad',
  specific_responsability: 'Comportamiento',
  score: 'Puntaje',
  previous_score: 'Puntaje Anterior',
  previous_score_abbr: 'Punt. Ant.',
  current_score: 'Puntaje Actual',
  cut_score: 'Puntaje Corte',
  previous: 'Anterior',
  current: 'Actual',
  ten_percent: '10% Superior',
  dispersion: 'Dispersión',
  trend: 'Tendencia',
  higher_trends: 'Tendencias más altas positivas',
  lower_trends: 'Tendencias más altas negativas',
  trend_abbr: 'Tend.',
  data_range: 'Rango de datos (máx-min)',
  higher_bold: 'Calificaciones más altas en negrilla',
  lower_sub: 'Calificaciones más bajas subrayadas',
  management: 'Dirección',
  mobilization: 'Movilización',
  achievement: 'Logro',
  as_person: 'Como persona',
  leader_as_person: 'Líder como persona',
  results: 'Resultados',
  copyright: 'OCC - Todos los derechos reservados.',
  higher_dispersion: 'Mayor dispersión',
  lower_dispersion: 'Menor dispersión',
  higher_gap: 'Brechas más altas positivas',
  lower_gap: 'Brechas más altas negativas',
  gap: 'Brecha',
  months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  lead_by_example: 'Lidera la cultura con el ejemplo',
  ensures_learning: 'Asegura aprendizaje continuo y desarrollo integral',
  confirm_report_title: 'Confirme la generación del reporte',
  report_cost: 'Costo del reporte',
  balance_after: 'Saldo luego de generar el reporte:',
  confirm_btn: 'Confirmar y Generar',
  opetaion_init: 'Se ha dado inicio a un proceso de descarga "Reporte organizacional", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',

  background_1: 'CONTENIDO',
  background_2: 'RESULTADOS',
  background_3: 'DISPERSIÓN',
  background_4: 'TENDENCIA',

  scores_title_1: '1. Responsabilidades con Puntaje Mayor y Menor según Relación',
  scores_title_1_sub_1: 'Tabla 1. Tres Puntajes más altos y bajos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  scores_title_1_sub_2: 'Tabla 2. Tres Puntajes más altos y bajos según Líder(es)',
  scores_title_1_sub_3: 'Tabla 3. Tres Puntajes más altos y bajos según Pares',
  scores_title_1_sub_4: 'Tabla 4. Tres Puntajes más altos y bajos según Colaboradores',
  scores_title_2: '2. Comportamientos con Puntaje Mayor y Menor según Relación',
  scores_title_2_sub_5: 'Tabla 5. Cinco Puntajes más altos y bajos según Otros; donde Otros es el promedio de todas las relaciones (Líderes, Pares y Colaboradores)',
  scores_title_2_sub_6: 'Tabla 6. Cinco Puntajes más altos y bajos según Líder(es)',
  scores_title_2_sub_7: 'Tabla 7. Cinco Puntajes más altos y bajos según Pares',
  scores_title_2_sub_8: 'Tabla 8. Cinco Puntajes más altos y bajos según Colaboradores',
  scores_table_1_header_1: 'PUNTAJES MÁS ALTOS',
  scores_table_1_header_2: 'PUNTAJES MÁS BAJOS',
  scores_table_1_sub_header_1: 'Responsabilidad',
  scores_table_1_sub_header_2: 'Comportamiento',
  scores_table_1_sub_header_3: 'Puntaje',
  scores_table_1_sub_header_4: 'Puntaje Anterior',
  scores_table_1_sub_header_5: 'Tendencia',
  scores_table_2_header_1: 'PUNTAJES MÁS ALTOS',
  scores_table_2_header_2: 'PUNTAJES MÁS BAJOS',
  scores_table_2_sub_header_1: 'D',
  scores_table_2_sub_header_2: 'COMPETENCIA',
  scores_table_2_sub_header_3: 'CONDUCTA',
  scores_table_2_sub_header_4: 'PUNTAJE',
  scores_table_2_sub_header_5: 'TENDENCIA',

  results_title_1: '3. Resultados por Responsabilidad',
  results_title_1_sub_1: 'Tabla 9. Resultados por Responsabilidad',
  results_legend_1_table_1: 'Rango de datos (min-max)',
  results_legend_1_table_2: 'Puntaje',
  results_legend_1_table_3: 'Calificaciones más altas en negrita',
  results_legend_1_table_4: 'Calificaciones más bajas en subrayadas',
  results_title_2: '4. Resultados por Comportamiento',
  results_title_2_sub_2: 'Tabla {0}. Resultados por Responsabilidad y Comportamiento {1}',

  scatter_title: '5. Análisis de Dispersión por Responsabilidad y Comportamiento',
  scatter_info: 'La dispersión se calcula como la desviación estándar de los datos',
  scatter_table_1_title: 'Tabla {0}. Dispersión por Responsabilidad',
  scatter_table_1__header_1: 'MAYOR DISPERSIÓN',
  scatter_table_1__header_2: 'MENOR DISPERSIÓN',
  scatter_table_1__sub_header_2: 'Dispersión',
  scatter_table_2_title: 'Tabla {0}. Dispersión por Comportamiento',

  trend_title: '6. Análisis de Tendencias',
  trend_msg: 'Disponible a partir de la segunda medición',
  trend_table_1_title: 'Tabla {0}. Tendencia por Comportamiento',
  trend_table_1__header_1: 'TENDENCIAS POSITIVAS',
  trend_table_1__header_2: 'TENDENCIAS NEGATIVAS',
  trend_table_2_header_5: 'Tendencia',

  gap_title: '7. Análisis de Brechas',
  gap_msg: 'Disponible a partir de la segunda medición',
  gap_table_1_title: 'Tabla {0}. Brechas por Comportamiento',
  gap_table_1__header_1: 'BRECHAS MÁS ALTAS POSITIVAS',
  gap_table_1__header_2: 'BRECHAS MÁS ALTAS NEGATIVAS',
  gap_table_1_sub_header_3: 'Puntaje Corte',
  gap_table_1_sub_header_4: 'Puntaje Total',
  gap_table_2_header_5: 'Brecha'
}
