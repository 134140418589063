
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-container grid-list-md text-xs-center>
              <ValidationObserver ref="open_questions_form" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit()">
                  <v-row wrap>
                    <!-- General Questions -->
                    <v-col cols="12" class="pa-5 pt-0 headline">
                      {{ $t('questions.general') }}
                    </v-col>
                    <v-col cols="12"
                      v-for="(question, index) in generalQuestions"
                      v-bind:key="`general-${index}`"
                      class="py-0"
                    >
                      <ValidationProvider v-slot="{ errors }"
                        :vid="`general-${index}`"
                        :name="`general-${index}`"
                        rules="required"
                      >
                        <v-text-field outlined autofocus
                          v-model="question.general"
                          :append-icon="question.generalIcon || 'mdi-content-save-edit-outline'"
                          :color="question.generalColor || 'blue'"
                          :error-messages="errors"
                          @blur="editQuestion()"
                          @keyup.enter="editQuestion()"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <!-- Auto Questions -->
                    <v-col cols="12" class="pa-5 headline">
                      {{ $t('questions.auto') }}
                    </v-col>
                    <v-col cols="12"
                      v-for="(question, index) in generalQuestions"
                      v-bind:key="`auto-${index}`"
                      class="py-0"
                    >
                      <ValidationProvider v-slot="{ errors }"
                        :vid="`auto-${index}`"
                        :name="`auto-${index}`"
                        rules="required"
                      >
                        <v-text-field outlined autofocus
                          v-model="question.auto"
                          :append-icon="question.autoIcon || 'mdi-content-save-edit-outline'"
                          :color="question.autoColor || 'blue'"
                          :error-messages="errors"
                          @blur="editQuestion()"
                          @keyup.enter="editQuestion()"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </form>
              </ValidationObserver>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import resolver from '../../utils/resolver'
import OpenQuestionService from '../../services/open-questions'
import questionnairesService from '../../services/questionnaires'

export default Vue.extend({
  data () {
    return {
      generalQuestions: [],
      language: '',
      languages: [],
      questions: []
    }
  },
  methods: {
    getOpenQuestions (options) {
      OpenQuestionService.list(options)
        .then(resp => ({
          items: resp,
          total: resp.length
        }))
    },
    formatQuestionsByLanguage () {
      this.generalQuestions = this.items.map((question) => ({
        name: question.name,
        generalColor: 'blue',
        autoColor: 'blue',
        generalIcon: 'mdi-content-save-edit-outline',
        autoIcon: 'mdi-content-save-edit-outline',
        general: question.general[this.language],
        auto: question.auto[this.language]
      }))
    },
    editQuestion () {
      this.$refs.open_questions_form.validate().then((success) => {
        if (success) {
          // Check if any of the questions changed
          let hasChanged = false
          this.generalQuestions.forEach((itm, inx) => {
            if (itm.general !== this.items[inx].general[this.language] ||
                itm.auto !== this.items[inx].auto[this.language]) {
              hasChanged = true
              this.items[inx].general[this.language] = itm.general
              this.items[inx].auto[this.language] = itm.auto
            }
          })
          if (hasChanged) {
            this.information.evaluations.openQuestions = this.items
            return questionnairesService.edit(this.information.slug, this.information)
              .then((res) => {
                this.$store.dispatch('alert/success', this.$t('questionnaires.edited_succesfully'))
              }).catch(() => {
                this.$store.dispatch('alert/error', this.$t('questionnaires.error_editing'))
              })
              .finally(() => {
                this.$store.dispatch('loading/hide')
              })
          }
        } else {
          this.$store.dispatch('alert/error', this.$t('errors.question_empty'))
        }
      })
    }
  },
  created () {
    resolver.all({
      // languages: languagesService.list(),
      questions: OpenQuestionService.list()
    })
      .then(({ languages, questions }) => {
        this.language = this.user.lang
        /* this.languages = languages.items.map((l) => ({
          text: l.translate.label,
          value: l.code
        })) */
        this.questions = questions
      })
      .catch((error) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${error.code}`))
      })
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        this.language = val
      }
    },
    language () {
      this.formatQuestionsByLanguage()
    }
  },
  props: ['items', 'information']
})
</script>
