<template>
  <v-row row justify-center>
    <v-dialog persistent
      v-model="showDialog"
      width="600px"
    >
      <v-card>
        <v-card-title>
          {{ $t('questions.edit_full_desc') }}
        </v-card-title>
        <v-card-text class="pt-4 pb-0">
          <v-textarea light outlined dense no-resize
            v-model="description"
            :rules="[rules.required]"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="grey lighten-1"
            @click="closeModal"
          >
            {{ $t('input.close') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="computedDisabled"
            @click="$emit('saveDescription', description)"
          >
            {{ $t('input.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    showDialog: Boolean,
    rules: Object,
    model: String
  },
  data: () => ({
    description: ''
  }),
  computed: {
    computedDisabled () {
      return !this.description || this.model === this.description
    }
  },
  watch: {
    model: {
      handler (val) {
        if (val) {
          this.description = val
        }
      },
      immediate: true
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    }
  }
})
</script>
