
const examplesWords = ['Cuerda', 'Limpieza', 'Esmalte', 'Propina', 'Bailarina', 'Micro', 'Venecia', 'Chinche', 'Alzar', 'Casado', 'trasnocha', 'Cabina', 'Nombre', 'Malo', 'Pesadilla', 'Vegetal', 'Buzo', 'Tragaluz', 'Detenerse', 'Judas', 'Morder', 'Trigo', 'Alquiler', 'Kamikaze', 'Cordón', 'Oasis', 'Biplano', 'Fingir', 'Insensible', 'Lucifer', 'Calentador', 'Camping', 'Pasamanos', 'Nacimiento', 'Adoptar', 'Tierras', 'Soldar', 'Indios', 'Picotear', 'Visera', 'Lanza', 'Remolcador', 'Manubrio', 'Velero', 'Papelera', 'Reno', 'Ovillo', 'Transparente', 'Calculadora', 'China', 'Sastre', 'Altavoz', 'Consistencia', 'Granjero', 'Decir', 'Negocios', 'Revocar', 'Estatua', 'Cometa', 'Sentado', 'Acupuntura', 'Momia', 'Perla', 'Ciego', 'Fecha', 'Hincha', 'Involuntario', 'Diagrama', 'Escuchar', 'Bailarina', 'Moverse', 'Abrigo', 'Coma']

function getIdx (max, min) {
  const num = Math.floor(Math.random() * 10)
  if (num > max) {
    return max
  } else if (num < min) {
    return min
  }
  return num
}

export default (vueInstance, options) => {
  options = options || {}
  const min = options.min && typeof options.min === 'number' ? options.min - 1 : 0
  const max = options.max && typeof options.max === 'number' ? options.max - 1 : 5
  const words = options.words && typeof options.words === 'object' ? options.words : examplesWords

  const divs = document.querySelectorAll('.v-input--radio-group__input')
  for (let i = 0; i < divs.length; i++) {
    divs[i].querySelectorAll('input[type="radio"]')[getIdx(max, min)].click()
  }

  if (words.length) {
    const inputs = document.querySelectorAll('input[type="text"]')
    for (let i = 0; i < inputs.length; i++) {
      const rnd = Math.floor(i / 3)
      if (vueInstance.openQuestions[rnd] && vueInstance.openQuestions[rnd].answer) {
        vueInstance.openQuestions[rnd].answer[Math.floor(i % 3)] = words[getIdx(words.length - 1, 0)]
      }
    }
    vueInstance.answerOpenQuestions()
  }
}
