import PdfUtil from '../utils/pdf'

export default {
  data () {
    return {
      // chrs: 'wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww',
      maxFoRespChars: 173
    }
  },
  methods: {
    limitFoText (str) {
      // return this.chrs.slice(0, this.maxFoRespChars) + '...'
      return str.length > this.maxFoRespChars ? str.slice(0, this.maxFoRespChars) + '...' : str
    },
    legendTbl (clr) {
      return {
        layout: {
          defaultBorder: '',
          border: [false, false, false, true]
        },
        table: {
          headerRows: 1,
          widths: ['48%', '52%'],
          body: [
            [
              { text: [{ text: `${this.$t('individualReport.confirmed_strengths')}: `, bold: true }, `${this.$t('individualReport.confirmed_strengths_text')}`], color: clr, margin: [0, 3, 0, 0], border: [true, true, true, true], fontSize: 9 },
              { text: [{ text: `${this.$t('individualReport.unknown_strengths')}: `, bold: true }, `${this.$t('individualReport.unknown_strengths_text')}`], color: clr, border: [true, true, true, true], margin: [0, 3, 0, 0], fontSize: 9 }
            ],
            [
              { text: [{ text: `${this.$t('individualReport.confirmed_improvement_opportunities')}: `, bold: true }, `${this.$t('individualReport.confirmed_improvement_opportunities_text')}`], color: clr, margin: [0, 3, 0, 0], border: [true, true, true, true], fontSize: 9 },
              { text: [{ text: `${this.$t('individualReport.unknown_improvement_opportunities')}: `, bold: true }, `${this.$t('individualReport.unknown_improvement_opportunities_text')}`], color: clr, border: [true, true, true, true], margin: [0, 3, 0, 0], fontSize: 9 }
            ]
          ]
        },
        margin: [7, 0, 10, 17],
        border: [false, false, false, true],
        alignment: 'center',
        fontSize: 8
      }
    },
    $generateFoResults () {
      const strengths = []
      const opportunities = []
      const confirmedStrengths = this.strengthsConfirmed
      const unknownStrengths = this.strengthsUnknown
      const confirmedOpportunities = this.improvementConfirmed
      const unknownOpportunities = this.improvementUnknown
      const getName = (general, specific) => {
        return !general || !specific ? '' : this.questionnaire.general[general].specific[specific][this.user.lang]
      }
      const textColor = '#6d7a7a'
      for (let i = 0; i < 4; i++) {
        const confirmedStrengthsName = getName(confirmedStrengths[i].general, confirmedStrengths[i].specific)
        const unknownStrengthsName = getName(unknownStrengths[i].general, unknownStrengths[i].specific)
        const confirmedOpportunitiesName = getName(confirmedOpportunities[i].general, confirmedOpportunities[i].specific)
        const unknownOpportunitiesName = getName(unknownOpportunities[i].general, unknownOpportunities[i].specific)
        strengths.push([
          // Confirmed
          { text: i < confirmedStrengths.length && confirmedStrengthsName ? this.limitFoText(confirmedStrengthsName) : '--', margin: [0, 1, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < confirmedStrengths.length && confirmedStrengthsName ? (confirmedStrengths[i].second >= 1 ? this.round(confirmedStrengths[i].second, 2) : '--') : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < confirmedStrengths.length && confirmedStrengthsName ? (confirmedStrengths[i].score >= 1 ? this.round(confirmedStrengths[i].score, 2) : '--') : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          // Unknown
          { text: i < unknownStrengths.length && unknownStrengthsName ? this.limitFoText(unknownStrengthsName) : '--', margin: [0, 1, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < unknownStrengths.length && unknownStrengthsName ? (unknownStrengths[i].second >= 1 ? this.round(unknownStrengths[i].second, 2) : '--') : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < unknownStrengths.length && unknownStrengthsName ? (unknownStrengths[i].score >= 1 ? this.round(unknownStrengths[i].score, 2) : '--') : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
        opportunities.push([
          // Confirmed
          { text: i < confirmedOpportunities.length && confirmedOpportunitiesName ? this.limitFoText(confirmedOpportunitiesName) : '--', margin: [0, 1, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < confirmedOpportunities.length && confirmedOpportunitiesName ? (confirmedOpportunities[i].second >= 1 ? this.round(confirmedOpportunities[i].second, 2) : '--') : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < confirmedOpportunities.length && confirmedOpportunitiesName ? (confirmedOpportunities[i].score >= 1 ? this.round(confirmedOpportunities[i].score, 2) : '--') : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          // Unknown
          { text: i < unknownOpportunities.length && unknownOpportunitiesName ? this.limitFoText(unknownOpportunitiesName) : '--', margin: [0, 1, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < unknownOpportunities.length && unknownOpportunitiesName ? (unknownOpportunities[i].second >= 1 ? this.round(unknownOpportunities[i].second, 2) : '--') : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: i < unknownOpportunities.length && unknownOpportunitiesName ? (unknownOpportunities[i].score >= 1 ? this.round(unknownOpportunities[i].score, 2) : '--') : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
      }
      return [
        PdfUtil.generateTitleLandscape(this.$t('individualReport.confirmed_fo_title'), 'before', true, true),
        // PAGE ONE (Strengths)
        this.legendTbl(textColor), // Computed Legend Table
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('individualReport.confirmed_strengths').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                { text: this.$t('individualReport.unknown_strengths').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {}
              ],
              // Header
              [
                { text: this.specResponsibilityTitle, margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [-2, 1, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.specResponsibilityTitle, margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [-2, 1, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...strengths
            ],
            widths: ['*', 17, 17, '*', 17, 17]
          },
          margin: [-5, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        // PAGE TWO (Opportunities)
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('individualReport.confirmed_improvement_opportunities').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                { text: this.$t('individualReport.unknown_improvement_opportunities').toUpperCase(), colSpan: 3, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {}
              ],
              // Header
              [
                { text: this.specResponsibilityTitle, margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [-2, 1, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.specResponsibilityTitle, margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [-2, 1, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...opportunities
            ],
            widths: ['*', 17, 17, '*', 17, 17]
          },
          margin: [-5, 0, 0, 0],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
