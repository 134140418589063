<template>
  <v-container>
    <v-row>
      <v-col>
        <h4 class="display-1"> {{ $t('conceptualFrameworks.create') }} </h4>
      </v-col>
    </v-row>
    <v-card class="px-4">
      <ValidationObserver
        v-slot="{ handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(saveConceptualFramework)">
          <v-row>
            <v-col cols="12" sm="6">
              <x-inputs-input
                :input="form.name"
                @updateInput="($event) => form.name = $event"
                :label="$t('input.name')"
                rules="required"
                autofocus
                light
                name="name"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <x-inputs-autocomplete light
                :items="conceptual_frameworks"
                :input="form.baseConceptual"
                :label="$t('conceptualFrameworks.base')"
                :rules="conceptual_frameworks.length ? 'required' : ''"
                name="base_conceptual_framework"
                @updateInput="($event) => form.baseConceptual = $event"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <x-inputs-autocomplete clearable light
                :items="assignationTypes"
                :input="form.assignationType"
                :label="$t('conceptualFrameworks.asignation_type')"
                name="assign_type"
                @updateInput="($event) => form.assignationType = $event"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <x-inputs-autocomplete light
                :items="assignations"
                :input="form.assignationFor"
                :label="$t('conceptualFrameworks.assign_to')"
                name="assign_for"
                :rules="form.assignationType ? 'required' : ''"
                @updateInput="($event) => form.assignationFor = $event"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col align="end">
              <v-btn
                text
                to="/conceptual_frameworks"
              >
                {{ $t('input.cancel') }}
              </v-btn>
              <v-btn
                type="submit"
                color="primary"
              >
                {{ $t('input.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'

import resolver from '../../utils/resolver'

import conceptualFrameworksService from '../../services/conceptual-frameworks'
import customersService from '../../services/customers'
import sectorsService from '../../services/sectors'

export default Vue.extend({
  data () {
    return {
      conceptual_frameworks: [],
      form: {
        name: '',
        baseConceptual: '',
        assignationType: '',
        assignationFor: ''
      },
      assignationTypes: [
        { text: '', value: 'customer' },
        { text: '', value: 'enterprise' },
        { text: '', value: 'sector' }
      ],
      customersResponse: [],
      sectorsRespone: []
    }
  },
  created () {
    this.assignationTypes.forEach(assignation => { assignation.text = this.$t(`conceptualFrameworks.${assignation.value}`) })
    resolver
      .all({
        conceptual_frameworks: conceptualFrameworksService.list(),
        customers: customersService.shortListWithEnterprises(),
        sectors: sectorsService.list()
      })
      .then((res) => {
        this.conceptual_frameworks = res.conceptual_frameworks.items.map((conceptualFramework) => ({
          text: conceptualFramework.name,
          value: conceptualFramework.slug
        }))
        this.customersResponse = res.customers
        this.sectorsRespone = res.sectors.items
      })
  },
  computed: {
    assignations () {
      switch (this.form.assignationType) {
        case 'customer':
          return this.customersResponse.map((customer) => ({
            text: customer.name,
            value: customer.id
          }))
        case 'enterprise':
          const assignations = []
          for (const customer of this.customersResponse) {
            assignations.push({ header: customer.name })
            for (const enterprise of customer.enterprises) {
              assignations.push({
                text: enterprise.name,
                value: enterprise.id
              })
            }
            assignations.push({ divider: true })
          }
          return assignations
        case 'sector':
          return this.sectorsRespone.map((sector) => {
            return {
              text: sector.translate ? sector.translate.label : sector.code,
              value: sector.id
            }
          })
      }
      return []
    }
  },
  methods: {
    saveConceptualFramework () {
      return conceptualFrameworksService.create(this.form)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('conceptualFrameworks.created_succesfully'))
          this.$router.push(`/conceptual_frameworks/${res.slug}/edit`)
        }).catch((err) => {
          this.$store.dispatch('alert/success', this.$t('conceptualFrameworks.error_on_creation'))
          console.log('error', err)
        })
    }
  }
})
</script>
