
const required = '<br/><br/><b>Este campo es obligatorio.</b>'

export default {
  icon: 'mdi-help-circle',
  evaluation: {
    create: {
      name: {
        title: 'Nombre de la encuesta',
        text: `Sirve como medio de identificación para la encuesta. ${required}`
      },
      displayName: {
        title: 'Nombre externo para los encuestados',
        // tslint:disable-next-line:max-line-length
        text: 'En caso de ser seleccionado, este será el nombre mostrado a los colaboradores que vayan a realizar la encuesta.'
      },
      deliveredAt: {
        title: 'Fecha de lanzamiento',
        text: `Establece la fecha de inicio de la encuesta. A partir de esta fecha, se enviaran los correos de participación y los colaboradores podrán responder la medición. ${required}`
      },
      validUntil: {
        title: 'Encuesta válida hasta',
        text: `Establece la fecha de cierre de la medición. Después del cierre, no se podrán responder más encuestas de esta medición. ${required}`
      },
      timezone: {
        title: 'Zona horaria',
        text: `Las fechas de lanzamiento, cierre y recordatorios se basarán en la zona horaria elegida. ${required}`
      },
      reminders: {
        title: 'Recordatorios',
        // tslint:disable-next-line:max-line-length
        text: 'Los recordatorios se enviaran vía correo electrónico a aquellos colaboradores que no hayan respondido hasta el momento. Se pueden configurar máximo 5 recordatorios.'
      },
      questionnaire: {
        title: 'Cuestionarios',
        text: 'Active esta opción si desea seleccionar otro cuestionario o utilizar la ultima versión del cuestionario previamente seleccionado.'
      },
      conceptualFramework: {
        title: 'Marco conceptual',
        text: 'Active esta opción si desea seleccionar otro marco conceptual o utilizar la ultima versión del marco conceptual previamente seleccionado.'
      },
      subject: {
        title: 'Asunto del mensaje',
        text: 'Asunto que se utilizará en el correo electrónico enviado.'
      },
      body: {
        title: 'Cuerpo del mensaje',
        text: 'Contenido del correo electrónico enviado.'
      },
      thankMessage: {
        title: 'Mensaje de agradecimiento',
        text: 'Este mensaje será enviado al correo electrónico del receptor que finalice satisfactoriamente el llenado de la encuesta.'
      },
      remember_load_update_collaborators: 'Recuerda cargar o actualizar los colaboradores antes de crear la encuesta.'
    }
  },
  enterprise: {
    create: {
      logo: {
        title: 'Logo',
        text: 'El logo de la empresa debe ser una imagen cuyo peso no supere los 2MB.'
      }
    },
    massive: {
      file_input: {
        title: 'Archivo a cargar',
        text: `Cargue un archivo excel (.xls/.xlsx) o csv (.csv) que contenga los datos de sus colaboradores. Esta acción actualizará su base total de colaboradores, por lo que debe agregar la base completa de colaboradores disponibles.<br/><br/>Recuerde utilizar la plantilla descargable que puede obtener al hacer click en el botón "DESCARGAR PLANTILLA". ${required}`
      }
    }
  }
}
