
import dataObj from '../utils_old/data-obj'
import PdfUtil from '../utils/pdf'

export default {
  data () {
    return {
      // chrTst: [
      //   'wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww',
      //   'wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww'
      // ],
      firstTblMaxChars: 182,
      secondTblMaxChars: 326
    }
  },
  methods: {
    limitGaspsTablesText (str, tbl) {
      // str = this.chrTst[tbl - 1]
      let max = tbl === 1 ? this.firstTblMaxChars : this.secondTblMaxChars
      return str.length > max ? str.slice(0, max) + '...' : str
    },
    generateTitleTextG (translate, repeat, fontSize, pageBreak, margin) {
      let array = []
      array.push(
        {
          text: this.$t(`${translate + '[' + 0 + ']'}`),
          alignment: 'justify',
          margin: margin !== undefined ? margin : [0, 0, 10, 6],
          color: '#6d7a7a',
          lineHeight: 1.5,
          fontSize: fontSize,
          bold: true,
          pageBreak: pageBreak
        }
      )
      for (let index = 1; index < repeat + 1; index++) {
        array.push(
          {
            text: this.$t(`${translate + '[' + index + ']'}`),
            alignment: 'justify',
            margin: [0, 0, 10, 6],
            color: '#6d7a7a',
            lineHeight: 1.5,
            fontSize: fontSize
          }
        )
      }
      return array
    },
    $generateGapsAnalysis (tableNum) {
      const table25 = []
      const gaps = []
      const textGapEvaluator = {
        leadersEvaluators: this.$t('individualReport.leaders'),
        pairsEvaluators: this.$t('individualReport.pairs'),
        dependentsEvaluators: this.$t('individualReport.dependents')
      }

      const getName = (general, specific) => {
        return !general || !specific ? '' : this.questionnaire.general[general].specific[specific][this.user.lang]
      }

      const textColor = '#6d7a7a'
      for (let i = 0; i < 6; i++) {
        const positiveGapsName = getName(this.positiveGaps[i].general, this.positiveGaps[i].specific)
        const negativeGapsName = getName(this.negativeGaps[i].general, this.negativeGaps[i].specific)
        table25.push([
          // Higher score
          { text: positiveGapsName ? this.limitGaspsTablesText(positiveGapsName, 1) : '--', margin: [0, 0, 0, -1], border: [true, true, true, true], style: { color: textColor } },
          { text: positiveGapsName ? this.round(this.positiveGaps[i].score, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: positiveGapsName ? this.round(this.positiveGaps[i].second, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: positiveGapsName ? this.round(this.positiveGaps[i].gap, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          // Lower score
          { text: negativeGapsName ? this.limitGaspsTablesText(negativeGapsName, 1) : '--', margin: [0, 0, 0, -1], border: [true, true, true, true], style: { color: textColor } },
          { text: negativeGapsName ? this.round(this.negativeGaps[i].score, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: negativeGapsName ? this.round(this.negativeGaps[i].second, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: negativeGapsName ? this.round(this.negativeGaps[i].gap, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])

        const gap = this.evaluatorsGaps[i]
        if (gap) {
          const evaluatorsGapsName = getName(gap.generalResponsibility, gap.specificResponsibility)
          gaps.push([
            { text: evaluatorsGapsName ? this.limitGaspsTablesText(evaluatorsGapsName, 2) : '--', margin: [0, 0, 0, -1], border: [true, true, true, true], style: { color: textColor } },
            { text: evaluatorsGapsName ? this.round(gap.higherScore, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: evaluatorsGapsName ? textGapEvaluator[gap.higherScoreEvaluator] : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
            { text: evaluatorsGapsName ? this.round(gap.lowerScore, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: evaluatorsGapsName ? textGapEvaluator[gap.lowerScoreEvaluator] : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
            { text: evaluatorsGapsName ? this.round(gap.gap, 2) : '--', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
          ])
        } else {
          gaps.push([
            { text: '--', margin: [0, 5, 0, 0], border: [true, true, true, true], style: { color: textColor } },
            { text: '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
            { text: '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
            { text: '--', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
            { text: '--', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
          ])
        }
      }
      return [
        PdfUtil.generateTitleLandscape(this.$t('individualReport.gaps_analysis_title'), 'before', true, true),
        ...this.generateTitleTextG('individualReport.gaps_analysis', 2, 9),
        {
          table: {
            widths: [760], // 479 Portrait
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} ${tableNum}. ${this.$t('individualReport.table25')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [0, 0, 135, 10]
        },
        {
          layout: {
            defaultBorder: '--',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['40%', '3%', '3%', '4%', '40%', '3%', '3%', '4%'],
            body: [
              [
                { text: this.$t('individualReport.higher_gap').toUpperCase(), colSpan: 4, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                { text: this.$t('individualReport.lower_gap').toUpperCase(), colSpan: 4, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {}
              ],
              // Header
              [
                // Positive
                { text: this.specResponsibilityTitle, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.gap'), margin: [-2, 5, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                // Negative
                { text: this.specResponsibilityTitle, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.gap'), margin: [-2, 5, -2, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...table25
            ]
          },
          margin: [-7, 0, 0, 7],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        ...this.generateTitleTextG('individualReport.gaps_analysis2', 1, 9, 'before'),
        {
          table: {
            widths: [760], // 479 Portrait
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} ${tableNum + 1}. ${this.$t('individualReport.table26')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [0, 0, 135, 10]
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['70%', '5%', '8%', '5%', '8%', '4%'],
            body: [
              // Header
              [
                { text: this.specResponsibilityTitle, margin: [25, 9, 25, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.higher_score'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.evaluator'), margin: [0, 9, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.lower_score'), margin: [0, 4, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.evaluator'), margin: [0, 9, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.gap'), margin: [0, 9, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...gaps
            ]
          },
          margin: [-7, 0, 0, 7],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
