
import PDFUtil from '../utils/pdf'

export default {
  data () {
    return {
      maxScatterGralAndSpecRespChars: {
        gral: 50,
        spec: 92
      }
    }
  },
  methods: {
    truncateScatterText (str, type) {
      return str.length > this.maxScatterGralAndSpecRespChars[type] ? str.slice(0, this.maxScatterGralAndSpecRespChars[type]) + '...' : str
    },
    getRespName (general, specific = undefined, truncate = false) {
      const resp = this.questionnaire.general
      let text = ''
      if (!specific) {
        text = resp[general] ? resp[general].name[this.user.lang] : '--'
        if (truncate) {
          text = this.truncateScatterText(text, 'gral')
        }
      } else {
        text = resp[general].specific[specific][this.user.lang]
        if (truncate) {
          text = this.truncateScatterText(text, 'spec')
        }
      }
      return {
        text: text,
        fontSize: 9,
        margin: [0, 3, 0, 0],
        border: [true, true, true, true],
        style: { color: '#6d7a7a' }
      }
    },
    getScatter (score, bold = false, fontSize = 9) {
      return {
        text: this.round(score, 2),
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    doubleTableScatter (highest, lowest) {
      const table = {
        widths: ['43%', '7%', '0.5%', '42.5%', '7%'],
        headerRows: 2,
        body: [
          [
            this.getBaseHeader(this.$t(`demographicReport.scatter_table_1__header_1`), 2, 8, true),
            {},
            {},
            this.getBaseHeader(this.$t(`demographicReport.scatter_table_1__header_2`), 2, 8, true),
            {}
          ],
          [
            this.getBaseHeader(this.gralResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.$t(`demographicReport.scatter_table_1__sub_header_3`), 1, 9),
            {},
            this.getBaseHeader(this.gralResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.$t(`demographicReport.scatter_table_1__sub_header_3`), 1, 9)
          ]
        ]
      }

      for (var i = 0; i < 3; i++) {
        table.body.push([
          this.getRespName(highest[i].general),
          this.getScatter(highest[i].scatter),
          {},
          this.getRespName(lowest[i].general),
          this.getScatter(lowest[i].scatter)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex <= 1 && columnIndex !== 2) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, 0, 0, 25],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    singleTableScatter (type, scatters) {
      const table = {
        widths: ['33%', '60%', '7%'],
        headerRows: 1,
        body: [
          [
            this.getBaseHeader(this.$t(`demographicReport.scatter_table_1__header_${type}`), 3, 8, true),
            {},
            {}
          ],
          [
            this.getBaseHeader(this.gralResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.specResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.$t(`demographicReport.scatter_table_1__sub_header_3`), 1, 9)
          ]
        ]
      }

      for (var i = 0; i < 5; i++) {
        table.body.push([
          this.getRespName(scatters[i].general, undefined, true),
          this.getRespName(scatters[i].general, scatters[i].specific, true),
          this.getScatter(scatters[i].scatter)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex <= 1) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, 0, 5, type === 1 ? 14 : 0],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    $generateScatter () {
      return [
        PDFUtil.generateTitleLandscape(this.$t('demographicReport.scatter_title'), 'before', true),
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scatter_table_1_title', [this.tableNum])),
        PDFUtil.generateCenteredText(this.$t('demographicReport.scatter_info'), 11, false, '#6d7a7a'),
        this.doubleTableScatter(this.highestScoreScatterGeneral, this.lowestScoreScatterGeneral),
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.scatter_table_2_title', [this.tableNum + 1]), 'before'),
        PDFUtil.generateCenteredText(this.$t('demographicReport.scatter_info'), 11, false, '#6d7a7a'),
        this.singleTableScatter(1, this.highestScoreScatterSpecific),
        this.singleTableScatter(2, this.lowestScoreScatterSpecific)
      ]
    }
  }
}
