
import Services from './base-services'

const service = new Services('charges')

export default {
  listFromSuite: () => {
    return service.suiteOperation(() => service.get('list-by-enterprise'))
  },
  listByDepartmentsFromSuite: (department:Array<number>) => {
    return service.suiteOperation(() => service.get(`list-by-multiple-departments?department=${department}`))
  }
}
