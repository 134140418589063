
export default {
  no_answers_modal_msg: 'La encuesta actual no posee respuestas, por lo cual no será posible generar un reporte.',
  general_report: 'Generar reporte',
  download_report: 'Descargar reporte',
  required_general_title: 'Reporte Organizacional de Liderazgo',
  required_general_desc: 'Este reporte cuenta con los resultados a nivel organizacional de las valoraciones Liderazgo 360° a los líderes.',
  change_report: '¿Desea generar un reporte organizacional por población?',
  select_general_demographics_items: 'Seleccione la demografía que necesita visualizar en la tabla de respuestas',
  required_current_culture_title: 'Reporte Cultura Actual vs Cultura Requerida',
  required_current_culture_description: 'Reporte que posee estadísticas de toda la población incluída en la encuesta y la cultura requerida asignada.',
  required_actual_button: 'Generar Reporte',
  select_report_type: 'Seleccione el tipo de reporte que desea generar',
  individual_title: 'Reporte individual de liderazgo',
  individual_desc: 'Este reporte cuenta con los resultados de la valoración de Liderazgo 360° a la medida, por cada líder.',
  demographic_title: 'Reporte por corte demográfico',
  demographic_desc: 'Este reporte cuenta con los resultados de la valoración Liderazgo 360° segmentado por cortes demográficos',
  number_leaders_evaluated: 'Cantidad de líderes evaluados',
  message_number_leaders_evaluated: 'Debe haber al menos un lider evaluado que cumpla con los criterios del filtro',
  evaluated: 'Líder Evaluado',
  no_autocompleted: 'Este Líder no realizó auto-evaluación',
  no_autocompleteds: 'Al menos un Líder debe realizar la autoevaluación',
  demographicCuts: {
    additionalDemographic1: 'Demográfico Adicional 1',
    additionalDemographic2: 'Demográfico Adicional 2',
    jobTypes: 'Tipo de contratación',
    headquarter: 'Sede',
    gender: 'Género',
    departments: 'Departamento/Área',
    country: 'País',
    charge: 'Cargo',
    antiquity: 'Rango de antigüedad',
    age: 'Rango de edad',
    academicDegree: 'Nivel académico'
  },
  threadOrganizationalReportExec: {
    input_generating_report: 'Generando reporte',
    input_update_progress: 'Actualizar progreso',
    input_failed_generation: 'Generación fallida',
    input_download_report: 'Descargar reporte'
  },
  threadIndividualReport: {
    opetaion_init: 'Se ha dado inicio a un proceso de descarga "Reporte Individual", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
    input_generate_report: 'Generar reporte',
    input_no_participation: 'Sin participación'
  },
  threadIndividualReportExec: {
    input_generating_report: 'Generando reporte',
    input_update_progress: 'Actualizar progreso',
    input_failed_generation: 'Generación fallida',
    input_download_report: 'Descargar reporte'
  }
}
