
<template>
  <div>
    <v-btn large
      :disabled="thread.status !== 'completed'"
      :loading="lockPdfButton"
      color="success"
      class="mt-3"
      @click="openPdf"
    >
      <v-icon>mdi-file-pdf</v-icon>
      <span v-if="thread.status === 'pending'">{{ $t('reports.threadOrganizationalReportExec.input_generating_report') }}</span>
      <span v-else-if="thread.status === 'in_action' || thread.status === 'in_progress'">{{ $t('reports.threadOrganizationalReportExec.input_generating_report') + ` ${thread.data.progress}%` }}</span>
      <span v-else-if="thread.status === 'failed'">{{ $t('reports.threadOrganizationalReportExec.input_failed_generation') }}</span>
      <span v-else>{{ $t('reports.threadOrganizationalReportExec.input_download_report') }}</span>
    </v-btn>

    <!-- Leadership 360 page Header Logo -->
    <img
      src="/img/20210423_x_occ_l_logo.png"
      style="visibility:hidden;"
      id="occCultureCover"
      alt="hidden"
      width="0"
      height="0"
    />
    <!-- Empty img container to load Enterprise Logo if any -->
    <img
      v-if="enterpriseLogo"
      :src="enterpriseLogo"
      id="dynamicEnterpriseLogo"
      class="d-none"
    />
  </div>
</template>

<script>

import { mapState } from 'vuex'
import is from 'is_js'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts.js'

import initial from './thread_mixins_organizational/00-initial'
import cover from './thread_mixins_organizational/01-cover'
import index from './thread_mixins_organizational/02-index'
import scores from './thread_mixins_organizational/03-scores'
import gralResults from './thread_mixins_organizational/04-gral-results'
import specResults from './thread_mixins_organizational/05-spec-results'
import scatter from './thread_mixins_organizational/06-scatter'
import trend from './thread_mixins_organizational/07-trend'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'thread-organizational-report-exec',
  mixins: [
    initial,
    cover,
    index,
    scores,
    gralResults,
    specResults,
    scatter,
    trend
  ],
  props: {
    baseQuestionnaire: Object,
    pollId: String,
    thread: Object
  },
  data () {
    return {
      downloadPdf: true,
      cultureCoverSrc: null,
      enterpriseLogo: null,
      lockPdfButton: false,
      evaluation: {},
      questionnaire: {},
      answersResponsibility: {},
      completedPolls: 0,
      expectedPolls: 0
    }
  },
  mounted () {
    this.cultureCoverSrc = document.getElementById('occCultureCover').src
    this.gralResponsibilityTitle = this.baseQuestionnaire.generalResponsibility[this.user.lang]
    this.specResponsibilityTitle = this.baseQuestionnaire.specificResponsibility[this.user.lang]
  },
  watch: {
    cultureCoverSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureCoverSrc, (dataURL) => {
          this.cultureCoverBase64 = dataURL
        })
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    async openPdf () {
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      await this.$getInitialData()
      await this.renderPdf()
    },
    async renderPdf () {
      this.$emit('render-pdf')
      const configuration = await this.$getConfiguration()
      if (this.downloadPdf) {
        if (is.edge() || is.ie()) {
          const pdfDocGenerator = pdfMake.createPdf(configuration)
          pdfDocGenerator.getBlob((blob) => {
            window.navigator.msSaveBlob(blob, `${this.evaluation.name}.pdf`)
            this.closeRenderPdf()
          })
        } else {
          pdfMake.createPdf(configuration).download(`${this.evaluation.name}.pdf`, () => {
            this.closeRenderPdf()
          })
        }
      } else {
        this.closeRenderPdf()
      }
    },
    closeRenderPdf () {
      this.$store.dispatch('loading/hide')
      this.lockPdfButton = false
      this.$emit('pdfRenderedOrg')
    },
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    round (value, decimals) {
      if (isNaN(Number(value))) {
        return '--'
      }
      if ((value * 100) < 1 && (value * 100) > -1) {
        value = 0
      }
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    }
  }
}
</script>
