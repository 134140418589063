
import PDFUtil from '../utils/pdf'

export default {
  data () {
    return {
      maxGralAndSpecRespChars: {
        gral: 56,
        spec: 100
      }
    }
  },
  methods: {
    truncateText (str, type) {
      return str.length > this.maxGralAndSpecRespChars[type] ? str.slice(0, this.maxGralAndSpecRespChars[type]) + '...' : str
    },
    getBaseHeader (text, colSpan, fontSize, uppercase = false) {
      return {
        bold: true,
        fontSize: fontSize || 12,
        color: 'white',
        text: uppercase ? text.toUpperCase() : text,
        colSpan: colSpan || 1,
        alignment: 'center',
        margin: [2, 4, 2, 3],
        border: [true, true, true, true]
      }
    },
    getRespName (general, specific = undefined, truncate = false) {
      const resp = this.questionnaire.general
      let text = ''
      if (!specific) {
        text = resp[general] ? resp[general].name[this.user.lang] : '--'
        if (truncate) {
          text = this.truncateText(text, 'gral')
        }
      } else {
        text = resp[general].specific[specific][this.user.lang]
        if (truncate) {
          text = this.truncateText(text, 'spec')
        }
      }
      return {
        text: text,
        fontSize: 9,
        margin: [0, 3, 0, 0],
        border: [true, true, true, true],
        style: { color: '#6d7a7a' }
      }
    },
    isValidScore (val) {
      return val > 0 && val < 7
    },
    getScore (score, bold = false, fontSize = 9) {
      return {
        text: this.isValidScore(score) ? this.round(score, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getPrevScore (score, bold = false, fontSize = 9) {
      return {
        text: this.hasPrevious && this.isValidScore(score) ? this.round(score, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getRankTrend (trend, bold = false, fontSize = 9) {
      return {
        text: this.hasPrevious && this.isValidScore(trend) ? this.round(trend, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    doubleTableScores (base, highest, lowest) {
      const table = {
        widths: ['37%', '6%', '7%', '0.5%', '36.5%', '6%', '7%'],
        headerRows: 2,
        body: [
          [
            this.getBaseHeader(this.$t(`organizationalReport.${base}header_1`), 3, 8, true),
            {},
            {},
            {},
            this.getBaseHeader(this.$t(`organizationalReport.${base}header_2`), 3, 8, true),
            {},
            {}
          ],
          [
            this.getBaseHeader(this.gralResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.${base}sub_header_3`), 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.${base}sub_header_5`), 1, 9),
            {},
            this.getBaseHeader(this.gralResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.${base}sub_header_3`), 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.${base}sub_header_5`), 1, 9)
          ]
        ]
      }

      for (var i = 0; i < 3; i++) {
        table.body.push([
          this.getRespName(highest[i].general),
          this.getScore(highest[i].score),
          this.getRankTrend(highest[i].trend),
          {},
          this.getRespName(lowest[i].general),
          this.getScore(lowest[i].score),
          this.getRankTrend(lowest[i].trend)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex <= 1 && columnIndex !== 3) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, 0, 0, 25],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    singleTableScores (base, scores) {
      const table = {
        widths: ['28%', '50%', '6%', '11%', '7%'],
        headerRows: 1,
        body: [
          [
            this.getBaseHeader(this.gralResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.specResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.${base}sub_header_3`), 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.${base}sub_header_4`), 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.${base}sub_header_5`), 1, 9)
          ]
        ]
      }

      for (var i = 0; i < 5; i++) {
        table.body.push([
          this.getRespName(scores[i].general, undefined, true),
          this.getRespName(scores[i].general, scores[i].specific, true),
          this.getScore(scores[i].score),
          this.getPrevScore(scores[i].previous),
          this.getRankTrend(scores[i].trend)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex < 1) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, 0, 14, 25],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    $generateScores () {
      return [
        // GENERAL SCORES
        PDFUtil.generateTitleLandscape(this.$t('organizationalReport.scores_title_1'), 'before', true),
        // Others Scores
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.scores_title_1_sub_1')),
        this.doubleTableScores('scores_table_1_', this.highestScoreGeneralEvaluatorsItems, this.lowestScoreGeneralEvaluatorsItems),
        // Leaders Scores
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.scores_title_1_sub_2')),
        this.doubleTableScores('scores_table_1_', this.highestScoreGeneralLeadersEvaluatorsItems, this.lowestScoreGeneralLeadersEvaluatorsItems),
        // Pairs Scores
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.scores_title_1_sub_3'), 'before'),
        this.doubleTableScores('scores_table_1_', this.highestScoreGeneralPairsEvaluatorsItems, this.lowestScoreGeneralPairsEvaluatorsItems),
        // Dependants Scores
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.scores_title_1_sub_4')),
        this.doubleTableScores('scores_table_1_', this.highestScoreGeneralDependentsEvaluatorsItems, this.lowestScoreGeneralDependentsEvaluatorsItems),

        // SPECIFIC SCORES
        PDFUtil.generateTitleLandscape(this.$t('organizationalReport.scores_title_2'), 'before', true),
        // Others Scores
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.scores_title_2_sub_5')),
        this.singleTableScores('scores_table_1_', this.highestScoreSpecificEvaluatorsItems),
        this.singleTableScores('scores_table_1_', this.lowestScoreSpecificEvaluatorsItems),
        // Leaders Scores
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.scores_title_2_sub_6'), 'before'),
        this.singleTableScores('scores_table_1_', this.highestScoreSpecificLeadersEvaluatorsItems),
        this.singleTableScores('scores_table_1_', this.lowestScoreSpecificLeadersEvaluatorsItems),
        // Pairs Scores
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.scores_title_2_sub_7'), 'before'),
        this.singleTableScores('scores_table_1_', this.highestScoreSpecificPairsEvaluatorsItems),
        this.singleTableScores('scores_table_1_', this.lowestScoreSpecificPairsEvaluatorsItems),
        // Dependents Scores
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.scores_title_2_sub_8'), 'before'),
        this.singleTableScores('scores_table_1_', this.highestScoreSpecificDependentsEvaluatorsItems),
        this.singleTableScores('scores_table_1_', this.lowestScoreSpecificDependentsEvaluatorsItems)
      ]
    }
  }
}
