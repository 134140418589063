<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
  >
    <v-card class="wellcome-dialog">
      <v-container fluid>
        <v-row>
          <v-col cols="7" sm="4" md="3" lg="2" class="ml-auto pt-0">
            <v-select dense hide-details
              v-model="language"
              :items="languages"
              class="styled-select mr-3"
              prepend-icon="translate"
              @change="changeLocale"
            ></v-select>
          </v-col>
        </v-row>
        <v-row row wrap>
          <v-col cols="12"
            class="pt-2 pt-sm-0 text-center grey--text text--darken-1"
          >
            <section class="enterprise-logo">
              <img src="/img/20200301_occ_solution_logo.png" v-if="!evaluation.enterprise.logo"/>
              <img v-else :src="evaluation.enterprise.logo" alt="Enterprise Logo"/>
            </section>
            <br>
            <h2 class="display-3 mt-4 mx-3" style="color: #42A5F5;" v-if="userExcluded">{{ $t('evaluations.you_have_been_excluded') }}</h2>
            <template v-else>
              <h2 class="display-3 mt-4 mx-3" style="color: #42A5F5;">{{ $t('evaluations.wellcome_title') }}</h2>
              <h3 class="display-2 mt-4 mx-3" style="color: #42A5F5;">{{ evaluation.displayName || evaluation.name }}</h3>
              <p class="mt-4 mx-5 px-5" v-html="$t('evaluations.wellcome_instructions', { deadline: this.deadLine })"></p>
              <v-row justify="center">
                <v-col cols="12">
                  <v-btn
                    @click="close"
                    class="mt-4"
                    color="primary"
                    large
                  >{{ $t('input.start_poll') }}</v-btn>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    startDialog: Boolean,
    evaluatedRole: String,
    evaluation: Object,
    userExcluded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      language: 'es',
      languages: [],
      deadLine: '',
      show: false
    }
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        const languages = this.$t('languages')
        this.languages = []
        for (let key in languages) {
          this.languages.push({ value: key, text: languages[key] })
        }
        this.$emit('changeLang', val)
      },
      immediate: true
    },
    startDialog: {
      handler () {
        this.show = this.startDialog
        if (this.startDialog && !this.userExcluded) {
          this.setDeadLine()
        }
      },
      immediate: true
    }
  },
  created () {
    const storedLang = localStorage.getItem('occ-lang')
    if (storedLang) {
      this.language = storedLang
    } else {
      localStorage.setItem('occ-lang', this.language)
    }
    this.$i18n.locale = this.language
  },
  methods: {
    changeLocale (e) {
      this.$i18n.locale = e
      localStorage.setItem('occ-lang', e)
    },
    setDeadLine () {
      let timeZone = this.evaluation.timeZone.split('UTC')[1].split(')')[0]
      let maxDateTime = new Date(Date.parse(this.evaluation.validUntil.split('Z')[0] + timeZone))
      let months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
      let ampm = maxDateTime.getHours() >= 12 ? ' pm' : ' am'
      let hour = (maxDateTime.getHours() % 12) || 12
      this.deadLine = (maxDateTime.getDate() < 10 ? '0' + maxDateTime.getDate() : maxDateTime.getDate()) + ' ' +
        months[maxDateTime.getMonth()] + ' de ' + maxDateTime.getFullYear() + ', ' +
        (hour < 10 ? '0' + hour : hour) + ':' + (maxDateTime.getMinutes() < 10 ? '0' + maxDateTime.getMinutes() : maxDateTime.getMinutes()) + ':' +
        (maxDateTime.getSeconds() < 10 ? '0' + maxDateTime.getSeconds() : maxDateTime.getSeconds()) + ampm
    },
    close () {
      this.show = false
      this.$emit('close')
    }
  }
})
</script>
