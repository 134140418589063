
<template>
  <div class="d-inline">
    <v-btn
    color="primary"
    class="white--text"
    :loading="loadingBtn"
    @click="edit ? showDialog() : downloadTemplate('new')"
    >
      <v-icon left>mdi-download</v-icon>
      {{ $t('evaluations.download_template') }}
    </v-btn>
    <v-dialog v-model="openTypeDownload" max-width="300px" @click:outside="openTypeDownload = false">
      <v-card>
        <v-card-title class="justify-center">{{$t('evaluations.chose_an_option')}}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" align="center">
              <v-btn outlined small @click="downloadTemplate('new')">
               {{$t('evaluations.download_template')}}
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined small
                    class="mt-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadTemplate('edit')"
                  >
                    {{$t('evaluations.edit_evaluators')}}
                  </v-btn>
                </template>
                <span>{{$t('evaluations.remember_only_employee_active')}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

import fileDownload from '../../../utils/file-download'

import evaluationsService from '../../../services/evaluations'

export default Vue.extend({
  data: () => ({
    loadingBtn: false,
    openTypeDownload: false
  }),
  methods: {
    async downloadTemplate (typ) {
      this.loadingBtn = true
      let concatenad
      let arrAux
      if (typ === 'new') {
        return evaluationsService.generateTemplate()
          .then((res) => {
            const blob = new Blob([res.template])
            fileDownload(blob, 'plantilla.csv')
            this.openTypeDownload = false
          })
          .catch(err => this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`)))
          .finally(() => {
            this.loadingBtn = false
          })
      } else {
        arrAux = Promise.all(this.emplooyes.map((item) => {
          let arrEvaluated = [{ email: item.employee.employee.employee.email, relacion: null }]
          let arrDependts = item.dependentsEvaluators.map(element => {
            return {
              email: element.employee.employee.email,
              relacion: String(this.$t('evaluations.dependent')).toLowerCase()
            }
          })
          let arrLeaders = item.leadersEvaluators.map(element => {
            return {
              email: element.employee.employee.email,
              relacion: String(this.$t('evaluations.leader')).toLowerCase()
            }
          })
          let arrPairs = item.pairsEvaluators.map(element => {
            return {
              email: element.employee.employee.email,
              relacion: String(this.$t('evaluations.pair')).toLowerCase()
            }
          })
          concatenad = arrEvaluated.concat(arrLeaders, arrDependts, arrPairs)
          return concatenad
        })).then(data => {
          return data.flat() // Reducir el arreglo a una minima expresion
        })
        evaluationsService.generateTemplateUsers(await arrAux)
          .then((res) => {
            const blob = new Blob([res.template])
            fileDownload(blob, 'editarEvaluadores.csv')
            this.openTypeDownload = false
          })
          .catch((err) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          })
          .finally(() => {
            this.loadingBtn = false
          })
      }
    },
    showDialog () {
      this.openTypeDownload = true
    }
  },
  props: ['emplooyes', 'edit']
})
</script>
