<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="10">
        <h4 class="display-1"> {{$t('navigation.questionnaires')}} </h4>
      </v-col>
      <v-col cols="12" sm="2" align="end">
        <v-btn
          to="/questionnaires/create"
          class="text-capitalize"
          color="primary"
          block
        >
          <v-icon left>fa-plus-circle</v-icon>&nbsp;&nbsp;{{ $t('input.create') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="px-4">
      <v-row>
        <v-col>
          <x-data-table
            :headers="headers"
            :parent-fetch-data="getQuestionnaires"
            :options="options"
            no-data="questionnaires.no_data"
          >
            <template v-slot:structure="prop">
              <td class="text-center text-capitalize">{{ prop.item.name }}</td>
              <td class="text-center">{{ prop.item.updatedAt.split('T')[0] | date({date: true, hour:false}) }}</td>
              <td class="text-center align-center">
                <v-switch
                  v-model="prop.item.active"
                  class="ma-2"
                  @click="openModal(prop.item.active, prop.item)"
                  readonly
                />
              </td>
              <td class="text-center">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`questionnaires/${prop.item.slug}/edit`"
                      v-on="on"
                      text icon>
                      <v-icon small>fa-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('questionnaires.edit_questions') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :to="`questionnaires/${prop.item.slug}/edit-questionnaire`"
                      v-on="on"
                      text icon>
                      <v-icon small>fa-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('questionnaires.settings') }}</span>
                </v-tooltip>
              </td>
            </template>
          </x-data-table>
        </v-col>
      </v-row>
      <x-confirmation-modal
      :show.sync="modalEna.open"
      :reversible="true"
      :title="$t('questionnaires.modal_enable')"
      :action="enable"
      @close="modalEna.open = false"
    >
      <template v-slot:question>{{ $t('questionnaires.modal_enable_des') }}</template>
    </x-confirmation-modal>

    <x-confirmation-modal
      :show.sync="modalDis.open"
      :reversible="true"
      :title="$t('questionnaires.modal_disable')"
      :action="disable"
      @close="modalDis.open = false"
    >
      <template v-slot:question>{{ $t('questionnaires.modal_disable_des') }}</template>
    </x-confirmation-modal>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'

import questionnairesService from '../../services/questionnaires'

export default Vue.extend({
  data () {
    return {
      headers: [
        'questionnaires.name',
        'questionnaires.last_edition',
        'questionnaires.status',
        'questionnaires.actions'
      ],
      options: {
        filter: null,
        search: null
      },
      modalDis: {
        open: false,
        item: {}
      },
      modalEna: {
        open: false,
        item: {}
      }
    }
  },
  methods: {
    getQuestionnaires (options) {
      return questionnairesService.list(options)
    },
    openModal (active, item) {
      if (active) {
        this.modalDis.item = item
        this.modalDis.open = true
      } else {
        this.modalEna.item = item
        this.modalEna.open = true
      }
    },
    disable () {
      return questionnairesService.toggle(this.modalDis.item.slug, false)
        .then((res) => {
          this.modalDis.item.active = false
          this.modalDis.open = false
        }).catch((err) => {
          this.$store.dispatch('alert/error', err.code === 'limit' ? 'No pueden desactivarse todos los cuestionarios' : 'Error al desactivar el cuestionario')
          this.modalDis.open = false
        })
    },
    enable () {
      return questionnairesService.toggle(this.modalEna.item.slug, true)
        .then((res) => {
          this.modalEna.item.active = true
          this.modalEna.open = false
        }).catch((err) => {
          this.$store.dispatch('alert/error', err.code === 'limit' ? 'No pueden desactivarse todos los cuestionarios' : 'Error al activar el cuestionario')
          this.modalEna.open = false
        })
    }
  }
})
</script>
