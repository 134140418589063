
export default {
  no_answers_modal_msg: 'The current assessment has no answers, so it is not possible to generate a report.',
  general_report: 'Generate report',
  download_report: 'Download report',
  required_general_title: 'Organizational Leadership Report',
  required_general_desc: 'This report has the results at the organizational level of the 360° Leadership assessments for leaders.',
  change_report: 'Do you want to generate an organizational report by population?',
  select_general_demographics_items: 'Select the demographics you need to display in the response table',
  required_current_culture_title: 'Current Culture vs. Required Culture Report',
  required_current_culture_description: 'Report with statistics of the entire population included in the assessment and the required culture assigned.',
  required_actual_button: 'Generate Report',
  select_report_type: 'Select the type of report you want to generate',
  individual_title: 'Individual leadership report',
  individual_desc: 'This report has the results of the tailored 360° Leadership assessment for each leader.',
  demographic_title: 'Report by demographic cut',
  demographic_desc: 'This report has the results of the 360° Leadership assessment segmented by demographic cuts',
  number_leaders_evaluated: 'Number of leaders assessed',
  message_number_leaders_evaluated: 'There must be at least one assessed leader that meets the filter criteria',
  evaluated: 'Leader Assessed',
  no_autocompleted: 'This Leader did not perform a self-assessment',
  no_autocompleteds: 'At least one Leader must complete the self-assessment',
  demographicCuts: {
    additionalDemographic1: 'Additional Demographics 1',
    additionalDemographic2: 'Additional Demographics 2',
    jobTypes: 'Job Types',
    headquarter: 'Headquarters',
    gender: 'Gender',
    departments: 'Departments',
    country: 'Countries',
    charge: 'Charges',
    antiquity: 'Antiquity',
    age: 'Age Range',
    academicDegree: 'Academic Degree'
  },
  threadOrganizationalReportExec: {
    input_generating_report: 'Generating report',
    input_update_progress: 'Update progress',
    input_failed_generation: 'Generation failed',
    input_download_report: 'Download report'
  },
  threadIndividualReport: {
    opetaion_init: 'A download process "Individual Report" has started, this process may take several minutes, you can see its progress by refreshing the page',
    input_generate_report: 'Generate report',
    input_no_participation: 'No participation'
  },
  threadIndividualReportExec: {
    input_generating_report: 'Generating report',
    input_update_progress: 'Update progress',
    input_failed_generation: 'Generation failed',
    input_download_report: 'Download report'
  }
}
