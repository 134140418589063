
import PDFUtil from '../utils/pdf'

export default {
  data () {
    return {
      maxTrendGralAndSpecRespChars: {
        gral: 58,
        spec: 102
      }
    }
  },
  methods: {
    truncateTrendText (str, type) {
      return str.length > this.maxTrendGralAndSpecRespChars[type] ? str.slice(0, this.maxTrendGralAndSpecRespChars[type]) + '...' : str
    },
    getTrendRespName (general, specific = undefined, truncate = false) {
      const resp = this.questionnaire.general
      let text = ''
      if (!specific) {
        text = resp[general] ? resp[general].name[this.user.lang] : '--'
        if (truncate) {
          text = this.truncateTrendText(text, 'gral')
        }
      } else {
        text = resp[general].specific[specific][this.user.lang]
        if (truncate) {
          text = this.truncateTrendText(text, 'spec')
        }
      }
      return {
        text: text,
        fontSize: 9,
        lineHeight: 0.9,
        margin: [0, 0, 0, 2],
        border: [true, true, true, true],
        style: { color: '#6d7a7a' }
      }
    },
    getTrendScore (score, bold = false, fontSize = 9) {
      return {
        text: this.hasPrevious ? this.round(score, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getTrend (trend, bold = false, fontSize = 9) {
      return {
        text: this.hasPrevious ? this.round(trend, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    singleTableTrend (type, trends) {
      const table = {
        widths: ['28%', '50%', '6%', '11%', '7%'],
        headerRows: 1,
        body: [
          [
            this.getBaseHeader(this.$t(`organizationalReport.trend_table_1__header_${type}`), 5, 8, true),
            {},
            {},
            {},
            {}
          ],
          [
            this.getBaseHeader(this.gralResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.specResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.scores_table_1_sub_header_3`), 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.scores_table_1_sub_header_4`), 1, 9),
            this.getBaseHeader(this.$t(`organizationalReport.trend_table_2_header_5`), 1, 9)
          ]
        ]
      }

      for (var i = 0; i < 5; i++) {
        table.body.push([
          this.getTrendRespName(trends[i].general, undefined, true),
          this.getTrendRespName(trends[i].general, trends[i].specific, true),
          this.getTrendScore(trends[i].score),
          this.getPrevScore(trends[i].previous),
          this.getTrend(trends[i].trend)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex <= 1) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, 0, 5, type === 1 ? 14 : 0],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    $generateTrend () {
      return [
        PDFUtil.generateTitleLandscape(this.$t('organizationalReport.trend_title'), 'before', true),
        PDFUtil.generateSubTitleLandscape(this.$t('organizationalReport.trend_table_1_title', [this.tableNum + 2])),
        this.singleTableTrend(1, this.positiveTrends),
        this.singleTableTrend(2, this.negativeTrends),
        {
          columns: PDFUtil.generateCenteredText(!this.hasPrevious ? this.$t('organizationalReport.trend_msg') : '', 15, false),
          margin: [0, 10, 0, 0]
        }
      ]
    }
  }
}
