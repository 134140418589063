
import circle64 from '../base64Files/circle64'
import axis64 from '../base64Files/axis64'
import diamond64 from '../base64Files/diamond64'

export default {
  generateTitle: (text: string, pageBreak?: string, fontSize?: number, margin?: number[] | number, color?: string): object => {
    return {
      text,
      pageBreak,
      fontSize: fontSize || 20,
      margin: margin || [0, 30],
      color: color || '#839291'
    }
  },
  validateTendency: (actual: number, tendency: number): object => {
    if (actual === undefined) {
      actual = 0
    }
    if (tendency === undefined) {
      tendency = 0
    }
    const dif = actual - tendency
    if (dif > 0.5) {
      return { bold: true, decoration: '' }
    } else if (dif < 0.5) {
      return { decoration: 'underline', bold: false }
    } else {
      return { bold: false, decoration: '' }
    }
  },
  // tslint:disable-next-line:max-line-length
  generateCenteredText: (text: string, fontSize?: number, bold?: boolean, styled?: boolean, italic?: boolean, pageBreak?: string): object[] => {
    const normal = [
      { width: '*', text: '' },
      {
        width: 'auto',
        text,
        color: '',
        fontSize: fontSize || 22,
        bold,
        italics: false,
        pageBreak
      },
      { width: '*', text: '' }
    ]

    if (styled) {
      normal[1].color = '#1a98da'
    }

    if (italic) {
      normal[1].italics = true
    }

    return normal
  },
  generateTable: (
    widths: string[],
    rows: string[][],
    headers: string[]
  ): object => {
    return {
      headerRows: 1,
      widths,
      body: [
        // Cabeceras
        headers.map((header) => {
          return {
            text: header,
            fillColor: '#2196F3',
            color: '#FFFFFF',
            bold: true,
            alignment: 'center'
          }
        }),
        // Contenido
        ...rows
      ]
    }
  },
  generateVariablesTable: (
    headers: string[],
    rows: any[],
    widths: string[]
  ): object => {
    const response = {
      headerRows: 1,
      widths,
      heights: 25,
      body: rows
    }

    if (headers.length !== 0) {
      response.body.unshift(headers.map((header) => {
        return {
          text: header,
          fillColor: '#2196F3',
          color: '#FFFFFF',
          bold: true,
          alignment: 'center'
        }
      }))
    }

    return response
  },
  generateHeatMapTable: (headers: string[], titles: string[]): object => {
    const baseHeader = {
      bold: true,
      alignment: 'center',
      fillColor: '#2196F3',
      color: '#FFFFFF'
    }

    const colors = [
      [
        { text: '', fillColor: '#D50000', color: '#FFFFFF', alignment: 'center' },
        { text: '0% - 30%', alignment: 'center' },
        { text: titles[0], alignment: 'center' }
      ],
      [
        { text: '', fillColor: '#F4511E', color: '#FFFFFF', alignment: 'center' },
        { text: '31% - 50%', alignment: 'center' },
        { text: titles[1], alignment: 'center' }
      ],
      [
        { text: '', fillColor: '#F57C00', color: '#000000', alignment: 'center' },
        { text: '51% - 70%', alignment: 'center' },
        { text: titles[2], alignment: 'center' }
      ],
      [
        { text: '', fillColor: '#FFEB3B', color: '#000000', alignment: 'center' },
        { text: '71% - 85%', alignment: 'center' },
        { text: titles[3], alignment: 'center' }
      ],
      [
        { text: '', fillColor: '#ADFF2F', color: '#000000', alignment: 'center' },
        { text: '86% - 95%', alignment: 'center' },
        { text: titles[4], alignment: 'center' }
      ],
      [
        { text: '', fillColor: '#00FF00', color: '#000000', alignment: 'center' },
        { text: '96% - 100%', alignment: 'center' },
        { text: titles[5], alignment: 'center' }
      ]
    ]

    return {
      headerRows: 1,
      widths: ['20%', '15%', '40%'],
      heights: 25,
      body: [
        // Cabecera
        headers.map((header) => {
          return {
            ...baseHeader,
            text: header
          }
        }),
        // Contenido
        ...colors
      ]
    }
  },
  generateLegendTable: (rows: any[]): object => {
    return {
      widths: ['5%', '45%', '5%', '45%'],
      heights: '5%',
      body: [
        ...rows
      ]
    }
  },
  generateTableBars: (oldResult: number, newResult: number, dimensionId: number): object => {
    const colors = [
      'rgba(235, 96, 77, 0.8)',
      'rgba(235, 96, 77, 0.8)',
      'rgba(161, 228, 215, 0.8)',
      'rgba(161, 228, 215, 0.8)',
      'rgba(75, 155, 208, 0.8)',
      'rgba(75, 155, 208, 0.8)'
    ]

    if (oldResult === 0) { oldResult = 0.01 }
    if (newResult === 0) { newResult = 0.01 }

    return {
      xAxis: {
        type: 'value',
        max: 100,
        show: true,
        axisLabel: {
          fontSize: 16
        }
      },
      yAxis: {
        type: 'category'
      },
      series: [
        {
          type: 'bar',
          data: [oldResult, 0],
          color: colors[dimensionId]
        },
        {
          type: 'bar',
          data: [0, newResult],
          color: '#000000'
        }
      ]
    }
  },
  setLayout: (): object => {
    return {
      hLineColor: '#607D8B',
      vLineColor: '#607D8B',
      fillColor: (rowIndex: any) => {
        return (rowIndex % 2 === 0) ? '#EEEEEE' : null
      }
    }
  },
  getColor: (value: number): string => {
    if (value >= 0 && value <= 30) { return '#D50000' }
    if (value > 30 && value <= 50) { return '#F4511E' }
    if (value > 50 && value <= 70) { return '#F57C00' }
    if (value > 70 && value <= 85) { return '#FFEB3B' }
    if (value > 85 && value <= 95) { return '#ADFF2F' }
    if (value > 95 && value <= 100) { return '#00FF00' }
    return ''
  },
  generateNote: (text: any): object => {
    return {
      margin: [0, 15, 0, 0],
      text,
      fontSize: 12
    }
  },
  generateLiderazgoLegend (translations: any[]) {
    return {
      layout: {
        defaultBorder: '',
        border: [false, false, false, true]
      },
      table: {
        headerRows: 1,
        body: [
          // Header
          [
            {
              layout: {
                fillColor: function (rowIndex: any, node: any, columnIndex: any) {
                  return (columnIndex === 0) ? '#BFBFBF' : null
                }
              },
              table: {
                body: [
                  [
                    { text: '', margin: [10, 0, 10, -10], border: [false, false, false, false], bold: true, style: { color: '#fff' } },
                    { text: translations[0], margin: [0, 0, 0, 0], border: [false, false, false, false], style: { color: '#000000' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    { text: 'X', margin: [5, 0, 0, 0], border: [false, false, false, false], bold: true, style: { color: '#000000' } },
                    { text: translations[1], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#000000' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    {
                      image: diamond64,
                      width: 12,
                      height: 12,
                      aligment: 'center',
                      margin: [0, 0, 0, 0],
                      border: [false, false, false, false]
                    },
                    { text: translations[2], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#000000' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    { text: translations[3], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: true, style: { color: '#000000' } }
                  ],
                  [
                    { text: translations[4], margin: [0, -5, 0, 0], border: [false, false, false, false], decoration: 'underline', style: { color: '#000000' } }
                  ]
                ]
              }
            }
          ]
        ]
      },
      margin: [10, 0, 10, 10],
      border: [false, false, false, false],
      alignment: 'center',
      fontSize: 8
    }
  },
  higherLowerRow: (scores: any[], score: number, color: string, margin: any[], alignment: string, border: any[], type?: string): object => {
    alignment = alignment || 'left'
    if (isNaN(score)) {
      return { text: score, color: color, margin: margin, alignment, border, bold: false }
    } else if (!scores.length) {
      return { text: score.toFixed(2), color: color, margin: margin, alignment, border, bold: false }
    }
    scores.sort((a, b) => b - a)

    if (score === scores[0]) {
      return { text: score.toFixed(2), color: '#3f4242', margin: margin, alignment, border, bold: true }
    } else if (score === scores[scores.length - 1]) {
      return { text: score.toFixed(2), color: color, margin: margin, alignment, border, bold: false, decoration: 'underline' }
    } else {
      return { text: score.toFixed(2), color: color, margin: margin, alignment, border, bold: false }
    }
  },
  higherLowerResponsability: (scores: any[], score: number, color: string, margin: any[], alignment: string, border: any[]): object => {
    if (isNaN(score)) {
      return { text: score, color: color, margin: margin, alignment: alignment === '' ? 'left' : alignment, border: border, bold: false }
    } else if (!scores.length) {
      return { text: score.toFixed(2), color: color, margin: margin, alignment: alignment === '' ? 'left' : alignment, border: border, bold: false }
    }
    let copyScores = scores.slice(0)
    copyScores.sort((a, b) => {
      if (!isNaN(a) && !isNaN(b)) {
        return b - a
      } else {
        return b.score - a.score
      }
    })
    if (score === copyScores[0] || score === copyScores[0].score) {
      return { text: score.toFixed(2), color: color, margin: margin, alignment: alignment === '' ? 'left' : alignment, border: border, bold: true }
    } else if (score === copyScores[copyScores.length - 1] || score === copyScores[copyScores.length - 1].score) {
      return { text: score.toFixed(2), color: color, margin: margin, alignment: alignment === '' ? 'left' : alignment, border: border, bold: false, decoration: 'underline' }
    } else {
      return { text: score.toFixed(2), color: color, margin: margin, alignment: alignment === '' ? 'left' : alignment, border: border, bold: false }
    }
  },
  calculateWidthsByScore (max: number, min: number, p1: number = 29, p2: number = 11) {
    return (((max - 1) * p1) - ((min - 1) * p1) - p2)
  },
  calculateWidthsByScoreX (max: number, min: number, p1: number = 29, p2: number = 3) {
    return (((max - 1) * p1) - ((min - 1) * p1) - p2) - (max > 5 ? 1.5 : max > 3 ? 1 : 0)
  },
  calculateWidthsByScoreD (score: number, min: number, p1 : number = 145) {
    return this.calculateWidthsByScoreX(score, min, p1) - (score > 5 ? 2.5 : score > 3 ? 3 : 3.3)
  },
  calculateWidthsByScoreMargin (min: number, p1: number = 17, p2: number = 29) {
    return (p1 + (p2 * (min - 1)) - (min > 3 ? 1 : 0) - (min > 5 ? 1 : 0))
  },
  generateXDChart (scores: any[], i: number, j?: number, type?: string, p1 : number = 17, p2 : number = 29, p3 : number = 29, p4 : number = 145, p5 : number = 29) {
    let tenPercent = 0
    let score = 0
    let range = []
    let min = 0
    let max = 0
    if (j !== undefined) {
      if (type === 'responsability') {
        score = scores[i].responsability[j].score
        tenPercent = scores[i].responsability[j].tenPercent
        range = scores[i].responsability[j].questionsScores
      } else {
        score = scores[i].questions[j].score
        tenPercent = scores[i].questions[j].tenPercent
        range = scores[i].questions[j].individualScores
      }
    } else {
      score = scores[i].total
      tenPercent = scores[i].tenPercent
      range = scores[i].questionsScores
    }
    range.sort((a: number, b: number) => b - a)
    max = range[0]
    min = range[range.length - 1]
    if (min === 0) {
      min = 1
    }
    // debugger // eslint-disable-line no-debugger
    const margin = this.calculateWidthsByScoreMargin(min, p1, p2)
    return {
      layout: { defaultBorder: '', fillColor: '#BFBFBF' },
      table: {
        headerRows: 2,
        widths: max !== min ? [this.calculateWidthsByScore(max, min, p3)] : [-10],
        heights: [7],
        body: [
          [
            {
              image: diamond64,
              width: 12,
              height: 12,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreD(tenPercent, min, p5), y: 2 },
              border: [false, false, false, false]
            }
          ],
          [
            {
              image: axis64,
              width: 6,
              height: 8,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreX(score, min, p5), y: 4 },
              border: [false, false, false, false]
            }
          ]
        ]
      },
      margin: j === undefined ? i === 0 ? [margin, 3, 0, 0] : [margin, 0, 0, 0] : [margin, 5, 0, 0],
      style: { color: '#fff' },
      border: [true, true, true, true],
      alignment: 'left'
    }
  },
  generateLiderazgoLegendIndividual (translations: any[], isLandscape: boolean = false) {
    return {
      layout: {
        defaultBorder: '',
        border: [false, false, false, true]
      },
      table: {
        headerRows: 1,
        body: [
          // Header
          [
            {
              text: translations[0],
              alignment: 'center',
              margin: [0, 0, 0, 0],
              color: '#6d7a7a'
            },
            {
              layout: {
                fillColor: function (rowIndex: any, node: any, columnIndex: any) {
                  return (columnIndex === 0) ? '#BFBFBF' : null
                }
              },
              table: {
                body: [
                  [
                    { text: '', margin: [10, 0, 10, -10], border: [false, false, false, false], bold: true, style: { color: '#fff' } },
                    { text: translations[1], margin: [0, 0, 0, 0], border: [false, false, false, false], style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    { text: 'X', margin: [5, 0, 0, 0], border: [false, false, false, false], bold: true, style: { color: '#6d7a7a' } },
                    { text: translations[2], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            /* Top 10% column is removed
            {
              table: {
                body: [
                  [
                    {
                      image: diamond64,
                      width: 12,
                      height: 12,
                      aligment: 'center',
                      margin: [0, 0, 0, 0],
                      border: [false, false, false, false]
                    },
                    { text: translations[3], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            */
            {
              table: {
                body: [
                  [
                    {
                      image: circle64,
                      width: 9,
                      height: 9,
                      aligment: 'center',
                      margin: [0, 2, 0, 0],
                      border: [false, false, false, false]
                    },
                    { text: translations[4], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    { text: translations[5], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: true, style: { color: '#6d7a7a' } }
                  ],
                  [
                    { text: translations[6], margin: [0, -5, 0, 0], border: [false, false, false, false], decoration: 'underline', style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            }
          ]
        ]
      },
      margin: [isLandscape ? 114 : 0, -10, 0, 10],
      border: [false, false, false, false],
      alignment: 'center',
      fontSize: 6.5
    }
  },
  generateLiderazgoLegendIndividualExample (translations: any[]) {
    return {
      layout: {
        defaultBorder: '',
        border: [false, false, false, true]
      },
      table: {
        headerRows: 1,
        body: [
          // Header
          [
            {
              text: translations[0],
              alignment: 'center',
              margin: [140, 0, 0, 0],
              color: '#6d7a7a'
            },
            {
              layout: {
                fillColor: function (rowIndex: any, node: any, columnIndex: any) {
                  return (columnIndex === 0) ? '#BFBFBF' : null
                }
              },
              table: {
                body: [
                  [
                    { text: '', margin: [10, 0, 10, -10], border: [false, false, false, false], bold: true, style: { color: '#fff' } },
                    { text: translations[1], margin: [0, 0, 0, 0], border: [false, false, false, false], style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    { text: 'X', margin: [5, 0, 0, 0], border: [false, false, false, false], bold: true, style: { color: '#6d7a7a' } },
                    { text: translations[2], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            /* Top 10% column is removed
            {
              table: {
                body: [
                  [
                    {
                      image: diamond64,
                      width: 12,
                      height: 12,
                      aligment: 'center',
                      margin: [0, 0, 0, 0],
                      border: [false, false, false, false]
                    },
                    { text: translations[3], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            */
            {
              table: {
                body: [
                  [
                    {
                      image: circle64,
                      width: 9,
                      height: 9,
                      aligment: 'center',
                      margin: [0, 2, 0, 0],
                      border: [false, false, false, false]
                    },
                    { text: translations[4], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    { text: translations[5], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: true, style: { color: '#6d7a7a' } }
                  ],
                  [
                    { text: translations[6], margin: [0, -5, 0, 0], border: [false, false, false, false], decoration: 'underline', style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            }
          ]
        ]
      },
      margin: [0, 15, 0, -5],
      border: [false, false, false, false],
      alignment: 'center',
      fontSize: 6.5
    }
  },
  calculateWidthsByScoreIndividual (max: number, min: number) {
    const margin = 15 + (30 * (min - 1))
    let result = (((max - 1) * 138) / 5)
    result = result - ((min - 1) * 30)
    return { result: result, margin: margin }
  },
  calculateWidthsByScoreI (max: number, min: number) {
    let result = ((max - 1) * 29) - ((min - 1) * 29) - 10// + (min < 2 ? 1 : 0)
    return result
  },
  calculateWidthsByScoreI2 (max: number, min: number) {
    let result = ((max - 1) * 29) - ((min - 1) * 29) - 2// + (min < 2 ? 1 : 0)
    return result
  },
  calculateWidthsByScoreXI (max: number, min: number) {
    let result = ((max - 1) * 29) - ((min - 1) * 29) - 3
    return result
  },
  calculateWidthsByScoreXISlim (max: number, min: number) {
    let result = ((max - 1) * 29) - ((min - 1) * 29) - 5
    return result
  },
  calculateWidthsByScoreCI (max: number, min: number) {
    let result = ((max - 1) * 29) - ((min - 1) * 29) - 3
    return result
  },
  calculateWidthsByScoreDI (max: number, min: number) {
    let result = this.calculateWidthsByScoreCI(max, min) - 2
    return result
  },
  calculateWidthsByScoreMarginI (min: number) {
    const margin = 17 + (29 * (min - 1)) + (min > 1 ? 1 : 0) + (min < 2 ? 1 : 0)
    return margin
  },
  generateXDCChartT3 (range: {min: number, max: number}, score: number, i: number, averageLeaders: number, tenPercent: number) {
    let min = range.min
    let max = range.max
    const margin = this.calculateWidthsByScoreMarginI(min)
    return {
      layout: { defaultBorder: '', fillColor: '#BFBFBF' },
      table: {
        headerRows: 2,
        widths: max !== min ? [this.calculateWidthsByScoreI(max, min)] : [-10],
        heights: [0, 0],
        body: [
          [
            {
              image: circle64,
              width: 6,
              height: 6,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreCI(averageLeaders, min), y: 2.4 },
              border: [false, false, false, false]
            }
          ],
          [
            {
              image: axis64,
              width: 5.5,
              height: 6.4,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreXI(score, min), y: 2.2 },
              border: [false, false, false, false]
            }
          ]
        ]
      },
      margin: i === 0 ? [margin, 0.5, 0, 0] : [margin, 0, 0, 0],
      style: { color: '#fff' },
      border: [true, true, true, true],
      alignment: 'left'
    }
  },
  generateXDCChartCover (scores: any[], i: number, averageLeaders: number, type?: string) {
    let tenPercent = 0
    let score = 0
    let range = []
    let min = 0
    let max = 0
    score = scores[i].total
    tenPercent = scores[i].tenPercent
    if (type === 'detail') {
      range = scores[i].individualScores
    } else {
      if (scores[i].questionsScores && scores[i].questionsScores.length > 0) {
        range = scores[i].questionsScores
      } else {
        scores[i].questions.forEach((element: any) => {
          range.push(...element.individualScores)
        })
      }
    }

    if (range && range.length > 0) {
      range.sort((a: number, b: number) => b - a)
      max = range[0]
      min = range[range.length - 1]
    }
    if (min === 0) {
      min = 1
    }
    if (max === 0) {
      max = 6
    }
    const margin = this.calculateWidthsByScoreMarginI(min)
    return {
      layout: { defaultBorder: '', fillColor: '#BFBFBF' },
      table: {
        headerRows: 3,
        widths: max !== min ? [this.calculateWidthsByScoreI(max, min)] : [-10],
        heights: [1, 0, 0],
        body: [
          // Top 10% column is removed
          [
            {
              text: '',
              // image: diamond64,
              width: 10,
              height: 10,
              aligment: 'center',
              // absolutePosition: { x: this.calculateWidthsByScoreDI(tenPercent, min), y: 2 },
              border: [false, false, false, false]
            }
          ],
          [
            {
              image: circle64,
              width: 6,
              height: 6,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreCI(averageLeaders, min), y: 4 },
              border: [false, false, false, false]
            }
          ],
          [
            {
              image: axis64,
              width: 6,
              height: 8,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreXI(score, min), y: 3 },
              border: [false, false, false, false]
            }
          ]
        ]
      },
      margin: i === 0 ? [margin, 1, 0, 0] : [margin, 0, 0, 0],
      style: { color: '#fff' },
      border: [true, true, true, true],
      alignment: 'left'
    }
  },
  generateXDCChartT9 (scores: any[], i: number, averageLeaders: number, tenPercent: number, type?: string) {
    let score = 0
    let range = []
    let min = 0
    let max = 0
    score = scores[i].total ? scores[i].total : scores[i].score
    if (type === 'detail') {
      range = scores[i].individualScores
    } else {
      if (scores[i].questionsScores && scores[i].questionsScores.length > 0) {
        range = scores[i].questionsScores
      } else {
        scores[i].questions.forEach((element: any) => {
          range.push(...element.individualScores)
        })
      }
    }

    if (range && range.length > 0) {
      range.sort((a: number, b: number) => b - a)
      max = range[0]
      min = range[range.length - 1]
    }
    if (min === 0) {
      min = 1
    }
    if (max === 0) {
      max = 6
    }
    const margin = this.calculateWidthsByScoreMarginI(min)
    return {
      layout: { defaultBorder: '', fillColor: '#BFBFBF' },
      table: {
        headerRows: 3,
        widths: max !== min ? [this.calculateWidthsByScoreI(max, min)] : [-10],
        heights: [1, 0, 0],
        body: [
          [
            {
              image: diamond64,
              width: 10,
              height: 10,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreDI(tenPercent, min), y: 2 },
              border: [false, false, false, false]
            }
          ],
          [
            {
              image: circle64,
              width: 6,
              height: 6,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreCI(averageLeaders, min), y: 4 },
              border: [false, false, false, false]
            }
          ],
          [
            {
              image: axis64,
              width: 6,
              height: 8,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreXI(score, min), y: 3 },
              border: [false, false, false, false]
            }
          ]
        ]
      },
      margin: i === 0 ? [margin, 1, 0, 0] : [margin, 0, 0, 0],
      style: { color: '#fff' },
      border: [true, true, true, true],
      alignment: 'left'
    }
  },
  generateXDCChartT7Slim (range: {min: number, max: number}, score: number, averageLeaders: number, tenPercent: number) {
    const min = range.min
    const max = range.max
    const margin = this.calculateWidthsByScoreMarginI(min)

    return {
      layout: {
        defaultBorder: '',
        fillColor: '#BFBFBF',
        paddingLeft: function () { return 0 },
        paddingRight: function () { return 0 },
        paddingTop: function () { return 5 },
        paddingBottom: function () { return -2 }
      },
      table: {
        headerRows: 2,
        widths: max !== min ? [this.calculateWidthsByScoreI2(max, min)] : [-2],
        heights: [0, 0],
        body: [
          [
            {
              image: circle64,
              width: 6,
              height: 6,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreCI(averageLeaders, min), y: 2.4 },
              border: [false, false, false, false]
            }
          ],
          [
            {
              image: axis64,
              width: 5.5,
              height: 6.4,
              aligment: 'center',
              absolutePosition: { x: this.calculateWidthsByScoreXI(score, min), y: 2.2 },
              border: [false, false, false, false]
            }
          ]
        ]
      },
      margin: [margin, 0.5, 0, 0],
      style: { color: '#fff' },
      border: [true, true, true, true],
      alignment: 'left'
    }
  },
  /**
   * @param result Valor a mostrar, puede ser numérico o string
   * @param mRight Cantidad de margen a la derecha, solo aplicable para casos necesarios, donde el centrado por alignment no es opción
   * @param condition Condición para validar mostrar el valor o string por defecto, aplicable a casos condicionales
   * @param decimals Numero de caracteres a mostrar por defecto 2
   * @return Objet
   */
  colResul (result: any, mRight?: undefined|number, condition?: boolean, decimals?: undefined|number) {
    return {
      text: condition === undefined || condition ? this.round(typeof result === 'function' ? result() : result, decimals, '--') : '--',
      ...(mRight ? { alignment: 'right', margin: [0, 5, mRight, 0] } : { alignment: 'center', margin: [0, 5, 0, 0] }),
      border: [true, true, true, true]
    }
  },
  round (value: any, decimals?: undefined| number, df?: undefined|string|number) {
    if (isNaN(value)) return df !== undefined ? df : value
    decimals = decimals === undefined || isNaN(decimals) ? 2 : decimals
    return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`).toFixed(decimals)
  }
}
