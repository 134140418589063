<template>
    <v-container flat>
      <v-card flat>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(changeStep)">
            <v-row>
              <v-col>
                {{ $t('evaluations.questions') }}
                <v-divider></v-divider>
                <v-switch
                  v-if="evaluation.edit && evaluation.status === 'pending'"
                  v-model="editQuestionnaire"
                  :label="$t('evaluations.edit_questionnaire')"
                  :append-icon="$t('help.icon')"
                  @click:append="$store.dispatch('help/display', $t('help.evaluation.create.questionnaire'))"
                  @change="changeEditQuestionnaire"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row
              v-for="(item,index) in questionnaires"
              v-bind:key="index"
            >
              <v-col cols="12" sm="7" class="pt-3 pl-4">
                <v-radio-group
                  v-model="evaluation.questionnaire"
                  :value="evaluation.questionnaire"
                  :mandatory="false"
                  class="my-0"
                  @change="changeQuestionnaire(item.name)"
                >
                  <v-radio
                    color="primary"
                    class="pt-3"
                    :label="item.name"
                    :value="item.slug"
                    :readonly="disabledQuestionnaire"
                    :disabled="disabledQuestionnaire"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="5" class="pt-6 text-right">
                <x-download-questionnaire :item="item" />
              </v-col>
            </v-row>
            <v-alert type="info" color="grey lighten-1">
              {{ $t('evaluations.custom_questionnaire_info') }}
            </v-alert>
             <v-row>
              <v-col>
                {{ $t('navigation.conceptual_framework') }}
                <v-divider></v-divider>
                <v-switch
                  v-if="evaluation.edit"
                  v-model="editConceptualFramework"
                  :label="$t('evaluations.edit_conceptual_framework')"
                  :append-icon="$t('help.icon')"
                  @click:append="$store.dispatch('help/display', $t('help.evaluation.create.conceptualFramework'))"
                  @change="changeEditConceptualFramework"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row
              v-for="(item,index) in conceptualFrameworks"
              :key="'cf-'+index"
            >
              <v-col cols="12" sm="7" class="pt-3 pl-4">
                <v-radio-group
                  v-model="evaluation.conceptualFramework"
                  :value="evaluation.conceptualFramework"
                  :mandatory="false"
                  class="my-0"
                  @change="changeConceptualFramework(item.name)"
                >
                  <v-radio
                    color="primary"
                    class="pt-3"
                    :label="item.name"
                    :value="item.slug"
                    :readonly="disabledConceptualFramework"
                    :disabled="disabledConceptualFramework"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="5" class="pt-6 text-right">
                <x-download-conceptual-framework :item="item" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-btn
                  block
                  large
                  @click="changeStep(true)"
                >{{ $t('input.back') }}</v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-btn
                  v-if="questionnaires.length && conceptualFrameworks.length"
                  large
                  block
                  color="primary"
                  type="submit"
                >{{ $t(nextAction) }}
                </v-btn>
                <v-btn
                  v-else
                  large
                  block
                  disabled
                  color="primary"
                  type="submit"
                >{{ $t(nextAction) }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-card>
    </v-container>
</template>

<script>

import questionnairesService from '../../../services/questionnaires'
import conceptualFrameworksService from '../../../services/conceptual-frameworks'

import XDownloadQuestionnaire from '../components/download-questionnaire.vue'
import XDownloadConceptualFramework from '../components/download-conceptual-framework.vue'

export default {
  components: {
    XDownloadConceptualFramework,
    XDownloadQuestionnaire
  },
  props: {
    evaluation: Object,
    step: String,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      questionnaires: [],
      conceptualFrameworks: [],
      editQuestionnaire: false,
      editConceptualFramework: false
    }
  },
  computed: {
    disabledQuestionnaire () {
      return this.evaluation.edit ? !this.editQuestionnaire || this.evaluation.status !== 'pending' : false
    },
    disabledConceptualFramework () {
      return this.evaluation.edit && !this.editConceptualFramework
    }
  },
  methods: {
    changeConceptualFramework (label) {
      this.evaluation.conceptualFrameworkLabel = label
    },
    changeQuestionnaire (label) {
      this.evaluation.questionnaireLabel = label
    },
    changeEditQuestionnaire () {
      this.evaluation.editQuestionnaire = this.editQuestionnaire
    },
    changeEditConceptualFramework () {
      this.evaluation.editConceptualFramework = this.editConceptualFramework
    },
    changeStep (isBack = false) {
      this.$emit('changeStep', this.evaluation, isBack ? +this.step - 1 : +this.step + 1)
    },
    getQuestionnaires () {
      this.$store.dispatch('loading/show')
      return questionnairesService.listFiltered().then((res) => {
        this.questionnaires = res.items
        this.evaluation.questionnaires = res.items
        this.$store.dispatch('loading/hide')
        if (!this.evaluation.edit) {
          this.changeQuestionnaire((res.items.find(item => item.slug === this.evaluation.questionnaire) || {}).name)
        }
      })
    },
    getConceptualFrameworks () {
      this.$store.dispatch('loading/show')
      return conceptualFrameworksService.listFiltered().then((res) => {
        this.conceptualFrameworks = res.items
        this.$store.dispatch('loading/hide')
        if (!this.evaluation.edit) {
          this.changeConceptualFramework((res.items.find(item => item.slug === this.evaluation.conceptualFramework) || {}).name)
        }
      })
    }
  },
  created () {
    this.getQuestionnaires()
    this.getConceptualFrameworks()
  }
}
</script>
