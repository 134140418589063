
import PdfUtil from '../utils/pdf'
import pdfUtil from '../utils_old/pdf'

export default {
  data () {
    return {
      maxRGChars: 332, // Generals
      maxRSChars: 248, // Specifics
      resultsTables: [],
      tableCount: 5,
      tableWidths: {
        landscape: ['46%', '10%', '26%', '6%', '6%', '6%'],
        portrait: ['24%', '14%', '38%', '8%', '8%', '8%']
      }
    }
  },
  methods: {
    limitGAndSText (str, type = 'specific') {
      let max = type === 'gral' ? this.maxRGChars : this.maxRSChars
      return str.length > max ? str.slice(0, max) + '...' : str
    },
    $generateSpecificResponsibilityResults () {
      const getItemByIdx = (responsability, idx) => {
        switch (idx) {
          case 0: return {
            score: responsability.auto,
            previous: responsability.previousAuto,
            tenUp: responsability.tenUpAuto,
            range: responsability.rangeAuto
          }
          case 1: return responsability.relations.leadersEvaluators
          case 2: return responsability.relations.pairsEvaluators
          case 3: return responsability.relations.dependentsEvaluators
          case 4: return {
            score: responsability.evaluators,
            previous: responsability.previousEvaluators,
            tenUp: responsability.tenUpEvaluators,
            range: responsability.rangeEvaluators
          }
        }
        return responsability
      }
      const textColor = '#6d7a7a'

      let idx = 0
      const responsabilitiesKeys = Object.keys(this.answersResponsibility.general)
      for (const key of responsabilitiesKeys) {
        const responsability = this.answersResponsibility.general[key].filtered
        const responsabilityName = this.questionnaire.general[key].name[this.user.lang]
        const responsabilityDesc = this.baseQuestionnaire.evaluations.generalEvaluation[idx].description
          ? this.baseQuestionnaire.evaluations.generalEvaluation[idx].description[this.user.lang]
          : this.questionnaire.general[key].name[this.user.lang]
        const allScoreData = [
          getItemByIdx(responsability, 0).score,
          getItemByIdx(responsability, 1).score,
          getItemByIdx(responsability, 2).score,
          getItemByIdx(responsability, 3).score,
          getItemByIdx(responsability, 4).score
        ].filter(score => score)
        // Calcula simbolo a mostrar (+) o (-)
        const symbol = this.getSymbol(responsability.auto, responsability.evaluators)
        const currentGeneralResponsibility = []
        for (let i = 0; i < 5; i++) {
          const data = getItemByIdx(responsability, i)
          const dataLeaders = getItemByIdx(this.answersResponsibility.general[key].score, i)

          const isValid = !isNaN(data.score) && data.score >= 1
          const hasValidTrend = this.hasPrevious && isValid && !isNaN(data.previous) && data.previous >= 1

          currentGeneralResponsibility.push([
            { text: this.limitGAndSText(responsabilityDesc, 'gral') + symbol, rowSpan: 5, color: textColor, margin: [0, 2, 0, 0], border: [true, true, true, true] },
            { text: this.relations[i], bold: true, color: textColor, margin: [-3, 0, -3, -4], border: [true, true, true, true], fontSize: 8.5 },
            isValid ? pdfUtil.generateXDCChartT3(data.range, data.score, i, this.round(dataLeaders.score, 2), dataLeaders.tenUp) : { text: '--', margin: [0, 0, 0, -2.5], border: [true, true, true, true] },
            pdfUtil.higherLowerRow(allScoreData, isValid ? data.score : '--', textColor, [0, 0.5, 0, -4], 'center', [true, true, true, true]),
            { text: isValid > 0 ? this.round(dataLeaders.score, 2) : '--', bold: false, color: textColor, margin: [0, 0.5, 0, -4], border: [true, true, true, true], alignment: 'center' },
            { text: `${hasValidTrend ? this.round(data.score - data.previous, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 0.5, 0, -4], border: [true, true, true, true], alignment: 'center' }
          ])
        }
        // ASSEMBLE GENERAL TABLE
        this.resultsTables.push([
          {
            table: {
              widths: [760], // 479 Portrait
              body: [
                [{ text: [
                  `${this.$t('individualReport.table')} ${this.tableCount}. ${this.$t('individualReport.table4')}`,
                  { text: ` ${responsabilityName}`, color: this.chartHexColors[idx] }
                ],
                bold: true,
                color: '#fff',
                margin: [20, 6, 0, 6] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [0, 0, 135, 15],
            pageBreak: idx === 0 ? '' : 'before'
          },
          pdfUtil.generateLiderazgoLegendIndividual(this.legend, true),
          this.generateResultsTableWithHeader(this.tableWidths.landscape, currentGeneralResponsibility, -5, true)
        ])

        const currentFirstThreeSpecificResponsibilities = []
        const currentMidleFive1SpecificResponsibilities = []
        const currentMidleFive2SpecificResponsibilities = []
        const currentRemainingSpecificResponsibilities = []
        const specificKeys = Object.keys(this.answersResponsibility.general[key].specific)
        for (const specifickey of specificKeys) {
          const specific = this.answersResponsibility.general[key].specific[specifickey].filtered
          const specificName = this.questionnaire.general[key].specific[specifickey][this.user.lang]
          const allScoreDataSpecific = [
            getItemByIdx(specific, 0).score,
            getItemByIdx(specific, 1).score,
            getItemByIdx(specific, 2).score,
            getItemByIdx(specific, 3).score,
            getItemByIdx(specific, 4).score
          ].filter(score => score)
          // Calcula simbolo a mostrar (+) o (-)
          const symbol = this.getSymbol(specific.auto, specific.evaluators)
          for (let i = 0; i < 5; i++) {
            const data = getItemByIdx(specific, i)
            const dataLeaders = getItemByIdx(this.answersResponsibility.general[key].specific[specifickey].score, i)
            // Calcula porcentaje de respuestas "No sabe" para los pares
            const unknownPercent = i >= 1 || i <= 3 ? this.getUnknownPercent(data) : ''

            const isValid = !isNaN(data.score) && data.score >= 1
            const hasValidTrend = this.hasPrevious && isValid && !isNaN(data.previous) && data.previous >= 1

            const temp = [
              { text: this.limitGAndSText(specificName) + symbol, rowSpan: 5, color: textColor, margin: [-1, 1, -1, 0], border: [true, true, true, true] },
              { text: this.relations[i] + unknownPercent, bold: true, color: textColor, margin: [-3, 0, -3, -4], border: [true, true, true, true], fontSize: 8.5 },
              isValid ? pdfUtil.generateXDCChartT7Slim(data.range, data.score, this.round(dataLeaders.score, 2), dataLeaders.tenUp) : { text: '--', margin: [0, 0, 0, -2.5], border: [true, true, true, true] },
              pdfUtil.higherLowerRow(allScoreDataSpecific, isValid ? data.score : '--', textColor, [0, 0.5, 0, -4], 'center', [true, true, true, true], 'question'),
              { text: isValid ? this.round(dataLeaders.score, 2) : '--', bold: false, color: textColor, margin: [0, 0.5, 0, -4], border: [true, true, true, true], alignment: 'center' },
              { text: `${hasValidTrend ? this.round(data.score - data.previous, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 0.5, 0, -4], border: [true, true, true, true], alignment: 'center' }
            ]
            if (currentFirstThreeSpecificResponsibilities.length < 15) {
              currentFirstThreeSpecificResponsibilities.push(temp)
            } else {
              // Five middle 5 x 5 = 25
              if (currentMidleFive1SpecificResponsibilities.length < 25) {
                currentMidleFive1SpecificResponsibilities.push(temp)
              } else if (currentMidleFive2SpecificResponsibilities.length < 25) {
                currentMidleFive2SpecificResponsibilities.push(temp)
              } else {
                currentRemainingSpecificResponsibilities.push(temp)
              }
            }
          }
        }
        // ASSEMBLE SPECIFICS TABLE
        this.resultsTables.push(
          this.generateResultsTableWithHeader(this.tableWidths.landscape, currentFirstThreeSpecificResponsibilities, 10, false)
        )
        // Second page with the five middle (if any)
        if (currentMidleFive1SpecificResponsibilities.length > 0) {
          this.resultsTables.push(
            this.generateResultsTableWithHeader(this.tableWidths.landscape, currentMidleFive1SpecificResponsibilities, -2, false, 'before')
          )
        }
        // Third page with the five middle (if any)
        if (currentMidleFive2SpecificResponsibilities.length > 0) {
          this.resultsTables.push(
            this.generateResultsTableWithHeader(this.tableWidths.landscape, currentMidleFive2SpecificResponsibilities, -2, false, 'before')
          )
        }
        // Last page with the rest (if any)
        if (currentRemainingSpecificResponsibilities.length > 0) {
          this.resultsTables.push(
            this.generateResultsTableWithHeader(this.tableWidths.landscape, currentRemainingSpecificResponsibilities, -2, false, 'before')
          )
        }
        idx++
        this.tableCount++
      }
      return [
        PdfUtil.generateTitleLandscape(this.$t('individualReport.responsability_specifics_results_title'), 'before', true, true),
        ...this.resultsTables
      ]
    }
  }
}
