
import axis64 from '../base64Files/axis64'

const generateTitle = (text: string, pageBreak?: string, isToc?: boolean, isSubToc?: boolean, isLandscape?: boolean): object => {
  const maxChars = 77
  const body: {[key: string]: any} = [
    [{ text: text.toUpperCase(), bold: true, color: '#FFFFFF', margin: [40, 5, 5, 5] }]
  ]
  if (isToc) {
    let indexText = text.length > maxChars ? text.slice(0, maxChars) + '...' : text
    body[0].push(
      {
        text: indexText,
        fontSize: 0,
        tocItem: isSubToc ? ['mainToc', 'subToc'] : 'mainToc',
        tocMargin: isSubToc ? [15, 8, 0, 0] : [0, 8, 0, 0] }
    )
  } else {
    body[0].push({})
  }
  if (isLandscape) {
    pageBreak = 'before'
  }
  return {
    table: {
      widths: [479, 0],
      body: body
    },
    layout: {
      fillColor: '#aaaaaa',
      defaultBorder: ''
    },
    alignment: 'left',
    margin: [-40, 0, 0, 20],
    ...(pageBreak ? { pageBreak } : {}),
    pageOrientation: isLandscape ? 'landscape' : 'portrait'
  }
}

const generateSubTitle = (text: string, pageBreak?: string, isSubToc?: boolean, wdth: number = 470): object => {
  const maxChars = 40
  const body: {[key: string]: any} = [
    [{ text: text, bold: true, color: '#FFFFFF', margin: [20, 6, 0, 6] }]
  ]
  if (isSubToc) {
    let indexText = text.length > maxChars ? text.slice(0, maxChars) + '...' : text
    body[0].push(
      { text: indexText, fontSize: 0, tocItem: ['mainToc', 'subToc'], tocMargin: [15, 8, 0, 0] }
    )
  }
  return {
    table: {
      widths: [wdth, 0],
      body: body
    },
    layout: {
      fillColor: '#aaaaaa',
      defaultBorder: ''
    },
    alignment: 'left',
    margin: [35, 0, 100, 18],
    pageBreak: pageBreak
  }
}

export default {
  generateTitle,
  generateTitleLandscape: (text: string, pageBreak?: string, isToc: boolean = false, isSubToc: boolean = false): object => {
    const base: any = {
      ...generateTitle(text, pageBreak, isToc, isSubToc),
      pageOrientation: 'landscape'
    }
    base.table.widths[0] = 621
    base.margin = [130, -50, 0, 20]
    return base
  },
  generateSubTitle,
  generateSubTitleLandscape: (text: string, pageBreak?: string) => {
    const base: any = {
      ...generateSubTitle(text, pageBreak),
      pageOrientation: 'landscape'
    }
    base.table.widths[0] = 725
    return base
  },
  generateParagraph: (text: string|Array<any>, options?: {
    alignment?: string;
    fontSize?: number;
    pageBreak?: string;
    italics?: boolean;
    bold?: boolean;
  }) => {
    options = options || {}
    return {
      width: 'auto',
      text,
      color: '#6d7a7a',
      fontSize: options.fontSize || 12,
      alignment: options.alignment || 'justify',
      bold: Boolean(options.bold),
      italics: Boolean(options.italics),
      ...(options.pageBreak ? { pageBreak: options.pageBreak } : {})
    }
  },
  ucfirst: (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  },
  generateCenteredText: (text: string, fontSize?: number, bold?: boolean, color?: string, italic?: boolean, pageBreak?: string): object[] => {
    return [
      { width: '*', text: '' },
      {
        width: 'auto',
        text,
        color: color || '',
        fontSize: fontSize || 22,
        alignment: 'center',
        bold,
        italics: Boolean(italic),
        pageBreak
      },
      { width: '*', text: '' }
    ]
  },
  generateResultsLegend (translations: any[]) {
    return {
      layout: {
        defaultBorder: '',
        border: [false, false, false, true]
      },
      table: {
        headerRows: 1,
        body: [
          [
            {
              layout: {
                fillColor: function (rowIndex: any, node: any, columnIndex: any) {
                  return (columnIndex === 0) ? '#BFBFBF' : null
                }
              },
              table: {
                body: [
                  [
                    { text: '', margin: [10, 0, 10, -10], border: [false, false, false, false], bold: true, style: { color: '#fff' } },
                    { text: translations[0], margin: [0, 0, 0, 0], border: [false, false, false, false], style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    { text: 'X', margin: [5, 0, 0, 0], border: [false, false, false, false], bold: true, style: { color: '#6d7a7a' } },
                    { text: translations[1], margin: [0, 0, 0, 0], border: [false, false, false, false], bold: false, style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            },
            {
              table: {
                body: [
                  [
                    { text: translations[2], margin: [0, -5, 0, 0], border: [false, false, false, false], bold: true, style: { color: '#6d7a7a' } }
                  ],
                  [
                    { text: translations[3], margin: [0, -5, 0, 0], border: [false, false, false, false], decoration: 'underline', style: { color: '#6d7a7a' } }
                  ]
                ]
              }
            }
          ]
        ]
      },
      margin: [220, 0, 0, 10],
      border: [false, false, false, false],
      alignment: 'center',
      fontSize: 6.5
    }
  },
  calculateWidthsByScoreMarginGeneral (min: number) {
    const margin = 17 + (29 * (min - 1)) + (min > 1 ? 1 : 0) + (min < 2 ? 1 : 0)
    return margin
  },
  calculateWidthsByScoreGeneral (max: number, min: number) {
    let result = ((max - 1) * 29) - ((min - 1) * 29) - 10// + (min < 2 ? 1 : 0)
    return result
  },
  calculateWidthsByScoreXGeneral (score: number, min: number, noBreakRow: boolean, isDemog: boolean) {
    const initMrg = isDemog ? 332.7 : 410.5
    const result = ((score - 1) * 29) - ((min - 1) * 29) - 3
    const resultNoBrk = (initMrg + (score * 29)) - 29
    return noBreakRow ? resultNoBrk : result
  },
  generateGeneralXDCChartT3 (range: {min: number, max: number}, score: number, noBrkRow: boolean = false, isDemographic: boolean = false) {
    let min = range.min
    let max = range.max
    const margin = this.calculateWidthsByScoreMarginGeneral(min)
    return {
      layout: { defaultBorder: '', fillColor: '#BFBFBF' },
      table: {
        headerRows: 1,
        widths: max !== min ? [this.calculateWidthsByScoreGeneral(max, min)] : [-10],
        heights: [10, 0, 0],
        body: [
          [
            {
              image: axis64,
              width: 6,
              height: 8,
              aligment: 'center',
              absolutePosition: {
                x: this.calculateWidthsByScoreXGeneral(score, min, noBrkRow, isDemographic),
                y: noBrkRow ? 12 : 3.5
              },
              border: [false, false, false, false]
            }
          ]
        ]
      },
      margin: [margin, 5.5, 0, 5],
      style: { color: '#fff' },
      border: [true, true, true, true],
      alignment: 'left'
    }
  }
}
