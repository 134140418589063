
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-row justify="space-between" class="px-8">
          <h4 class="display-1 left">{{ evaluation.name }}</h4>
          <v-chip
            class="mb-3 white--text right"
            color="primary"
            v-if="evaluation.displayName"
          >{{ evaluation.displayName }}</v-chip>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-row class="ma-3" justify="center">
            <v-col cols="12">
              <v-select
                v-model="typeReport"
                :items="reportTypes"
                :label="$t('reports.select_report_type')"
                :menu-props="{ offsetY: true }"
                item-value="value"
                item-text="name"
              />
            </v-col>
          </v-row>

          <!-- General -->
          <v-card-text v-if="typeReport === 'organizational'">
            <h5 class="headline mb-2">{{ $t('reports.required_general_title') }}</h5>
            <p class="mt-5 mb-12">{{ $t('reports.required_general_desc') }}</p>
            <v-row justify="center">
              <span v-if="displayReport">
                  <x-thread-organizational-report
                    v-if="validEvaluation"
                    :poll-id="this.$route.params.id"
                    :disable-button="disableButton"
                  ></x-thread-organizational-report>
                  <div v-else class="headline red--text">{{ $t('reports.no_autocompleteds') }}</div>
              </span>
            </v-row>
          </v-card-text>

          <!-- Individual -->
          <v-card-text v-if="typeReport === 'individual'">
            <h5 class="headline mb-2">{{ $t('reports.individual_title') }}</h5>
            <p class="mt-5 mb-12">{{ $t('reports.individual_desc') }}</p>
            <v-row justify="center">
              <v-col cols="12">
                <v-autocomplete
                  v-model="evaluated"
                  :items="evaluation.evaluated"
                  :label="$t('reports.evaluated')"
                  item-value="employee.employee.value"
                  item-text="employee.employee.text"
                  @change="changeIndividual"
                />
              </v-col>
              <v-col v-if="displayReport" class="d-flex justify-center">
                <x-thread-individual-report
                  v-if="validEvaluated"
                  :poll-id="this.$route.params.id"
                  :evaluatedId="evaluated"
                  :evaluatedName="evaluatedName"
                  :disable-button="!validEvaluated"
                  :poll-completed="validEvaluated"
                ></x-thread-individual-report>
                <div v-else class="headline red--text">{{ $t('reports.no_autocompleted') }}</div>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Demographic -->
          <v-card-text v-if="typeReport === 'demographic'" class="px-8">
            <h5 class="headline mb-2">{{ $t('reports.demographic_title') }}</h5>
            <p class="mt-5 mb-2">{{ $t('reports.demographic_desc') }}</p>
            <h6 class="headline mb-2 text-right mt-10">
              {{ $t('reports.number_leaders_evaluated') }}:
              {{ showCountedEvaluated }}/{{ evaluatedLeaders.length }}
            </h6>
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-row>
                  <!-- Country -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['country']"
                      :label="$t(`reports.demographicCuts.country`)"
                      :items="demographicCuts['country']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                      @change="updateHeadquarters($event)"
                    />
                  </v-col>
                  <!-- Headquarter -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['headquarter']"
                      :label="$t(`reports.demographicCuts.headquarter`)"
                      :items="demographicCuts['headquarter']"
                      :item-value="'id'"
                      :item-text="'name'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                      :loading="loadingHeadquarters"
                    />
                  </v-col>
                  <!-- Department -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['departments']"
                      :label="$t(`reports.demographicCuts.departments`)"
                      :items="demographicCuts['departments']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                      @change="updateCharges($event)"
                    />
                  </v-col>
                  <!-- Charge -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['charge']"
                      :label="$t(`reports.demographicCuts.charge`)"
                      :items="demographicCuts['charge']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                      :loading="loadingCharges"
                    />
                  </v-col>
                  <!-- Job Type -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['jobTypes']"
                      :label="$t(`reports.demographicCuts.jobTypes`)"
                      :items="demographicCuts['jobTypes']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <!-- Academic Degree -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['academicDegree']"
                      :label="$t(`reports.demographicCuts.academicDegree`)"
                      :items="demographicCuts['academicDegree']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <!-- Age -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['age']"
                      :label="$t(`reports.demographicCuts.age`)"
                      :items="demographicCuts['age']"
                      :item-value="'range'"
                      :item-text="'name'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <!-- Antiquity -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['antiquity']"
                      :label="$t(`reports.demographicCuts.antiquity`)"
                      :items="demographicCuts['antiquity']"
                      :item-value="'range'"
                      :item-text="'name'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <!-- Gender -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['gender']"
                      :label="$t(`reports.demographicCuts.gender`)"
                      :items="demographicCuts['gender']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>

                  <v-col cols="12" sm="6"
                    v-if="$vuetify.breakpoint.smAndUp"
                    v-bind:style="'margin-right:auto'"
                  ></v-col>
                  <!-- Additional Demographic 1 -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['additionalDemographic1']"
                      :label="$t(`reports.demographicCuts.additionalDemographic1`)"
                      :items="demographicCuts['additionalDemographics1']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                  <!-- Additional Demographic 2 -->
                  <v-col xs="12" sm="6">
                    <v-select
                      v-model="cutsSelected['additionalDemographic2']"
                      :label="$t(`reports.demographicCuts.additionalDemographic2`)"
                      :items="demographicCuts['additionalDemographics2']"
                      :item-value="'id'"
                      :item-text="'translate.label'"
                      :menu-props="{ offsetY: true }"
                      outlined
                      multiple
                      chips
                      deletable-chips
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col xs="12" class="pt-0">
                <span class="d-flex justify-center">
                  <x-thread-demographic-report
                    v-show="hasCutSelected && showCountedEvaluated > 0"
                    :poll-id="this.$route.params.id"
                    :cutsSelected="cutsSelected"
                  ></x-thread-demographic-report>
                </span>
                <span
                  v-if="!showCountedEvaluated > 0"
                  class="headline red--text d-flex justify-center"
                >{{ $t('reports.message_number_leaders_evaluated') }}</span>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider class="mt-4"></v-divider>

          <v-card-text v-if="reportThreads.length" class="pa-0">
            <!-- Refresh Page Button -->
            <v-row class="pr-8" v-if="showUpdateProgressBtn">
              <v-col cols="12" class="pr-8 text-right">
                <span
                  class="caption d-inline-block mt-2 grey--text text--darken-1 cursor-pointer"
                  @click="getThreads"
                >
                  {{ $t('individualReport.input_update_progress') }}
                </span>
                <v-btn fab dark x-small
                  absolute
                  right
                  color="primary"
                  class="refresh-fab mr-n1"
                  :loading="loadingThreads"
                  @click="getThreads"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- Report Requests -->
            <v-row>
              <v-col cols="12" class="my-0 pt-12 py-0">
                <x-report-threads
                  :evaluation="evaluation"
                  :individuals="evaluation.evaluated"
                  :threads="reportThreads"
                  :demographics="demographicCuts"
                ></x-report-threads>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row justify="center">
              <v-col cols="10">
                <v-btn class="ml-5 my-3" @click="goBack()" large block>{{ $t('input.back') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" width="600px">
      <v-card>
        <v-card-text style="padding: 24px 20px;">{{ $t('reports.no_answers_modal_msg') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="showModal = !showModal"
          >{{ $t('input.accept') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
  .refresh-fab.v-btn--fab.v-btn--absolute {
    z-index: 3;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>

<script>

import { mapState } from 'vuex'
import resolver from '../../utils/resolver'

import XThreadOrganizationalReport from '../reports/thread_organizational_report'
import XThreadIndividualReport from '../reports/thread_individual_report'
import XThreadDemographicReport from '../reports/thread_demographic_report'

import XReportThreads from '../reports/report-threads'

import evaluationService from '../../services/evaluations'
import additionalDemographic1Service from '../../services/additional-demographics1'
import additionalDemographic2Service from '../../services/additional-demographics2'
import demographicService from '../../services/demographic-items'
import academicDegreeService from '../../services/academic-degrees'
import chargeService from '../../services/charges'
import countryService from '../../services/countries'
import departmentService from '../../services/departments'
import genderService from '../../services/genders'
import headquarterService from '../../services/headquarters'
import jobTypeService from '../../services/job-types'

export default {
  components: {
    XThreadOrganizationalReport,
    XThreadIndividualReport,
    XThreadDemographicReport,
    XReportThreads
  },
  data () {
    return {
      typeReport: 'organizational',
      demographicCuts: {},
      cutsSelected: {},
      isAnyCutSelected: false,
      loadingThreads: true,
      loadingHeadquarters: false,
      loadingCharges: false,
      disableButton: false,
      evaluation: {},
      evaluatedLeaders: [],
      displayReport: true,
      showModal: false,
      evaluated: 0,
      countedEvaluated: 0,
      validEvaluation: true,
      reportThreads: [],
      evaluatedName: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    reportTypes () {
      return [
        { name: this.$i18n.t('reports.required_general_title'), value: 'organizational' },
        { name: this.$i18n.t('reports.individual_title'), value: 'individual' },
        { name: this.$i18n.t('reports.demographic_title'), value: 'demographic' }
      ]
    },
    showUpdateProgressBtn () {
      let shouldShow = false
      if (this.reportThreads.length) {
        const inProgress = this.reportThreads.find((t) => {
          return t.data.progress !== 100
        })
        if (inProgress) {
          shouldShow = true
        }
      }
      return shouldShow
    },
    showCountedEvaluated () {
      return this.countedEvaluated
    },
    hasCutSelected () {
      return this.isAnyCutSelected
    },
    validEvaluated () {
      if (this.evaluated === 0) {
        return true
      }
      if (this.evaluation && this.evaluation.evaluated) {
        const evaluated = this.evaluation.evaluated.find((ev) => ev.employee.employee.id === this.evaluated)
        return evaluated && evaluated.employee && evaluated.employee.completed
      } else {
        return false
      }
    }
  },
  created () {
    this.getEvaluation()
    this.getDemographicsItems()
  },
  methods: {
    reportFinishedTwo () {
      this.evaluated = ''
    },
    changeIndividual (e) {
      const found = this.evaluation.evaluated.find(ev => ev.employee.employee.id === e)
      if (found) {
        this.evaluatedName = found.employee.employee.text
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    getEvaluation () {
      this.$store.dispatch('loading/show')
      evaluationService.findById(this.$route.params.id)
        .then((res) => {
          if (res.status !== 'completed') {
            this.goBack()
            return
          }
          this.evaluation = res
          this.validateEvaluation(res.evaluated)
          this.evaluatedLeaders = res.evaluated
          this.disableButton = true
          this.$store.dispatch('loading/hide')
          this.getThreads()
        })
        .catch(err => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    validateEvaluation (evaluated) {
      const validEvaluated = evaluated.filter((ev) => ev.employee.completed === true)
      this.validEvaluation = validEvaluated.length > 0
    },
    getDemographicsItems () {
      this.getDemographicsItemsByEntreprise()
        .then(res => {
          this.demographicCuts = res.data
          let antiquity = {
            'es': [
              { range: { min: 0, max: 0.5 }, name: 'Menos de 6 meses' },
              { range: { min: 0.5, max: 1 }, name: 'De 6 meses a 1 año' },
              { range: { min: 1, max: 3 }, name: 'De 1 año a 3 años' },
              { range: { min: 3, max: 5 }, name: 'De 3 años a 5 años' },
              { range: { min: 5, max: 10 }, name: 'De 5 años a 10 años' },
              { range: { min: 10, max: 0 }, name: 'Más de 10 años' }
            ],
            'en': [
              { range: { min: 0, max: 0.5 }, name: 'Less than 6 months' },
              { range: { min: 0.5, max: 1 }, name: 'From 6 months to 1 year' },
              { range: { min: 1, max: 3 }, name: 'From 1 year to 3 years' },
              { range: { min: 3, max: 5 }, name: 'From 3 years to 5 years' },
              { range: { min: 5, max: 10 }, name: 'From 5 years to 10 years' },
              { range: { min: 10, max: 0 }, name: 'More than 10 years' }
            ]
          }

          let age = {
            'es': [
              { range: { min: 0, max: 25 }, name: 'Menos de 25 años' },
              { range: { min: 25, max: 35 }, name: 'De 25 a 35 años' },
              { range: { min: 35, max: 45 }, name: 'De 35 a 45 años' },
              { range: { min: 45, max: 55 }, name: 'De 45 a 55 años' },
              { range: { min: 55, max: 0 }, name: 'Más de 55 años' }
            ],
            'en': [
              { range: { min: 0, max: 25 }, name: 'Less than 25 years' },
              { range: { min: 25, max: 35 }, name: 'From 25 to 35 years' },
              { range: { min: 35, max: 45 }, name: 'From 35 to 45 years' },
              { range: { min: 45, max: 55 }, name: 'From 45 to 55 years' },
              { range: { min: 55, max: 0 }, name: 'More than 55 years' }
            ]
          }

          this.demographicCuts.age = age[this.user.lang]
          this.demographicCuts.antiquity = antiquity[this.user.lang]
          this.headquarterTemp = this.demographicCuts.headquarter
          this.chargesTemp = this.demographicCuts.charge
        })
        .catch(err => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    getDemographicsItemsByEntreprise () {
      let demographicItemsData = {
        additionalDemographics1: [],
        additionalDemographics2: [],
        jobTypes: [],
        headquarter: [],
        gender: [],
        departments: [],
        country: [],
        charge: [{}],
        antiquity: [],
        age: [],
        academicDegree: []
      }
      let demographicItem = []

      return resolver.all({
        demographicItem: demographicService.getDemographicsItemsFromSuite(),
        additionalDemographics1: additionalDemographic1Service.listFromSuite(),
        additionalDemographics2: additionalDemographic2Service.listFromSuite(),
        academicDegrees: academicDegreeService.listFromSuite(),
        charges: chargeService.listFromSuite(),
        countries: countryService.listFromSuite(),
        departments: departmentService.listFromSuite(),
        genders: genderService.listFromSuite(),
        headquarters: headquarterService.listFromSuite(),
        jobTypes: jobTypeService.listFromSuite()
      })
        .then((res) => {
          demographicItem.push(...res.demographicItem.items)
          demographicItemsData.additionalDemographics1 = res.additionalDemographics1.items
          demographicItemsData.additionalDemographics2 = res.additionalDemographics2.items
          demographicItemsData.academicDegree = res.academicDegrees.items
          demographicItemsData.departments = res.departments.items
          demographicItemsData.charge = []
          for (let index = 0; index < res.charges.items.length; index++) {
            let object = {
              label: res.charges.items[index].translations[0].label, id: res.charges.items[index].id
            }
            demographicItemsData.charge.push(res.charges.items[index])
            demographicItemsData.charge[index].translate = object
          }
          demographicItemsData.country = res.countries.items
          demographicItemsData.gender = res.genders.items
          demographicItemsData.headquarter = res.headquarters
          demographicItemsData.jobTypes = res.jobTypes.items

          return { demographicItem: demographicItem, data: demographicItemsData }
        })
    },
    updateHeadquarters (countries) {
      this.loadingHeadquarters = true
      if (countries.length > 0) {
        headquarterService.listByCountriesFromSuite(countries).then(res => {
          this.demographicCuts.headquarter = res.items
          this.loadingHeadquarters = false
        })
      } else {
        this.demographicCuts.headquarter = this.headquarterTemp
        this.loadingHeadquarters = false
      }
    },
    updateCharges (departments) {
      this.loadingCharges = true
      if (departments.length > 0) {
        chargeService.listByDepartmentsFromSuite(departments).then(res => {
          this.demographicCuts.charge = res.items
          this.loadingCharges = false
        })
      } else {
        this.demographicCuts.charge = this.chargesTemp
        this.loadingCharges = false
      }
    },
    countEvaluated (newValue) {
      let cutSelected = {
        additionalDemographic1: newValue.additionalDemographic1 || [],
        additionalDemographic2: newValue.additionalDemographic2 || [],
        country: newValue.country || [],
        headquarter: newValue.headquarter || [],
        departments: newValue.departments || [],
        charge: newValue.charge || [],
        jobTypes: newValue.jobTypes || [],
        academicDegree: newValue.academicDegree || [],
        gender: newValue.gender || [],
        age: newValue.age || [],
        antiquity: newValue.antiquity || []
      }
      if (
        cutSelected.additionalDemographic1.length === 0 &&
        cutSelected.additionalDemographic2.length === 0 &&
        cutSelected.country.length === 0 &&
        cutSelected.headquarter.length === 0 &&
        cutSelected.departments.length === 0 &&
        cutSelected.charge.length === 0 &&
        cutSelected.jobTypes.length === 0 &&
        cutSelected.academicDegree.length === 0 &&
        cutSelected.gender.length === 0 &&
        cutSelected.age.length === 0 &&
        cutSelected.antiquity.length === 0
      ) {
        this.countedEvaluated = 0
        this.isAnyCutSelected = false
      } else {
        const leadersFiltered = this.evaluatedLeaders.filter((ev) => {
          return !(
            (cutSelected.additionalDemographic1.indexOf(ev.employee.employee.additionalDemographic1Id) === -1 && cutSelected.additionalDemographic1.length > 0) ||
            (cutSelected.additionalDemographic2.indexOf(ev.employee.employee.additionalDemographic2Id) === -1 && cutSelected.additionalDemographic2.length > 0) ||
            (cutSelected.country.indexOf(ev.employee.employee.countryId) === -1 && cutSelected.country.length > 0) ||
            (cutSelected.headquarter.indexOf(ev.employee.employee.headquarterId) === -1 && cutSelected.headquarter.length > 0) ||
            (cutSelected.departments.indexOf(ev.employee.employee.departmentId) === -1 && cutSelected.departments.length > 0) ||
            (cutSelected.charge.indexOf(ev.employee.employee.chargeId) === -1 && cutSelected.charge.length > 0) ||
            (cutSelected.jobTypes.indexOf(ev.employee.employee.jobTypeId) === -1 && cutSelected.jobTypes.length > 0) ||
            (cutSelected.academicDegree.indexOf(ev.employee.employee.academicDegreeId) === -1 && cutSelected.academicDegree.length > 0) ||
            (cutSelected.gender.indexOf(ev.employee.employee.genderId) === -1 && cutSelected.gender.length > 0) ||
            (cutSelected.age.length > 0 && !this.calculateTime(cutSelected.age, ev.employee.employee.birthdate)) ||
            (cutSelected.antiquity.length > 0 && !this.calculateTime(cutSelected.antiquity, ev.employee.employee.admission))
          ) || false
        })
        this.countedEvaluated = leadersFiltered.length
        this.isAnyCutSelected = true
      }
    },
    calculateTime (range = [], date) {
      let currentDate = new Date()
      let oldDate = new Date(date)
      let milliseconds = 12 * 30.416666666666668 * 24 * 60 * 60 * 1000
      let millisecondsElapsed = currentDate - oldDate
      let yearsElapsed = Math.floor(millisecondsElapsed / milliseconds)
      let evaluatedLeaderRange = range.filter((item) => {
        return (yearsElapsed >= item.min && (yearsElapsed < item.max || item.max === 0)) || false
      })
      return evaluatedLeaderRange.length > 0 || false
    },
    getThreads () {
      this.loadingThreads = true
      evaluationService.currentThreadsById(this.$route.params.id)
        .then((res) => {
          this.reportThreads = res
        })
        .finally(() => {
          this.loadingThreads = false
        })
    }
  },
  watch: {
    cutsSelected: {
      deep: true,
      handler: function (newValue, oldValue) {
        this.countEvaluated(newValue)
      }
    }
  }
}
</script>
