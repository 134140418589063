
import PdfUtil from '../utils/pdf'
import pdfUtil from '../utils_old/pdf'

export default {
  data () {
    return {
      chartTableCharCount: 0,
      maxChartGralRespChars: 40
    }
  },
  methods: {
    limitChartTableText (str) {
      let strLenght = str.length
      this.chartTableCharCount += strLenght
      return strLenght > this.maxChartGralRespChars ? str.slice(0, this.maxChartGralRespChars) + '...' : str
    },
    $generateChartGraph () {
      const responsabilities = []
      const noZero = (n) => (!n ? '--' : this.round(n, 2, '--'))

      const responsabilitiesKeys = Object.keys(this.answersResponsibility.general)
      for (const key of responsabilitiesKeys) {
        const resp = this.answersResponsibility.general[key].filtered
        const trend = this.hasPrevious ? this.round(resp.score - resp.previousScore, 2) : '--'
        const name = this.questionnaire.general[key].name[this.user.lang]
        responsabilities.push([
          { text: this.limitChartTableText(name), color: '#6d7a7a', margin: [0, 3, -1, 0], alignment: 'left', border: [true, true, true, true], fontSize: 8 },
          { text: noZero(resp.auto), color: '#6d7a7a', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 8 },
          { text: noZero(resp.evaluators), margin: [5, 5, 5, 0], border: [true, true, true, true], style: { color: '#6d7a7a' }, fontSize: 8 },
          { text: trend, margin: [5, 5, 5, 0], border: [true, true, true, true], style: { color: '#6d7a7a' }, fontSize: 8 }
        ])
      }
      // Color leyend
      let colorLeyend = []
      for (let i = 0; i < 3; i++) {
        colorLeyend.push(
          { width: '2.6%', text: '---', color: this.chartHexColors[i], background: this.chartHexColors[i] }
        )
      }
      return [
        PdfUtil.generateTitleLandscape(`${this.$t('individualReport.chart')} 1 ${this.$t('individualReport.general_responsibility_results_text')}`, 'before'),
        {
          columns: [
            // LEFT COLUMN
            [
              // Gráfica va aqui
              {
                image: this.flowerChart,
                width: 440,
                height: 296,
                margin: [-48, 10, 0, 0]
              },
              {
                columns: [
                  ...colorLeyend,
                  { width: '1%', text: '\u0020' },
                  { width: '91.2%', text: this.$t('individualReport.autoevaluation') }
                ],
                margin: [0, 14, 0, 4]
              },
              {
                columns: [
                  // { width: '17.1%', text: '---', color: '#FFFFFF' },
                  { width: '7.8%', text: '--------', color: '#BFBFBF', background: '#BFBFBF', fontSize: 13, lineHeight: 1 },
                  { width: '1%', text: '\u0020' },
                  { width: '91.2%', text: this.$t('individualReport.others') }
                ]
              }
            ],
            // LEFT COLUMN
            [
              {
                layout: {
                  defaultBorder: '',
                  border: [false, false, false, true],
                  fillColor: function (rowIndex, node, columnIndex) {
                    return (rowIndex === 0) ? '#BFBFBF' : null
                  }
                },
                table: {
                  headerRows: 1,
                  widths: ['68%', '9%', '9%', '14%'],
                  body: [
                    [
                      { text: `${this.$t('individualReport.general_responsability')}`.toUpperCase(), bold: true, color: '#fff', margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 9 },
                      { text: `${this.$t('individualReport.auto')}`.toUpperCase(), bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 5, 0, 0], fontSize: 9 },
                      { text: `${this.$t('individualReport.others')}`.toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                      { text: `${this.$t('individualReport.trend')}`.toUpperCase(), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
                    ],
                    ...responsabilities
                  ]
                },
                margin: [-5, 15, 5, 0],
                border: [false, false, false, true],
                alignment: 'center',
                fontSize: 8
              },
              this.chartTableCharCount < 1100 ? {
                layout: {
                  defaultBorder: '',
                  border: [false, false, false, true]
                },
                table: {
                  headerRows: 1,
                  widths: [366],
                  body: [
                    [
                      { text: `${this.$t('individualReport.notes')}:`, bold: true, margin: [0, 0, 0, 0], border: [false, false, false, true] }
                    ]
                  ]
                },
                margin: [-5, 20, 0, 0],
                border: [false, false, false, true],
                alignment: 'left',
                fontSize: 10
              } : ''
            ]
          ]
        }
      ]
    }
  }
}
