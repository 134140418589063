
<template>
  <v-container fluid>
    <div>
      <v-card
        style="position: fixed; top: 0; z-index: 2; width: 100%;"
        v-if="showFixed"
        elevation="9"
      >
        <v-row justify="center">
          <v-col cols="12" class="pb-0">
            <p class="headline pt-2 px-3">
              {{ $t('evaluations.evaluate') }}: {{ evaluated }} | {{ $t(`evaluations.iam_${evaluatedRole}`) }}
              <v-btn class="mx-2 hidden-sm-and-up" fab dark small color="info" @click="showExample = !showExample">
                <v-icon dark v-if="showExample">mdi-minus</v-icon>
                <v-icon dark v-else>mdi-plus</v-icon>
              </v-btn>
            </p>
          </v-col>
        </v-row>
        <v-row justify="space-around" class="pb-4 px-2 text-center" v-show="showExample || $vuetify.breakpoint.name !== 'xs'">
          <v-col v-for="i in (evaluatedRole !== 'pair' ? 6 : 7)" cols="6" sm="2" lg="1"
            :key="i"
            class="pt-0 pb-1"
          >
            <v-avatar :color="legendColors[i - 1]" size="25" style="margin: 3px 0;">
              <span v-if="i !== 7" class="white--text headline">{{i}}</span>
            </v-avatar>
            <br/>
            <span style="margin: 5px 0 0 5px; font-weight: bold;">{{ $t(`evaluations.score_label${i}`) }}</span>
          </v-col>
        </v-row>
      </v-card>
      <v-card v-if="!completed" style="margin-bottom:10px; overflow-y-auto" id="scrolling-evaluations">
        <!-- Header (evaluado - rol) -->
        <v-row wrap row align="center" class="pl-2 pr-2">
          <v-col cols="12" sm="6">
            <v-card style="border-radius:10px; border-top:10px solid #309DE0;">
              <div class="d-flex flex-no-wrap justify-space-around align-center">
                <p class="headline pt-2">
                  {{ $t('evaluations.product_name') }}
                </p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card style="border-radius:10px; border-top:10px solid #4ACAAE;">
              <div class="d-flex flex-no-wrap justify-space-around align-center">
                <p class="headline pt-2">
                  {{ $t('evaluations.evaluate') }}: {{ evaluated }} | {{ $t(`evaluations.iam_${evaluatedRole}`) }}
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <!-- Explicación de la Encuesta -->
        <v-row justify="center" class="example">
          <v-col cols="11">
            <v-card>
              <p style="padding: 30px; font-weight: bold;">
                {{ $t('evaluations.information') }}
                <v-spacer/>
                <br/>
                {{ $t('evaluations.information_2') }}
              </p>
              <v-row justify="space-around" class="pa-2 text-center">
                <v-col v-for="i in (evaluatedRole !== 'pair' ? 6 : 7)" :key="i" class="pa-2" cols="6" sm="2" lg="1">
                  <v-avatar :color="legendColors[i - 1]" size="36" style="margin: 15px 0;">
                    <span v-if="i === 7" class="white--text headline"></span>
                    <span v-else class="white--text headline">{{i}}</span>
                  </v-avatar>
                  <br/>
                  <span style="margin: 5px 0 0 5px; font-weight: bold;">{{ $t(`evaluations.score_label${i}`) }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <!-- Encuesta -->
        <v-row v-for="(evaluation, i) in generalEvaluation" :key="`${i}-content`" justify="center">
          <v-col cols="11">
            <v-card>
              <v-col class="text-center">
                <p class="mb-0" style="font-size: 25px; font-weigth: bold; color: rgb(94,175,169);">
                  {{ evaluation.name[lang] }}
                </p>
              </v-col>
            </v-card>
          </v-col>
          <v-row justify="center">
            <v-col cols="11" v-for="(item, k) in evaluation.specifics" :key="k">
              <v-card style="border-radius: 5px;">
                <v-row wrap row justify="space-between">
                  <v-col class="ml-3 mt-5">
                    {{ item[lang] }}
                  </v-col>
                  <v-col class="pb-1">
                    <v-radio-group row
                      v-model="radioObject[i][k]"
                      :mandatory="false"
                      @change="setRadioValues(radioObject[i][k], i, k)"
                    >
                      <v-radio style="margin: 0px 20px" label="1" value="1" :color="legendColors[0]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="2" value="2" :color="legendColors[1]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="3" value="3" :color="legendColors[2]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="4" value="4" :color="legendColors[3]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="5" value="5" :color="legendColors[4]"></v-radio>
                      <v-radio style="margin: 0px 20px" label="6" value="6" :color="legendColors[5]"></v-radio>
                      <v-radio
                        v-if="evaluatedRole === 'pair'"
                        :style="{margin: $vuetify.breakpoint.width > 1350 ? '0 0 0 20px' : '0px 20px' }"
                        :label="$t('evaluations.dont_know')"
                        value="-1" :color="legendColors[6]">
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
        <v-row justify="center" class="mt-8 pl-4 pr-4">
          <v-col cols="12" class="text-center">
            <p style="font-size: 25px; font-weigth: bold; color: rgb(94,175,169);">{{ $t('evaluations.open_questions') }}</p>
          </v-col>
          <v-col cols="12" class="py-0" v-for="(n, i) in openQuestions" :key="i">
            <v-row wrap row class="mt-5" justify="center">
              <v-col cols="12" class="py-0 text-center">
                <h4 v-if="evaluatedRole === 'autoevaluation'" style="font-size: 20px;">{{ n.auto[lang] }}</h4>
                <h4 v-else style="font-size: 20px;">{{ n.general[lang] }}</h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-row justify="space-between">
                  <v-col cols="12" sm="4" v-for="(item, j) in n.answer" :key="j">
                    <v-text-field
                      v-model="openQuestions[i].answer[j]"
                      class="pt-4"
                      :maxlength="99"
                      counter
                      @change="answerOpenQuestions"
                      :rules="rules"
                      :label="$t('input.answer')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-row wrap row class="mt-5" justify="center">
        <v-col cols="12" md="6" lg="6" sm="6" class="text-center mt-4">
          <v-btn :disabled="progress !== 100" width="80%" class="mt-4" @click="showConfirmation = true" large color="primary">{{ $t('input.save') }}</v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="6" sm="6" class="text-center mt-4" v-if="reconnectEvaluation">
          <v-btn width="80%" class="mt-4" @click="reconnectSystem()" large color="primary">{{ $t('input.reconnect') }}</v-btn>
        </v-col>
      </v-row>
      <v-col>
        <v-fab-transition style="bottom: 10px;">
          <v-btn
            :color="progress === 100 ? 'green darken-3' : 'red darken-1'"
            dark fixed bottom right fab x-large
          >
            <span>{{ progress.toFixed(0) }}%</span>
          </v-btn>
        </v-fab-transition>
      </v-col>
    </div>

    <v-dialog v-model="successConect" max-width="400px" @click:outside="successConect = false">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>{{$t('evaluations.connect_success')}}</v-card-text>
      </v-card>
    </v-dialog>

    <x-wellcome-dialog
      :evaluation="evaluation"
      :evaluatedRole="evaluatedRole"
      :start-dialog="startDialog"
      :user-excluded="userExcluded"
      @changeLang="($event) => this.lang = $event"
      @close="($event) => this.startDialog = false"
    ></x-wellcome-dialog>
    <x-middle-dialog
      :middle-dialog="middleDialog"
      @close="($event) => this.middleDialog = false"
    ></x-middle-dialog>
    <x-info-dialog :dialog="outIntervalDialog" :icon="dialogIcon" :text="dialogText"></x-info-dialog>
    <x-confirmation-modal
      :show.sync="showConfirmation"
      :title="$t('evaluations.confirmation_modal_title')"
      :action="saveLiderazgo"
      color="success"
      @close="showConfirmation = false"
    >
      <template v-slot:question>{{ $t('evaluations.confirmation_modal_des') }}</template>
    </x-confirmation-modal>
    <v-snackbar
      v-model="save"
      :timeout="1000"
      right
      top
      :color="typeColor"
    >
      {{msjInformation}}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="save = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import evaluationsService from '../../services/evaluations'
// import authService from '../../services/auth'

import XWellcomeDialog from './components/wellcome-dialog.vue'
import XMiddleDialog from './components/middle-dialog.vue'

import DevFillForm from '../../utils/dev-fill-form.js'

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default Vue.extend({
  name: 'Evaluation',
  components: {
    XWellcomeDialog,
    XMiddleDialog
  },
  data () {
    return {
      successConect: false,
      outIntervalDialog: false,
      conectionCallBack: null,
      conectionAttempt: 0,
      timeConectionAttempt: 0,
      conectionTry: false,
      reconnectEvaluation: false,
      intervalConection: undefined,
      dialogIcon: '',
      dialogText: '',
      evaluation: {
        timeZone: '',
        enterprise: {}
      },
      generalEvaluation: [],
      autoEvaluation: [],
      radios: null,
      evaluated: '',
      evaluatedRole: 'autoevaluation', // default to avoid i18n warning
      evaluator: '',
      evaluatorIndex: '',
      answersDimention: [],
      radioObject: {},
      evaluatedIndex: 0,
      rules: [
        value => !!value || this.$i18n.t('input.required_field')
      ],
      lang: 0,
      questionCount: 0,
      openQuestions: [],
      completed: false,
      startDialog: false,
      middleDialog: false,
      displayedMiddleDialog: false,
      progress: 0,
      legendColors: ['#BB3E3E', '#B8663D', '#C2B147', '#B6C144', '#44C156', '#1B5E20', '#BDBDBD'],
      showFixed: false,
      showExample: true,
      showConfirmation: false,
      employeeData: null,
      userExcluded: false,
      save: false,
      typeColor: null,
      msjInformation: null
    }
  },
  watch: {
    progress (val) {
      if (val >= 50 && !this.displayedMiddleDialog) {
        this.middleDialog = this.displayedMiddleDialog = true
      }
    },
    startDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    middleDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    outIntervalDialog (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  created () {
    this.lang = this.user ? (this.user.lang || 'es') : 'es'
    this.getEvaluation()
    window.addEventListener('scroll', this.handleScroll)
    window.fillForm = (options) => { DevFillForm(this, options) }
  },
  methods: {
    handleScroll ($event) {
      const example = document.querySelector('.example')
      if (example) {
        this.showFixed = example.getBoundingClientRect().bottom <= 0 && !this.startDialog && !this.middleDialog
      } else {
        this.showFixed = false
      }
    },
    setProgress () {
      let progress = 0
      let counterAnsweredQuestions = 0
      this.questionCount = 0
      this.generalEvaluation.forEach((general, i) => {
        this.questionCount += general.specifics.length
      })
      for (const type in this.radioObject) {
        counterAnsweredQuestions += this.radioObject[type].filter(q => +q === -1 || (q >= 1 && q <= 6)).length
      }
      for (const open in this.openQuestions) {
        this.questionCount += this.openQuestions[open].answer.length
        counterAnsweredQuestions += this.openQuestions[open].answer.filter(a => typeof a === 'string' && a).length
      }

      this.progress = (counterAnsweredQuestions / this.questionCount) * 100
    },
    getEvaluation () {
      this.$store.dispatch('loading/show')
      return evaluationsService.findByTokenId(this.$route.params.tokenId)
        .then((res) => {
          if (res.executed) {
            if (res.data.status === 'completed') {
              this.$store.dispatch('loading/hide')
              this.showDialog('/img/expiracion.png', this.$i18n.t('evaluations.expiration_date'))
            } else {
              this.evaluation = res.data
              if (this.evaluation.evaluated[0].employee.status === 'excluded' || this.checkIfEvaluatorIsExcluded() === 'excluded') {
                this.userExcluded = true
                this.startDialog = true
                return
              }
              let releasedAtParsed = Date.parse(res.data.deliveredAt.split('Z')[0]) / 1000
              let deadLineAtParsed = Date.parse(res.data.validUntil.split('Z')[0]) / 1000
              if (releasedAtParsed > parseInt(Date.now() / 1000)) {
                this.showDialog('/img/reloj.png', this.$i18n.t('evaluations.before_date'))
              } else if (deadLineAtParsed < parseInt(Date.now() / 1000)) {
                this.showDialog('/img/expiracion.png', this.$i18n.t('evaluations.expiration_date'))
              } else if (res.data.status === 'pending') {
                this.showDialog('/img/reloj.png', this.$i18n.t('evaluations.not_available'))
              } else {
                this.getRelation(this.$route.params.tokenId)
                if (this.completed) {
                  this.showDialog('/img/expiracion.png', this.$i18n.t('evaluations.evaluation_completed'))
                } else {
                  this.startDialog = true
                  if (this.openQuestions === undefined || this.openQuestions.length === 0) {
                    this.openQuestions = this.evaluation.questionnaire.evaluations.openQuestions
                    for (let i = 0; i < this.openQuestions.length; i++) {
                      this.openQuestions[i].answer = ['', '', '']
                    }
                  }
                  this.getTemporal()
                  if (this.progress >= 50) {
                    this.displayedMiddleDialog = true
                  }
                }
              }
            }
          } else {
            this.$store.dispatch('loading/hide')
            this.showDialog('/img/alerta.png', this.$i18n.t('evaluations.invalid_token'))
          }
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          this.$store.dispatch('loading/hide')
        })
    },
    checkIfEvaluatorIsExcluded () {
      const roles = ['leadersEvaluators', 'pairsEvaluators', 'dependentsEvaluators']
      for (let role of roles) {
        if (role in this.evaluation.evaluated[0] && this.evaluation.evaluated[0][role].length) {
          return this.evaluation.evaluated[0][role][0].status
        }
      }
    },
    getRelation (tokenId) {
      const employee = this.evaluation.evaluated.find(evaluated => evaluated.employee.token === tokenId)
      if (employee !== undefined) {
        this.evaluated = employee.employee.employee.text
        this.evaluatedRole = 'autoevaluation'
        this.evaluator = 'autoevaluation'
        this.generalEvaluation = this.evaluation.questionnaire.evaluations.autoEvaluation
        this.answersDimention = employee.employee.answersDimention
        this.openQuestions = employee.employee.openQuestions
        this.completed = employee.employee.completed
        this.employeeData = employee.employee.employee
      } else {
        const getEvaluator = (key, keyTranslate) => {
          let arrIndex = 0
          let evaluatorIndex = 0
          const employee = this.evaluation.evaluated.find((evaluated, index) => {
            arrIndex = index
            return evaluated[key].find((o, i) => {
              evaluatorIndex = i
              return o.token === tokenId
            })
          })
          const resp = employee !== undefined
          if (resp) {
            this.evaluatedIndex = arrIndex
            this.evaluated = employee.employee.employee.text
            this.evaluatedRole = keyTranslate
            this.evaluator = key
            this.generalEvaluation = this.evaluation.questionnaire.evaluations.generalEvaluation
            this.evaluatorIndex = evaluatorIndex
            this.answersDimention = employee[key][evaluatorIndex].answersDimention
            this.openQuestions = employee[key][evaluatorIndex].openQuestions
            this.completed = employee[key][evaluatorIndex].completed
            this.employeeData = employee[key][evaluatorIndex].employee
          }
          return resp
        }
        getEvaluator('leadersEvaluators', 'leader') || getEvaluator('dependentsEvaluators', 'dependent') || getEvaluator('pairsEvaluators', 'pair')
      }
      // this.checkActivity()
      this.generalEvaluation.forEach((general, i) => {
        this.radioObject[i] = []
      })
      this.$store.dispatch('loading/hide')
    },
    // checkActivity () {
    //   if (this.employeeData) {
    //     authService.checkActivity(this.employeeData.id)
    //   }
    // },
    getTemporal () {
      let countGral = 0
      for (const general of this.answersDimention) {
        let countSpecific = 0
        for (const specific of general.specifics) {
          if (specific.score) {
            this.radioObject[countGral][countSpecific] = specific.score.toString()
          }
          countSpecific++
        }
        countGral++
      }
      this.setProgress()
    },
    setRadioValues (value, general, specific) {
      this.answersDimention[general].specifics[specific].score = parseInt(value)
      if (this.evaluatedRole === 'autoevaluation') {
        this.tryConection(this.updateAutoEvaluation)
      } else {
        this.tryConection(this.updateEvaluator)
      }
      this.setProgress()
    },
    showDialog (icon, text) {
      this.outIntervalDialog = true
      this.dialogIcon = icon
      this.dialogText = text
    },
    saveLiderazgo () {
      if (this.progress === 100) {
        return this.tryConection(this.updateEvaluationStatus)
          .then((res) => {
            this.$store.dispatch('loading/hide')
            let weirdErrorResponse = false
            if (res.er && res.er._code && res.er._code === 'empty-answers') {
              this.showDialog('/img/alerta.png', this.$i18n.t('questionnaires.empty_questions'))
              weirdErrorResponse = true
            }
            if (!weirdErrorResponse) {
              this.showDialog('/img/actual-culture-img3.png', this.$i18n.t('evaluations.end'))
            }
          })
          .catch((err) => {
            this.$store.dispatch('loading/hide')
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          })
      } else {
        return Promise.resolve(false)
      }
    },
    answerOpenQuestions () {
      if (this.evaluatedRole === 'autoevaluation') {
        this.tryConection(this.updateAutoOpenQuestion)
      } else {
        this.tryConection(this.updateEvaluatorOpenQuestion)
      }
      this.setProgress()
    },
    async tryConection (callBack) {
      this.save = false
      this.conectionAttempt++
      if (callBack) {
        this.conectionCallBack = callBack
      }
      return this.conectionCallBack().then((res) => {
        this.conectionTry = false
        this.conectionAttempt = 0
        this.timeConectionAttempt = 0
        if (this.reconnectEvaluation) {
          this.reconnectEvaluation = false
          this.successConect = true
        }
        // this.typeColor = 'success'
        // this.msjInformation = this.$t('evaluations.save_success')
        // this.save = true
        return res
      }).catch(async () => {
        this.typeColor = 'error'
        this.msjInformation = this.$t('evaluations.save_error')
        this.save = true
        this.reconnectEvaluation = true
        this.conectionTry = this.conectionAttempt > 3
        if (this.conectionAttempt < 3) {
          const time = this.timeConectionAttempt = this.conectionAttempt <= 3 ? 2 : (this.conectionAttempt <= 6 ? 5 : 10)
          this.intervalConection = setInterval(() => {
            this.timeConectionAttempt--
            if (this.timeConectionAttempt <= 0) {
              clearInterval(this.intervalConection)
            }
          }, 1000)
          await sleep(time * 1000)
          const res = await this.tryConection()
          return res
        }
        throw new Error('No hay conexión a la red')
      })
    },
    updateAutoEvaluation () {
      return evaluationsService.updateAutoEvaluation(this.$route.params.tokenId, this.answersDimention)
    },
    async updateEvaluator () {
      return evaluationsService.updateEvaluator(this.$route.params.tokenId, this.answersDimention, this.evaluator, this.evaluatorIndex, this.evaluatedIndex)
    },
    updateAutoOpenQuestion () {
      return evaluationsService.updateAutoOpenQuestion(this.$route.params.tokenId, this.openQuestions)
    },
    updateEvaluatorOpenQuestion () {
      return evaluationsService.updateEvaluatorOpenQuestion(this.$route.params.tokenId, this.openQuestions, this.evaluator, this.evaluatorIndex, this.evaluatedIndex)
    },
    reconnectSystem () {
      let arrAux = [this.updateAutoEvaluation, this.updateEvaluator, this.updateAutoOpenQuestion, this.updateEvaluatorOpenQuestion]
      arrAux.forEach((item) => {
        this.tryConection(item)
      })
    },
    updateEvaluationStatus () {
      return evaluationsService.updateEvaluationStatus(this.$route.params.tokenId, this.evaluator, this.evaluatorIndex, this.evaluatedIndex)
    },
    retryConection () {
      if (this.intervalConection) {
        clearInterval(this.intervalConection)
      }
      this.conectionAttempt = 0
      this.tryConection()
    }
  }
})
</script>
