
export default {
  cover_table_header_1: 'EXPECTED',
  cover_table_header_2: 'RECEIVED',
  cover_table_header_3: 'RESPONSE RATE',
  cover_table_row_1: 'SELF-ASSESSMENTS',
  cover_table_row_2: 'LEADER(S)',
  cover_table_row_3: 'PEERS',
  cover_table_row_4: 'COLLABORATORS',
  cover_table_row_5: 'TOTAL',
  index_title: 'Report content',
  title: 'Results report',
  subtitle: 'Organizational Leadership Report',
  poll_answers: 'Assessment responses',
  autoevaluations: 'self-assessments',
  leaders: 'Leader(s)',
  pairs: 'Peers',
  dependents: 'Collaborators',
  total: 'Total',
  received_evaluations: 'Assessments Received',
  received: 'Received',
  requested_evaluations: 'Assessments Requested',
  requested: 'Expected',
  answers_rate: 'Response Rate',
  legend_description: 'In the bars of the following table, the gray color, the rhombus and the cross represent',
  dispersion_description: 'The dispersion is calculated as the standard deviation of the data',
  table_description: 'In the bars of the tables, the gray color, the cross and the rhombus represent',
  higher_scores: 'Highest scores',
  lower_scores: 'Lowest scores',
  general_responsability: 'Responsibility',
  specific_responsability: 'Behaviour',
  score: 'Score',
  previous_score: 'Previous Score',
  previous_score_abbr: 'Prev. Score',
  current_score: 'Current Score',
  cut_score: 'Cut Score',
  previous: 'Previous',
  current: 'Current',
  ten_percent: '10% higher',
  dispersion: 'Dispersion',
  trend: 'Trend',
  higher_trends: 'Positive Higher Trends',
  lower_trends: 'Negative Higher Trends',
  trend_abbr: 'Trend.',
  data_range: 'Data range (max-min)',
  higher_bold: 'Highest ratings in bold',
  lower_sub: 'Lowest ratings underlined',
  management: 'Management',
  mobilization: 'Mobilization',
  achievement: 'Achievement',
  as_person: 'As a person',
  leader_as_person: 'Leader as a person',
  results: 'Results',
  copyright: 'OCC - All rights reserved.',
  higher_dispersion: 'Greater dispersion',
  lower_dispersion: 'Less dispersion',
  higher_gap: 'Positive higher gaps',
  lower_gap: 'Negative higher gaps',
  gap: 'Gap',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  lead_by_example: 'Lead the culture by example',
  ensures_learning: 'Ensures continuous learning and overall development',
  confirm_report_title: 'Confirm report generation',
  report_cost: 'Report cost',
  balance_after: 'Balance after generating the report',
  confirm_btn: 'Confirm and Generate',
  opetaion_init: 'A download process "Organizational Report" has started, this process may take several minutes, you can see its progress by refreshing the page',

  background_1: 'CONTENTS',
  background_2: 'RESULTS',
  background_3: 'DISPERSION',
  background_4: 'TREND',

  scores_title_1: '1. Responsibilities with Highest and Lowest Score according to Relationship',
  scores_title_1_sub_1: 'Table 1. Three highest and lowest scores according to Others; where Others is the average of all relationships (Leaders, Peers and Collaborators)',
  scores_title_1_sub_2: 'Table 2. Three Highest and Lowest Scores according to Leader(s)',
  scores_title_1_sub_3: 'Table 3. Three Highest and Lowest Scores according to Peers',
  scores_title_1_sub_4: 'Table 4. Three highest and lowest scores according to Collaborators',
  scores_title_2: '2. Behaviors with a Higher and Lower Score according to Relationship',
  scores_title_2_sub_5: 'Table 5. Five highest and lowest scores according to Others; where Others is the average of all work relationships (Leaders, Peers and Collaborators)',
  scores_title_2_sub_6: 'Table 6. Five Highest and Lowest Scores according to Leader(s)',
  scores_title_2_sub_7: 'Table 7. Five Highest and Lowest Scores by Peers',
  scores_title_2_sub_8: 'Table 8. Five highest and lowest scores according to Collaborators',
  scores_table_1_header_1: 'HIGHEST SCORES',
  scores_table_1_header_2: 'LOWEST SCORES',
  scores_table_1_sub_header_1: 'Responsibility',
  scores_table_1_sub_header_2: 'Behavior',
  scores_table_1_sub_header_3: 'Score',
  scores_table_1_sub_header_4: 'Previous Score',
  scores_table_1_sub_header_5: 'Trend',
  scores_table_2_header_1: 'HIGHEST SCORES',
  scores_table_2_header_2: 'LOWEST SCORES',
  scores_table_2_sub_header_1: 'D',
  scores_table_2_sub_header_2: 'COMPETENCE',
  scores_table_2_sub_header_3: 'BEHAVIOR',
  scores_table_2_sub_header_4: 'SCORE',
  scores_table_2_sub_header_5: 'TREND',

  results_title_1: '3. Results by Responsibility',
  results_title_1_sub_1: 'Table 9. Results by Responsibility',
  results_legend_1_table_1: 'Data range (min-max)',
  results_legend_1_table_2: 'Score',
  results_legend_1_table_3: 'Highest ratings in bold',
  results_legend_1_table_4: 'Lowest ratings in underlines',
  results_title_2: '4. Results by Behavior',
  results_title_2_sub_2: 'Table {0}. Results by Responsibility and Behavior {1}',

  scatter_title: '5. Analysis of Dispersion by Responsibility and Behavior',
  scatter_info: 'The dispersion is calculated as the standard deviation of the data',
  scatter_table_1_title: 'Table {0}. Dispersion by Responsibility',
  scatter_table_1__header_1: 'GREATER DISPERSION',
  scatter_table_1__header_2: 'LESS DISPERSION',
  scatter_table_1__sub_header_2: 'Dispersion',
  scatter_table_2_title: 'Table {0}. Dispersion by Behavior',

  trend_title: '6. Trend Analysis',
  trend_msg: 'Available from the second assessment',
  trend_table_1_title: 'Table {0}. Trend by Behavior',
  trend_table_1__header_1: 'POSITIVE TRENDS',
  trend_table_1__header_2: 'NEGATIVE TRENDS',
  trend_table_2_header_5: 'Trend',

  gap_title: '7. Gap Analysis',
  gap_msg: 'Available from the second assessment',
  gap_table_1_title: 'Table {0}. Gaps by Behavior',
  gap_table_1__header_1: 'HIGHER POSITIVE GAPS',
  gap_table_1__header_2: 'HIGHER NEGATIVE GAPS',
  gap_table_1_sub_header_3: 'Cut Score',
  gap_table_1_sub_header_4: 'Total score',
  gap_table_2_header_5: 'Gap'
}
