
<template>
  <v-container fluid grid-list-xl>
    <v-row>
      <v-col xs12>
        <h4 class="display-1"> {{$t('dashboard.general_title')}} </h4>
      </v-col>
    </v-row>
    <dashboard-employee :info="info" v-if="user.role === 'employee'" />
    <dashboard-enterprise :info="info" v-if="['customer', 'enterprise_admin'].includes(user.role)" />
    <dashboard-admin :info="info" v-if="user.role === 'admin'" />
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import dashboardService from '../services/dashboard'
import DashboardEmployee from './dashboard/dashboard-employee.vue'
import DashboardEnterprise from './dashboard/dashboard-enterprise.vue'
import DashboardAdmin from './dashboard/dashboard-admin.vue'

export default {
  name: 'Dashboard',
  components: {
    DashboardEmployee,
    DashboardEnterprise,
    DashboardAdmin
  },
  data () {
    return {
      info: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    })
  },
  created () {
    this.$store.dispatch('loading/show')
    if (this.user.role === 'employee') {
      this.getEmployeeInfo()
    } else if (['customer', 'enterprise_admin'].includes(this.user.role)) {
      this.getEnterpriseInfo()
    } else {
      this.getAdminInfo()
    }
  },
  methods: {
    getEmployeeInfo () {
      let aux
      let init
      return dashboardService.getEmployeeInfo(this.user.employeeId)
        .then((res) => {
          this.info = res
          this.$store.dispatch('loading/hide')
        })
    },
    getEnterpriseInfo () {
      let aux
      let init
      return dashboardService.getEnterpriseInfo()
        .then((res) => {
          init = res.length - 10
          aux = res.slice(init, res.length)
          this.info = aux
          this.$store.dispatch('loading/hide')
        })
    },
    getAdminInfo () {
      let aux
      let init
      return dashboardService.getInfo()
        .then((res) => {
          init = res.length - 10
          aux = res.slice(init, res.length)
          this.info = aux
          this.$store.dispatch('loading/hide')
        })
    }
  }
}
</script>

<style lang="css">
.no-polls-msg {
  font-size: 1.8em;
  margin-left: 0.6em;
  color: gray;
}
#text-no-chart {
  margin-bottom: 10em;
}
</style>
