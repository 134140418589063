<template>
  <v-container>
    <template>
      <v-row>
        <v-col>
          <h4 class="display-1">{{ $t('conceptualFrameworks.edit_conceptual_framework') }}</h4>
        </v-col>
      </v-row>
      <v-card>
        <v-expansion-panels accordion>
          <v-expansion-panel
            v-for="(item, index) in conceptual_framework.detail"
            :key="index"
            @click="itemOnFocus = item"
          >
            <v-form ref="form" v-model="valid">
              <v-expansion-panel-header class="mb-n4">
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-text-field
                        v-model="item.title[user.lang]"
                        @input="changeTitle(index, item.title, $event)"
                        solo
                        outlined
                        color="green"
                        flat
                        maxLength="120"
                        dense
                        :rules="[rules.required]"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters >
                    <v-col cols="12" class="inline-block primary--text">
                      <v-row class="textInfo" no-gutters>
                        <v-col cols="10">
                          <v-icon color="info">info</v-icon>
                          {{ $t('conceptualFrameworks.max_length_for_this_topic')+' '+item['max-length'] }}
                        </v-col>
                        <v-col cols="2" :class="item.valid ? 'green--text' : 'orange--text'" class="text-right">
                          {{ maxLengthValidation(item)+' / '+item['max-length'] }}.
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat>
                  <v-row  class="flex justify-center" v-for="(text, bIndex) in item.body[user.lang]" :key="bIndex" no-gutters>
                    <v-col cols="auto">
                      <v-btn class="mr-1" icon x-small text color="red" dark @click="removeTextArea(item,bIndex)">
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="11">
                      <v-textarea
                        counter
                        :value="text"
                        @input="changeBody(item, bIndex, $event)"
                        :color="item.valid ? 'green' : 'orange'"
                        :rules="[rules.required]"
                        outlined
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row class="flex justify-center" no-gutters>
                    <v-col cols="1">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn x-small fab outlined color="blue" v-on="on" @click="addTextArea(index)">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('conceptualFrameworks.add_new_text_area') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
              </v-expansion-panel-content>
            </v-form>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn fixed dark fab right
          color="green"
          class="btn btn-save"
          @click="sendForm"
        >
          <v-icon>mdi-content-save-edit</v-icon>
        </v-btn>
        <!--
        <v-btn fixed dark fab
          color="red"
          class="btn btn-cancel"
          to="/conceptual_frameworks"
        >
          <v-icon>mdi-file-pdf</v-icon>
        </v-btn>
        -->
        <v-card-actions class="pt-6 pb-3">
          <v-btn
            color="primary"
            class="mx-auto"
            @click="generatePDF"
          >
            {{ $t('input.preview') }}
          </v-btn>
          <v-btn
            text
            class="mx-auto px-4"
            to="/conceptual_frameworks"
          >
            {{ $t('input.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <img
        src="../../../public/img/20210423_x_occ_l_logo.png"
        class="d-none"
        id="occLogo1"
        width="0"
        height="0"
      />
    </template>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'
import is from 'is_js'

import conceptualFrameworksService from '../../services/conceptual-frameworks'
import conceptualFrameworkAssemble from '../reports/thread_mixins_individual/03-conceptual-framework'

const maxCharsPerLine = 165

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default Vue.extend({
  name: 'conceptual-frameworks-edit',
  mixins: [
    conceptualFrameworkAssemble
  ],
  data () {
    return {
      list: 1,
      conceptual_framework: {},
      maxLength: 0,
      itemOnFocus: null,
      valid: true,
      rules: {
        required: v => !!v || 'Campo requerido'
      },
      occLogo: null
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.conceptualFrameworkFn()
  },
  mounted () {
    this.getLogo()
  },
  watch: {
    occLogo (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(newVal, (dataURL) => {
          this.pulseLogoBase64 = dataURL
        })
      }
    }
  },
  methods: {
    getLogo () {
      this.toDataURL(document.getElementById('occLogo1').src, (dataURL) => {
        this.occLogo = dataURL
      })
    },
    changeTitle (index, title, value) {
      title[this.user.lang] = value
      Vue.set(this.conceptual_framework.detail[index], 'title', title)
    },
    changeBody (item, bIndex, value) {
      Vue.set(item.body[this.user.lang], bIndex, value)
      this.maxLengthValidation(item)
      this.maxLengthWarningMsg(item.valid)
    },
    conceptualFrameworkFn () {
      this.$store.dispatch('loading/show')
      return conceptualFrameworksService.getOne(this.$route.params.slug)
        .then((res) => {
          res.detail.map(i => {
            i.valid = true
            i.charsCount = 0
            return i
          })
          this.conceptual_framework = res
          this.$store.dispatch('loading/hide')
        }).catch(() => {
          this.$store.dispatch('loading/hide')
        })
    },
    sendForm () {
      if (!this.valid) {
        this.$store.dispatch(`alert/error`, this.$t(`errors.validator/verify_fields`))
        return
      }
      // if (!this.globalValidation()) {
      //   this.notify('error', 'max_length_validation_error')
      //   return
      // }
      this.$store.dispatch('loading/show')
      return conceptualFrameworksService.edit(this.conceptual_framework.slug, this.conceptual_framework)
        .then(() => {
          this.notify('success', 'edited_succesfully')
        }).catch(() => {
          this.notify('error', 'error_editing')
        }).finally(() => this.$store.dispatch('loading/hide'))
    },
    addTextArea () {
      // this.maxLengthValidation()
      if (!this.itemOnFocus.body[this.user.lang].filter(text => text === '' || text === undefined).length) {
        this.itemOnFocus.body[this.user.lang].push('')
      }
    },
    removeTextArea (item, bIndex) {
      item.body[this.user.lang].splice(bIndex, 1)
      this.maxLengthValidation(item)
    },
    notify (type, msg) {
      this.$store.dispatch(`alert/${type}`, this.$t(`conceptualFrameworks.${msg}`))
    },
    globalValidation () {
      let validLength = true
      this.conceptual_framework.detail.forEach((v, k) => {
        if (!v.valid) {
          validLength = false
        }
        this.$refs['form'][k].validate()
      })
      return validLength
    },
    maxLengthValidation (item) {
      item.charsCount = this.characterCount(item) + this.whiteLinesCount(item)
      item.valid = item['max-length'] >= item.charsCount
      return item.charsCount
    },
    characterCount (item) {
      let bodyLength = 0
      for (let p of item.body[this.user.lang]) {
        bodyLength += p.length // + (maxCharsPerLine - (p.length % maxCharsPerLine))
      }
      return bodyLength
    },
    whiteLinesCount (item) {
      return (item.body[this.user.lang].length - 1) * maxCharsPerLine
    },
    maxLengthWarningMsg (maxExceeded) {
      if (!maxExceeded) {
        this.notify('warning', 'max_length_warning')
      }
    },
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    writeRotatedText (text) {
      const canvas = document.createElement('canvas')
      canvas.width = 40
      canvas.height = 842

      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = '#BFBFBF'
      ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(40, 690)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '17pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    generatePDF () {
      this.$store.dispatch('loading/show')
      const configuration = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 50, 50],
        info: {
          title: this.$t('help.evaluation.create.conceptualFramework.title'),
          author: 'OCC',
          subject: this.$t('help.evaluation.create.conceptualFramework.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: () => {
          return [{
            image: this.occLogo,
            height: 45,
            width: 117,
            margin: [15, 4, 25, 15]
          }]
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  margin: [0, 10, 0, 0],
                  width: 'auto',
                  text: this.$t('individualReport.copyright'),
                  fontSize: 10
                },
                { width: '*', text: '' }
              ]
            }
          ]
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(this.$t('navigation.conceptual_framework')),
            aligment: 'center',
            absolutePosition: { x: 810, y: 0 }
          }

          return result
        },
        content: [
          this.$generateConceptualFramework(this.conceptual_framework.detail, false)
        ]
      }

      let fileName = this.$t('help.evaluation.create.conceptualFramework.title')
      if (is.edge() || is.ie()) {
        fileName += '.pdf'
        setTimeout(() => {
          const pdfDocGenerator = pdfMake.createPdf(configuration)
          pdfDocGenerator.getBlob((blob) => {
            window.navigator.msSaveBlob(blob, fileName)
            this.$store.dispatch('loading/hide')
          })
        }, 400)
      } else {
        setTimeout(() => {
          pdfmake.createPdf(configuration).download(fileName)
          this.$store.dispatch('loading/hide')
        }, 400)
      }
    }
  }
})
</script>

<style scoped>
  .btn {
    opacity: 0.9;
  }
  .btn-cancel{
    bottom: 1.9rem;
    right: 5rem;
  }
  .btn-save{
    bottom: 1.9rem;
  }
  .v-expansion-panel-header div {
    font-size: 0.80rem
  }
</style>
