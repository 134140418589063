
<template>
  <div style="display:inline;">
    <v-btn large
      color="primary"
      :loading="lockPdfButton"
      :disabled="lockPdfButton || !disableButton"
      @click="getPreviousAssessments"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ $t('reports.general_report') }}
    </v-btn>

    <!-- Prev. Poll selection modal -->
    <v-dialog persistent
      v-model="modalPreviousAssessment"
      max-width="560px"
    >
      <v-card>
        <v-card-text class="text-center grey--text text--darken-1 pt-6">
          <h2 class="mb-5 title">
            {{ $t('evaluations.prev_assesment_dialog_title') }}.
          </h2>
          <p class="mb-7" v-html="$t('evaluations.prev_assesment_dialog_description')"></p>
          <x-inputs-autocomplete light clearable
            :items="previousAssessments"
            :input="selectedPreviousAssessment"
            :label="$t('evaluations.previous_assessment')"
            name="prev_poll"
            @updateInput="($event) => selectedPreviousAssessment = $event"
          />
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0">
            <v-col cols="12" sm="6" order-sm="1" order="2">
              <v-btn block large
                :disabled="selectedPreviousAssessment"
                @click="openReportOneById()"
              >
                {{ $t('input.skip') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" order-sm="2" order="1">
              <v-btn block large
                class="white--text"
                color="primary"
                :disabled="!selectedPreviousAssessment"
                @click="openReportOneById()"
              >
                {{ $t('input.continue') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Spend modal -->
    <x-confirm-spend-dialog
      :confirmText="$t('organizationalReport.confirm_report_title')"
      :costText="$t('organizationalReport.report_cost')"
      :showModalConfirm="showModalConfirm"
      :balance="balance"
      :price="price"
      :noBalanceResponse="noBalanceResponse"
      :disableButtonModal="disableButton"
      @result="verifySpend"
      @update="checkBalance">
    </x-confirm-spend-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

import evaluationService from '../../services/evaluations'

export default Vue.extend({
  name: 'thread-organizational-report',
  components: {
    //
  },
  props: {
    pollId: String,
    disableButton: Boolean
  },
  data () {
    return {
      modalPreviousAssessment: false,
      previousAssessments: [],
      selectedPreviousAssessment: null,
      lockPdfButton: false,
      showModalConfirm: false,
      disableButtonModal: true,
      noBalanceResponse: false,
      operationsLink: '',
      balance: 0,
      price: 0
    }
  },
  watch: {
    showModalConfirm (val) {
      if (val) {
        this.modalPreviousAssessment = false
      } else {
        this.selectedPreviousAssessment = null
      }
    },
    disableButton () {
      this.disableButtonModal = this.disableButton
    }
  },
  methods: {
    openReportOneById () {
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      return this.checkBalance()
    },
    runOpenReport () {
      this.$store.dispatch('loading/show')
      return evaluationService.getOrganizationalReport(this.pollId, this.selectedPreviousAssessment)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('organizationalReport.opetaion_init'))
          this.$store.dispatch('loading/hide')
          this.showModalConfirm = false
          setTimeout(this.refreshPage, 3000)
        })
        .catch((err) => {
          if (err.code === 'suite-fail/evaluation/spend-fail') {
            this.noBalanceResponse = true
            this.disableButtonModal = true
            this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          this.$store.dispatch('loading/hide')
          this.lockPdfButton = false
        })
    },
    checkBalance () {
      return evaluationService.getBalance('organizational')
        .then((res) => {
          this.balance = res.balance
          this.price = res.productService
          this.showModalConfirm = true
          this.$store.dispatch('loading/hide')
        })
    },
    verifySpend ($event) {
      if ($event === 1) {
        this.runOpenReport()
      } else {
        this.lockPdfButton = false
      }
      this.showModalConfirm = false
    },
    getPreviousAssessments () {
      if (this.previousAssessments.length > 0) {
        this.modalPreviousAssessment = true
      } else {
        return evaluationService.getAssessmentsPreviousToCurrent(this.pollId)
          .then((res) => {
            if (res.length) {
              this.previousAssessments = res.map(ev => {
                return {
                  text: ev.name,
                  value: ev._id
                }
              })
              this.modalPreviousAssessment = true
            } else {
              this.checkBalance()
            }
          })
          .catch((err) => {
            console.log(err)
            this.showModalConfirm = true
          })
      }
    },
    refreshPage () {
      window.location.reload()
    }
  }
})
</script>
