
export default {
  methods: {
    $generateTableOfContents () {
      return [
        {
          table: {
            widths: [479],
            body: [
              [{ text: `${this.$t('individualReport.index_title')}`.toUpperCase(), bold: true, color: '#fff', margin: [50, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [-40, 0, 0, 20]
        },
        // TABLE OF CONTENTS
        {
          toc: {
            id: 'mainToc',
            toc: {
              id: 'subToc'
            }
          },
          color: '#6d7a7a',
          fontSize: 13,
          margin: [15, 0, 65, 0],
          maxHeight: 0,
          lineHeight: 0.7
        }
      ]
    }
  }
}
