
import PdfUtil from '../utils/pdf'
import pdfUtil from '../utils_old/pdf'

export default {
  data () {
    return {
      maxGralRespChars: 332
    }
  },
  methods: {
    limitGralText (str) {
      return str.length > this.maxGralRespChars ? str.slice(0, this.maxGralRespChars) + '...' : str
    },
    generateResultsTableWithHeader (widths, data, marginTop, isGeneral, pgBrk = '') {
      const responsibilityTitle = isGeneral ? this.gralResponsibilityTitle : this.specResponsibilityTitle
      return [
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: widths,
            body: [
              [
                { text: responsibilityTitle, bold: true, color: '#fff', margin: [0, 8, 0, 0], border: [true, true, true, true], fontSize: 9 },
                { text: this.$t('individualReport.evaluator'), bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 8, 0, 0], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, 0, 15, -2] },
                      { text: '2\n|', margin: [0, 0, 15, -2] },
                      { text: '3\n|', margin: [0, 0, 15, -2] },
                      { text: '4\n|', margin: [0, 0, 15, -2] },
                      { text: '5\n|', margin: [0, 0, 15, -2] },
                      { text: '6\n|', margin: [0, 0, 15, -2] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true]
                },
                { text: this.$t('individualReport.score'), margin: [0, 8, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [-3, 2, -3, -5], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-3, 8, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...data
            ]
          },
          margin: [-8, marginTop, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9,
          pageBreak: pgBrk
        }
      ]
    },
    $generateResponsibilityResults () {
      const resultTables = []
      const firstFiveResponsibilities = []
      const middleFiveResponsibilities = []
      const finalFiveResponsibilities = []
      const legend = [
        this.$t('individualReport.no_answer'),
        this.$t('individualReport.data_range'),
        this.$t('individualReport.score'),
        // this.$t('individualReport.ten_percent'),
        '',
        this.$t('individualReport.leaders_average'),
        this.$t('individualReport.higher_bold'),
        this.$t('individualReport.lower_sub')
      ]
      const evaluator = [
        this.$t('individualReport.me'),
        this.$t('individualReport.leaders'),
        this.$t('individualReport.pairs'),
        this.$t('individualReport.dependents'),
        this.$t('individualReport.others')
      ]

      const getItemByIdx = (responsability, idx) => {
        switch (idx) {
          case 0: return {
            score: responsability.auto,
            previous: responsability.previousAuto,
            tenUp: responsability.tenUpAuto,
            range: responsability.rangeAuto
          }
          case 1: return responsability.relations.leadersEvaluators
          case 2: return responsability.relations.pairsEvaluators
          case 3: return responsability.relations.dependentsEvaluators
          case 4: return {
            score: responsability.evaluators,
            previous: responsability.previousEvaluators,
            tenUp: responsability.tenUpEvaluators,
            range: responsability.rangeEvaluators
          }
        }
        return responsability
      }
      const textColor = '#6d7a7a'
      const responsabilitiesKeys = Object.keys(this.answersResponsibility.general)
      let cnt = 0
      for (const key of responsabilitiesKeys) {
        const responsability = this.answersResponsibility.general[key].filtered
        const responsabilityName = this.baseQuestionnaire.evaluations.generalEvaluation[cnt].description
          ? this.baseQuestionnaire.evaluations.generalEvaluation[cnt].description[this.user.lang]
          : this.questionnaire.general[key].name[this.user.lang]
        const allScoreData = [
          getItemByIdx(responsability, 0).score,
          getItemByIdx(responsability, 1).score,
          getItemByIdx(responsability, 2).score,
          getItemByIdx(responsability, 3).score,
          getItemByIdx(responsability, 4).score
        ].filter(score => score)
        // Calcula simbolo a mostrar (+) o (-)
        const symbol = this.getSymbol(responsability.auto, responsability.evaluators)
        for (let i = 0; i < 5; i++) {
          const data = getItemByIdx(responsability, i)
          const dataLeaders = getItemByIdx(this.answersResponsibility.general[key].score, i)
          // Calcula porcentaje de respuestas "No sabe" para los pares
          // const unknownPercent = i >= 1 || i <= 3 ? this.getUnknownPercent(data) : ''

          const isValid = !isNaN(data.score) && data.score >= 1
          const hasValidTrend = this.hasPrevious && isValid && !isNaN(data.previous) && data.previous >= 1

          const temp = [
            { text: this.limitGralText(responsabilityName) + symbol, rowSpan: 5, color: textColor, margin: [0, 2, 0, 0], border: [true, true, true, true] },
            { text: evaluator[i], bold: true, color: textColor, margin: [-3, 0, -3, -4], border: [true, true, true, true], fontSize: 8.5 },
            isValid ? pdfUtil.generateXDCChartT3(data.range, data.score, i, this.round(dataLeaders.score, 2), dataLeaders.tenUp) : { text: '--', margin: [0, 0, 0, -2.5], border: [true, true, true, true] },
            pdfUtil.higherLowerRow(allScoreData, isValid ? data.score : '--', textColor, [0, 0.5, 0, -4], 'center', [true, true, true, true]),
            { text: isValid ? this.round(dataLeaders.score, 2) : '--', bold: false, color: textColor, margin: [0, 0.5, 0, -4], border: [true, true, true, true], alignment: 'center' },
            { text: `${hasValidTrend ? this.round(data.score - data.previous, 2, '--') : '--'}`, bold: false, color: textColor, margin: [0, 0.5, 0, -4], border: [true, true, true, true], alignment: 'center' }
          ]

          if (firstFiveResponsibilities.length < 25) {
            firstFiveResponsibilities.push(temp)
          } else {
            if (middleFiveResponsibilities.length < 25) {
              middleFiveResponsibilities.push(temp)
            } else {
              finalFiveResponsibilities.push(temp)
            }
          }
        }
        cnt++
      }

      // ASSEMBLE RESULTS TABLE
      resultTables.push(
        this.generateResultsTableWithHeader(this.tableWidths.landscape, firstFiveResponsibilities, -10, true)
      )
      // If more than three (5) General Responsibilities
      if (middleFiveResponsibilities.length > 0) {
        resultTables.push(
          this.generateResultsTableWithHeader(this.tableWidths.landscape, middleFiveResponsibilities, -2, true, 'before')
        )
      }
      // If more than seven (10) General Responsibilities
      if (finalFiveResponsibilities.length > 0) {
        resultTables.push(
          this.generateResultsTableWithHeader(this.tableWidths.landscape, finalFiveResponsibilities, -2, true, 'before')
        )
      }

      return [
        PdfUtil.generateTitleLandscape(this.$t('individualReport.responsability_results_title'), 'before', true, true),
        pdfUtil.generateLiderazgoLegendIndividual(legend, true),
        ...resultTables
      ]
    }
  }
}
