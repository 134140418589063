
import PDFUtil from '../utils/pdf'

export default {
  data () {
    return {
      maxSpeclRespChars: 166,
      tableNum: 10,
      chartHexColors: [
        '#eb604d',
        '#51c7af',
        '#1999da',
        '#da5946',
        '#0b8778',
        '#0070c0',
        '#ff7c80',
        '#13ddc5',
        '#0996ff',
        '#f2a180',
        '#eb604d',
        '#51c7af',
        '#1999da',
        '#da5946',
        '#0b8778'
      ],
      resultsTables: []
    }
  },
  methods: {
    truncateSpec (str) {
      return str.length > this.maxSpeclRespChars ? str.slice(0, this.maxSpeclRespChars) + '...' : str
    },
    getSpecificName (general, specific) {
      const resp = this.questionnaire.general
      return {
        text: this.truncateSpec(resp[general].specific[specific][this.user.lang]),
        fontSize: 9,
        lineHeight: 0.8,
        margin: [0, 0, 0, 2],
        border: [true, true, true, true],
        style: { color: '#6d7a7a' }
      }
    },
    generalResponsibilityTable (general, data) {
      const table = {
        widths: ['54.5%', '26%', '6%', '6.5%', '7%'],
        headerRows: 1,
        body: [
          [
            this.getResultsHeader(this.gralResponsibilityTitle),
            { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
              table: {
                headerRows: 1,
                body: [[
                  { text: '1\n|', margin: [10, 2, 15, 0] },
                  { text: '2\n|', margin: [0, 2, 15, 0] },
                  { text: '3\n|', margin: [0, 2, 15, 0] },
                  { text: '4\n|', margin: [0, 2, 15, 0] },
                  { text: '5\n|', margin: [0, 2, 15, 0] },
                  { text: '6\n|', margin: [0, 2, 15, 0] }
                ]]
              },
              bold: true,
              style: { color: '#fff' },
              fontSize: 9,
              border: [true, true, true, true]
            },
            this.getResultsHeader(this.$t(`organizationalReport.scores_table_1_sub_header_3`)),
            this.getResultsHeader(this.$t(`organizationalReport.scores_table_1_sub_header_4`), 5),
            this.getResultsHeader(this.$t(`organizationalReport.scores_table_1_sub_header_5`))
          ]
        ]
      }

      // Row
      const trend = data.score - data.previousScore
      table.body.push([
        this.getGralName(general),
        PDFUtil.generateGeneralXDCChartT3(data.range, data.score),
        this.getScore(data.score),
        this.getPrevScore(data.previousScore),
        this.getTrend(trend)
      ])

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex < 1) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, 2, 5, 14],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    specificsResultsTable (responsibility, specifics) {
      const table = {
        widths: ['54.5%', '26%', '6%', '6.5%', '7%'],
        headerRows: 1,
        dontBreakRows: true,
        body: [
          [
            this.getResultsHeader(this.specResponsibilityTitle),
            {
              layout: { defaultBorder: '', fillColor: '#BFBFBF' },
              table: {
                headerRows: 1,
                body: [[
                  { text: '1\n|', margin: [10, 2, 15, 0] },
                  { text: '2\n|', margin: [0, 2, 15, 0] },
                  { text: '3\n|', margin: [0, 2, 15, 0] },
                  { text: '4\n|', margin: [0, 2, 15, 0] },
                  { text: '5\n|', margin: [0, 2, 15, 0] },
                  { text: '6\n|', margin: [0, 2, 15, 0] }
                ]]
              },
              bold: true,
              style: { color: '#fff' },
              fontSize: 9,
              border: [true, true, true, true]
            },
            this.getResultsHeader(this.$t(`organizationalReport.scores_table_1_sub_header_3`)),
            this.getResultsHeader(this.$t(`organizationalReport.scores_table_1_sub_header_4`), 5),
            this.getResultsHeader(this.$t(`organizationalReport.scores_table_1_sub_header_5`))
          ]
        ]
      }

      // Get All Scores
      const allScores = []
      for (const key of Object.keys(specifics)) {
        allScores.push(specifics[key].score.score)
      }
      allScores.sort((a, b) => b - a)

      // Rows
      for (const specific of Object.keys(specifics)) {
        const data = specifics[specific].score
        const trend = data.score - data.previousScore
        table.body.push([
          this.getSpecificName(responsibility, specific),
          PDFUtil.generateGeneralXDCChartT3(data.range, data.score, true),
          this.higherLowerScore(allScores, data.score),
          this.getPrevScore(data.previousScore),
          this.getTrend(trend)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex < 1) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, 2, 5, 0],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    loopResults () {
      const results = this.answersResponsibility.general
      const initTableNum = this.tableNum
      for (const general of Object.keys(results)) {
        const idx = this.tableNum - initTableNum
        const gralData = results[general].score
        const gralName = this.truncateGeneral(this.questionnaire.general[general].name[this.user.lang])
        this.resultsTables.push(
          {
            table: {
              widths: [725],
              body: [
                [{ text: [
                  `${this.$t('organizationalReport.results_title_2_sub_2', [this.tableNum])}`,
                  { text: ` ${gralName}`, color: this.chartHexColors[idx] }
                ],
                bold: true,
                color: '#fff',
                margin: [20, 6, 0, 6] }]
              ]
            },
            layout: {
              fillColor: '#aaaaaa',
              defaultBorder: ''
            },
            alignment: 'left',
            margin: [35, 0, 100, 18],
            pageBreak: idx === 0 ? '' : 'before'
          },
          PDFUtil.generateResultsLegend(this.legend),
          this.generalResponsibilityTable(general, gralData)
        )
        const specifics = results[general].specific
        this.resultsTables.push(this.specificsResultsTable(general, specifics))
        this.tableNum++
      }
    },
    $generateResultsSpec () {
      this.loopResults()
      return [
        PDFUtil.generateTitleLandscape(this.$t('organizationalReport.results_title_2'), 'before', true),
        ...this.resultsTables
      ]
    }
  }
}
