
import Vue from 'vue'
import Router from 'vue-router'

import AppLayout from '../views/app-layout.vue'
import AuthLayout from '../views/auth/auth-layout.vue'
import Dashboard from '../views/dashboard.vue'
import Forbidden from '../views/errors/forbidden.vue'
import NotFound from '../views/errors/not-found.vue'
import InvalidEnterprise from '../views/errors/invalid-enterprise.vue'
// Evaluations
import CreateEvaluation from '../views/evaluations/create.vue'
import EditEvaluation from '../views/evaluations/edit.vue'
import ListEvaluations from '../views/evaluations/list.vue'
import ShowEvaluation from '../views/evaluations/show.vue'
import Evaluation from '../views/evaluations/evaluation.vue'
import EvaluationReport from '../views/evaluations/report.vue'
import EvaluationLayout from '../views/evaluations/evaluation-layout.vue'

// Questionnaires
import ListQuestionnaires from '../views/questionnaires/list.vue'
import CreateQuestionnaires from '../views/questionnaires/create.vue'
import EditQuestionnaires from '../views/questionnaires/edit.vue'
import EditQuestionnaireInfo from '../views/questionnaires/edit-questionnaire.vue'

// Conceptual Frameworks
import ListConceptualFrameworks from '../views/conceptual_frameworks/list.vue'
import CreateConceptualFrameworks from '../views/conceptual_frameworks/create.vue'
import EditConceptualFrameworks from '../views/conceptual_frameworks/edit.vue'
import EditConceptualFrameworkInfo from '../views/conceptual_frameworks/edit-framework.vue'

//
import Home from '../views/home.vue'
import Maintenance from '../views/maintenance.vue'
import OperationSummary from '../views/operations/summary.vue'
import RequireAdmin from './guards/require-admin'
import RequireAuthentication from './guards/require-authentication'
import RequireEnterprise from './guards/require-enterprise'
import SsoAuthentication from './guards/sso-authentication'

Vue.use(Router)

const routes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'sso',
        beforeEnter: SsoAuthentication
      },
      {
        path: 'sign-in',
        beforeEnter: RequireAuthentication
      }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '',
        component: Home,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/dashboard',
        component: Dashboard,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/questionnaires',
        component: ListQuestionnaires,
        beforeEnter: RequireAdmin
      },
      {
        path: '/questionnaires/create',
        component: CreateQuestionnaires,
        beforeEnter: RequireAdmin
      },
      {
        path: '/questionnaires/:slug/edit',
        component: EditQuestionnaires,
        beforeEnter: RequireAdmin
      },
      {
        path: '/questionnaires/:slug/edit-questionnaire',
        component: EditQuestionnaireInfo,
        beforeEnter: RequireAdmin
      },
      {
        path: '/evaluations',
        component: ListEvaluations,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/evaluations/create',
        component: CreateEvaluation,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/evaluations/:slug/edit',
        component: EditEvaluation,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/evaluations/:slug/details',
        component: ShowEvaluation,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/evaluations/liderazgo/:tokenId',
        component: Evaluation
      },
      {
        path: '/evaluations/reports/:id',
        component: EvaluationReport,
        beforeEnter: RequireAuthentication
      },
      {
        path: '/conceptual_frameworks',
        component: ListConceptualFrameworks,
        beforeEnter: RequireAdmin
      },
      {
        path: '/conceptual_frameworks/create',
        component: CreateConceptualFrameworks,
        beforeEnter: RequireAdmin
      },
      {
        path: '/conceptual_frameworks/:slug/edit',
        component: EditConceptualFrameworks,
        beforeEnter: RequireAdmin
      },
      {
        path: '/conceptual_frameworks/:slug/edit-framework',
        component: EditConceptualFrameworkInfo,
        beforeEnter: RequireAdmin
      }
    ]
  },
  {
    path: '/outside',
    component: EvaluationLayout,
    children: [
      {
        path: 'evaluations/leadership360/:tokenId',
        component: Evaluation
      }
    ]
  },
  {
    path: '/forbidden',
    component: Forbidden
  },
  {
    path: '/maintenance',
    component: Maintenance
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/invalid-enterprise',
    component: InvalidEnterprise,
    beforeEnter: RequireEnterprise
  },
  {
    path: '/operation-summary/:type/:slug',
    component: OperationSummary
  }
]

export default new Router({
  mode: 'history',
  routes
})
