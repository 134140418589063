
import pdfUtil from '../utils_old/pdf'
import OCCLogoBase64 from '../base64Files/occ-logo'

const fontSizeBylength = [
  { min: 0, max: 30, fontSize: 20 },
  { min: 31, max: 34, fontSize: 18 },
  { min: 35, max: 38, fontSize: 16 },
  { min: 39, max: 42, fontSize: 14 },
  { min: 43, max: 57, fontSize: 12 },
  { min: 57, max: 70, fontSize: 10 },
  { min: 70, max: 9999999, fontSize: 8 }
]

const getFontSizeBylength = (l) => fontSizeBylength.find((fsbl) => fsbl.min <= l && fsbl.max >= l).fontSize
const getFontSizeBylength2 = (l) => fontSizeBylength.find((fsbl) => fsbl.min <= l && fsbl.max >= l).fontSize + 3

export default {
  data () {
    return {
      today: new Date(),
      coverLogoWidth: 280,
      coverLogoHeight: 280,
      coverLogoRatio: 1
    }
  },
  methods: {
    $generateCover () {
      const evaluatedFontSize = getFontSizeBylength2((this.evaluatedName || '').length)
      const pollNameFontSize = getFontSizeBylength((this.currentEvaluation.name || '').length)
      const enterpriseFontSize = getFontSizeBylength((this.user.enterprise.name || '').length)
      const evaluators = [
        this.$t('organizationalReport.leaders'),
        this.$t('organizationalReport.pairs'),
        this.$t('organizationalReport.dependents')
      ]
      const keys = ['leadersEvaluators', 'pairsEvaluators', 'dependentsEvaluators']
      let rows = []
      let total = []
      let receivedTotal = 0
      let expectedTotal = 0
      let rateTotal = 0
      for (let i = 0; i < evaluators.length; i++) {
        let received = this.completedPollsFiltered[keys[i]]
        let expected = this.expectedPollsFiltered[keys[i]]
        let rate = null
        if (expected > 0) {
          rate = (received * 100) / expected
        }
        rows.push([
          { text: evaluators[i].toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } },
          { text: expected === 0 ? '--' : expected, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } },
          { text: expected === 0 && received === 0 ? '--' : received, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } },
          { text: rate == null ? '--' : this.round(rate, 2) + '%', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' } }
        ])
        receivedTotal += received
        expectedTotal += expected
      }
      if (expectedTotal > 0) {
        rateTotal = (receivedTotal * 100) / expectedTotal
      }
      total.push([
        { text: this.$t('organizationalReport.total').toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' } },
        { text: expectedTotal === 0 ? '--' : expectedTotal, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' } },
        { text: expectedTotal === 0 && receivedTotal === 0 ? '--' : receivedTotal, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' } },
        { text: rateTotal === 0 ? '--' : this.round(rateTotal, 2) + '%', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' } }
      ])
      if (this.leadership360CoverOnlyBase64) {
        let domEnterpriseLogo = document.getElementById('dynamicEnterpriseLogo')
        let w = domEnterpriseLogo.width
        let h = domEnterpriseLogo.height
        let rem = w % h
        let wRem = w / rem
        let hRem = h / rem
        let ratio = wRem / hRem
        if (ratio > 1) {
          this.coverLogoHeight = this.coverLogoWidth / ratio
          this.coverLogoRatio = ratio
        }
      }
      return [
        {
          columns: [
            // LEFT MAIN COLUMN
            {
              width: '40%',
              image: this.leadership360CoverOnlyBase64 || OCCLogoBase64,
              fit: [this.coverLogoWidth, this.coverLogoHeight],
              aligment: 'center',
              margin: [0, 0, 0, 0],
              absolutePosition: { x: 35, y: ((595 - this.coverLogoHeight) / 2) }
            },
            // RIGHT MAIN COLUMN
            [
              {
                margin: [0, 25, 0, 0],
                columns: pdfUtil.generateCenteredText(this.$t('individualReport.title').toUpperCase(), 18, true, false, false)
              },
              {
                margin: [0, 0, 0, 0],
                columns: pdfUtil.generateCenteredText(this.$t('individualReport.subtitle').toUpperCase(), 17, false)
              },
              {
                margin: [0, 15, 0, 0],
                columns: pdfUtil.generateCenteredText(this.evaluatedName, evaluatedFontSize, false)
              },
              {
                margin: [0, 15, 0, 0],
                columns: pdfUtil.generateCenteredText(this.currentEvaluation.name, pollNameFontSize, true)
              },
              {
                margin: [0, 10, 0, 0],
                columns: pdfUtil.generateCenteredText(this.user.enterprise.name, enterpriseFontSize, true)
              },
              {
                margin: [0, 0, 0, 0],
                columns: pdfUtil.generateCenteredText(`${this.today.getFullYear()}-${this.today.getMonth() + 1}-${this.today.getDate()}`, 15, true)
              },
              {
                margin: [0, 25, 0, 1],
                columns: pdfUtil.generateCenteredText(this.$t('individualReport.poll_answers').toUpperCase(), 18, true)
              },
              {
                layout: {
                  defaultBorder: '',
                  fillColor: function (rowIndex) {
                    return (rowIndex === 0) ? '#BFBFBF' : null
                  },
                  border: [false, false, false, true]
                },
                table: {
                  widths: [90, 80, 80, 100],
                  headerRows: 1,
                  body: [
                    [
                      { text: '', margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                      { text: this.$t('individualReport.requested').toUpperCase(), margin: [10, 10, 10, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                      { text: this.$t('individualReport.received').toUpperCase(), margin: [10, 10, 10, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                      { text: this.$t('individualReport.answers_rate').toUpperCase(), margin: [10, 3, 10, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
                    ],
                    // Body
                    ...rows,
                    ...total
                  ]
                },
                pageBreak: 'after',
                margin: [34, 0, 0, 0],
                border: [false, false, false, true],
                alignment: 'center',
                fontSize: 10
              }
            ]
          ]
        }
      ]
    }
  }
}
