<template>
  <v-container fluid>
    <v-row align="center" justify="start" fill-height>
      <v-col xs="9" sm="9" class="ml-2">
        <h4 style="'float:left'" class="display-1 mb-3">{{ $t('evaluations.create') }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="mt-4">
          <x-stepper
            :step="step"
            :headers="stepperHeaders"
            :max-sm="1"
            :max-md="2"
            :max-lg="3"
            :max-xl="4"
            @step="step = $event"
          >
            <v-stepper-content key="1-content" step="1">
              <x-step-overview
                v-if="step == 1"
                :evaluation="evaluation"
                :total-receptors="totalParticipants"
                step="1"
                nextAction="input.next"
                prevAction="input.cancel"
                @changeStep="verifyStepChanged"
              />
            </v-stepper-content>
            <v-stepper-content key="2-content" step="2">
              <x-step-date
                v-if="step == 2"
                :evaluation="evaluation"
                step="2"
                nextAction="input.next"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
              ></x-step-date>
            </v-stepper-content>
            <v-stepper-content key="3-content" step="3">
              <x-step-question
                v-if="step == 3"
                :evaluation="evaluation"
                step="3"
                nextAction="input.next"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
              ></x-step-question>
            </v-stepper-content>
            <v-stepper-content key="4-content" step="4">
              <x-step-evaluated-selection
                v-if="step == 4"
                :evaluation="evaluation"
                step="4"
                nextAction="input.next"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
                :employees="employees"
              />
            </v-stepper-content>
            <v-stepper-content key="5-content" step="5">
              <x-step-revition
                v-if="step === 5"
                :evaluation="evaluation"
                :price="price"
                step="5"
                nextAction="input.confirm_save"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
                :balance="balance"
              ></x-step-revition>
            </v-stepper-content>
          </x-stepper>
        </v-card>
      </v-col>
    </v-row>
    <x-confirm-spend-dialog
      :confirmText="$t('evaluations.confirm_create_title')"
      :costText="$t('evaluations.workshop_cost')"
      :showModalConfirm="showModalConfirm"
      :balance="balance"
      :price="price"
      :noActiveEmployee="false"
      :noBalanceResponse="noBalanceResponse"
      @result="verifySpend"
      @update="checkBalance"
    >
    </x-confirm-spend-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'

import { mapState } from 'vuex'
import EvaluationsService from '../../services/evaluations'
import employeesService from '../../services/employees'

import XStepOverview from './steps/overview.vue'
import XStepDate from './steps/date.vue'
import XStepQuestion from './steps/question.vue'
import XStepEvaluatedSelection from './steps/evaluated-selection.vue'
import XStepRevition from './steps/revition.vue'

export default Vue.extend({
  components: {
    XStepOverview,
    XStepDate,
    XStepQuestion,
    XStepEvaluatedSelection,
    XStepRevition
  },
  data () {
    return {
      stepperHeaders: [
        'evaluations.overview',
        'evaluations.date',
        'evaluations.questions',
        'evaluations.evaluated_selection',
        'evaluations.revition'
      ],
      evaluation: {
        evaluated: [],
        name: '',
        displayName: '',
        timeZone: '(UTC-05:00) Bogotá, Lima, Quito',
        deliveredAt: {
          value: '',
          hour: '23:00'
        },
        validUntil: {
          value: '',
          hour: '23:00'
        },
        status: 'pending',
        reminders: [{
          value: '',
          hour: '23:00'
        }],
        switchName: false,
        switchDate: false,
        questionnaire: 'base-questionnaire',
        conceptualFramework: 'base-conceptual-framework',
        questionnaireLabel: '',
        conceptualFrameworkLabel: '',
        porcent_total: 0,
        pollInvitation: {
          subject: '',
          body: '',
          file: ''
        },
        reminderMail: {
          subject: '',
          body: '',
          file: ''
        },
        thankMessage: '',
        active: null,
        offset: ''
      },
      step: 1,
      enterpriseId: null,
      totalParticipants: null,
      employees: [],
      balance: 0,
      showModalConfirm: false,
      price: 0,
      noBalanceResponse: false,
      createdSlug: ''
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getEmployees()
  },
  methods: {
    toConfirm () {
      this.showModalConfirm = true
      this.checkBalance()
    },
    verifySpend (data) {
      if (data === 1) {
        return this.create()
      }
      this.showModalConfirm = false
    },
    verifyStepChanged (data, step) {
      switch (step) {
        case 0: return this.$router.push('/evaluations')
        case 5: this.step = step; return this.checkBalance()
        case 6: return this.toConfirm()
        default: this.step = step
      }
    },
    cleanEvaluated (evaluatedList) {
      const toBuild = JSON.parse(JSON.stringify(evaluatedList))
      toBuild.forEach((evaluated) => {
        // Evaluado
        evaluated.employee.employee = {
          id: evaluated.employee.employee.id,
          employee: { id: evaluated.employee.employee.employee.id }
        }
        // Lideres
        evaluated.leadersEvaluators = evaluated.leadersEvaluators.map((leader) => {
          return {
            id: leader.id,
            employee: { id: leader.employee.id }
          }
        })
        // Dependientes
        evaluated.dependentsEvaluators = evaluated.dependentsEvaluators.map((dependent) => {
          return {
            id: dependent.id,
            employee: { id: dependent.employee.id }
          }
        })
        // Pares
        evaluated.pairsEvaluators = evaluated.pairsEvaluators.map((pair) => {
          return {
            id: pair.id,
            employee: { id: pair.employee.id }
          }
        })
      })
      return toBuild
    },
    create () {
      this.$store.dispatch('loading/show')
      const pollInvitationFile = this.evaluation.pollInvitation.file
      const reminderMailFile = this.evaluation.reminderMail.file
      let data = JSON.parse(JSON.stringify(this.evaluation))
      data.pollInvitation.file = this.evaluation.pollInvitation.file.name
      data.reminderMail.file = this.evaluation.reminderMail.file.name
      if (this.user.enterprise) {
        data.enterprise = {
          id: this.user.enterprise.id,
          customerId: this.user.enterprise.customerId,
          name: this.user.enterprise.name,
          active: this.user.enterprise.active,
          createdAt: this.user.enterprise.createdAt,
          updatedAt: this.user.enterprise.updatedAt,
          logo: this.user.enterprise.logo
        }
        data.enterprise.customer = {
          id: this.user.customer.id,
          userId: this.user.customer.userId,
          name: this.user.customer.name,
          active: this.user.customer.active,
          createdAt: this.user.customer.createdAt,
          updatedAt: this.user.customer.updatedAt
        }
        data.enterpriseId = this.user.enterprise.id
      }
      data.evaluated = this.cleanEvaluated(data.evaluated)
      data.lang = this.user.lang
      return EvaluationsService.create(data)
        .then((res) => {
          if (!res._id) {
            if (res.status === 401) {
              this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
              this.noBalanceResponse = true
            } else {
              this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
            }
            return Promise.reject(this.$t('errors.no_balance'))
          }

          this.createdSlug = res.slug
          return pollInvitationFile ? EvaluationsService.sendInvitationFiles(res._id, { pollInvitationFile })
            .then(() => res)
            .catch(() => {
              this.$store.dispatch('alert/error', this.$t('errors.uploadInvitationError'))
              setTimeout(this.redirectSummary, 3000)
            }) : Promise.resolve(res)
        })
        .then((res) => {
          return reminderMailFile ? EvaluationsService.sendReminderFiles(res._id, { reminderMailFile })
            .then(() => res)
            .catch(() => {
              this.$store.dispatch('alert/error', this.$t('errors.uploadReminderError'))
              setTimeout(this.redirectSummary, 3000)
            }) : Promise.resolve(res)
        })
        .then((res) => {
          if (res) {
            this.$store.dispatch('alert/success', this.$t('evaluations.created_evaluation'))
            setTimeout(this.redirectSummary, 3000)
          }
          return false
        })
        .catch((err) => {
          this.$store.dispatch('loading/hide')
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    getEmployees () {
      return employeesService.listActive()
        .then((res) => {
          this.totalParticipants = res.total
          this.employees = res.items.map((employee) => {
            return {
              text: `${employee.firstName} ${employee.lastName}`,
              value: employee.id,
              id: employee.id,
              firstName: employee.firstName,
              lastName: employee.lastName,
              employee: { id: employee.employee.id }
            }
          })
        })
    },
    checkBalance () {
      this.$store.dispatch('loading/show')
      return EvaluationsService.checkBalance()
        .then((res) => {
          this.balance = res.balance
          this.price = res.productService * (this.evaluation.evaluated || []).length
          this.$store.dispatch('loading/hide')
        })
    },
    redirectSummary () {
      this.$store.dispatch('loading/hide')
      this.$router.push('/operation-summary/individual/' + this.createdSlug)
    }
  }
})
</script>
