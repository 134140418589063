
export default {
  edit_committal: 'Edit commital questions',
  edit_word: 'Edit open questions',
  questions: 'Questions',
  languages: 'Languages',
  tooltip_edit: 'You must press ENTER to edit',
  update_question: 'Question modified successfully!',
  edit_full_desc: 'Edit Full description',
  general: '360°',
  auto: 'Self-assessment'
}
