
export default {
  email: 'Email',
  password: 'Password',
  password_confirm: 'Confirm Password',
  new_password: 'New Password',
  new_password_confirm: 'Confirm new password',
  logout: 'Log out',
  first_name: 'Name',
  last_name: 'Last name',
  identify_document: 'ID',
  gender: 'Gender',
  department: 'Department/Area',
  employment: 'Job title',
  academic_degree: 'Level of education',
  admission: 'Start date',
  age_range: 'Age range',
  antiquity_range: 'Seniority Rank',
  job_type: 'Type of contract',
  jobType: 'Type of contract',
  charge: 'Job title',
  country: 'Country',
  headquarter: 'Location',
  phone_number: 'Phone number',
  accept: 'Accept',
  cancel: 'Cancel',
  confirm: 'Confirm',
  save: 'Save',
  next: 'Next',
  back: 'Back',
  enable: 'Enable',
  disable: 'Disable',
  skip: 'Skip',
  add: 'Add',
  trash: 'Delete',
  refresh: 'Refresh',
  import: 'Import',
  file: 'File',
  save_changes: 'Save Changes',
  error_fix: 'Fix errors',
  confirm_changes: 'Comfirm changes',
  back_home: 'Back to top',
  back_list: 'Back to list',
  name: 'Name',
  sector: 'Industry',
  address: 'Address',
  enterprise: 'Company',
  enterprises: 'Companies',
  enterprise_size: 'Company size',
  language: 'Language',
  employees_number: 'Number of collaborators',
  demographic_cuts: 'Demographic cuts',
  license: 'License',
  duration: 'Duration',
  start_date: 'Start date',
  edit: 'Edit',
  edit_questions: 'Edit Questionnaire',
  details: 'Details',
  view_details: 'See details',
  birthdate: 'Date of Birth',
  confirm_save: 'Confirm and create',
  continue: 'Continue',
  start_poll: 'Start assessment',
  finish: 'Finish',
  back_to_dashboard: 'Back to Dashboard',
  select_image: 'Select company logo',
  continue_filling: 'Continue answering',
  languages: 'Languages',
  begin: 'Start',
  answer: 'Answer',
  resend: 'Forward',
  view_pdf: 'Go to report',
  extend_plan: 'Extend plan',
  enterprise_name: 'Company name',
  customer: 'Client',
  pulses_qty_add: 'Amount of Pulse to add',
  engagements_qty_add: 'Amount of Engagement to add',
  admin_extension: 'Manage extension requests',
  reject: 'Decline',
  see_requests: 'View submitted requests',
  identify_type: 'ID type',
  identify_type_s: 'ID',
  exist_identification: 'The ID number entered already exists.',
  newplan: 'New plan',
  admin_update: 'Manage update request',
  update_plan: 'Upgrade plan',
  no_permissions: 'You do not have permission to perform this action',
  want_request_extend: 'If you wish to request an upgrade to your license, click on the extend plan button.',
  group: 'Group',
  engagementsQty: 'Amount of Engagement',
  pulsesQty: 'Pulse Quantity',
  view_extension_history: 'See request history',
  plan_extend_info: 'Plan extension',
  demo_duration: '15 days',
  order: 'Order',
  required_field: 'Required field',
  valid_email: 'Enter a valid email address.',
  close: 'Close',
  generic_link_btn: 'Link for Collaborators without an Email',
  invitation_url: 'Invitation link',
  url_copied: 'Link copied',
  copy_invitation_url_title: 'Link for collaborators without email',
  // tslint:disable-next-line: max-line-length
  copy_invitation_url: 'You can copy this link and send it to the collaborators who are participating in the assessment and do not have an email or have not received the invitation email. In order to answer the assessment, collaborators must enter their identity document with which they have been uploaded to the platform.',
  send_invitation_url: 'Send invite link',
  modal_title: 'Edit employee data',
  preview: 'Preview',
  close_poll: 'Close assessment',
  poll_delete_title: 'Close assessment',
  // tslint:disable-next-line: max-line-length
  master_references_delete_poll: 'After closing the assessment none of your collaborators will be able to participate in it and this action cannot be changed in any way. All reporting information will be saved up to this point.',
  select_video: 'Select video for email',
  login_as_enterprise: 'Log in as this company',
  login_as_employee: 'Login as this employee',
  add_token: 'Add OCC Tokens',
  enterprise_profile: 'Company Profile',
  contact_information: 'ID - Contact Information',
  complete_name: 'Full name',
  change: 'Change',
  actual_password: 'Current password',
  got_it: 'Understood!',
  password_change: 'Change password',
  actual_email: 'Current mailing address',
  email_change: 'Change Email address',
  new_email: 'New email',
  view_profile: 'View profile',
  change_enterprise: 'Change company',
  changing_enterprise: 'Change company',
  fast_search: 'Quick search',
  sales_range: 'Sales rank',
  manage_token: 'Manage OCC Tokens',
  select: 'Select',
  update: 'Update',
  see_more: 'See more',
  back_to_login: 'Back to login',
  authorizations: 'Authorizations',
  manage_authorizations: 'Manage Authorizations',
  back_to_list: 'Back to list',
  display_name: 'External name',
  create: 'Add',
  evaluated: 'Assessee',
  relation: 'Work relationship',
  evaluators: 'Assessors',
  status: 'Status',
  apply: 'Apply',
  download: 'Download',
  upload_file: 'Upload files',
  warning: 'Warning',
  error: 'Error',
  see_warnings: 'See warnings',
  see_errors: 'See errors',
  no_reversible_msg: 'Remember that this action is irreversible',
  start: 'Start',
  update_model: 'Update model',
  reconnect: 'Reconnect',
  download_reports: 'Download Reports',
  the_reminders: 'The reminders',
  select_file_to_upload: 'Select a file to upload',
  may_take_while: 'This action may take a few minutes'
}
