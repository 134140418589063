
export default {
  title: 'Prices and recommendations',
  cost: ' It costs ',
  rate: ' Tokens for ',
  table_ranges: 'Token Range',
  table_prices: 'Value per Token',
  medition: 'assessment',
  download: 'download',
  person: 'assessee',
  recomendation: 'It is recommended',
  onetime: ' a single execution',
  annual: ' Annual',
  ondemand: ' on demand',
  ormore: 'Or More',
  services: 'SERVICES',
  recomendations: 'RECOMMENDATIONS'
}
