
import PdfUtil from '../utils/pdf'
import OldPdfUtil from '../utils_old/pdf'
import circle64 from '../base64Files/circle64'
import diamond64 from '../base64Files/diamond64'

export default {
  data () {
    return {
      conceptualFramework: [],
      asPersonResponsability: [],
      relationsMeaning: [],
      dataFiltereds: [
        [{ total: 4.00, tenPercent: 4.88, averageLeaders: 3.99, previous: 1.00, individualScores: [2, 5] }],
        [{ total: 4.25, tenPercent: 4.69, averageLeaders: 4.50, previous: -0.69, individualScores: [1, 6] }],
        [{ total: 5.00, tenPercent: 5.00, averageLeaders: 5.00, previous: 1.16, individualScores: [1, 5] }],
        [{ total: 3.90, tenPercent: 5.23, averageLeaders: 4.20, previous: -0.23, individualScores: [3, 6] }],
        [{ total: 4.10, tenPercent: 5.00, averageLeaders: 4.88, previous: 1.09, individualScores: [2, 5] }]
      ],
      dataTotalFiltereds: [
        { total: 4.00 },
        { total: 4.25 },
        { total: 5.00 },
        { total: 3.90 },
        { total: 4.10 }
      ],
      tablesMeaning: []
    }
  },
  computed: {
    computedLeftMrg () {
      return this.$i18n.locale === 'es' ? 394 : 260
    },
    relations () {
      return [
        this.$t(`individualReport.me`),
        this.$t(`individualReport.leaders`),
        this.$t(`individualReport.pairs`),
        this.$t(`individualReport.dependents`),
        this.$t(`individualReport.others`)
      ]
    },
    legend () {
      return [
        this.$t('individualReport.no_answer'),
        this.$t('individualReport.data_range'),
        this.$t('individualReport.score'),
        // this.$t('individualReport.ten_percent'),
        '',
        this.$t('individualReport.leaders_average'),
        this.$t('individualReport.higher_bold'),
        this.$t('individualReport.lower_sub')
      ]
    },
    graphText () {
      return [
        { title: this.$t('individualReport.bars'), text: this.$t('individualReport.bars_text') },
        { title: this.$t('individualReport.score'), text: this.$t('individualReport.score_text') },
        { title: this.$t('individualReport.leaders_average'), text: this.$t('individualReport.leaders_average_text') },
        { title: this.$t('individualReport.trend'), text: this.$t('individualReport.trend_text') },
        { title: this.$t('individualReport.plus_minus_symbols'), text: this.$t('individualReport.plus_minus_symbols_text') },
        { title: this.$t('individualReport.others_symbols'), text: this.$t('individualReport.others_symbols_text') },
        { title: this.$t('individualReport.others_symbols'), text: this.$t('individualReport.bold_text') }
      ]
    }
  },
  methods: {
    roundNum (value, decimals, df) {
      if (isNaN(value)) return df !== undefined ? df : value
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    pushParagraph (text) {
      this.conceptualFramework.push(
        {
          text: text,
          alignment: 'justify',
          margin: [0, 0, 8, 10],
          color: '#6d7a7a',
          lineHeight: 1.05,
          fontSize: 11
        }
      )
    },
    pushTable1 () {
      let i = 0
      for (const translation of this.$t('individualReport.relations_texts')) {
        this.relationsMeaning.push([
          { text: this.relations[i], margin: [6, -2, 6, -3], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' }, alignment: 'center' },
          { text: translation, margin: [6, -2, 10, -3], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'left' }
        ])
        i++
      }

      this.conceptualFramework.push(
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              ...this.relationsMeaning
            ]
          },
          margin: [0, 15, 15, 0],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 9
        }
      )
    },
    pushTable2 () {
      for (let j = 0; j < 5; j++) {
        const dataFiltered = JSON.parse(JSON.stringify(this.dataFiltereds[j]))
        this.asPersonResponsability.push([
          { text: 'xyz', rowSpan: 5, color: '#6d7a7a', margin: [0, 50, 0, 0], border: [true, true, true, true] },
          { text: this.relations[j], bold: true, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [true, true, true, true] },
          OldPdfUtil.generateXDCChartCover(dataFiltered, 0, dataFiltered[0].averageLeaders, 'detail'),
          OldPdfUtil.higherLowerRow(this.dataTotalFiltereds, dataFiltered[0].total, '#6d7a7a', [0, 4, 0, 0], 'center', [true, true, true, true]),
          // { text: this.roundNum(dataFiltered[0].tenPercent, 2), bold: false, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: this.roundNum(dataFiltered[0].averageLeaders, 2), bold: false, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: `${this.hasPrevious ? this.roundNum(dataFiltered[0].previous, 2) : '--'}`, bold: false, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
        ])
      }

      this.conceptualFramework.push(
        // example table
        OldPdfUtil.generateLiderazgoLegendIndividualExample(this.legend),
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 1,
            widths: ['39%', '14%', '26%', '7%', '7%', '7%'],
            body: [
              [
                { text: `${this.$t('individualReport.general_responsability')}`, bold: true, color: '#fff', margin: [0, 6, 0, -5], border: [true, true, true, true], fontSize: 9 },
                { text: `${this.$t('individualReport.evaluator')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 6, 0, -5], fontSize: 9 },
                { layout: { defaultBorder: '', fillColor: '#BFBFBF' },
                  table: {
                    headerRows: 1,
                    body: [[
                      { text: '1\n|', margin: [10, -5, 15, -5] },
                      { text: '2\n|', margin: [0, -5, 15, -5] },
                      { text: '3\n|', margin: [0, -5, 15, -5] },
                      { text: '4\n|', margin: [0, -5, 15, -5] },
                      { text: '5\n|', margin: [0, -5, 15, -5] },
                      { text: '6\n|', margin: [0, -5, 15, -5] }
                    ]]
                  },
                  bold: true,
                  style: { color: '#fff' },
                  border: [true, true, true, true],
                  margin: [0, 0, 0, -5]
                },
                { text: this.$t('individualReport.score'), margin: [0, 6, 0, -5], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                // { text: this.$t('individualReport.ten_percent'), margin: [0, 0, 0, -5], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.leaders_average'), margin: [0, 0, 0, -3], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 },
                { text: this.$t('individualReport.trend'), margin: [-3, 6, -3, -5], border: [true, true, true, true], bold: true, style: { color: '#fff' }, fontSize: 9 }
              ],
              ...this.asPersonResponsability
            ]
          },
          margin: [0, 15, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          pageBreak: 'after',
          fontSize: 9
        }
      )
    },
    pushTable3 () {
      for (let i = 0; i < 7; i++) {
        if (i === 2) {
          this.tablesMeaning.push([
            { text: this.graphText[i].title.toUpperCase(), margin: [5, 0, 5, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' }, alignment: 'center' },
            {
              table: {
                body: [
                  [
                    { text: this.graphText[i].text, border: [false, false, false, false] }
                  ],
                  [
                    { image: circle64, width: 10, height: 10, margin: [this.computedLeftMrg, -17, -20, 0], border: [false, false, false, false] }
                  ]
                ]
              },
              margin: [0, -2, 0, -2],
              border: [true, true, true, true],
              bold: false,
              style: { color: '#6d7a7a' },
              alignment: 'left'
            }
          ])
        } else {
          this.tablesMeaning.push([
            i === 6
              ? {
                table: {
                  body: [
                    [
                      { text: this.$t('individualReport.higher_bold'), margin: [-5, 0, -5, 0], border: [false, false, false, false], bold: true, style: { color: '#6d7a7a' }, fontSize: 6, alignment: 'center' }
                    ],
                    [
                      { text: this.$t('individualReport.lower_sub'), margin: [-9, -5, -9, 0], border: [false, false, false, false], decoration: 'underline', style: { color: '#6d7a7a' }, fontSize: 6, alignment: 'center' }
                    ]
                  ]
                },
                border: [true, true, true, true],
                margin: [5, 0, 5, 0]
              }
              : { text: this.graphText[i].title.toUpperCase(), margin: [0, 0, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#6d7a7a' }, alignment: 'center' },
            { text: this.graphText[i].text, margin: [5, -2, 5, -2], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'left' }
          ])
        }
      }

      this.conceptualFramework.push({
        table: {
          widths: [760], // 479 Portrait
          body: [
            [
              {
                text: [
                  `${this.$t('individualReport.table')} 1. ${this.$t('individualReport.conventions_table')}`
                ],
                bold: true,
                color: '#fff',
                margin: [20, 6, 0, 6]
              }
            ]
          ]
        },
        layout: {
          fillColor: '#aaaaaa',
          defaultBorder: ''
        },
        alignment: 'left',
        margin: [0, 0, 135, 10]
      })

      this.conceptualFramework.push(
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            body: [
              [
                { text: this.$t('individualReport.references_text'), margin: [10, 0, 10, 0], colSpan: 2, border: [true, true, true, true], style: { color: '#6d7a7a' }, alignment: 'center' },
                {}
              ],
              ...this.tablesMeaning
            ],
            widths: [73, 'auto']
          },
          margin: [-18, 30, -6, 10],
          border: [true, true, true, true],
          alignment: 'center',
          pageBreak: 'after',
          fontSize: 9
        }
      )
    },
    $generateConceptualFramework (details, isReport = true) {
      this.conceptualFramework = []
      this.asPersonResponsability = []
      this.relationsMeaning = []
      this.tablesMeaning = []
      let counter = 1
      for (const detail of details) {
        let pgBrk = !isReport && counter === 1 ? '' : 'before'
        let forceLandscape = pgBrk === 'before'
        this.conceptualFramework.push(PdfUtil.generateTitle(counter + '. ' + detail.title[this.user.lang], pgBrk, true, false, forceLandscape))
        // Add initial static page for section #5
        if (counter === 5) {
          for (const translation of this.$t('individualReport.how_to_interpret_the_report')) {
            this.pushParagraph(translation)
          }

          this.pushTable1()
          this.pushTable2()
          this.pushTable3()
        }

        // let maxLength = detail['max-length']
        // let remainingLength = maxLength
        // let lineJump = 77 // The number of characters that a line break occupies
        for (const bodyLine of detail.body[this.user.lang]) {
          // let thisLineLength = bodyLine.length
          this.pushParagraph(
            bodyLine
          )
          /*
          remainingLength -= thisLineLength + lineJump
          if (remainingLength <= 0) {
            break
          }
          */
        }
        // Add scores meaning table
        if (counter === 4) {
          const meaningRows = []
          // Old 2 line table
          /*
          const firstRow = []
          const secondRow = []
          const fillTable = (val) => {
            return typeof val !== 'string'
              ? { text: `(${val})`, margin: [10, 0, 10, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'center' }
              : { text: val, margin: [15, 0, 15, 0], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'left' }
          }
          */
          let count = 1
          for (const score of this.$t('individualReport.scores_meaning')) {
            meaningRows.push([
              { text: `(${count})`, margin: [10, 1, 10, -1], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'center' },
              { text: score, margin: [10, 1, 40, -1], border: [true, true, true, true], bold: false, style: { color: '#6d7a7a' }, alignment: 'left' }
            ])
            // Old 2 line table
            /*
            if (count <= 3) {
              firstRow.push(fillTable(count))
              firstRow.push(fillTable(score))
            } else {
              secondRow.push(fillTable(count))
              secondRow.push(fillTable(score))
            }
            */
            count++
          }
          this.conceptualFramework.push(
            {
              layout: {
                defaultBorder: '',
                border: [false, false, false, true]
              },
              table: {
                headerRows: 0,
                body: [
                  // Old 2 line table
                  /*
                  [
                    ...firstRow
                  ],
                  [
                    ...secondRow
                  ]
                  */
                  ...meaningRows
                ]
              },
              margin: [280, 5, 0, 0],
              border: [false, false, false, true],
              alignment: 'center',
              fontSize: 10
            }
          )
        }
        counter++
      }
      return this.conceptualFramework
    }
  }
}
