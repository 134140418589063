<template>
  <v-container fluid>
    <v-row align="center" justify="start" fill-height>
      <v-col xs="9" sm="9" class="ml-2">
        <h4 style="'float:left'" class="display-1 mb-3">{{ $t('evaluations.edit') }}</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" >
        <v-card class="mt-4">
          <x-stepper
            :step="step"
            :headers="stepperHeaders"
            :max-sm="1"
            :max-md="2"
            :max-lg="3"
            :max-xl="4"
            @step="step = $event"
          >
            <v-stepper-content key="1-content" step="1">
              <x-step-overview
                v-if="step == 1"
                :evaluation="evaluation"
                :total-receptors="totalParticipants"
                step="1"
                nextAction="input.next"
                prevAction="input.cancel"
                @changeStep="verifyStepChanged"
              />
            </v-stepper-content>
            <v-stepper-content key="2-content" step="2">
              <x-step-date
                v-if="step == 2"
                :evaluation="evaluation"
                step="2"
                nextAction="input.next"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
              ></x-step-date>
            </v-stepper-content>
            <v-stepper-content key="3-content" step="3">
              <x-step-question
                v-if="step == 3"
                :evaluation="evaluation"
                step="3"
                nextAction="input.next"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
              ></x-step-question>
            </v-stepper-content>
            <v-stepper-content key="4-content" step="4">
              <x-step-evaluated-selection
                v-if="step == 4"
                :evaluation="evaluation"
                step="4"
                nextAction="input.next"
                prevAction="input.back"
                @changeStep="verifyStepChanged"
                @methodCreate="checkEdit"
                :employees="employees"
              ></x-step-evaluated-selection>
            </v-stepper-content>
            <v-stepper-content key="5-content" step="5">
              <x-step-revition
                v-if="step === 5"
                :evaluation="evaluation"
                :price="price"
                step="5"
                nextAction="input.update"
                prevAction="input.back"
                :balance="balance"
                @changeStep="verifyStepChanged"
                @delete-invitation-file="deleteInvitationFile"
                @delete-reminder-file="deleteReminderFile"
              ></x-step-revition>
            </v-stepper-content>
          </x-stepper>
        </v-card>
      </v-col>
    </v-row>
    <x-confirm-spend-dialog
      :confirmText="$t('evaluations.confirm_edit_title')"
      :costText="$t('evaluations.diff_cost')"
      :showModalConfirm="showModalConfirm"
      :balance="balance"
      :price="price"
      :noActiveEmployee="false"
      :noBalanceResponse="noBalanceResponse"
      @result="verifySpend">
    </x-confirm-spend-dialog>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import EvaluationsService from '../../services/evaluations'
import employeesService from '../../services/employees'

import XStepOverview from './steps/overview.vue'
import XStepDate from './steps/date.vue'
import XStepQuestion from './steps/question.vue'
import XStepEvaluatedSelection from './steps/evaluated-selection.vue'
import XStepRevition from './steps/revition.vue'

export default Vue.extend({
  components: {
    XStepOverview,
    XStepDate,
    XStepQuestion,
    XStepEvaluatedSelection,
    XStepRevition
  },
  data () {
    return {
      editMassive: null,
      stepperHeaders: [
        'evaluations.overview',
        'evaluations.date',
        'evaluations.questions',
        'evaluations.evaluated_selection',
        'evaluations.revition'
      ],
      evaluation: {
        name: '',
        displayName: '',
        slug: '',
        status: '',
        timeZone: '',
        tokenId: '',
        questionnaire: '',
        conceptualFramework: '',
        questionnaireLabel: '',
        conceptualFrameworkLabel: '',
        deliveredAt: {
          value: '',
          hour: ''
        },
        validUntil: {
          value: '',
          hour: ''
        },
        evaluated: [],
        oldEvaluated: 0,
        reminderMail: '',
        reminders: [{
          value: '',
          hour: '23:00'
        }],
        switchName: false,
        switchDate: false,
        pollInvitation: '',
        thankMessage: '',
        customEmailRelease: '',
        customEmailDeadline: '',
        invitationFileFlag: true,
        reminderFileFlag: true
      },
      step: 1,
      enterpriseId: null,
      balance: 0,
      showModalConfirm: false,
      price: 0,
      noBalanceResponse: false,
      totalParticipants: null,
      employees: [],
      quetionnaires: null
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getEvaluation()
    this.getEmployees()
  },
  methods: {
    toConfirm () {
      if (this.evaluation.evaluated.length > this.evaluation.oldEvaluated) {
        this.showModalConfirm = true
        this.checkBalance()
      } else {
        this.edit()
      }
    },
    verifySpend (data) {
      if (data === 1) {
        return this.edit()
      }
      this.showModalConfirm = false
    },
    verifyStepChanged (data, step) {
      switch (step) {
        case 0: return this.$router.push('/evaluations')
        case 5: this.step = step; return this.checkBalance()
        case 6: return this.toConfirm()
        default: this.step = step
      }
    },
    getEvaluation () {
      this.$store.dispatch('loading/show')
      return EvaluationsService.getOneToEdit(this.$route.params.slug)
        .then((res) => {
          this.evaluation.edit = true
          this.evaluation.name = res.name
          this.evaluation.displayName = res.displayName
          this.evaluation.status = res.status
          this.evaluation.timeZone = res.timeZone
          this.evaluation.questionnaire = res.questionnaire.slug
          this.evaluation.conceptualFramework = res.conceptualFramework.slug
          this.evaluation.questionnaireLabel = res.questionnaire.name
          this.evaluation.conceptualFrameworkLabel = res.conceptualFramework.name
          this.evaluation.deliveredAt = this.getFormattedDate(res.deliveredAt)
          this.evaluation.validUntil = this.getFormattedDate(res.validUntil)
          this.evaluation.evaluated = res.evaluated
          this.evaluation.oldEvaluated = res.evaluated.length
          this.totalParticipants = res.evaluatedCount
          this.parseEvaluated()
          this.evaluation.customEmailRelease = res.customEmailRelease
          this.evaluation.customEmailReminder = res.customEmailReminder

          if (this.evaluation.displayName) {
            this.evaluation.switchName = true
          }
          this.evaluation.reminderMail = {
            body: '',
            subject: ''
          }
          if (res.reminders.length) {
            this.evaluation.switchDate = true
            this.evaluation.reminders = this.getFormattedReminders(res.reminders)
          }
          this.evaluation.pollInvitation = {}
          this.evaluation.pollInvitation.subject = this.evaluation.customEmailRelease.subject
          this.evaluation.pollInvitation.body = this.evaluation.customEmailRelease.body
          this.evaluation.pollInvitation.attachment = this.evaluation.customEmailRelease.attachment
          this.evaluation.reminderMail = {}
          this.evaluation.reminderMail.subject = this.evaluation.customEmailReminder.subject
          this.evaluation.reminderMail.body = this.evaluation.customEmailReminder.body
          this.evaluation.reminderMail.attachment = this.evaluation.customEmailReminder.attachment

          this.evaluation.thankMessage = this.evaluation.customEmailDeadline ? this.evaluation.customEmailDeadline.body : ''
          this.$store.dispatch('loading/hide')
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          this.$store.dispatch('loading/hide')
        })
    },
    getFormattedReminders (reminders) {
      const values = []
      for (const reminder of reminders) {
        values.push(this.getFormattedDate(reminder.dateTime))
      }
      return values
    },
    employeeMapper (employee) {
      if (employee.text) {
        return employee
      } else {
        return {
          text: `${employee.firstName} ${employee.lastName}`,
          value: employee.id,
          ...employee
        }
      }
    },
    cleanExcluded () {
      this.evaluation.evaluated.forEach((evaluated) => {
        ['leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators'].forEach((key) => {
          evaluated[key].forEach((it) => { it.employee = this.employeeMapper(it.employee) })
        })
        evaluated.employee.employee = this.employeeMapper(evaluated.employee.employee)
      })
    },
    parseEvaluated () {
      this.evaluation.evaluated.forEach((evaluated) => {
        ['leadersEvaluators', 'dependentsEvaluators', 'pairsEvaluators'].forEach((key) => {
          evaluated[key].forEach((it) => { it.employee = this.employeeMapper(it.employee) })
          evaluated[key] = evaluated[key].filter((it) => it.status !== 'excluded')
        })
        evaluated.employee.employee = this.employeeMapper(evaluated.employee.employee)
      })
    },
    getFormattedDate (ref) {
      if (!ref) {
        return {
          value: '',
          hour: '23:00'
        }
      }
      const date = ref.split('T')
      const time = date[1].split(':')
      return {
        value: date[0],
        hour: time[0] + ':00'
      }
    },
    getEmployees () {
      return employeesService.listActive()
        .then((res) => {
          this.employees = res.items.map((employee) => {
            return {
              text: `${employee.firstName} ${employee.lastName}`,
              value: employee.id,
              id: employee.id,
              firstName: employee.firstName,
              lastName: employee.lastName,
              employee: { id: employee.employee.id }
            }
          })
        })
    },
    cleanEvaluated (evaluatedList) {
      const toBuild = JSON.parse(JSON.stringify(evaluatedList))
      toBuild.forEach((evaluated) => {
        // Evaluado
        evaluated.employee.employee = {
          id: evaluated.employee.employee.id,
          employee: { id: evaluated.employee.employee.employee.id }
        }
        // Lideres
        evaluated.leadersEvaluators = evaluated.leadersEvaluators.map((leader) => {
          return {
            id: leader.id,
            employee: { id: leader.employee.id }
          }
        })
        // Dependientes
        evaluated.dependentsEvaluators = evaluated.dependentsEvaluators.map((dependent) => {
          return {
            id: dependent.id,
            employee: { id: dependent.employee.id }
          }
        })
        // Pares
        evaluated.pairsEvaluators = evaluated.pairsEvaluators.map((pair) => {
          return {
            id: pair.id,
            employee: { id: pair.employee.id }
          }
        })
      })
      return toBuild
    },
    edit () {
      this.$store.dispatch('loading/show')
      const pollInvitationFile = this.evaluation.pollInvitation.file
      const reminderMailFile = this.evaluation.reminderMail.file
      let data = JSON.parse(JSON.stringify(this.evaluation))
      const mapEval = (ev) => {
        ev.answersDimention = []
        ev.openQuestions = []
        return ev
      }
      data.evaluated = data.evaluated.map((ev) => {
        mapEval(ev.employee)
        ev.leadersEvaluators = ev.leadersEvaluators.map(mapEval)
        ev.pairsEvaluators = ev.pairsEvaluators.map(mapEval)
        ev.dependentsEvaluators = ev.dependentsEvaluators.map(mapEval)
        return ev
      })
      data.evaluated = this.cleanEvaluated(data.evaluated)
      return EvaluationsService.edit(this.$route.params.slug, data)
        .then((res) => {
          if (!res._id) {
            if (res.status === 401 && this.evaluation.evaluated.length > this.evaluation.oldEvaluated) {
              this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
              this.noBalanceResponse = true
              this.$store.dispatch('loading/hide')
              return
            } else {
              this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
              this.$store.dispatch('loading/hide')
              return
            }
          }

          return pollInvitationFile ? EvaluationsService.sendInvitationFiles(res._id, { pollInvitationFile })
            .then(() => res)
            .catch(() => {
              this.$store.dispatch('alert/error', this.$t('errors.uploadInvitationError'))
              setTimeout(this.redirectSummary, 3000)
            }) : Promise.resolve(res)
        })
        .then((res) => {
          return reminderMailFile ? EvaluationsService.sendReminderFiles(res._id, { reminderMailFile })
            .then(() => res)
            .catch(() => {
              this.$store.dispatch('alert/error', this.$t('errors.uploadReminderError'))
              setTimeout(this.redirectSummary, 3000)
            }) : Promise.resolve(res)
        })
        .then((res) => {
          if (res) {
            this.$store.dispatch('alert/success', this.$t('evaluations.updated_evaluation'))
            setTimeout(this.redirectSummary, 3000)
          }
          return false
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          this.$store.dispatch('loading/hide')
        })
    },
    redirectSummary () {
      this.$store.dispatch('loading/hide')
      this.$router.push('/evaluations')
    },
    deleteInvitationFile () {
      delete this.evaluation.customEmailRelease.attachment
      this.evaluation.invitationFileFlag = false
    },
    deleteReminderFile () {
      delete this.evaluation.reminderMail.attachment
      this.evaluation.reminderFileFlag = false
    },
    checkBalance () {
      this.$store.dispatch('loading/show')
      return EvaluationsService.checkBalance()
        .then((res) => {
          this.balance = res.balance
          this.price = res.productService * (this.evaluation.evaluated.length - this.evaluation.oldEvaluated)
          this.price = this.price < 0 ? 0 : this.price
          this.$store.dispatch('loading/hide')
        })
    },
    checkEdit (value) {
      this.editMassive = value
    }
  }
})
</script>
