
import PDFUtil from '../utils/pdf'

export default {
  methods: {
    getGapPrevScore (score, bold = false, fontSize = 9) {
      return {
        text: this.isValidValue(score) ? this.round(score, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    getGap (score, bold = false, fontSize = 9) {
      return {
        text: this.isValidValue(score) ? this.round(score, 2) : '--',
        bold: bold,
        fontSize: fontSize,
        style: { color: '#6d7a7a' },
        alignment: 'center',
        margin: [0, 3, 0, 0],
        border: [true, true, true, true]
      }
    },
    singleTableGap (type, gaps) {
      const table = {
        widths: ['27.5%', '49.5%', '9%', '9%', '5%'],
        headerRows: 1,
        body: [
          [
            this.getBaseHeader(this.$t(`demographicReport.gap_table_1__header_${type}`), 5, 8, true),
            {},
            {},
            {},
            {}
          ],
          [
            this.getBaseHeader(this.gralResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.specResponsibilityTitle, 1, 9),
            this.getBaseHeader(this.$t(`demographicReport.gap_table_1__sub_header_3`), 1, 9),
            this.getBaseHeader(this.$t(`demographicReport.gap_table_1__sub_header_4`), 1, 9),
            this.getBaseHeader(this.$t(`demographicReport.gap_table_1__sub_header_5`), 1, 9)
          ]
        ]
      }

      for (var i = 0; i < 5; i++) {
        table.body.push([
          this.getRespName(gaps[i].general, undefined, true),
          this.getRespName(gaps[i].general, gaps[i].specific, true),
          this.getScore(gaps[i].score),
          this.getGapPrevScore(gaps[i].second),
          this.getGap(gaps[i].gap)
        ])
      }

      return {
        layout: {
          defaultBorder: '',
          fillColor: function (rowIndex, node, columnIndex) {
            return (rowIndex <= 1) ? '#BFBFBF' : undefined
          },
          border: [false, false, false, true]
        },
        table,
        margin: [0, 0, 5, type === 1 ? 14 : 0],
        border: [false, false, false, true],
        alignment: 'center',
        verticalAlignment: 'center'
      }
    },
    $generateGap () {
      return [
        PDFUtil.generateTitleLandscape(this.$t('demographicReport.gap_title'), 'before', true),
        PDFUtil.generateSubTitleLandscape(this.$t('demographicReport.gap_table_1_title', [this.tableNum + 2])),
        this.singleTableGap(1, this.positiveGaps),
        this.singleTableGap(2, this.negativeGaps)
      ]
    }
  }
}
