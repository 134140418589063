
const required = '<br/><br/><b>This field is required.</b>'

export default {
  icon: 'mdi-help-circle',
  evaluation: {
    create: {
      name: {
        title: 'Survey name',
        text: `Used as identification for the survey. ${required}`
      },
      displayName: {
        title: 'External name for respondents',
        // tslint:disable-next-line:max-line-length
        text: 'If selected, this will be the name shown to the collaborators in charge of the survey.'
      },
      deliveredAt: {
        title: 'Release Date',
        text: `Set the start date of the survey. From this date, the attendance emails will be sent, and the collaborators will be able to answer the assessment. ${required}`
      },
      validUntil: {
        title: 'Survey valid until',
        text: `Sets the closing date of the assessment. After closing, no more surveys of this assessment can be answered. ${required}`
      },
      timezone: {
        title: 'Time zone',
        text: `The release, closing and reminder dates will be based on the chosen time zone. ${required}`
      },
      reminders: {
        title: 'Reminders',
        // tslint:disable-next-line: max-line-length
        text: 'Reminders will be sent via email to those collaborators who have not responded by that time. A maximum of 5 reminders can be set.'
      },
      questionnaire: {
        title: 'Questionnaires',
        text: 'Activate this option if you want to select another questionnaire or use the latest version of the previously selected questionnaire.'
      },
      conceptualFramework: {
        title: 'Conceptual Framework',
        text: 'Activate this option if you want to select another conceptual framework or use the latest version of the previously selected conceptual framework.'
      },
      subject: {
        title: 'Message subject',
        text: 'Subject to be used in the email sent.'
      },
      body: {
        title: 'Message body',
        text: 'Content of the email sent.'
      },
      thankMessage: {
        title: 'Thank you message',
        // tslint: disable-next-line: max-line-length
        text: 'This message will be sent to the email address of the recipient who successfully completes the assessment.'
      },
      remember_load_update_collaborators: 'Remember to upload or update collaborators before creating the assessment.'
    }
  },
  enterprise: {
    create: {
      logo: {
        title: 'Logo',
        text: 'The company logo must be an image whose weight does not exceed 2MB.'
      }
    },
    massive: {
      file_input: {
        title: 'File to upload',
        // tslint:disable-next-line:max-line-length
        text: `Upload an excel (.xls/.xlsx) or csv (.csv) file containing your collaborators' data. This action will update your total base of collaborators, so you must add the entire base of available collaborators.<br/><br/>Remember to use the downloadable template that you can get by clicking the "DOWNLOAD TEMPLATE" button. ${required}`
      }
    }
  }
}
