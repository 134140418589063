export default {
  create: 'Crear marco conceptual',
  edit: 'Editar marco conceptual',
  base: 'Marco conceptual base',
  no_data: 'No hay marcos conceptuales para mostrar',
  autoEvaluation: 'Autoevaluación',
  generalEvaluation: '360°',
  as_person: 'Como persona',
  management: 'Desarrollo',
  mobilization: 'Movilización',
  achievement: 'Logro',
  tooltip_edit: 'Debe presionar ENTER para editar',
  edit_conceptual_framework: 'Editar marco conceptual',
  settings: 'Configuración de marco conceptual',
  name: 'Nombre',
  last_edition: 'Ultima edición',
  status: 'Estado',
  actions: 'Acciones',
  customer: 'Cliente',
  enterprise: 'Empresa',
  sector: 'Sector',
  created_succesfully: 'Marco conceptual creado correctamente',
  error_on_creation: 'Error en la creación del marco conceptual',
  asignation_type: 'Tipo de asignación',
  assign_to: 'Asignar a',
  edited_succesfully: 'Marco conceptual editado correctamente',
  error_editing: 'Error en la edición del marco conceptual',
  responsability: 'Responsabilidad',
  question: 'Pregunta',
  add_new_text_area: 'Agregar nueva area de texto',
  max_length_for_this_topic: 'Caracteres recomendados para el contenido de este tema es:',
  max_length_validation_error: 'Ha excedido el máximo de caracteres permitido',
  max_length_warning: 'Ha excedido el límite de caracteres recomendado por página. El resultado podría no ser el esperado',
  modal_enable: '¿Desea habilitar el marco conceptual?',
  modal_disable: '¿Desea inhabilitar el marco conceptual?',
  modal_enable_des: 'Una vez habilitado, el marco conceptual nuevamente podrá ser seleccionado para la creación de mediciones por los clientes que lo disponen',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Una vez inhabilitado, los clientes que disponen de este marco conceptual no podrán elegir el marco conceptual para crear una medición'
}
