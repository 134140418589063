
<template>
  <div style="display:inline;">
    <v-btn large
      color="primary"
      :loading="loading"
      :disabled="loading"
      @click="getPreviousAssessments"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ $t('reports.general_report') }}
    </v-btn>

    <v-dialog persistent
      v-model="modalPreviousAssessment"
      max-width="560px"
    >
      <v-card>
        <v-card-text class="text-center grey--text text--darken-1 pt-6">
          <h2 class="mb-5 title">
            {{ $t('evaluations.prev_assesment_dialog_title') }}.
          </h2>
          <p class="mb-7" v-html="$t('evaluations.prev_assesment_dialog_description')"></p>
          <x-inputs-autocomplete light clearable
            :items="previousAssessments"
            :input="selectedPreviousAssessment"
            :label="$t('evaluations.previous_assessment')"
            name="prev_poll"
            @updateInput="($event) => selectedPreviousAssessment = $event"
          />
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0">
            <v-col cols="12" sm="6" order-sm="1" order="2">
              <v-btn block large
                :disabled="selectedPreviousAssessment"
                @click="showModalConfirm = true"
              >
                {{ $t('input.skip') }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" order-sm="2" order="1">
              <v-btn block large
                class="white--text"
                color="primary"
                :disabled="!selectedPreviousAssessment"
                @click="showModalConfirm = true"
              >
                {{ $t('input.continue') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <x-confirm-spend-dialog
      :confirmText="$t('demographicReport.confirm_report_title')"
      :costText="$t('demographicReport.report_cost')"
      :showModalConfirm="showModalConfirm && !updatingBalance"
      :balance="balance"
      :price="price"
      :enoughBalance="enoughBalance"
      :noActiveEmployee="false"
      :operationsLink="operationsLink"
      :noBalanceResponse="noBalanceResponse"
      :noBalanceMsg="noBalanceMsg()"
      :balanceAfterMsg="$t('demographicReport.balance_after')"
      :confirmBtn="$t('demographicReport.confirm_btn')"
      @result="verifySpend"
    ></x-confirm-spend-dialog>
  </div>
</template>

<script>

import { mapState } from 'vuex'

import evaluationService from '../../services/evaluations'
import balanceService from '../../services/token-account-detail'
import servicePriceService from '../../services/product-services'

export default {
  name: 'thread-demographic-report',
  props: {
    cutsSelected: Object,
    pollId: String
  },
  data () {
    return {
      modalPreviousAssessment: false,
      previousAssessments: [],
      selectedPreviousAssessment: null,
      loading: false,
      showModalConfirm: false,
      updatingBalance: false,
      balance: 0,
      price: 0,
      enoughBalance: false,
      operationsLink: '',
      noBalanceResponse: false
    }
  },
  created () {
    this.getBalance()
    this.$store.dispatch('session/getSuiteWebHost')
      .then((res) => {
        if (res) {
          this.operationsLink = `${res}/tokens/operations`
        } else {
          next('/404')
        }
      })
  },
  mounted () {
    //
  },
  watch: {
    showModalConfirm (val) {
      if (val) {
        this.modalPreviousAssessment = false
      } else {
        this.selectedPreviousAssessment = null
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    runOpenReport () {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.cutsSelected))
      if (this.selectedPreviousAssessment) {
        data.selectedPreviousRef = this.selectedPreviousAssessment
      }
      return evaluationService.getDemographicReport(this.pollId, data)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('demographicReport.operation_init'))
          this.showModalConfirm = false
          setTimeout(this.refreshPage, 3000)
        })
        .catch((err) => {
          if (err.code === 'suite-fail/evaluation/spend-fail') {
            this.noBalanceResponse = true
            this.disableButtonModal = true
            this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          this.loading = false
        })
    },
    noBalanceMsg () {
      if (this.user.customer.type === 'personal') {
        return this.$t('errors.no_balance')
      } else {
        return this.$t('errors.no_balance_msg')
      }
    },
    verifySpend ($event) {
      return $event ? this.runOpenReport() : (this.showModalConfirm = false)
    },
    getBalance () {
      this.updatingBalance = true
      return evaluationService.getBalance('by_demographic')
        .then((res) => {
          this.balance = res.balance
          this.price = res.productService
          this.$store.dispatch('loading/hide')
          this.enoughBalance = this.balance > this.price
          this.updatingBalance = false
        })
    },
    getPreviousAssessments () {
      if (this.previousAssessments.length > 0) {
        this.modalPreviousAssessment = true
      } else {
        return evaluationService.getAssessmentsPreviousToCurrent(this.pollId)
          .then((res) => {
            if (res.length) {
              this.previousAssessments = res.map(ev => {
                return {
                  text: ev.name,
                  value: ev._id
                }
              })
              this.modalPreviousAssessment = true
            } else {
              this.showModalConfirm = true
            }
          })
          .catch((err) => {
            console.log(err)
            this.showModalConfirm = true
          })
      }
    },
    refreshPage () {
      window.location.reload()
    }
  }
}
</script>
