
import Services from './base-services'

const service = new Services('headquarters')

export default {
  listFromSuite: () => {
    return service.suiteOperation(() => service.get('fetch-by-enterprise'))
  },
  listByCountriesFromSuite: (countries:Array<number>) => {
    return service.suiteOperation(() => service.get(`list?country=${countries}`))
  }
}
