import PdfUtil from '../utils/pdf'

export default {
  data () {
    return {
      // chrs: {
      //   gral: 'Es creador de futuro.',
      //   spec: 'Acompaño con determinación las iniciativas de cambio de la Organización y desarrollo con mi equipo las habilidades y rutinas para ser exitosos en un entorno ágil y digital.'
      // },
      trendsTblWidths: ['34%', '50%', '5%', '5%', '6%'],
      maxTrendChars: {
        gral: 121,
        spec: 178
      }
    }
  },
  methods: {
    limitTrendText (str, type) {
      // str = this.chrs[type]
      return str.length > this.maxTrendChars[type] ? str.slice(0, this.maxTrendChars[type]) + '...' : str
    },
    $generateTrends () {
      const higherPositive = []
      const higherNegative = []
      const getName = (general, specific) => {
        return !general ? '' : specific ? this.questionnaire.general[general].specific[specific][this.user.lang] : this.questionnaire.general[general].name[this.user.lang]
      }

      const textColor = '#6d7a7a'
      for (let i = 0; i < 4; i++) {
        const positiveTrendsName = getName(this.positiveTrends[i].general)
        const negativeTrendsName = getName(this.negativeTrends[i].general)
        const positiveTrendsNameSpecific = getName(this.positiveTrends[i].general, this.positiveTrends[i].specific)
        const negativeTrendsNameSpecific = getName(this.negativeTrends[i].general, this.negativeTrends[i].specific)

        higherPositive.push([
          // Higher score
          { text: `${positiveTrendsName ? this.limitTrendText(positiveTrendsName, 'gral') : '--'}`, margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
          { text: `${positiveTrendsName ? this.limitTrendText(positiveTrendsNameSpecific, 'spec') : '--'}`, margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
          { text: `${positiveTrendsName ? this.round(this.positiveTrends[i].score, 2) : '--'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: `${positiveTrendsName && this.positiveTrends[i].previous ? this.round(this.positiveTrends[i].previous, 2) : '--'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: `${positiveTrendsName ? this.round(this.positiveTrends[i].trend, 2) : '--'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
        higherNegative.push([
          { text: `${negativeTrendsName ? this.limitTrendText(negativeTrendsName, 'gral') : '--'}`, margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
          { text: `${negativeTrendsName ? this.limitTrendText(negativeTrendsNameSpecific, 'spec') : '--'}`, margin: [0, 1, 0, 0], border: [true, true, true, true], bold: true, style: { color: textColor } },
          { text: `${negativeTrendsName ? this.round(this.negativeTrends[i].score, 2) : '--'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: `${negativeTrendsName && this.negativeTrends[i].previous ? this.round(this.negativeTrends[i].previous, 2) : '--'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: `${negativeTrendsName ? this.round(this.negativeTrends[i].trend, 2) : '--'}`, alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
      }
      return [
        // PAGE ONE (Positive Trends)
        PdfUtil.generateTitleLandscape(this.$t('individualReport.trend_ranking'), 'before', true, true),
        {
          text: this.$t('individualReport.trends_report[1]'),
          alignment: 'justify',
          margin: [0, 0, 16, 12],
          color: textColor,
          lineHeight: 1.5,
          fontSize: 10
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: this.trendsTblWidths,
            body: [
              [
                { text: this.$t('individualReport.higher_positives_trends').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {}
              ],
              [
                { text: this.gralResponsibilityTitle, margin: [0, 9, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.specResponsibilityTitle, margin: [0, 9, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.actual_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.previous_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...higherPositive
            ]
          },
          margin: [-6, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        // PAGE TWO (Negative Trends)
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: this.trendsTblWidths,
            body: [
              [
                { text: this.$t('individualReport.higher_negatives_trends').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {}
              ],
              [
                { text: this.gralResponsibilityTitle, margin: [0, 9, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.specResponsibilityTitle, margin: [0, 9, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.actual_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.previous_score'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.trend'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...higherNegative
            ]
          },
          margin: [-5, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        (this.hasPrevious ? {} : {
          columns: PdfUtil.generateCenteredText(this.$t('individualReport.trend_msg'), 15, false),
          margin: [0, 10, 0, 0]
        })
      ]
    }
  }
}
