
import dataObj from '../utils_old/data-obj'
import PdfUtil from '../utils/pdf'

export default {
  data () {
    return {
      // charTest: {
      //   gral: 'wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww',
      //   spec: 'wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww'
      // },
      maxGralAndSpecRespChars: {
        gral: 103,
        spec: 193
      },
      tblsWidths: ['30%', '56.5%', '2.5%', '2.5%', '2.5%', '2.5%', '2.5%']
    }
  },
  methods: {
    limitTableText (type, general, specific) {
      if (!general) {
        return '--'
      }
      let str = ''
      // let str = this.charTest[type]
      if (specific && general) {
        str = this.questionnaire.general[general].specific[specific][this.user.lang]
      } else {
        str = this.questionnaire.general[general].name[this.user.lang]
      }
      return str.length > this.maxGralAndSpecRespChars[type] ? str.slice(0, this.maxGralAndSpecRespChars[type]) + '...' : str
    },
    $generateLiderazgoResults () {
      const higherScores = []
      const lowerScores = []

      const noZero = (n) => (!n ? '--' : this.round(n, 2, '--'))
      let othersScore = '--'
      let autoScore = '--'
      let leadersScore = '--'
      let pairsScore = '--'
      let dependentsScore = '--'
      for (let i = 0; i < 6; i++) {
        const rankingH = this.highestScoreSpecificEvaluatorsItems[i]
        if (rankingH.general && rankingH.specific) {
          othersScore = noZero(rankingH.score, '--')
          autoScore = noZero(rankingH.second, '--')
          leadersScore = noZero(this.answersResponsibility.general[rankingH.general].specific[rankingH.specific].filtered.relations.leadersEvaluators.score, '--')
          pairsScore = noZero(this.answersResponsibility.general[rankingH.general].specific[rankingH.specific].filtered.relations.pairsEvaluators.score, '--')
          dependentsScore = noZero(this.answersResponsibility.general[rankingH.general].specific[rankingH.specific].filtered.relations.dependentsEvaluators.score, '--')
        } else {
          othersScore = '--'
          autoScore = '--'
          leadersScore = '--'
          pairsScore = '--'
          dependentsScore = '--'
        }

        higherScores.push([
          { text: this.limitTableText('gral', rankingH.general), color: '#6d7a7a', margin: [-1, 0, -1, 0], border: [true, true, true, true] },
          { text: this.limitTableText('spec', rankingH.general, rankingH.specific), color: '#6d7a7a', margin: [-1, 0, -1, 0], border: [true, true, true, true] },
          { text: othersScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: autoScore,
            color: '#6d7a7a',
            margin: [0, 4, 0, 0],
            border: [true, true, true, true],
            alignment: 'center'
          },
          { text: leadersScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: pairsScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: dependentsScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
        ])

        const rankingL = this.lowestScoreSpecificEvaluatorsItems[i]
        if (rankingL.general && rankingL.specific) {
          othersScore = noZero(rankingL.score, '--')
          autoScore = noZero(rankingL.second, '--')
          leadersScore = noZero(this.answersResponsibility.general[rankingL.general].specific[rankingL.specific].filtered.relations.leadersEvaluators.score, '--')
          pairsScore = noZero(this.answersResponsibility.general[rankingL.general].specific[rankingL.specific].filtered.relations.pairsEvaluators.score, '--')
          dependentsScore = noZero(this.answersResponsibility.general[rankingL.general].specific[rankingL.specific].filtered.relations.dependentsEvaluators.score, '--')
        } else {
          othersScore = '--'
          autoScore = '--'
          leadersScore = '--'
          pairsScore = '--'
          dependentsScore = '--'
        }
        lowerScores.push([
          { text: this.limitTableText('gral', rankingL.general), color: '#6d7a7a', margin: [-1, 0, -1, 0], border: [true, true, true, true] },
          { text: this.limitTableText('spec', rankingL.general, rankingL.specific), color: '#6d7a7a', margin: [-1, 0, -1, 0], border: [true, true, true, true] },
          { text: othersScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: autoScore,
            color: '#6d7a7a',
            margin: [0, 4, 0, 0],
            border: [true, true, true, true],
            alignment: 'center'
          },
          { text: leadersScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: pairsScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' },
          { text: dependentsScore, color: '#6d7a7a', margin: [0, 4, 0, 0], border: [true, true, true, true], alignment: 'center' }
        ])
      }

      return [
        PdfUtil.generateTitleLandscape(this.$t('individualReport.results_liderazgo'), 'before', true),
        {
          text: `${this.$t('individualReport.results_text')}`,
          alignment: 'justify',
          margin: [0, -4, 20, 0],
          color: '#6d7a7a',
          lineHeight: 1.5,
          fontSize: 9
        },
        {
          columns: [
            {
              layout: {
                defaultBorder: '',
                border: [false, false, false, true],
                fillColor: function (rowIndex, node, columnIndex) {
                  return (rowIndex === 0) ? '#BFBFBF' : null
                }
              },
              table: {
                headerRows: 1,
                body: [
                  [
                    { text: `${this.$t('individualReport.others')}*`, bold: true, color: '#fff', margin: [0, 0, 0, 0], border: [true, true, true, true] },
                    { text: `${this.$t('individualReport.autoevaluation')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, 0] },
                    { text: `${this.$t('individualReport.leaders')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, 0] },
                    { text: `${this.$t('individualReport.pairs')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, 0] },
                    { text: `${this.$t('individualReport.dependents')}`, bold: true, color: '#fff', border: [true, true, true, true], margin: [0, 0, 0, 0] }
                  ],
                  [
                    { text: `O`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' },
                    { text: `A`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' },
                    { text: `L`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' },
                    { text: `P`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' },
                    { text: `C`, bold: true, color: '#6d7a7a', border: [true, true, true, true], margin: [0, 0, 0, 0], alignment: 'center' }
                  ]
                ]
              },
              margin: [80, 0, 0, 10],
              border: [false, false, false, true],
              alignment: 'center',
              fontSize: 10
            },
            {
              text: `${this.$t('individualReport.results_note')}`,
              alignment: 'center',
              margin: [30, 4, 30, 0],
              color: '#6d7a7a',
              lineHeight: 1.5,
              fontSize: 8
            }
          ]
        },
        // SubTitle 6.1 - Higher scores
        PdfUtil.generateSubTitle(this.$t('individualReport.higher_scores_title'), '', true, 716),
        {
          text: `${this.$t('individualReport.higher_scores[1]')}`,
          alignment: 'justify',
          margin: [0, -12, 14, 10],
          color: '#6d7a7a',
          lineHeight: 1.5,
          fontSize: 8
        },
        {
          table: {
            widths: [725], // 479 Portrait
            body: [
              [{ text: `${this.$t('individualReport.table')} 2. ${this.$t('individualReport.table1')}`, bold: true, color: '#fff', margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, -5, 100, 20]
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 2,
            widths: this.tblsWidths,
            body: [
              [
                { text: this.gralResponsibilityTitle, bold: true, color: '#fff', margin: [0, 7, 0, 0], border: [true, true, true, true], fontSize: 11, rowSpan: 2 },
                { text: this.specResponsibilityTitle, bold: true, color: '#fff', margin: [0, 7, 0, 0], border: [true, true, true, true], fontSize: 11, rowSpan: 2 },
                { text: this.$t('individualReport.scores'), bold: true, color: '#fff', margin: [0, -1, 0, -2], border: [true, true, true, true], fontSize: 11, colSpan: 5 },
                {},
                {},
                {},
                {}
              ],
              [
                {},
                {},
                { text: `O`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' },
                { text: `A`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' },
                { text: `L`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' },
                { text: `P`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' },
                { text: `C`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' }
              ],
              ...higherScores
            ]
          },
          margin: [-5, -10, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        /* Remove by OCC client's request
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: [300],
            body: [
              [
                { text: `${this.$t('individualReport.notes')}:`, bold: true, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [false, false, false, true] }
              ]
            ]
          },
          margin: [30, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'left',
          fontSize: 10
        },
        */
        // SubTitle 6.2 - Lower scores
        PdfUtil.generateSubTitle(this.$t('individualReport.lower_scores_title'), 'before', true, 716),
        {
          text: `${this.$t('individualReport.lower_scores[1]')}`,
          alignment: 'justify',
          margin: [0, -9, 14, 10],
          color: '#6d7a7a',
          lineHeight: 1.5,
          fontSize: 8
        },
        {
          table: {
            widths: [725], // 479 Portrait
            body: [
              [{ text: `${this.$t('individualReport.table')} 3. ${this.$t('individualReport.table2')}`, bold: true, color: '#fff', margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [35, -2, 100, 20]
        },
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true],
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            }
          },
          table: {
            headerRows: 2,
            widths: this.tblsWidths,
            body: [
              [
                { text: this.gralResponsibilityTitle, bold: true, color: '#fff', margin: [0, 7, 0, 0], border: [true, true, true, true], fontSize: 11, rowSpan: 2 },
                { text: this.specResponsibilityTitle, bold: true, color: '#fff', margin: [0, 7, 0, 0], border: [true, true, true, true], fontSize: 11, rowSpan: 2 },
                { text: this.$t('individualReport.scores'), bold: true, color: '#fff', margin: [0, -1, 0, -2], border: [true, true, true, true], fontSize: 11, colSpan: 5 },
                {},
                {},
                {},
                {}
              ],
              [
                {},
                {},
                { text: `O`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' },
                { text: `A`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' },
                { text: `L`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' },
                { text: `P`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' },
                { text: `C`, bold: true, color: '#fff', margin: [0, 0, 0, -2], border: [true, true, true, true], alignment: 'center' }
              ],
              ...lowerScores
            ]
          },
          margin: [-5, -8, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
        /* Remove by OCC client's request
        {
          layout: {
            defaultBorder: '',
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: [300],
            body: [
              [
                { text: `${this.$t('individualReport.notes')}:`, bold: true, color: '#6d7a7a', margin: [0, 0, 0, 0], border: [false, false, false, true] }
              ]
            ]
          },
          margin: [30, 0, 15, 10],
          border: [false, false, false, true],
          alignment: 'left',
          fontSize: 10
        }
        */
      ]
    }
  }
}
