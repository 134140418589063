
import mapParameters from '../utils/map-parameters'
import Services from './base-services'

const service = new Services('evaluations')

interface IOptionsList {
  page?: number;
  itemsPerPage?: number;
  filter?: string;
  search?: string;
}

export default {
  list: (options: IOptionsList) => {
    const params = mapParameters({
      page: options && options.page ? options.page : null,
      rowsPerPage: options && options.itemsPerPage ? options.itemsPerPage : null,
      filter: options && options.filter ? options.filter : null,
      search: options && options.search ? options.search : null
    })
    return service.get('list', params)
  },
  create: (evaluation: any) => {
    return service.post('create', { evaluation })
  },
  sendInvitationFiles: (id: string, file: object) => {
    return service.form(`upload-invitation-file/${id}`, file)
  },
  sendReminderFiles: (id: string, file: object) => {
    return service.form(`upload-reminder-file/${id}`, file)
  },
  getOne: (slug: string) => {
    return service.get(`get-one/${slug}`)
  },
  getOneToEdit: (slug: string) => {
    return service.get(`get-one-to-edit/${slug}`)
  },
  getOneById: (id: string) => {
    return service.get(`get-one-by-id/${id}`)
  },
  getPreviousByEvaluated (id: string, evaluatedId: number) {
    return service.get(`get-previous/${id}/${evaluatedId}`)
  },
  getAssessmentsPreviousToCurrent (id: string) {
    return service.get(`get-assessments-prior-to/${id}`)
  },
  findByTokenId: (tokenId: string) => {
    return service.get(`find-by-token-id/${tokenId}`)
  },
  findById: (id: string) => {
    return service.get(`find-by-id/${id}`)
  },
  updateModel: (id: string) => {
    return service.post(`update-model/${id}`)
  },
  findByIdToReport: (id: string) => {
    return service.get(`find-by-id-to-report/${id}`)
  },
  getOrganizationalReport: (id: string, selectedPreviousRef?: string) => {
    return service.post(`organizational-report/${id}`, { selectedPreviousRef })
  },
  openReportIndividualOneById: (id: number, evaluatedId: number) => {
    return service.post(`open-report-individual/${id}`, { evaluatedId })
  },
  getDemographicReport: (id: string, data: object) => {
    return service.post(`demographic-report/${id}`, data)
  },
  currentThreadsById: (id: number) => {
    return service.get(`current-threads/${id}`)
  },
  getOneReportByThreadId: (id: number, pollId: number) => {
    return service.post(`open-thread-report/${id}`, { id: pollId })
  },
  edit: (slug: string, evaluation: any) => {
    return service.post(`edit/${slug}`, { evaluation })
  },
  massiveUpload: (file: File) => {
    return service.form('massive-upload', { file: file })
  },
  generateTemplate: () => {
    return service.get('generate-template')
  },
  generateTemplateUsers: (data: Array<object>) => {
    return service.post(`generate-template-users`, data)
  },
  updateAutoEvaluation: (tokenId: string, data: object) => {
    return service.put(`update-auto-evaluation`, { tokenId: tokenId, data: data })
  },
  updateEvaluator: (tokenId: string, data: object, type: string, index: string, evaluatedIndex: string) => {
    return service.put(`update-evaluator`, { tokenId: tokenId, data: data, type: type, index: index, evaluatedIndex: evaluatedIndex })
  },
  updateAutoOpenQuestion: (tokenId: string, data: object) => {
    return service.put(`update-auto-open-question`, { tokenId: tokenId, data: data })
  },
  updateEvaluatorOpenQuestion: (tokenId: string, data: object, type: string, index: string, evaluatedIndex: string) => {
    return service.put(`update-evaluator-open-question`, { tokenId: tokenId, data: data, type: type, index: index, evaluatedIndex: evaluatedIndex })
  },
  updateEvaluationStatus: (tokenId: string, type: string, index?: string, evaluatedIndex?: string) => {
    return service.put(`update-evaluation-status`, { tokenId: tokenId, type: type, index: index, evaluatedIndex: evaluatedIndex })
  },
  sendReminders: (slug: string) => {
    return service.post('send-reminders', { slug })
  },
  closeEvaluation: (slug: string) => {
    return service.get(`close/${slug}`)
  },
  checkBalance: () => {
    return service.get('check-balance')
  },
  getBalance: (type: string) => {
    return service.get(`get-balance/${type}`)
  }
}
