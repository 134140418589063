export default {
  create: 'Create conceptual framework',
  edit: 'Edit conceptual framework',
  base: 'Base conceptual framework',
  no_data: 'There are no conceptual frameworks to be shown',
  autoEvaluation: 'Self-Assessment',
  generalEvaluation: '360°',
  as_person: 'As a person',
  management: 'Development',
  mobilization: 'Mobilization',
  achievement: 'Achievement',
  tooltip_edit: 'You must press ENTER to edit',
  edit_conceptual_framework: 'Edit conceptual framework',
  settings: 'Conceptual framework settings',
  name: 'Name',
  last_edition: 'Last edition',
  status: 'Status',
  actions: 'Actions',
  customer: 'Client',
  enterprise: 'Company',
  sector: 'Industry',
  created_succesfully: 'Conceptual framework successfully created ',
  error_on_creation: 'Error creating conceptual framework',
  asignation_type: 'Assignment Type',
  assign_to: 'Assign to',
  edited_succesfully: 'Conceptual framework successfully edited ',
  error_editing: 'Error editing conceptual framework',
  responsability: 'Responsibility',
  question: 'Question',
  add_new_text_area: 'Add new text area',
  max_length_for_this_topic: 'Characters recommended  for the content of this topic ',
  max_length_validation_error: 'You have exceeded the maximum number of characters allowed',
  max_length_warning: 'You have exceeded the recommended limit number of characters per page. The result might not be what you expected',
  modal_enable: 'Do you want to enable the conceptual framework?',
  modal_disable: 'Do you want to disable the conceptual framework?',
  modal_enable_des: 'Once enabled, the conceptual framework can be selected again to create assessments by the clients who have it.',
  // tslint:disable-next-line:max-line-length,
  modal_disable_des: 'Once disabled, clients who have this conceptual framework will not be able to choose the conceptual framework to create an assessment'
}
