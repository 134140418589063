
<template>
  <div class="d-inline">
    <v-btn dark small
      class="white--text mr-4"
      color="primary"
      @click="generatePDF(item)"
    >
      {{ $t('input.download') }}
      <span v-if="$vuetify.breakpoint.mdAndUp" class="d-inline-block ml-2">
        {{ $t('navigation.conceptual_framework') }}
      </span>
      <v-icon dark right small>mdi-file-pdf</v-icon>
    </v-btn>
    <img
      src="../../../../public/img/20210423_x_occ_l_logo.png"
      style="visibility:hidden;"
      id="occLiderazgo360Logo"
      width="0"
      height="0"
      alt=""
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import is from 'is_js'
import conceptualFrameworkAssemble from '@/views/reports/thread_mixins_individual/03-conceptual-framework'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default Vue.extend({
  name: 'download-conceptual-framework',
  mixins: [
    conceptualFrameworkAssemble
  ],
  props: {
    item: [Object, Array]
  },
  data () {
    return {
      liderazgoLogoSrc: null,
      pulseLogoBase64: null,
      maxTitleLineLength: 48,
      maxBodyLineLength: 80,
      maxLinesPerPage: 40
    }
  },
  mounted () {
    this.liderazgoLogoSrc = document.getElementById('occLiderazgo360Logo').src
  },
  watch: {
    liderazgoLogoSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.liderazgoLogoSrc, (dataURL) => {
          this.pulseLogoBase64 = dataURL
        })
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    writeRotatedText (text) {
      const canvas = document.createElement('canvas')
      canvas.width = 40
      canvas.height = 842

      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = '#BFBFBF'
      ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(40, 690)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '17pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    generatePDF (conceptualFramework) {
      this.$store.dispatch('loading/show')
      const configuration = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [40, 60, 50, 50],
        info: {
          title: this.$t('help.evaluation.create.conceptualFramework.title'),
          author: 'OCC',
          subject: this.$t('help.evaluation.create.conceptualFramework.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: () => {
          return [{
            image: this.pulseLogoBase64,
            height: 45,
            width: 117,
            margin: [15, 4, 25, 15]
          }]
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  margin: [0, 10, 0, 0],
                  width: 'auto',
                  text: this.$t('individualReport.copyright'),
                  fontSize: 10
                },
                { width: '*', text: '' }
              ]
            }
          ]
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(this.$t('navigation.conceptual_framework')),
            aligment: 'center',
            absolutePosition: { x: 810, y: 0 }
          }

          return result
        },
        content: [
          this.$generateConceptualFramework(conceptualFramework.detail, false)
        ]
      }

      let fileName = this.$t('help.evaluation.create.conceptualFramework.title')
      if (is.edge() || is.ie()) {
        fileName += '.pdf'
        setTimeout(() => {
          const pdfDocGenerator = pdfMake.createPdf(configuration)
          pdfDocGenerator.getBlob((blob) => {
            window.navigator.msSaveBlob(blob, fileName)
            this.$store.dispatch('loading/hide')
          })
        }, 400)
      } else {
        setTimeout(() => {
          pdfmake.createPdf(configuration).download(fileName)
          this.$store.dispatch('loading/hide')
        }, 400)
      }
    }
    /* async getPdf (conceptualFramework) {
      this.$store.dispatch('loading/show')
      let details = []
      let objContent = {}
      let line = 0
      let pageBreak = ''
      conceptualFramework.detail.forEach((detail, index) => {
        line += Math.ceil(detail.title.length / this.maxTitleLineLength) * 2
        // Title
        details.push({
          pageBreak: index === 0 ? '' : 'before',
          text: detail.title.toUpperCase()
          // margin: index > 0 ? [0, 15] : []
        })
        details.push(detail.body.map((body) => {
          return {
            text: body,
            fontSize: 12,
            margin: [0, 8, 25, 6],
            alignment: 'justify'
          }
        }))
        // line += Math.ceil(detail.title.length / this.maxTitleLineLength) * 2
        // if (line >= this.maxLinesPerPage) {
        //   line = 2
        // }
        // if (line === 2 && index) {
        //   pageBreak = 'before'
        // }
        // details.push({
        //   text: detail.title.toUpperCase(),
        //   bold: true,
        //   fontSize: 15,
        //   pageBreak: pageBreak,
        //   margin: [0, 14, 0, 7]
        // })
        // if (pageBreak) {
            console.log(this.conceptual_framework)
  //   pageBreak = ''
        // }

        // Body
        // detail.body.forEach((body) => {
        //   line += Math.ceil(body.length / this.maxBodyLineLength)
        //   if (line > this.maxLinesPerPage) {
        //     pageBreak = 'before'
        //   }
        //   details.push({
        //     text: body/!* + ' ' + line *!/,
        //     bold: false,
        //     fontSize: 12,
        //     alignment: 'justify',
        //     pageBreak: pageBreak,
        //     margin: pageBreak ? [0, 8, 25, 6] : [0, 0, 25, 6]
        //   })
        //   if (line >= this.maxLinesPerPage) {
        //     line = 0
        //     pageBreak = ''
        //   }
        // })
      })

      const configuration = {
        pageSize: 'A4',
        pageMargins: [40, 55, 50, 40],
        info: {
          title: this.$t('evaluations.title'),
          author: 'OCC',
          subject: this.$t('evaluations.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: () => {
          return [{
            image: this.pulseLogoBase64,
            height: 45,
            width: 117,
            margin: [15, 4, 25, 15]
          }]
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  width: 'auto',
                  text: this.$t('evaluations.copyright'),
                  color: 'grey',
                  fontSize: 10,
                  margin: [0, 10, 0, 0]
                },
                { width: '*', text: '' }
              ]
            }
          ]
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(conceptualFramework.name),
            aligment: 'center',
            absolutePosition: { x: 545, y: 0 }
          }

          return result
        },
        content: [
          // Título
          {
            text: conceptualFramework.name,
            fontSize: 20,
            margin: [0, 0, 0, 10]
          },
          // Marco Conceptual
          ...details
        ]
      }

      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, 'conceptual-framework.pdf')
          this.$store.dispatch('loading/hide')
        })
      } else {
        pdfmake.createPdf(configuration).download('conceptual-framework')
        this.$store.dispatch('loading/hide')
      }
    } */
  }
})
</script>
