
<template>
  <div class="d-inline">
    <v-btn dark small
      class="white--text mr-4"
      color="primary"
      @click="getPdf(item)"
    >
      {{ $t('input.download') }}
      <span v-if="$vuetify.breakpoint.mdAndUp" class="d-inline-block ml-2">
        {{ $t('evaluations.questionnaire') }}
      </span>
      <v-icon dark right small>mdi-file-pdf</v-icon>
    </v-btn>
    <img
      src="../../../../public/img/20210423_x_occ_l_logo.png"
      style="visibility:hidden;"
      id="occLiderazgo360Logo"
      width="0"
      height="0"
      alt=""
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import is from 'is_js'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')

pdfmake.vfs = pdffonts.pdfMake.vfs

export default Vue.extend({
  props: {
    item: [Object, Array]
  },
  data () {
    return {
      liderazgoLogoSrc: null,
      pulseLogoBase64: null
    }
  },
  mounted () {
    this.liderazgoLogoSrc = document.getElementById('occLiderazgo360Logo').src
  },
  watch: {
    liderazgoLogoSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.liderazgoLogoSrc, (dataURL) => {
          this.pulseLogoBase64 = dataURL
        })
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    writeRotatedText (text) {
      const canvas = document.createElement('canvas')
      canvas.width = 50
      canvas.height = 845

      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = '#2196F3'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '20pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    async getPdf (questionnaire) {
      let documentOpenQuestion = await this.constructOpenQuestions(questionnaire)
      this.$store.dispatch('loading/show')
      let details = []
      questionnaire.evaluations.autoEvaluation.forEach((general, index) => {
        details.push({
          text: general.name[this.user.lang],
          bold: true,
          fontSize: 15
          // margin: index === 2 ? [0, 5, 0, 0] : []
        })
        const questions = []
        general.specifics.forEach((specific) => {
          questions.push(specific[this.user.lang])
        })
        details.push({ ul: questions, margin: [15, 0, 15, 15] })
      })

      details.push({
        pageBreak: 'before',
        margin: [10, 0, 10, 10],
        fontSize: 15,
        bold: true,
        text: this.$t('questionnaires.openQuestions')
      })

      documentOpenQuestion.forEach((item) => {
        details.push(item)
      })

      const configuration = {
        pageSize: 'A4',
        pageMargins: [40, 50, 50, 40],
        info: {
          title: this.$t('evaluations.title'),
          author: 'OCC',
          subject: this.$t('evaluations.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: () => {
          return [{
            image: this.pulseLogoBase64,
            height: 45,
            width: 117,
            margin: [15, 4, 25, 15]
          }]
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  width: 'auto',
                  text: this.$t('evaluations.copyright'),
                  color: 'grey',
                  fontSize: 10,
                  margin: [0, 10, 0, 0]
                },
                { width: '*', text: '' }
              ]
            }
          ]
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(questionnaire.name),
            aligment: 'center',
            absolutePosition: { x: 545, y: 0 }
          }

          return result
        },
        content: [
          // Título
          {
            text: this.$t('evaluations.questionnaire_e'),
            fontSize: 20,
            margin: [0, 0, 0, 10]
          },
          // Explicación
          {
            text: this.$t('evaluations.pdf_explained', { name: questionnaire.name }),
            alignment: 'justify',
            margin: [0, 0, 20, 10]
          },
          // Cuestionario
          ...details
        ]
      }

      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, 'questionnaire.pdf')
          this.$store.dispatch('loading/hide')
        })
      } else {
        pdfmake.createPdf(configuration).download('questionnaire')
        this.$store.dispatch('loading/hide')
      }
    },
    async constructOpenQuestions (questionnaire) {
      let arr = [this.$t('questions.general'), this.$t('questions.auto')]
      let arrText = []
      let objText = {}
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index]
        objText = {
          text: element,
          fontSize: 15,
          margin: 10
        }
        arrText.push(objText)
        questionnaire.evaluations.openQuestions.forEach((dimension) => {
          switch (element) {
            case this.$t('questions.general'):
              arrText.push({ ul: [{ text: dimension.general[this.user.lang], margin: [15, 0, 0, 15] }] })
              break
            case this.$t('questions.auto'):
              arrText.push({ ul: [{ text: dimension.auto[this.user.lang], margin: [15, 0, 0, 15] }] })
              break
            default:
              break
          }
        })
      }
      return arrText
    }
  }
})
</script>
