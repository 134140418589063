import PdfUtil from '../utils/pdf'

export default {
  data () {
    return {
      dispTblWidths: ['34%', '52%', '6%', '4%', '4%'],
      maxDispersionChars: {
        gral: 121,
        spec: 181
      }
    }
  },
  methods: {
    limitTextDispersion (str, type) {
      // str = this.chrs[type]
      return str.length > this.maxDispersionChars[type] ? str.slice(0, this.maxDispersionChars[type]) + '...' : str
    },
    $generateDispersion () {
      const higherDispersion = []
      const lowerDispersion = []
      const getName = (general, specific) => {
        return !general ? '' : specific ? this.questionnaire.general[general].specific[specific][this.user.lang] : this.questionnaire.general[general].name[this.user.lang]
      }
      const isValidValue = (num) => {
        return !isNaN(num) && (num * 1 >= 1 && num * 1 <= 6)
      }
      const textColor = '#6d7a7a'
      for (let i = 0; i < 4; i++) {
        const highestScoreScatterSpecificName = getName(this.highestScoreScatterSpecific[i].general)
        const lowestScoreScatterSpecificName = getName(this.lowestScoreScatterSpecific[i].general)
        const highestScoreScatterSpecificNameSpecific = getName(this.highestScoreScatterSpecific[i].general, this.highestScoreScatterSpecific[i].specific)
        const lowestScoreScatterSpecificNameSpecific = getName(this.lowestScoreScatterSpecific[i].general, this.lowestScoreScatterSpecific[i].specific)
        const validHighestScore = isValidValue(this.highestScoreScatterSpecific[i].score)
        const validHighestSecond = isValidValue(this.highestScoreScatterSpecific[i].second)
        const validLowestScore = isValidValue(this.lowestScoreScatterSpecific[i].score)
        const validLowestSecond = isValidValue(this.lowestScoreScatterSpecific[i].second)

        higherDispersion.push([
          // Higher score
          { text: highestScoreScatterSpecificName ? this.limitTextDispersion(highestScoreScatterSpecificName, 'gral') : '--', margin: [-1, 0, -1, 0], border: [true, true, true, true], style: { color: textColor } },
          { text: highestScoreScatterSpecificName ? this.limitTextDispersion(highestScoreScatterSpecificNameSpecific, 'spec') : '--', margin: [-1, 0, -1, 0], border: [true, true, true, true], style: { color: textColor } },
          { text: highestScoreScatterSpecificName ? this.round(this.highestScoreScatterSpecific[i].scatter, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: highestScoreScatterSpecificName && validHighestScore ? this.round(this.highestScoreScatterSpecific[i].score, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: highestScoreScatterSpecificName && validHighestSecond ? this.round(this.highestScoreScatterSpecific[i].second, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
        lowerDispersion.push([
          { text: lowestScoreScatterSpecificName ? this.limitTextDispersion(lowestScoreScatterSpecificName, 'gral') : '--', margin: [-1, 0, -1, 0], border: [true, true, true, true], style: { color: textColor } },
          { text: lowestScoreScatterSpecificName ? this.limitTextDispersion(lowestScoreScatterSpecificNameSpecific, 'spec') : '--', margin: [-1, 0, -1, 0], border: [true, true, true, true], style: { color: textColor } },
          { text: lowestScoreScatterSpecificName ? this.round(this.lowestScoreScatterSpecific[i].scatter, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: lowestScoreScatterSpecificName && validLowestScore ? this.round(this.lowestScoreScatterSpecific[i].score, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } },
          { text: lowestScoreScatterSpecificName && validLowestSecond ? this.round(this.lowestScoreScatterSpecific[i].second, 2) : '--', alignment: 'center', margin: [0, 5, 0, 0], border: [true, true, true, true], bold: false, style: { color: textColor } }
        ])
      }

      return [
        // PAGE ONE (Higher)
        PdfUtil.generateTitleLandscape(this.$t('individualReport.dispersion_analysis[0]'), 'before', true, true),
        {
          text: this.$t('individualReport.dispersion_analysis[1]'),
          alignment: 'justify',
          margin: [0, -2, 16, 12],
          color: textColor,
          lineHeight: 1.5,
          fontSize: 10
        },
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: this.dispTblWidths,
            body: [
              [
                { text: this.$t('individualReport.higher_dispersion').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {}
              ],
              // Header
              [
                { text: this.gralResponsibilityTitle, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.specResponsibilityTitle, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.dispersion'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...higherDispersion
            ]
          },
          margin: [-6, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        },
        // PAGE TWO (Lower)
        {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex, node, columnIndex) {
              return (rowIndex <= 1) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: this.dispTblWidths,
            body: [
              [
                { text: this.$t('individualReport.lower_dispersion').toUpperCase(), colSpan: 5, margin: [0, 3, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                {},
                {},
                {},
                {}
              ],
              // Header
              [
                { text: this.gralResponsibilityTitle, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.specResponsibilityTitle, margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.dispersion'), margin: [-3, 5, -3, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.auto'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } },
                { text: this.$t('individualReport.others'), margin: [0, 5, 0, 0], border: [true, true, true, true], bold: true, style: { color: '#fff' } }
              ],
              // Body
              ...lowerDispersion
            ]
          },
          margin: [-6, 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 8
        }
      ]
    }
  }
}
