import PdfUtil from '../utils/pdf'

export default {
  data () {
    return {
      minChars: 2,
      maxChars: 150
    }
  },
  methods: {
    cleanStr (str) {
      str = str.trim().replace(/\s{2,}/g, '')
      return str.length > this.maxChars ? str.slice(0, this.maxChars) + '...' : str
    },
    $generateOpenQuestions (tableNum) {
      const strengths = [[]]
      const opportunities = [[]]
      const tips = [[]]
      const textColor = '#6d7a7a'
      const getOpenRow = (answer) => ({ text: answer, margin: [0, 1, 0, 0], border: [true, true, true, true], fontSize: 10, bold: false, style: { color: textColor }, alignment: 'left' })
      const getOpenTable = (title, body, pageBreak, index) => {
        const tblTitle = this.evaluationOpenQs[index].general[this.user.lang]
        const table = {
          layout: {
            defaultBorder: '',
            fillColor: function (rowIndex) {
              return (rowIndex === 0) ? '#BFBFBF' : null
            },
            border: [false, false, false, true]
          },
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [
                { text: tblTitle, margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 11, bold: true, style: { color: '#fff' }, alignment: 'center' }
                // { text: this.$t(`individualReport.leader_${title}`).toUpperCase(), margin: [0, 5, 0, 0], border: [true, true, true, true], fontSize: 10, bold: true, style: { color: '#fff' }, alignment: 'center' }
              ],
              ...body
            ]
          },
          margin: [-7, pageBreak ? 15 : 0, 0, 10],
          border: [false, false, false, true],
          alignment: 'center',
          fontSize: 10
        }
        if (pageBreak) {
          table.pageBreak = 'before'
        }
        return table
      }
      let firstIdx = 0
      let secondIdx = 0
      this.openQuestionsPlusPage = 0

      for (let i = 0; i < this.openQuestions[0].length; i++) {
        const str = this.cleanStr(this.openQuestions[0][i])
        const opp = this.cleanStr(this.openQuestions[1][i])
        const tip = this.cleanStr(this.openQuestions[2][i])

        if (str.length > this.minChars) {
          strengths[firstIdx].push([getOpenRow(str)])
        }
        if (i === 17 || ((i - 17) % 22 === 0)) {
          firstIdx++
          strengths.push([])
          this.openQuestionsPlusPage++
        }
        if (opp.length > this.minChars) {
          opportunities[secondIdx].push([getOpenRow(opp)])
        }
        if (tip.length > this.minChars) {
          tips[secondIdx].push([getOpenRow(tip)])
        }
        if (i === 19 || ((i - 19) % 22 === 0)) {
          secondIdx++
          opportunities.push([])
          tips.push([])
          this.openQuestionsPlusPage += 2
        }
      }
      const strengthsTable = strengths.filter(item => item.length).map((item, idx) => getOpenTable('strengths', item, Boolean(idx), 0))
      const opportunitiesTable = opportunities.filter(item => item.length).map((item, idx) => getOpenTable('opportunities', item, Boolean(idx), 1))
      const tipsTable = tips.filter(item => item.length).map((item, idx) => getOpenTable('tips', item, Boolean(idx), 2))
      return [
        PdfUtil.generateTitleLandscape(this.$t('individualReport.open_questions_title'), 'before', true, true),
        {
          text: this.$t('individualReport.open_questions[1]'),
          alignment: 'justify',
          margin: [0, 0, 16, 12],
          color: textColor,
          lineHeight: 1.5,
          fontSize: 10
        },
        {
          table: {
            widths: [760], // 479 Portrait
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} ${tableNum}.${this.$t('individualReport.open_questions_title').replace(/\d|\./g, '')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [0, 0, 135, 10]
        },
        ...strengthsTable,
        {
          table: {
            widths: [760], // 479 Portrait
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} ${tableNum + 1}.${this.$t('individualReport.open_questions_title').replace(/\d|\./g, '')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [0, 0, 135, 10],
          pageBreak: 'before'
        },
        ...opportunitiesTable,
        {
          table: {
            widths: [760], // 479 Portrait
            body: [
              [{ text: [
                `${this.$t('individualReport.table')} ${tableNum + 2}.${this.$t('individualReport.open_questions_title').replace(/\d|\./g, '')}`
              ],
              bold: true,
              color: '#fff',
              margin: [20, 6, 0, 6] }]
            ]
          },
          layout: {
            fillColor: '#aaaaaa',
            defaultBorder: ''
          },
          alignment: 'left',
          margin: [0, 0, 135, 10],
          pageBreak: 'before'
        },
        ...tipsTable
      ]
    }
  }
}
