
export default {
  edit_committal: 'Editar preguntas de compromiso',
  edit_word: 'Editar preguntas abiertas',
  questions: 'Preguntas',
  languages: 'Idiomas',
  tooltip_edit: 'Debe presionar ENTER para editar',
  update_question: '¡Pregunta modificada exitosamente!',
  edit_full_desc: 'Editar Descripción completa',
  general: '360°',
  auto: 'Autoevaluación'
}
