export default {
  create: 'Create questionnaire',
  edit: 'Edit questionnaire',
  no_data: 'There are no questionnaires to display',
  autoEvaluation: 'Self-assessment',
  generalEvaluation: '360°',
  as_person: 'As a person',
  management: 'Development',
  mobilization: 'Mobilization',
  achievement: 'Achievement',
  tooltip_edit: 'You must press ENTER to edit',
  edit_questions: 'Edit questions',
  settings: 'Questionnaire Settings',
  name: 'Name',
  last_edition: 'Last edition',
  status: 'Status',
  actions: 'Actions',
  customer: 'Client',
  enterprise: 'Company',
  sector: 'Industry',
  created_succesfully: 'Questionnaire created successfully',
  error_on_creation: 'Error creating questionnaire',
  asignation_type: 'Assignment Type',
  assign_to: 'Assign to',
  edited_succesfully: 'Questionnaire edited correctly',
  error_editing: 'Error editing questionnaire',
  responsability: 'Responsibility',
  question: 'Question',
  openQuestions: 'Open questions',
  customization: 'Personalization',
  generalResponsibility: 'Overall responsibility',
  specificResponsibility: 'Specific responsibility',
  empty_questions: 'Some fileds are empty',
  configuration_warning: 'The number of elements that can be configured in 360° will be subject to the number set in Self-assessment',
  modal_enable: 'Do you want to enable the questionnaire?',
  modal_disable: 'Do you want to disable questionnaire?',
  modal_enable_des: 'Once enabled, the questionnaire can again be selected to create assessments by the clients who have it.',
  // tslint:disable-next-line:max-line-length
  modal_disable_des: 'Once disabled, clients who have this questionnaire will not be able to choose it to create an assessment '
}
