
export default {
  dashboard: 'Dashboards',
  enterprises: 'Companies',
  employees: 'Collaborators',
  engagements: 'Engagement',
  questionnairesEngagement: 'Engagement Questionnaire',
  questionnairesPulse: 'Pulse Questionnaire',
  engagement: 'Engagement',
  pulse: 'Pulse ',
  setting: 'Settings',
  academic_degrees: 'Level of Education',
  languages: 'Languages',
  departments: 'Areas / Departments',
  countries: 'Countries',
  demographic_cuts: 'Demographic cuts',
  enterprise_sizes: 'Company sizes',
  genders: 'Genders',
  job_types: 'Contract types',
  sectors: 'Industries',
  committalQuestions: 'Engagement questions',
  wordQuestions: 'Open questions',
  group: 'Group',
  headquarters: 'Locations',
  charges: 'Job levels',
  ages: 'Age range',
  antiquity: 'Seniority range',
  sign_out: 'Sign out',
  token: 'OCC Tokens',
  profile: 'Profile',
  go_back: 'Back',
  language: 'Language',
  calculator: 'Calculator',
  operations: 'Operations Summary',
  services: 'Prices and recommendations',
  enterprises_list: 'Companies',
  profile_list: 'Profile',
  measuring_tools: 'Tools',
  customers: 'Clients',
  terms_and_conditions: 'Terms and Conditions',
  products: 'Products',
  token_price: 'Token Price',
  questionnaires: 'Questionnaires',
  evaluations: 'Assessments',
  open_questions: 'Open questions',
  suite: 'Suite',
  conceptual_framework: 'Conceptual framework'
}
